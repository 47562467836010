/* eslint-disable no-unused-vars */

import { toast } from "../../../components/Toast";
import { API } from "../API";
import { USER_ROLE, USER_TYPE } from "../../../utils/Helper";
import { navigate } from "@reach/router";
import {
  ADMIN_NAVIGATON,
  SALES_NAVIGATION,
  TMA_NAVIGATION,
  TMA_MANAGER_NAVIGATION,
  ADVERTISER_NAVIGATION,
  TALENT_NAVIGATION,
} from "../../../utils/SidebarNav";

const getCurrentUserDetails = () => {
  const curUserDetail = localStorage.getItem("current_user_details");
  if (curUserDetail) return JSON.parse(curUserDetail);

  return null;
};

export const CurrentUser = {
  setToken: (token) => localStorage.setItem("user_auth_token", token),
  getToken: () => localStorage.getItem("user_auth_token"),
  logout: async () => {
    localStorage.removeItem("user_auth_token");
    localStorage.removeItem("current_user_details");
    toast.success("Logging out...");
    setTimeout(() => {
      window.location.href = "/signin";
    }, 500);
  },
  isLoggedIn: () => !!localStorage.getItem("user_auth_token"),
  isAdmin: () =>
    getCurrentUserDetails()?.claims?.entity?.attributes?.role === "admin",
  getDetails: () => getCurrentUserDetails(),
  isFanshipAdmin: () => {
    if (
      CurrentUser.getRole() === USER_ROLE.ADMIN &&
      CurrentUser.getType() === USER_TYPE.ADMIN_PORTAL
    ) {
      return true;
    } else return false;
  },
  getRole: () => {
    return CurrentUser.getDetails()?.claims?.entity?.attributes?.role;
  },
  getType: () => {
    return CurrentUser.getDetails()?.claims?.entity?.type;
  },
  getId: () => {
    return CurrentUser.getDetails()?.claims?.entity?.id;
  },
  setDetails: (data) => {
    localStorage.setItem("current_user_details", data);
  },
  goToProfile: () => {
    switch (CurrentUser.getType()) {
      case USER_TYPE.ADMIN_PORTAL:
        navigate(`/${CurrentUser.getRole()}/profile`);
        break;
      case USER_TYPE.TMA_PORTAL:
        navigate(`/tma/${CurrentUser.getRole()}/profile`);
        break;
      case USER_TYPE.ADVERTISER_PORTAL:
        navigate(`/advertiser/profile`);
        break;
      case USER_TYPE.TALENT_PORTAL:
        navigate(`/talent/profile`);
        break;
      default:
        break;
    }
  },
  getAgencyName: () => {
    return CurrentUser.getDetails()?.claims?.entity?.attributes
      ?.talentManagement?.attributes?.name;
  },
  getTalentName: () => {
    return CurrentUser.getDetails()?.claims?.entity?.attributes?.name;
  },
  sideBarNavigation: () => {
    if (CurrentUser.getType() === USER_TYPE.ADMIN_PORTAL) {
      switch (CurrentUser.getRole()) {
        case USER_ROLE.ADMIN:
          return ADMIN_NAVIGATON;

        case USER_ROLE.SALES:
          return SALES_NAVIGATION;

        default:
          return [];
      }
    } else if (CurrentUser.getType() === USER_TYPE.TMA_PORTAL) {
      switch (CurrentUser.getRole()) {
        case USER_ROLE.ADMIN:
          return TMA_NAVIGATION;

        case USER_ROLE.MANAGER:
          return TMA_MANAGER_NAVIGATION;
        default:
          return [];
      }
    } else if (CurrentUser.getType() === USER_TYPE.ADVERTISER_PORTAL) {
      return ADVERTISER_NAVIGATION;
    } else if (CurrentUser.getType() === USER_TYPE.TALENT_PORTAL) {
      return TALENT_NAVIGATION;
    } else {
      toast.error("You are not authorized to access this portal.");
      return [];
    }
  },
};

export const logout = async () => {
  // Logout from server
  const { data, error } = await API.delete("/auth/logout");
  localStorage.removeItem("current_user_details");
  localStorage.removeItem("user_auth_token");
  toast.success("Logout successfully");
  setTimeout(() => {
    window.location.href = "/login";
  }, 1500);
};
