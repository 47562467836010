import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import Button from "../../../stories/Button";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import { AssignBrandValidationSchema } from "../../validation";
import FormFields from "../../FormFields";
import { useBrandsList } from "../../../lib/services/BrandService";
import _ from "lodash";

const AssignBrandForm = ({
  setToggle,
  initialValues,
  formButtonCta,
  talentId,
}) => {
  const buttonRef = useRef(null);
  const [searchBrand, setSearchBrand] = useState("");
  const { brands, isValidating } = useBrandsList(searchBrand, {
    defaultValue: initialValues.brandId,
  });

  const formValues = [
    {
      label: "Brand *",
      attributeId: "brandId",
      placeholder: "Select Brand",
      type: "Search",
      displayKey: "attributes.name",
      items: brands,
      onChange: setSearchBrand,
      isValidating: isValidating,
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    const apiParams = {
      data: {
        attributes: values,
      },
    };

    const endpoint = `/portal_api/v1/talents/${talentId}/brand`;

    const { error } = await API.post(endpoint, apiParams);
    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success("Brand assigned successfully");
      setToggle(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AssignBrandValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="space-y-7">
          <FormFields
            values={values}
            formValues={formValues}
            setFieldValue={setFieldValue}
          />
          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="w-full py-2"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AssignBrandForm;
