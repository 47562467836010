import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "@reach/router";
import moment from "moment";
import queryString from "query-string";
import _ from "lodash";
import Table from "../../../components/Table";
import TableLayout from "../../../components/TableLayout";
import SlideOver from "../../../stories/SlideOver";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import Layout from "../../../components/sideBar/Layout";
import SearchBar from "../../../components/SearchBar";
import { navigateSelf } from "../../../utils/Helper";
import AllQuestionHeader from "./AllQuestionHeader";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import { ModalWrapper } from "../../../utils/Modal";
import {
  ActionDeleteButton,
  ActionEditButton,
} from "../../../table-components/Buttons";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useShowTalentCategory } from "../../../lib/services/TalentService";
import QuizQuestionForm from "../../../forms/pageForms/QuizQuestionForm";
import { getCorrectOption } from "../../../utils/Helper";
import { useQuizQuestionList } from "../../../lib/services/QuizQuestionService";
import { CurrentUser } from "../../../lib/network/auth/Auth";
import Button from "../../../stories/Button";
const RegularPosts = () => {
  const addInitialValues = {
    question: "",
    optionA: "",
    optionB: "",
    optionC: "",
    category: "",
    correctOption: "",
    status: "disabled",
    talentCategoryId: "",
  };

  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const { categoryId } = useParams();
  const urlLastSegment = location.pathname.split("/").pop();
  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const [deleteId, setDeleteId] = useState(false);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const deleteQuestion = async () => {
    setModalPrimaryCtaLoading(true);
    if (deleteId) {
      // eslint-disable-next-line no-unused-vars
      const { data, error } = await API.delete(
        `/portal_api/v1/quiz_questions/${deleteId}`,
        {}
      );
      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ??
            "This question cannot be deleted. Please try again"
          }`
        );
        setModalPrimaryCtaLoading(false);
      } else {
        toast.success("Question Deleted Successfully");
        setModalPrimaryCtaLoading(false);
        setDeleteId(false);
      }
    } else {
      setModalPrimaryCtaLoading(false);
      toast.error("Something went Wrong. Please try again");
    }
  };

  const {
    quiz,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useQuizQuestionList(searchParamsQuery, {
    page: pageNumberQuery,
    categoryId,
    questionType: urlLastSegment,
  });

  const onAction = (action, data) => {
    if (action === "delete") {
      setDeleteId(data.id);
    } else if (action === "edit") {
      setEditData({
        id: data?.id,
        category: data?.category,
        status: data?.status,
        question: data?.question,
        optionA: data?.options[0],
        optionB: data?.options[1],
        optionC: data?.options[2],
        correctOption: getCorrectOption(data?.correctOption, data?.options),
        talentCategoryId: data?.talentCategory?.id,
        talentCategory: data?.talentCategory,
      });
    }
  };

  const columns = [
    {
      field: "question",
      render: (rowData) => (
        <div className="text-left  text-sm font-medium text-gray-900 flex flex-row  items-center ">
          {rowData?.attributes?.question}
        </div>
      ),
    },
    {
      field: "Question Category",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 uppercase">
          {rowData?.attributes?.category}
        </div>
      ),
    },
    {
      field: "status",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 uppercase">
          {rowData?.attributes?.status}
        </div>
      ),
    },
    {
      field: "added on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.createdAt
            ? moment(rowData?.attributes?.createdAt).format("ll")
            : "-na-"}
        </div>
      ),
    },
    {
      field: "actions",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionEditButton
            iconOnly
            onClick={() => {
              onAction("edit", {
                ...rowData?.attributes,
                id: rowData.id,
              });
            }}
          />
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              onAction("delete", { id: rowData.id });
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(quiz)) {
      return (
        <LoadContentHelper
          title="Quiz Question"
          cta="Add Quiz Question"
          ctaOnClick={() => setToggle(true)}
          data={quiz}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    return <Table column={columns} data={quiz} />;
  };

  useEffect(() => {
    if (!toggle || !editData || !deleteId || quiz) {
      setTimeout(() => {
        mutate();
      }, 400);
    }
    if (toggle && !editData) {
      addInitialValues.category =
        urlLastSegment === "general"
          ? "general"
          : urlLastSegment === "personal"
          ? "personal"
          : "";
      addInitialValues.talentCategoryId = categoryId ? categoryId : "";
      addInitialValues.talentCategory = categoryId ? talentCat : [];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData, deleteId]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  //Modal Value JSON
  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deleteQuestion,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  const { talent: talentCat } = useShowTalentCategory(categoryId);

  return (
    <Layout>
      <ModalWrapper title="Quiz Question" config={modalConfig} />
      <AllQuestionHeader
        talentCategory={talentCat?.attributes?.name ?? "Talent Category"}
        categoryId={categoryId}
        active={urlLastSegment}
      />
      <SlideOver
        open={toggle || !!editData}
        backgroundOverlay="dark"
        size="xl"
        title={`${toggle ? "Add" : "Edit"} Quiz Question`}
        dismissButtonOnClick={() => {
          setToggle(false);
          setEditData(false);
        }}
      >
        {/* Add Quiz question */}
        <QuizQuestionForm
          setToggle={editData ? setEditData : setToggle}
          initialValues={editData ? editData : addInitialValues}
          formButtonCta={`${toggle ? "Create" : "Update"} Quiz Question`}
          editMode={!!editData}
        />
      </SlideOver>
      {searchParamsQuery || !_.isEmpty(quiz) ? (
        <div className="flex justify-between items-center">
          <SearchBar
            searchTerm={searchParamsQuery}
            size="xl"
            align="center"
            onSearch={(val) => {
              navigateSelf(1, val, parsed);
            }}
            loading={loading}
          />
          {CurrentUser.isFanshipAdmin() ? (
            <Button className=" py-1.5 mr-7" onClick={() => setToggle(true)}>
              Add Question
            </Button>
          ) : null}
        </div>
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Quiz Question List"
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
          loading={loading || error}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default RegularPosts;
