import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";

export const useAdvertiserList = (pageParam = "1", searchTerm) => {
  const queryObject = { page: pageParam, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);

  const key = `/portal_api/v1/advertisers${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});
  return {
    advertiser: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

//Advertiser by brand
export const useAdvertiserByBrandList = (
  searchBrand,
  { defaultValue, page = "1", associateBrandId }
) => {
  const queryObject = { page, query: searchBrand, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);

  const key = associateBrandId
    ? `portal_api/v1/brands/${associateBrandId}/advertisers${queryValues}`
    : "";

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});
  return {
    advertisers: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
