import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "@reach/router";
import moment from "moment";
import queryString from "query-string";
import _ from "lodash";
import classNames from "classnames";
import { EyeIcon, BadgeCheckIcon, XCircleIcon } from "@heroicons/react/solid";
import Table from "../../../components/Table";
import Author from "../../../stories/Author";
import TableLayout from "../../../components/TableLayout";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import Layout from "../../../components/sideBar/Layout";
import SearchBar from "../../../components/SearchBar";
import { navigateSelf } from "../../../utils/Helper";
import { useTalent } from "../../../lib/services/TalentService";
import { usePostByTalentList } from "../../../lib/services/PostService";
import AllPostsHeader from "./AllPostsHeader";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import { ModalWrapper } from "../../../utils/Modal";
import { ActionDeleteButton } from "../../../table-components/Buttons";
import { ExclamationIcon } from "@heroicons/react/outline";

const Quizzes = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const { talentId } = useParams();
  const { talent } = useTalent(talentId);
  const [deleteId, setDeleteId] = useState(false);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  const urlLastSegment = location.pathname.split("/").pop();

  //Search to be added (not provided by backend)

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";
  const filterType = "quiz";
  const {
    posts: quizzes,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = usePostByTalentList(
    pageNumberQuery,
    searchParamsQuery,
    talentId,
    filterType
  );

  const total_pages = Number(paginationInfo?.totalPages);

  const deletePost = async () => {
    if (deleteId) {
      setModalPrimaryCtaLoading(true);
      const { error } = await API.delete(
        `/portal_api/v1/posts/${deleteId}`,
        {}
      );
      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ?? "This quiz cannot be deleted. Please try again"
          }`
        );
      } else {
        toast.success("Quiz Post deleted successfully");
        setDeleteId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const columns = [
    {
      field: "Post",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center max-w-xs">
          <a href={rowData?.attributes?.quiz?.attributes?.questionVideo || "#"}>
            <Author
              imageSrc={rowData?.attributes?.previewImage?.thumbnail?.url}
              size="lg"
              className={classNames(
                rowData?.attributes?.quiz?.attributes?.questionVideo
                  ? ""
                  : "cursor-default"
              )}
              imageShape="round-md"
            />
          </a>
        </div>
      ),
    },

    {
      field: "Questions",
      render: (rowData) => (
        <div className="text-left  text-sm text-gray-500">
          {rowData?.attributes?.quiz?.attributes?.question ?? "-NA-"}
        </div>
      ),
    },
    {
      field: "options",
      render: (rowData) => (
        <ol className=" px-6 list-decimal   text-sm text-gray-500">
          {_.map(
            rowData?.attributes?.quiz?.attributes?.options,
            (option, idx) => (
              <li key={idx}>{option}</li>
            )
          )}
        </ol>
      ),
    },
    {
      field: "Correct option",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.quiz?.attributes?.correctOption}
        </div>
      ),
    },
    {
      field: "Quiz Stats",
      render: (rowData) => (
        <div className="flex flex-col">
          <div className=" text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
            <EyeIcon className="w-4 h-4" />

            <span>
              {rowData?.attributes?.quiz?.attributes?.quizInteractionsCount ??
                "-NA-"}
            </span>
          </div>

          <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
            <BadgeCheckIcon className="w-4 h-4" />

            <span>
              {rowData?.attributes?.quiz?.attributes?.wonCount ?? "-NA-"}
            </span>
          </div>

          <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
            <XCircleIcon className="w-4 h-4" />
            <span>
              {rowData?.attributes?.quiz?.attributes?.lostCount ?? "-NA-"}
            </span>
          </div>
        </div>
      ),
    },
    {
      field: "Status",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap uppercase text-sm text-gray-500">
          {rowData?.attributes?.status}
        </div>
      ),
    },
    {
      field: "published on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.publishedAt
            ? moment(rowData?.attributes?.publishedAt).format("ll")
            : "-na-"}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              setDeleteId(rowData?.id);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]);

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const loadContent = () => {
    if (_.isEmpty(talent) || _.isEmpty(quizzes)) {
      return (
        <LoadContentHelper
          title="Quizzes"
          ctaOnClick={null}
          data={quizzes}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
        />
      );
    }

    return <Table column={columns} data={quizzes} />;
  };

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deletePost,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper title="Quiz Post" config={modalConfig} />
      <AllPostsHeader
        talentName={
          talent?.attributes?.fanAccount?.attributes?.name || "Quizzes"
        }
        talentId={talentId}
        active={urlLastSegment}
      />
      {searchParamsQuery || !_.isEmpty(quizzes) ? (
        <div className="flex justify-between items-center">
          <SearchBar
            searchTerm={searchParamsQuery}
            size="xl"
            align="center"
            onSearch={(val) => {
              navigateSelf(1, val, parsed);
            }}
            loading={loading}
          />
        </div>
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Quizzes List"
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
          loading={loading}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default Quizzes;
