import React from "react";
import _ from "lodash";
import Button from "../../stories/Button";
import Author from "../../stories/Author";
import SideNav from "./SideNavContent";
import {
  LogoutIcon,
  BriefcaseIcon,
  UserIcon,
  NewspaperIcon,
  IdentificationIcon,
} from "@heroicons/react/outline";
import { CurrentUser } from "../../lib/network/auth/Auth";
import classNames from "classnames";

import { useProfile } from "../../lib/services/ProfileService";
import Loader from "../../components/Loader";
import { USER_TYPE } from "../../utils/Helper";

const DesktopSideBar = () => {
  const { profile, loading, error, mutate, isValidating } = useProfile(
    CurrentUser.getId()
  );
  const userType = CurrentUser.getType();

  const onClickHandler = (e) => {
    e.preventDefault();
    CurrentUser.logout();
  };
  const loadContent = () => {
    if (_.isEmpty(profile)) {
      return (
        <div className="flex justify-center items-center w-full my-1.5 ">
          <Loader className="text-gray-50" />
        </div>
      );
    }
    return (
      <Author
        name={
          profile?.type === "talent"
            ? profile?.attributes?.name ?? "-NA-"
            : profile.attributes?.firstName
            ? `${profile.attributes?.firstName} ${
                profile.attributes?.lastName ?? null
              }`
            : "-NA-"
        }
        darkmode={false}
        cta={"View Profile"}
        onClick={() => CurrentUser.goToProfile()}
        imageSrc={profile?.attributes?.image}
      />
    );
  };
  return (
    <div
      className={classNames(
        "hidden md:flex border-gray-300 border-r-2  w-72",
        "cutom-sidebar-scrollbar"
      )}
    >
      <div className="flex flex-col flex-1">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col h-0 flex-1 ">
          <div className="flex items-center flex-shrink-0 px-4 pt-5 pb-3 ">
            <img
              className=" h-12 w-auto"
              src="/assets/images/logo/logo-white.svg"
              alt="fanship"
            />
          </div>

          {(userType === USER_TYPE.TMA_PORTAL ||
            userType === USER_TYPE.TALENT_PORTAL ||
            userType === USER_TYPE.ADVERTISER_PORTAL ||
            userType === USER_TYPE.ADMIN_PORTAL) && (
            <div className=" text-sm font-medium flex justify-start capitalize ">
              <div className="flex rounded-md px-2 mx-3  text-white">
                <div className=" mr-1 flex">
                  {userType === USER_TYPE.TMA_PORTAL ? (
                    <BriefcaseIcon className="w-4 h-4 mt-0.5 mr-1" />
                  ) : userType === USER_TYPE.TALENT_PORTAL ? (
                    <UserIcon className="w-4 h-4 mt-0.5 mr-1" />
                  ) : userType === USER_TYPE.ADVERTISER_PORTAL ? (
                    <NewspaperIcon className="w-4 h-4 mt-0.5 mr-1" />
                  ) : userType === USER_TYPE.ADMIN_PORTAL ? (
                    <IdentificationIcon className="w-4 h-4 mt-0.5 mr-1" />
                  ) : null}
                  <h4>
                    {userType === USER_TYPE.TMA_PORTAL
                      ? "agency portal :"
                      : userType === USER_TYPE.TALENT_PORTAL
                      ? "talent portal :"
                      : userType === USER_TYPE.ADVERTISER_PORTAL
                      ? "adveritser portal"
                      : userType === USER_TYPE.ADMIN_PORTAL
                      ? "admin portal"
                      : null}
                  </h4>
                </div>
                <h4>
                  {userType === USER_TYPE.TMA_PORTAL
                    ? CurrentUser?.getAgencyName()
                    : userType === USER_TYPE.TALENT_PORTAL
                    ? CurrentUser?.getTalentName()
                    : null}
                </h4>
              </div>
            </div>
          )}
          <hr className="my-2 mx-3" />
          <div className="flex-1 flex flex-col pr-3 pb-4 overflow-y-auto">
            <SideNav />
          </div>

          <div className="flex-shrink-0 flex p-4">
            <Button
              type="submit"
              className="mx-auto w-full"
              iconAlso={true}
              iconPlacement="leading"
              icon={<LogoutIcon />}
              buttonStyle="white"
              onClick={onClickHandler}
            >
              Logout
            </Button>
          </div>
          <div className="flex-shrink-0 flex bg-gray-900 p-4  ">
            {loadContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopSideBar;
