import React from "react";
import _ from "lodash";
import { LoadContentHelper } from "../table-components/LoadContentHelper";
import TableLayout from "./TableLayout";
import PostsSection from "./PostsSection";

const PostSectionContainer = ({
  brandCategory,
  selectedTalent,
  selectedPostId,
  setSelectedPostId,
}) => {
  return (
    <>
      <div className="flex px-6 py-4 text-2xl font-medium">
        <h3>Media</h3>
        <div className="mx-8 flex items-center text-sm">
          <h4 className="text-gray-400">Filtered by:</h4>
          <h3 className=" ml-1 capitalize">{brandCategory}</h3>
        </div>
      </div>
      <div className=" border-b-2 border-t-2 h-full overflow-auto">
        {selectedTalent ? (
          <PostsSection
            talentId={selectedTalent}
            selectedPostId={selectedPostId}
            setSelectedPostId={setSelectedPostId}
          />
        ) : (
          <div className="min-h-full bg-gray-100">
            <TableLayout title="Media List" forModal={true}>
              <LoadContentHelper title="Media" data={[]} />
            </TableLayout>
          </div>
        )}
      </div>
    </>
  );
};

export default PostSectionContainer;
