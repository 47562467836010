import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useField } from "formik";
import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

const TextArea = ({ size, ...props }) => {
  const [field, meta] = useField(props);

  const fieldHeight = () => {
    switch (size) {
      case "xs":
        return 1;
      case "sm":
        return 2;
      case "md":
        return 3;
      case "lg":
        return 7;
      case "xl":
        return 10;
      default:
        return 5;
    }
  };

  return (
    <div>
      <div className="mt-1 relative">
        <textarea
          rows={fieldHeight()}
          cols="50"
          className={classNames(
            " shadow-sm focus:ring-gray-900 focus:border-gray-900 block w-full sm:text-sm border-gray-300 rounded-md",
            meta.touched &&
              meta.error &&
              "border border-red-600 focus:ring-red-600 focus:border-red-600"
          )}
          name={props.name}
          {...field}
          {...props}
        />
        {meta.touched && meta.error && (
          <ExclamationCircleIcon
            className=" absolute h-5 w-5 text-red-500 right-2 top-2"
            aria-hidden="true"
          />
        )}
      </div>
      <ErrorMessage
        name={props.name}
        component="span"
        className="text-red-700 text-sm absolute"
      ></ErrorMessage>
    </div>
  );
};

TextArea.defaultProps = {
  name: "brief",
  placeholder: "description",
};

TextArea.propTypes = {
  /**
   * TextArea name attribute
   */
  name: PropTypes.string,
};
export default TextArea;
