import { XIcon } from "@heroicons/react/outline";
import React from "react";
import Button from "../../stories/Button";
import { CloudUploadIcon } from "@heroicons/react/solid";
import { formatBytes } from "../../utils/Helper";
const VideoInfoModal = ({
  open,
  handleClose,
  preVideoData,
  setVideoData,
  loading,
  progress,
}) => {
  const onUploadHandler = (file) => {
    setVideoData(file);
  };

  if (!open) return null;
  return (
    <div className="fixed w-full h-full bg-black bg-opacity-75 inset-0 z-50 cursor-default">
      <div
        className={`top-0 inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center ${"absolute"}`}
      >
        <div
          className="bg-white rounded animate-modal shadow-xl overflow-auto transform transition-all md:px-8 lg:py-12 p-4 sm:max-w-2xl sm:w-full"
          style={{
            maxHeight: "calc(100vh - 10px)",
          }}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex items-center space-x-2">
            <div className=" flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
              <CloudUploadIcon
                className="h-6 w-6 text-yellow-600"
                aria-hidden="true"
              />
            </div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Confirm Video Upload
            </h3>
          </div>
          <div className="mt-5">
            <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
              <h4 className="sr-only">Video</h4>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 sm:mt-0 sm:ml-4">
                  <div className="text-sm font-medium text-gray-900">
                    File Name: {preVideoData?.name}
                  </div>
                  <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                    <div>File Size: {formatBytes(preVideoData?.size)}</div>
                    <span
                      className="hidden sm:mx-2 sm:inline"
                      aria-hidden="true"
                    >
                      &middot;
                    </span>
                    <div className="mt-1 sm:mt-0">
                      File type: {preVideoData?.type}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="right-2 top-2 absolute focus:outline-none"
          >
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="bg-white">
            <div className="mt-5">
              <Button
                type="button"
                onClick={() => {
                  onUploadHandler(preVideoData);
                }}
                className="float-right"
                buttonStyle={!!preVideoData ? "primary" : "disabled"}
                disabled={!preVideoData}
              >
                {loading ? `Uploading (${progress}%)` : "Upload Video"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoInfoModal;
