import React from "react";
import SectionHeading from "../stories/SectionHeading";
import Breadcrumb from "../stories/Breadcrumb";
import Tabs from "../stories/Tabs";

const Header = ({
  breadcrumbsData,
  tabsData,
  talentName,
  primaryCta,
  primaryOnClick,
}) => {
  return (
    <div className="pt-4 bg-white sticky top-0 z-50">
      <Breadcrumb pages={breadcrumbsData} type="chevron" />
      <SectionHeading
        title={talentName}
        primaryCta={primaryCta}
        primaryOnClick={primaryOnClick}
      ></SectionHeading>
      <div className="overflow-x-auto">
        <Tabs tabs={tabsData} />
      </div>
    </div>
  );
};

export default Header;
