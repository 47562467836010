import React from "react";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import Breadcrumb from "../../../stories/Breadcrumb";
import _ from "lodash";
import moment from "moment";
import CampaignForm from "../../../forms/pageForms/campaignForm/CampaignForm";
import { useCampaign } from "../../../lib/services/CampaignService";

import { navigate } from "@reach/router";
import Loader from "../../../components/Loader";

const CreateCampaign = ({ location }) => {
  const campaignId = location?.state?.campaignId;
  const editing = campaignId ? true : false;
  const { campaign, loading } = useCampaign(campaignId || "");

  const addInitialValues = {
    monetizationType: "Cost Per Impression",
    name: "",
    brandCategoryId: "",
    associateBrandId: "",
    image: "",
    advertisers: [],
    location: "",
    ageGroups: "",
    gender: "",
    talents: [],
    posts: [],
    startTime: "",
    budget: "",

    endTime: "",

    status: "draft",
  };
  const breadcrumbsData = [
    {
      to: -1,
      name: "Campaigns",
    },
    {
      to: "",
      name: editing ? "Edit Campaign" : "Create Campaign",
      current: true,
    },
  ];

  const getValue = (item, getKey) =>
    getKey ? _.get(item, getKey) : item["name"] || item["id"];

  const getData = (data, key, getKey, returnInt) => {
    if (_.isEmpty(data)) {
      return [];
    }
    return data.map((item) => {
      return {
        [key]:
          getKey !== "item"
            ? returnInt
              ? parseInt(getValue(item, getKey))
              : getValue(item, getKey)
            : item,
      };
    });
  };

  const mergeArrays = (arr1 = [], arr2 = []) => {
    let res = [];
    res = arr1.map((obj) => {
      const index = arr2.findIndex((el) => el["postId"] === obj["postId"]);
      const { talentId } = index !== -1 ? arr2[index] : {};
      return {
        ...obj,
        talentId,
      };
    });
    return res;
  };

  const getPostData = (postData, campaignPostData) => {
    if (_.isEmpty(postData) || _.isEmpty(campaignPostData)) {
      return [];
    }
    const arr1 = campaignPostData.map((item) => {
      return {
        campaignPostId: parseInt(item?.id),
        postId: parseInt(item?.attributes?.post?.id),
        budget:
          parseFloat(((item?.attributes?.budget ?? 0) / 100).toFixed(2)) ?? 0,
      };
    });
    const arr2 = postData.map((item) => {
      return {
        postId: parseInt(item?.id),
        talentId: parseInt(item?.attributes?.talent?.id),
      };
    });

    return mergeArrays(arr1, arr2);
  };

  //campaign post data
  const getCampaignsPostData = (data) => {
    if (_.isEmpty(data)) {
      return [];
    }
    return data.map((item) => {
      return {
        campaignPostId: parseInt(item?.id),
        associatedPostId: parseInt(item?.attributes?.post?.id),
        budget:
          parseFloat(((item?.attributes?.budget ?? 0) / 100).toFixed(2)) ?? 0,
      };
    });
  };

  //get Total Budget
  const getTotalBudgetPostData = (data) => {
    if (_.isEmpty(data)) {
      return 0;
    }

    return data.reduce(
      (item, { attributes }) =>
        item + parseFloat((attributes?.budget / 100).toFixed(2)),
      0
    );
  };
  const getAdvValue = (item) => item["id"];
  const setFieldValue = (values) => {
    const data = values.map((item) => {
      return { advertiserId: getAdvValue(item) };
    });
    return data;
  };

  const editData = {
    id: campaign?.id,
    monetizationType:
      campaign?.attributes?.monetizationType === "cpi"
        ? "Cost Per Impression"
        : "",
    name: campaign?.attributes?.name,
    brandCategoryId: campaign?.attributes?.brandCategory?.id,
    brandCategory: campaign?.attributes?.brandCategory,
    associateBrandId: campaign?.attributes?.brand?.id,
    associateBrand: campaign?.attributes?.brand,
    image: campaign?.attributes?.adBanner,
    advertiserId: _.isEmpty(campaign?.attributes?.advertisers)
      ? []
      : setFieldValue(campaign?.attributes?.advertisers),
    advertisers: campaign?.attributes?.advertisers,
    location: getData(campaign?.attributes?.locations, "location", "id", true),
    locationName: getData(
      campaign?.attributes?.locations,
      "location",
      "attributes.name",
      false
    ),
    ageGroups: getData(
      campaign?.attributes?.ageGroups,
      "ageGroups",
      "item",
      false
    ),
    gender:
      campaign?.attributes?.gender === "any_gender"
        ? "any"
        : campaign?.attributes?.gender,
    budget: campaign?.attributes?.budget
      ? (campaign.attributes.budget / 100).toFixed(2)
      : "",
    talents: getData(campaign?.attributes?.talents, "talentId", "id", true),
    posts: getPostData(
      campaign?.attributes?.posts,
      campaign?.attributes?.campaignsPosts
    ),
    startTime: campaign?.attributes?.startTime
      ? moment(campaign.attributes.startTime * 1000).format("lll")
      : "",
    endTime: campaign?.attributes?.endTime
      ? moment(campaign.attributes.endTime * 1000).format("lll")
      : "",
    status: campaign?.attributes?.status ? campaign?.attributes?.status : "",
    totalFollowers: campaign?.attributes?.totalFollowers,
    maxBudget: campaign?.attributes?.maxBudget,
    totalBudget: getTotalBudgetPostData(campaign?.attributes?.campaignsPosts),
    campaignsPosts: getCampaignsPostData(campaign?.attributes?.campaignsPosts),
  };

  return (
    <Layout>
      <div className="pt-4 bg-white sticky top-0 z-50">
        <Breadcrumb pages={breadcrumbsData} type="chevron" />
        <SectionHeading
          title={`${editing ? "Edit" : "Create"} Campaign`}
          primaryCta="Back"
          primaryOnClick={() => navigate(-1)}
        />
      </div>
      <div className="m-8">
        {campaignId && loading ? (
          <div className="my-72">
            <Loader />
          </div>
        ) : (
          <CampaignForm
            editing={editing}
            initialValues={editing ? editData : addInitialValues}
            step={location?.state?.step}
            published={location?.state?.published}
          />
        )}
      </div>
    </Layout>
  );
};

export default CreateCampaign;
