import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import { useLocation } from "@reach/router";
import _ from "lodash";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import { useExportDataList } from "../../../lib/services/ExportDataService";
import Table from "../../../components/Table";

import SearchBar from "../../../components/SearchBar";

import { ExportDataButton } from "../../../table-components/Buttons";
import { ModalWrapper } from "../../../utils/Modal";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import Author from "../../../stories/Author";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import TableLayout from "../../../components/TableLayout";
import { navigateSelf } from "../../../utils/Helper";
const ExportData = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [talentId, setTalentId] = useState(false);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const downloadReport = async () => {
    setModalPrimaryCtaLoading(true);
    if (talentId) {
      // eslint-disable-next-line no-unused-vars
      const { data, error } = await API.get(
        `/portal_api/v1/talents${talentId ? `/${talentId}` : ""}/export_pdf`,
        {}
      );
      if (error) {
        toast.error(
          `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
        );
        setModalPrimaryCtaLoading(false);
        setTalentId(false);
      } else {
        toast.success("Downloading Talent report...");
        setModalPrimaryCtaLoading(false);
        const aws_pdf_url = data?.data?.url.split("?")[0];
        let pdf_open = document.createElement("a");
        pdf_open.target = "_blank";
        pdf_open.href = aws_pdf_url;
        setTimeout(() => {
          pdf_open.click();
        }, 1000);
        setTalentId(false);
      }
    } else {
      setModalPrimaryCtaLoading(false);
      toast.error("Something went Wrong. Please try again");
      setTalentId(false);
    }
  };

  const {
    reports,
    loading,
    error,
    mutate,
    isValidating,
    paginationInfo,
  } = useExportDataList(pageNumberQuery, searchParamsQuery);

  const onAction = (action, data) => {
    if (action === "export") {
      setTalentId(data.talentId);
    }
  };

  const columns = [
    {
      field: "Name",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm flex flex-row  items-center mr-4">
          <Author
            name={rowData?.attributes?.fanAccount?.attributes?.name ?? "-NA-"}
            imageSrc={rowData?.attributes?.fanAccount?.attributes?.image}
            cta={`@${rowData?.attributes?.fanAccount?.attributes?.username}`}
            size="lg"
            className="cursor-default"
          />
        </div>
      ),
    },
    {
      field: "Category",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm  text-gray-500 ">
          {!_.isEmpty(rowData?.attributes?.talentCategories)
            ? rowData?.attributes?.talentCategories?.map((item, idx) => {
                return <div key={idx}>{item?.attributes?.name}</div>;
              })
            : ["-na-"]}
        </div>
      ),
    },

    {
      field: "followers",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.stats?.followers_count ?? "-na-"}
        </div>
      ),
    },
    {
      field: "Likes",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.stats?.likes_count ?? "-na-"}
        </div>
      ),
    },
    // not provided by backend yet
    // {
    //   field: "Impression rate",
    //   render: (rowData) => (
    //     <div className="text-left whitespace-nowrap text-sm text-gray-500">
    //       {rowData?.export?.impression_rate ?? "-na-"}
    //     </div>
    //   ),
    // },

    {
      field: "Replays",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.stats?.replays ?? "-na-"}
        </div>
      ),
    },
    {
      field: "CPI",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.stats?.cost_per_impression
            ? rowData.attributes.stats.cost_per_impression / 100
            : "-na-"}
        </div>
      ),
    },

    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm  flex flex-row space-x-4 ">
          <ExportDataButton
            onClick={() => {
              onAction("export", {
                talentId: rowData?.id,
              });
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(reports)) {
      return (
        <LoadContentHelper
          title="Reports"
          data={reports}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
        />
      );
    }
    return <Table column={columns} data={reports} />;
  };

  useEffect(() => {
    if (!talentId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentId]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const downloadReportModalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: downloadReport,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setTalentId,
    },
    open: { value: talentId },
    icon: { value: <DocumentDownloadIcon />, type: "success" },
  };

  return (
    <Layout>
      <ModalWrapper
        fullTitle="Download Data"
        desc="Are you sure you want to download data of this account?"
        config={downloadReportModalConfig}
      />
      <div className="sticky top-0 z-50">
        <SectionHeading title="Export Data" />
      </div>
      {/* //SearchBar */}
      <SearchBar
        searchTerm={searchParamsQuery}
        size="xl"
        align="center"
        onSearch={(val) => {
          navigateSelf(1, val, parsed);
        }}
        loading={loading}
      />
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Export Data List"
          loading={loading || error}
          paginationInfo={paginationInfo}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default ExportData;
