import { Router, Redirect } from "@reach/router";
import { CurrentUser } from "./lib/network/auth/Auth";
import { USER_ROLE, USER_TYPE } from "./utils/Helper";

import Signin from "./pages/Auth/Signin";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import Sample from "./pages/Sample";

//Categories
import BrandCategories from "./pages/navigation/categories/BrandCategories";
import TalentCategories from "./pages/navigation/categories/TalentCategories";
import CategoryWiseTalents from "./pages/navigation/categories/CategoryWiseTalents";

import Brands from "./pages/navigation/Brands";
import TalentManagementAgencies from "./pages/navigation/TalentManagementAgencies";
import TmaWiseTalents from "./pages/navigation/TmaWiseTalents";
// Talents
import AllTalents from "./pages/navigation/talents/AllTalents";
import GuestFeedTalents from "./pages/navigation/talents/GuestFeedTalents";

import Posts from "./pages/navigation/Posts";
import QuizQuestion from "./pages/navigation/question-detail-page/QuizQuestion";
import QuizQuestionCategories from "./pages/navigation/QuizQuestionCategories";

import Fans from "./pages/navigation/Fans";
import Team from "./pages/navigation/Team";
import Advertisers from "./pages/navigation/Advertisers";

// Campaigns
import ActiveCampaigns from "./pages/navigation/campaigns/ActiveCampaigns";
import ScheduledCampaigns from "./pages/navigation/campaigns/ScheduledCampaigns";
import CompletedCampaigns from "./pages/navigation/campaigns/CompletedCampaigns";
import DraftCampaigns from "./pages/navigation/campaigns/DraftCampaigns";
import CreateCampaign from "./pages/navigation/campaigns/CreateCampaign";
import ViewCampaign from "./pages/navigation/campaigns/ViewCampaign";

import Reports from "./pages/navigation/Reports";

// configurations
import CreditModel from "./pages/navigation/configurations/CreditModel";
import BlacklistedKeywords from "./pages/navigation/configurations/BlacklistedKeywords";
import ExportData from "./pages/navigation/configurations/ExportData";
import Faq from "./pages/navigation/configurations/Faq";
import PrivacyPolicy from "./pages/navigation/configurations/PrivacyPolicy";
import TermsAndConditons from "./pages/navigation/configurations/TermsAndConditons";

import EarningCompletedCampaigns from "./pages/navigation/talents/detail-page/EarningCompletedCampaigns";
import EarningOngoingCampaigns from "./pages/navigation/talents/detail-page/EarningOngoingCampaigns";
import BlockedAccounts from "./pages/navigation/talents/detail-page/BlockedAccounts";
import TalentCreditModel from "./pages/navigation/talents/detail-page/TalentCreditModel";
import EndorsedBrands from "./pages/navigation/talents/detail-page/EndorsedBrands";

//post-details-page
import RegularPosts from "./pages/navigation/posts-detail-page/RegularPosts";
import MonetizablePosts from "./pages/navigation/posts-detail-page/MonetizablePosts";
import Quizzes from "./pages/navigation/posts-detail-page/Quizzes";

import TalentMonetizablePosts from "./pages/navigation/talents/detail-page/posts/TalentMonetizablePosts";
import TalentQuizzes from "./pages/navigation/talents/detail-page/posts/TalentQuizzes";
import TalentRegularPosts from "./pages/navigation/talents/detail-page/posts/TalentRegularPosts";
import ProfilePage from "./pages/navigation/ProfilePage";

// Tma Protal
import TmaManagers from "./pages/navigation/tma-portal/TmaManagers";
import TmaTalents from "./pages/navigation/tma-portal/tma-talents/TmaTalents";
import TmaBrands from "./pages/navigation/tma-portal/tma-talents/detail-page/TmaBrands";
import TmaReports from "./pages/navigation/tma-portal/tma-talents/detail-page/TmaReports";
import TmaRegularPosts from "./pages/navigation/tma-portal/tma-talents/detail-page/posts/TmaRegularPosts";
import ViewRegularPosts from "./pages/navigation/tma-portal/tma-talents/detail-page/posts/ViewRegularPosts";
import TmaMonetizablePosts from "./pages/navigation/tma-portal/tma-talents/detail-page/posts/TmaMonetizablePosts";
import TmaMonetizedPosts from "./pages/navigation/tma-portal/tma-talents/detail-page/posts/TmaMonetizedPosts";
import ViewMonetizedPosts from "./pages/navigation/tma-portal/tma-talents/detail-page/posts/ViewMonetizedPosts";
import TmaQuizzes from "./pages/navigation/tma-portal/tma-talents/detail-page/posts/TmaQuizzes";
import CreateTmaRegularPost from "./pages/navigation/tma-portal/tma-talents/detail-page/posts/form-pages/CreateTmaRegularPost";
import CreateTmaMonetizablePost from "./pages/navigation/tma-portal/tma-talents/detail-page/posts/form-pages/CreateTmaMonetizablePost";
import ViewQuizPost from "./pages/navigation/tma-portal/tma-talents/detail-page/posts/quiz/ViewQuizPost";
import CreateQuiz from "./pages/navigation/tma-portal/tma-talents/detail-page/posts/quiz/CreateQuiz";
import TmaCampaigns from "./pages/navigation/tma-portal/TmaCampaigns";
import TmaViewCampaign from "./pages/navigation/tma-portal/TmaViewCampaigns";
//Advertiser Portal
import Campaigns from "./pages/navigation/advertiser-portal/Campaigns";
import UnapprovedCampaigns from "./pages/navigation/campaigns/UnapprovedCampaigns";

//Open Pages
import TnCPage from "./pages/navigation/TnCPage";
import PrivacyPage from "./pages/navigation/PrivacyPage";
//ProtectedRoute
const PrivateRoute = ({ children }) => {
  const token = CurrentUser.getToken();
  if (!token) {
    window.location.href = "/signin";
  }
  return (
    <div>
      {!!token ? (
        <>{children}</>
      ) : (
        <>
          <Signin path="/signin" />
        </>
      )}
    </div>
  );
};

//Admin Portal
const AdminPanel = ({ children }) => {
  const userType = CurrentUser.getType();
  if (userType !== USER_TYPE.ADMIN_PORTAL) {
    window.location.href = "/sample";
    return <></>;
  }
  return children;
};

//for role based segregation of pages (Admin,sales,manager,etc)
const ProtectedRoutesByRoles = ({ children, allowedRoles }) => {
  const userRole = CurrentUser.getRole();
  if (allowedRoles.indexOf(userRole) === -1) {
    window.location.href = "/sample";
    return <></>;
  }
  return children;
};

//TMA Portal
const TmaPanel = ({ children }) => {
  const userType = CurrentUser.getType();
  if (userType !== USER_TYPE.TMA_PORTAL) {
    window.location.href = "/sample";
    return <></>;
  }
  return children;
};

//Advertiser Portal
const AdvertiserPanel = ({ children }) => {
  const userType = CurrentUser.getType();
  if (userType !== USER_TYPE.ADVERTISER_PORTAL) {
    window.location.href = "/sample";
    return <></>;
  }
  return children;
};

//Talent Portal
const TalentPanel = ({ children }) => {
  const userType = CurrentUser.getType();
  if (userType !== USER_TYPE.TALENT_PORTAL) {
    window.location.href = "/sample";
    return <></>;
  }
  return children;
};

//redirection function based on role/type
const redirectionFunction = (redirectFrom) => {
  const userType = CurrentUser.getType();
  const userRole = CurrentUser.getRole();
  if (userType === USER_TYPE.ADMIN_PORTAL) {
    if (userRole === USER_ROLE.ADMIN) {
      return (
        <Redirect
          noThrow
          from={`${redirectFrom}`}
          to="/admin/categories/talent-categories"
        />
      );
    }
    if (userRole === USER_ROLE.SALES) {
      return (
        <Redirect
          noThrow
          from={`${redirectFrom}`}
          to="/sales/categories/talent-categories"
        />
      );
    }
  } else if (userType === USER_TYPE.TMA_PORTAL) {
    if (userRole === USER_ROLE.ADMIN) {
      return (
        <Redirect noThrow from={`${redirectFrom}`} to="/tma/admin/managers" />
      );
    }
    if (userRole === USER_ROLE.MANAGER) {
      return (
        <Redirect noThrow from={`${redirectFrom}`} to="/tma/manager/talents" />
      );
    }
  } else if (userType === USER_TYPE.TALENT_PORTAL) {
    return <Redirect noThrow from={`${redirectFrom}`} to="/talent/brands" />;
  }
  if (
    userType === USER_TYPE.ADVERTISER_PORTAL &&
    userRole === USER_ROLE.ADMIN
  ) {
    return (
      <Redirect noThrow from={`${redirectFrom}`} to="/advertiser/campaigns" />
    );
  } else {
    return <Redirect noThrow from={`${redirectFrom}`} to="/sample" />;
  }
};

function App() {
  return (
    <Router>
      {CurrentUser.getToken() ? (
        redirectionFunction("/signin")
      ) : (
        <Signin path="/signin" />
      )}

      {CurrentUser.getToken() ? (
        redirectionFunction("/")
      ) : (
        <Signin path="/signin" />
      )}

      <ForgotPassword path="/forgot-password" />
      <ResetPassword path="/reset-password" />

      {/* protectedRoute */}
      <PrivateRoute path="/">
        <Sample path="/sample" />

        {/* FanshipAdmin Routes */}
        <AdminPanel path="/">
          {/* Sales Routes(FanshipAdmin Portal) */}
          <ProtectedRoutesByRoles
            path="/sales"
            allowedRoles={[USER_ROLE.SALES]}
          >
            {/* Brand Categories */}
            <BrandCategories path="/categories/brand-categories" />
            {/* Talent Categories */}
            <TalentCategories path="/categories/talent-categories" />
            <CategoryWiseTalents path="/categories/talent-categories/:categoryId" />
            <Brands path="/brands" />

            <AllTalents path="/talents/all-talents" />
            <EarningOngoingCampaigns path="/talents/all-talents/:talentId/earning-ongoing-campaigns" />
            <EarningCompletedCampaigns path="/talents/all-talents/:talentId/earning-completed-campaigns" />
            <EndorsedBrands path="/talents/all-talents/:talentId/endorsed-brands" />
            <BlockedAccounts path="/talents/all-talents/:talentId/blocked-accounts" />
            <TalentCreditModel path="/talents/all-talents/:talentId/credit-model" />

            <GuestFeedTalents path="/talents/guest-feed-talents" />
            <Advertisers path="/advertisers" />

            <ActiveCampaigns path="/campaigns/active-campaigns" />
            <CompletedCampaigns path="/campaigns/completed-campaigns" />
            <ScheduledCampaigns path="/campaigns/scheduled-campaigns" />
            <ViewCampaign path="/campaigns/:campaignId" />

            <ExportData path="/configurations/export-data" />
            <ProfilePage path="/profile" />
          </ProtectedRoutesByRoles>

          {/* Admin Routes(FanshipAdmin Portal) */}
          <ProtectedRoutesByRoles
            path="/admin"
            allowedRoles={[USER_ROLE.ADMIN]}
          >
            {/* Brand Categories */}
            <BrandCategories path="/categories/brand-categories" />

            {/* Talent Categories */}
            <TalentCategories path="/categories/talent-categories" />
            <CategoryWiseTalents path="/categories/talent-categories/:categoryId" />

            <Brands path="/brands" />
            <TalentManagementAgencies path="/talent-management-agencies" />
            <TmaWiseTalents path="/talent-management-agencies/:agencyId" />
            {/* Talents */}
            <AllTalents path="/talents/all-talents" />
            <EarningOngoingCampaigns path="/talents/all-talents/:talentId/earning-ongoing-campaigns" />
            <EarningCompletedCampaigns path="/talents/all-talents/:talentId/earning-completed-campaigns" />
            <EndorsedBrands path="/talents/all-talents/:talentId/endorsed-brands" />

            <TalentRegularPosts path="/talents/all-talents/:talentId/posts/regular-posts" />
            <TalentMonetizablePosts path="/talents/all-talents/:talentId/posts/monetizable-posts" />
            <TalentQuizzes path="/talents/all-talents/:talentId/posts/quizzes" />

            <BlockedAccounts path="/talents/all-talents/:talentId/blocked-accounts" />
            <TalentCreditModel path="/talents/all-talents/:talentId/credit-model" />

            <GuestFeedTalents path="/talents/guest-feed-talents" />
            <Posts path="/posts" />
            <RegularPosts path="/posts/:talentId/regular-posts" />
            <MonetizablePosts path="/posts/:talentId/monetizable-posts" />
            <Quizzes path="/posts/:talentId/quizzes" />
            {/* Quiz Questions */}
            <QuizQuestionCategories path="/quiz-questions/talent-categories" />
            <QuizQuestion path="/quiz-questions/talent-categories/:categoryId/personal" />
            <QuizQuestion path="/quiz-questions/talent-categories/:categoryId/general" />

            <Fans path="/fans" />
            <Team path="/team" />
            <Advertisers path="/advertisers" />
            {/* Campaigns */}
            <ActiveCampaigns path="/campaigns/active-campaigns" />
            <CompletedCampaigns path="/campaigns/completed-campaigns" />
            <ScheduledCampaigns path="/campaigns/scheduled-campaigns" />
            <UnapprovedCampaigns path="/campaigns/unapproved-campaigns" />
            <DraftCampaigns path="/campaigns/draft-campaigns" />
            <CreateCampaign path="/campaigns/campaign-new" />
            <ViewCampaign path="/campaigns/:campaignId" />

            <Reports path="/reports" />
            {/* configurations */}
            <CreditModel path="/configurations/credit-model" />
            <BlacklistedKeywords path="/configurations/blacklisted-keywords" />
            <ExportData path="/configurations/export-data" />
            <Faq path="/configurations/faq" />
            <PrivacyPolicy path="/configurations/privacy-policy" />
            <TermsAndConditons path="/configurations/terms-and-conditions" />

            <ProfilePage path="/profile" />
          </ProtectedRoutesByRoles>
        </AdminPanel>

        {/* tma routes */}
        <TmaPanel path="/tma">
          {/* Admin Routes(tma Portal) */}
          <ProtectedRoutesByRoles
            path="/admin"
            allowedRoles={[USER_ROLE.ADMIN]}
          >
            {/* managers */}
            <TmaManagers path="/managers" />
            {/* talents */}
            <TmaTalents path="/talents" />
            <TmaBrands path="/talents/:talentId/brands" />
            <TmaReports path="/talents/:talentId/reports" />

            <TmaRegularPosts path="/talents/:talentId/posts/regular-posts" />
            {/* //create post */}
            <CreateTmaRegularPost path="/talents/:talentId/posts/create-regular-posts" />
            {/* View regular post details */}
            <ViewRegularPosts path="/talents/:talentId/posts/regular-posts/:postId" />
            <TmaMonetizablePosts path="/talents/:talentId/media-bank" />
            <CreateTmaMonetizablePost path="/talents/:talentId/posts/create-monetizable-media" />
            <TmaMonetizedPosts path="/talents/:talentId/posts/monetized-posts" />
            <ViewMonetizedPosts path="/talents/:talentId/posts/monetized-posts/:postId" />
            <TmaQuizzes path="/talents/:talentId/posts/quizzes" />
            <ProfilePage path="/profile" />
            <ViewQuizPost path="/talents/:talentId/posts/quizzes/:postId" />
            <CreateQuiz path="/talents/:talentId/posts/quizzes/new-quiz" />
            {/* Campaigns */}
            <TmaCampaigns path="/campaigns/approve-campaigns" />
            <TmaViewCampaign path="/campaigns/:campaignId" />
          </ProtectedRoutesByRoles>
          {/* Manager Routes(tma Portal) */}
          {/* talents */}
          <ProtectedRoutesByRoles
            path="/manager"
            allowedRoles={[USER_ROLE.MANAGER]}
          >
            {/* talents */}
            <TmaTalents path="/talents" />
            <ProfilePage path="/profile" />

            <TmaBrands path="/talents/:talentId/brands" />
            <TmaReports path="/talents/:talentId/reports" />

            <TmaRegularPosts path="/talents/:talentId/posts/regular-posts" />
            {/* //create post */}
            <CreateTmaRegularPost path="/talents/:talentId/posts/create-regular-posts" />
            {/* View regular post details */}
            <ViewRegularPosts path="/talents/:talentId/posts/regular-posts/:postId" />
            <TmaMonetizablePosts path="/talents/:talentId/media-bank" />
            <CreateTmaMonetizablePost path="/talents/:talentId/posts/create-monetizable-media" />
            <TmaMonetizedPosts path="/talents/:talentId/posts/monetized-posts" />
            <ViewMonetizedPosts path="/talents/:talentId/posts/monetized-posts/:postId" />
            <TmaQuizzes path="/talents/:talentId/posts/quizzes" />
            <ViewQuizPost path="/talents/:talentId/posts/quizzes/:postId" />
            <CreateQuiz path="/talents/:talentId/posts/quizzes/new-quiz" />
            {/* Campaigns */}
            <TmaCampaigns path="/campaigns/approve-campaigns" />
            <TmaViewCampaign path="/campaigns/:campaignId" />
          </ProtectedRoutesByRoles>
        </TmaPanel>

        {/* Advertiser routes */}
        <AdvertiserPanel path="/advertiser">
          <Campaigns path="/campaigns" />
          <ViewCampaign path="/campaigns/:campaignId" />
          <ProfilePage path="/profile" />
        </AdvertiserPanel>

        {/* Talent routes */}
        <TalentPanel path="/talent">
          <TmaBrands path="/brands" />
          <TmaReports path="/reports" />
          <TmaRegularPosts path="/posts/regular-posts" />
          <ViewRegularPosts path="/posts/regular-posts/:postId" />
          <CreateTmaRegularPost path="/posts/create-regular-posts" />
          <TmaMonetizablePosts path="/media-bank" />
          <TmaMonetizedPosts path="/posts/monetized-posts" />
          <ViewMonetizedPosts path="posts/monetized-posts/:postId" />
          <CreateTmaMonetizablePost path="/posts/create-monetizable-media" />
          <TmaQuizzes path="/posts/quizzes" />
          <CreateQuiz path="/posts/quizzes/new-quiz" />
          <ViewQuizPost path="/posts/quizzes/:postId" />
          {/* Campaigns */}
          <TmaCampaigns path="/campaigns/approve-campaigns" />
          <TmaViewCampaign path="/campaigns/:campaignId" />
          <ProfilePage path="/profile" />
        </TalentPanel>
      </PrivateRoute>
      {/* open routes */}
      <TnCPage path="/terms" />
      <PrivacyPage path="/privacy" />
      {/* Default Route */}
      <Signin default />
    </Router>
  );
}

export default App;
