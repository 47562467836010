import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import AwsImageService from "../../lib/services/AwsImageService";
import Button from "../../stories/Button";
import { talentProfileValidationSchema } from "../validation";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import FormFields from "../FormFields";
const TalentProfileForm = ({
  setToggle,
  initialValues,
  editMode,
  formButtonCta,
  setEditData,
}) => {
  const buttonRef = useRef(null);

  const formValues = [
    {
      label: "Profile Image",
      attributeId: "image",
      type: "ImageInput",
    },
    {
      label: "Full Name *",
      attributeId: "name",
      placeholder: "Rahul Gupta",
      type: "TextInput",
    },
    {
      label: "Social Profile *",
      attributeId: "socialProfile",
      placeholder: "https://twitter.com/RanveerOfficial",
      type: "TextInput",
    },
    {
      label: "Username *",
      attributeId: "username",
      placeholder: "ranveersingh",
      type: "TextInput",
    },
    {
      label: "Email *",
      attributeId: "email",
      placeholder: "xyz@example.com",
      type: "TextInput",
    },
    {
      label: "Quote *",
      attributeId: "quote",
      type: "TextArea",
      placeholder: "The man who has no imagination has no wing.",
    },
    {
      label: "Cost Per Impression *",
      attributeId: "costPerImpression",
      placeholder: "1.00",
      type: "PriceInput",
    },
    {
      label: "Location *",
      attributeId: "location",
      placeholder: "Mumbai",
      type: "TextInput",
    },
    {
      label: "Phone Number *",
      attributeId: "phone",
      placeholder: "+91 98568-74589",
      type: "PhoneInput",
      countryCode: "countryCode",
    },
    {
      label: "Gender *",
      attributeId: "gender",
      placeholder: "Select Gender",
      type: "DropDown",
      options: [
        { attributes: { gender: "male" } },
        { attributes: { gender: "female" } },
        { attributes: { gender: "undisclosed gender" } },
      ],
      displayKey: "gender",
    },
    {
      label: "Date Of Birth*",
      attributeId: "dob",
      placeholder: "July 23, 2021",
      type: "DatePicker",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    const fetchedValues = {
      ..._.pick(values, [
        "name",
        "email",
        "username",
        "socialProfile",
        "quote",
        "dob",
        "location",
        "countryCode",
      ]),
      phone: values.phone.slice(values.countryCode.length),
      image: values?.image?.data
        ? await AwsImageService(values)
        : initialValues.image,
      gender:
        values?.gender === "undisclosed gender"
          ? "undisclosed_gender"
          : values?.gender,
      costPerImpression: values?.costPerImpression
        ? values.costPerImpression * 100
        : "",
    };

    const apiParams = {
      data: { attributes: fetchedValues },
    };

    const endpoint = "/portal_api/v1/profile";

    const { error } = await API.put(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(`Profile updated successfully`);
      setToggle(false);
      setEditData(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={talentProfileValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="flex flex-col h-screen">
          <div className="sticky pl-2 pr-4 -mr-5 pb-10 overflow-y-auto h-3/4 ">
            <FormFields
              setFieldValue={setFieldValue}
              values={values}
              formValues={formValues}
            />
          </div>
          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="mt-4 w-full sticky bottom-2 z-50"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default TalentProfileForm;
