import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";

export const useExportDataList = (pageParam = "1", searchTerm) => {
  const queryObject = {
    page: pageParam,
    query: searchTerm,
    per_page: 20,
  };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/talents/export_data/${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    reports: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
