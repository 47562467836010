import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";

import Button from "../../../../stories/Button";

import { API } from "../../../../lib/network/API";
import { toast } from "../../../../components/Toast";
import AwsImageService from "../../../../lib/services/AwsImageService";

// Talentcategory data
import { useTalentList } from "../../../../lib/services/TalentService";

import { talentValidationSchema } from "../../../validation";
import FormFields from "../../../FormFields";
import moment from "moment";

const TmaTalentForm = ({
  setToggle,
  initialValues,
  editMode,
  formButtonCta,
}) => {
  const buttonRef = useRef(null);
  const [searchTalentCategory, setSearchTalentCategory] = useState("");

  //talenty Category
  const {
    talents: talentCategoriesData,
    isValidating: isValidatingTalentCategory,
  } = useTalentList(searchTalentCategory, {
    defaultValue: initialValues.talentCategories,
  });

  const formValues = [
    {
      label: "Talent Image",
      attributeId: "image",
      type: "ImageInput",
    },
    {
      label: "Name *",
      attributeId: "name",
      placeholder: "Ranveer Singh",
      type: "TextInput",
    },

    {
      label: "Username *",
      attributeId: "username",
      placeholder: "ranveersingh",
      type: "TextInput",
    },
    {
      label: "Email *",
      attributeId: "email",
      placeholder: "xyz@example.com",
      type: "TextInput",
    },
    {
      label: "Social Profile *",
      attributeId: "socialProfile",
      placeholder: "https://twitter.com/RanveerOfficial",
      type: "TextInput",
    },

    {
      label: "Quote *",
      attributeId: "quote",
      type: "TextArea",
      placeholder: "The man who has no imagination has no wing.",
    },
    {
      label: "Talent Category*",
      attributeId: "talentCategoryId",
      placeholder: "Select Category",
      type: "Multiselect",
      displayKey: "attributes.name",
      items: talentCategoriesData,
      valueKey: "id",
      onChange: setSearchTalentCategory,
      isValidating: isValidatingTalentCategory,
    },
    {
      label: "Cost Per Impression *",
      attributeId: "costPerImpression",
      placeholder: "1.00",
      type: "PriceInput",
    },
    {
      label: "Location *",
      attributeId: "location",
      placeholder: "Mumbai",
      type: "TextInput",
    },
    {
      label: "Phone Number *",
      attributeId: "phone",
      placeholder: "+91 98568-74589",
      type: "PhoneInput",
    },

    {
      label: "Gender *",
      attributeId: "gender",
      placeholder: "Select Gender",
      type: "DropDown",
      options: [
        { attributes: { gender: "male" } },
        { attributes: { gender: "female" } },
        { attributes: { gender: "undisclosed gender" } },
      ],
      displayKey: "gender",
    },
    {
      label: "Date Of Birth*",
      attributeId: "dob",
      placeholder: "July 23, 2021",
      type: "DatePicker",
    },

    {
      label: "Show Talent : ",
      attributeId: "status",
      type: "Toggle",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    const fetchedValues = {
      ..._.pick(values, [
        "name",
        "email",
        "username",
        "socialProfile",
        "quote",
        "location",
        "countryCode",
      ]),
      dob: moment(values.dob).format("YYYY-MM-D"),
      phone: values.phone.slice(values.countryCode.length),
      gender:
        values?.gender === "undisclosed gender"
          ? "undisclosed_gender"
          : values?.gender,
      image: values?.image?.data
        ? await AwsImageService(values)
        : initialValues.image,
      talentCategoriesTalentsAttributes: values?.talentCategoryId,
      costPerImpression: values?.costPerImpression
        ? values.costPerImpression * 100
        : "",
      status: values?.status,
    };

    const apiParams = {
      data: { attributes: fetchedValues },
    };

    const endpoint = `/portal_api/v1/talents/${
      editMode ? initialValues.id : ""
    }`;

    const { error } = editMode
      ? await API.put(endpoint, apiParams)
      : await API.post(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(`Talent ${editMode ? "Updated" : "Created"} Successfully`);
      setToggle(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={talentValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="flex flex-col h-screen">
          {/* json Form component */}
          <div className="sticky pl-2 pr-4 -mr-5 pb-10 overflow-y-auto h-3/4  ">
            <FormFields
              setFieldValue={setFieldValue}
              values={values}
              formValues={formValues}
            />
          </div>

          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="mt-4 w-full sticky bottom-2 z-50"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default TmaTalentForm;
