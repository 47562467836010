import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useLocation, useParams } from "@reach/router";
import queryString from "query-string";
import Table from "../../../../components/Table";
import SlideOver from "../../../../stories/SlideOver";
import moment from "moment";
import Author from "../../../../stories/Author";
import { ActionDeleteButton } from "../../../../table-components/Buttons";
import SearchBar from "../../../../components/SearchBar";
import _ from "lodash";
import { API } from "../../../../lib/network/API";
import { toast } from "../../../../components/Toast";
import TableLayout from "../../../../components/TableLayout";
import { ModalWrapper } from "../../../../utils/Modal";
import { LoadContentHelper } from "../../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../../utils/Helper";
import Button from "../../../../stories/Button";
import AssignBrandForm from "../../../../forms/pageForms/talentForms/AssignBrandForm";
import Layout from "../../../../components/sideBar/Layout";
import {
  useTalentAssignedBrands,
  useTalent,
} from "../../../../lib/services/TalentService";
import AllTalentHeader from "./AllTalentHeader";
import { CurrentUser } from "../../../../lib/network/auth/Auth";
const EndorsedBrands = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);
  const [toggle, setToggle] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);
  const { talentId } = useParams();

  const { talent } = useTalent(talentId);
  const urlLastSegment = location.pathname.split("/").pop();

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const addInitialValues = {
    brandId: "",
  };

  const removeBrand = async () => {
    if (deleteId) {
      setModalPrimaryCtaLoading(true);

      const apiParams = {
        data: {
          attributes: {
            brandId: deleteId,
          },
        },
      };
      const endpoint = `/portal_api/v1/talents/${talentId}/brand`;

      const { error } = await API.delete(endpoint, apiParams);
      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${error.status ?? null}: ${
            error.message
              ? error.message
              : "Something went Wrong. Please try again"
          } `
        );
      } else {
        toast.success("Brand Removed Successfully");
        setDeleteId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const columns = [
    {
      field: "name",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center">
          <Author
            name={rowData?.attributes?.name}
            imageSrc={rowData?.attributes?.image}
            type="brand"
            size="lg"
            className="cursor-default"
          />
        </div>
      ),
    },
    {
      field: "category",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm  text-gray-500 capitalize">
          {!_.isEmpty(rowData?.attributes?.brandCategories)
            ? rowData?.attributes?.brandCategories?.map((item, idx) => {
                return <div key={idx}>{item?.attributes?.name}</div>;
              })
            : ""}
        </div>
      ),
    },
    {
      field: "added on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {moment(`${rowData?.attributes?.createdAt}`).format("LL")}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              setDeleteId(rowData.id);
            }}
          />
        </div>
      ),
    },
  ];

  const {
    brands,
    loading,
    error,
    mutate,
    isValidating,
    paginationInfo,
  } = useTalentAssignedBrands(pageNumberQuery, searchParamsQuery, talentId);

  const loadContent = () => {
    if (_.isEmpty(brands)) {
      return (
        <LoadContentHelper
          title="Brand"
          cta="Assign Brand"
          message="Please start assigning new Brand by clicking below."
          ctaOnClick={() => setToggle(true)}
          data={brands}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    if (CurrentUser.isFanshipAdmin()) {
      return <Table column={columns} data={brands} />;
    } else {
      return <Table column={_.initial(columns)} data={brands} />;
    }
  };

  useEffect(() => {
    if (!toggle || !deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, deleteId]);

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: removeBrand,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper title="Brand" config={modalConfig} />

      <SlideOver
        open={toggle}
        backgroundOverlay="dark"
        size="xl"
        title="Assign Brand"
        dismissButtonOnClick={() => {
          setToggle(false);
        }}
      >
        <AssignBrandForm
          setToggle={setToggle}
          initialValues={addInitialValues}
          formButtonCta="Assign Brand"
          talentId={talentId}
        />
      </SlideOver>
      <AllTalentHeader
        talentName={
          talent?.attributes?.fanAccount?.attributes?.name || "Endorsed Brands"
        }
        talentId={talentId}
        active={urlLastSegment}
      />
      {searchParamsQuery || !_.isEmpty(brands) ? (
        <div className="flex justify-between items-center">
          <SearchBar
            searchTerm={searchParamsQuery}
            size="xl"
            align="center"
            onSearch={(val) => {
              navigateSelf(1, val, parsed);
            }}
            loading={loading}
          />

          {CurrentUser.isFanshipAdmin() ? (
            <Button className=" py-1.5 mr-7" onClick={() => setToggle(true)}>
              Assign Brand
            </Button>
          ) : null}
        </div>
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Brands Assigned"}
          loading={loading}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default EndorsedBrands;
