import React from "react";
import { useField, useFormikContext } from "formik";
import classNames from "classnames";
import TextInput from "./TextInput";

const PriceInput = ({ name, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  // eslint-disable-next-line
  const [field, meta] = useField({ name, ...props });
  const invalidChars = ["-", "+", "e", "E", "ArrowUp", "ArrowDown"];
  const checkChars = (e) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };
  const setTwoNumberDecimal = (e) => {
    const value = e.target.value ? parseFloat(e.target.value).toFixed(2) : "";
    setFieldValue(name, value);
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();
    // Prevent scrolling
    e.stopPropagation();
    // Refocus
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };
  return (
    <>
      <div className="mt-1 relative rounded-md shadow-sm ">
        <TextInput
          name={name}
          id={name}
          type="number"
          step=".01"
          className={classNames(
            "pl-7 pr-12  shadow-sm focus:ring-gray-900 focus:border-gray-900 block w-full sm:text-sm border-gray-300 rounded-md",
            meta.touched &&
              meta.error &&
              "border border-red-600 focus:ring-red-600 focus:border-red-600"
          )}
          onKeyDown={(e) => checkChars(e)}
          aria-describedby="price-currency"
          showErrorIcon={false}
          onBlur={(e) => {
            setTwoNumberDecimal(e);
            setFieldTouched(name, true);
          }}
          onWheel={numberInputOnWheelPreventChange}
          {...props}
        />

        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">₹</span>
        </div>
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            INR
          </span>
        </div>
      </div>
    </>
  );
};

export default PriceInput;
