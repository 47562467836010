import React, { useRef } from "react";
import TextInput from "./TextInput";
import Button from "../stories/Button";
import { useField } from "formik";

const Image = ({
  name,
  placeholder,
  imageData,
  setFieldValue,
  setImageData,
  ...props
}) => {
  const fileInputRef = useRef();
  const [field] = useField({ name, ...props });

  const getImageName = () => {
    return field?.value?.split("/").pop();
  };

  return (
    <>
      <div className="relative">
        <TextInput
          name={name}
          type="text"
          placeholder={placeholder}
          value={field.value?.uri || getImageName()}
          readOnly
        />
        <Button
          {...field}
          onClick={() => fileInputRef?.current?.click()}
          buttonStyle="secondary"
          size="sm"
          className="absolute right-0.5 top-px bottom-0.5 rounded-l-none focus:ring-0 focus:ring-offset-0"
        >
          Browse
        </Button>
      </div>
      <input
        ref={fileInputRef}
        onChange={(event) => {
          const { target } = event;
          const { files } = target;

          if (files && files[0]) {
            let reader = new FileReader();
            reader.onload = (event) => {
              let file_object;
              file_object = {
                uri: files?.[0]?.name?.toString(),
                file: files[0],
                data: event?.target?.result,
              };
              setFieldValue(name, file_object);
              setImageData(file_object?.data);
            };
            reader.readAsDataURL(files[0]);
          }
        }}
        accept=".jpg,.jpeg,.png"
        type="file"
        className="opacity-0 hidden absolute  px-12 border-gray-300 border"
      />
    </>
  );
};

export default Image;
