import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";

export const useCommentReportList = ({ page = "1" }) => {
  const queryObject = { page, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/comment_reports${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    reports: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useTalentCommentReportList = ({ page = "1", talentId }) => {
  const queryObject = { page, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/talents/${talentId}/comment_reports${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    reports: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
