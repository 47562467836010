import React, { useState } from "react";
import { useField, ErrorMessage } from "formik";
import Select from "react-select";
import Loader from "./Loader";
import classNames from "classnames";
import _ from "lodash";
import { capitalize } from "../utils/Helper";

const Multiselect = ({
  name,
  placeholder,
  items,
  onChange = () => {},
  displayKey,
  valueKey,
  localSearch,
  isValidating,
  customDisplay = null,
  ...props
}) => {
  const [field, meta, { setValue, setTouched }] = useField({ name, ...props });
  const color = (isFocused, error, touched) => {
    return isFocused && !error
      ? "rgba(23, 23, 23, 1)"
      : touched && error
      ? "rgba(220, 38, 38, 1)"
      : "rgba(212, 212, 212, 1)";
  };
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      borderRadius: "0.375rem",
      zIndex: 50,
    }),

    menuList: (provided, state) => ({
      ...provided,
      padding: 0,
    }),

    control: (provided, state) => ({
      ...provided,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      borderRadius: "0.375rem",
      padding: "0 0.25rem",
      boxShadow: state.isFocused
        ? `0 0 0 1px ${color(state.isFocused, meta.error, meta.touched)}`
        : 0,
      borderColor: color(state.isFocused, meta.error, meta.touched),

      "&:hover": {
        borderColor: color(state.isFocused, meta.error, meta.touched),
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      backgroundColor:
        state.isSelected || state.isFocused
          ? "rgba(243, 244, 246, 1)"
          : "transparent",
      color:
        state.isSelected || state.isFocused
          ? "rgba(17, 24, 39, 1)"
          : "rgba(55, 65, 81, 1);",
      padding: "0.5rem 1rem",
      ":active": {
        backgroundColor:
          state.isSelected || state.isFocused
            ? "rgba(243, 244, 246, 1)"
            : "transparent",
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "rgba(243, 244, 246, 1)",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "rgba(252, 165, 165, 1)" : undefined,
      ":hover": {
        backgroundColor: "rgba(254, 202, 202, 1)",
        color: "rgba(220, 38, 38, 1)",
      },
    }),
  };

  const getDisplayValue = (item) =>
    displayKey ? _.get(item, displayKey) : item["name"] || item["title"];

  const getValue = (item) => (valueKey ? _.get(item, valueKey) : item["id"]);

  const defaultItem = !_.isEmpty(field?.value)
    ? field?.value?.map((item) => {
        return _.find(items || [], (obj) => {
          return getValue(obj) === item[name];
        });
      })
    : [];

  const [selectedValues, setSelectedValues] = useState(
    field?.value ? defaultItem : []
  );

  const setFieldValue = (values) => {
    const data = values.map((item) => {
      return { [name]: getValue(item) };
    });
    setValue(data);
  };

  const onSelect = (value) => {
    setTouched(false);
    setSelectedValues(value);
    setFieldValue(value);
  };

  return (
    <div>
      <div
        className={classNames(
          "multiselect relative mt-1 text-left ",
          customDisplay ? "w-4/5 px-3" : "capitalize w-full"
        )}
      >
        <Select
          {...props}
          {...field}
          styles={customStyles}
          isMulti
          options={!_.isEmpty(items) ? items : []}
          getOptionLabel={(item) => {
            return customDisplay
              ? `${capitalize(getDisplayValue(item))} ${capitalize(
                  item?.attributes?.lastName
                )} (${
                  item?.attributes?.advertiserType === "brand_owner"
                    ? "Brand Owner"
                    : capitalize(item?.attributes?.advertiserType)
                }) - ${item?.attributes?.email}`
              : getDisplayValue(item);
          }}
          getOptionValue={(item) => getValue(item)}
          value={selectedValues}
          onChange={onSelect}
          onInputChange={(value) => {
            return onChange(value.length > 1 ? value : "");
          }}
          onBlur={() => setTouched(true)}
          placeholder={placeholder}
        />
        {isValidating && (
          <div
            className={classNames(
              "absolute top-2 w-6",
              selectedValues.length !== 0 ? "right-16" : "right-9"
            )}
          >
            <Loader />
          </div>
        )}
      </div>
      <ErrorMessage
        name={name}
        component="span"
        className={classNames(
          "text-red-700 text-sm absolute",
          customDisplay ? "px-3" : ""
        )}
      ></ErrorMessage>
    </div>
  );
};

export default Multiselect;
