import React, { useEffect, useState } from "react";
import _ from "lodash";
import Layout from "../../../../../../components/sideBar/Layout";
import { useLocation, useParams, navigate } from "@reach/router";
import { useTalent } from "../../../../../../lib/services/TalentService";
import PostHeader from "./PostHeader";
import PostTalentPortalHeader from "../../../../../navigation/talent-portal/talent-info/detail-page/posts/PostHeader";
import moment from "moment";
import Table from "../../../../../../components/Table";
import Author from "../../../../../../stories/Author";
import TableLayout from "../../../../../../components/TableLayout";
import { LoadContentHelper } from "../../../../../../table-components/LoadContentHelper";
import { usePostByTalentList } from "../../../../../../lib/services/PostService";
import { navigateSelf } from "../../../../../../utils/Helper";
import queryString from "query-string";
import SearchBar from "../../../../../../components/SearchBar";
import Button from "../../../../../../stories/Button";
import {
  ActionDeleteButton,
  ActionEditButton,
  ViewDataButton,
} from "../../../../../../table-components/Buttons";
import { ModalWrapper } from "../../../../../../utils/Modal";
import { API } from "../../../../../../lib/network/API";
import { toast } from "../../../../../../components/Toast";
import { ExclamationIcon } from "@heroicons/react/outline";
import { getCorrectOption } from "../../../../../../utils/Helper";
import { CurrentUser } from "../../../../../../lib/network/auth/Auth";
const TmaQuizzes = () => {
  const location = useLocation();
  const userType = CurrentUser.getType();
  const { talentId: tId } = useParams();
  const talentId = userType === "talent" ? CurrentUser.getId() : tId;
  const { talent } = useTalent(talentId);
  const urlLastSegment = location.pathname.split("/").pop();
  const [deleteId, setDeleteId] = useState(false);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  const parsed = queryString.parse(location.search);
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;

  const filterType = "quiz";
  const {
    posts: quizzes,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = usePostByTalentList(
    pageNumberQuery,
    searchParamsQuery,
    talentId,
    filterType
  );

  const deletePost = async () => {
    if (deleteId) {
      setModalPrimaryCtaLoading(true);
      const { error } = await API.delete(
        `/portal_api/v1/posts/${deleteId}`,
        {}
      );
      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ?? "This quiz cannot be deleted. Please try again"
          }`
        );
      } else {
        toast.success("Quiz post deleted successfully");
        setDeleteId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const columns = [
    {
      field: "Posts",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center">
          <Author
            imageSrc={
              _.find(rowData?.attributes?.attachments, {
                attributes: { purpose: "quiz_question" },
              })?.attributes?.attachmentVersions?.thumbnail?.key
                ? `https://fanship-uploads.s3.ap-south-1.amazonaws.com/${
                    _.find(rowData?.attributes?.attachments, {
                      attributes: { purpose: "quiz_question" },
                    })?.attributes?.attachmentVersions?.thumbnail?.key
                  }`
                : null
            }
            size="lg"
            className="cursor-default"
            imageShape="round-md"
          />
        </div>
      ),
    },

    {
      field: "Questions",
      render: (rowData) => (
        <div className="text-left  text-sm text-gray-500 max-w-xs">
          {rowData?.attributes?.quiz?.attributes?.question ?? "-NA-"}
        </div>
      ),
    },
    {
      field: "options",
      render: (rowData) => (
        <ol className=" px-6 list-decimal   text-sm text-gray-500">
          {_.map(
            rowData?.attributes?.quiz?.attributes?.options,
            (option, idx) => (
              <li key={idx}>{option}</li>
            )
          )}
        </ol>
      ),
    },
    {
      field: "Correct option",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.quiz?.attributes?.correctOption}
        </div>
      ),
    },
    {
      field: "status",
      render: (rowData) => (
        <div className="text-left uppercase whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.status || "-NA-"}
        </div>
      ),
    },
    {
      field: "published on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.publishedAt
            ? moment(rowData?.attributes?.publishedAt).format("ll")
            : "-NA-"}
        </div>
      ),
    },
    {
      field: "Actions",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row space-x-4 ">
          <ViewDataButton
            iconOnly
            onClick={() => {
              navigate(`./quizzes/${rowData?.id}`);
            }}
            disabled={
              rowData?.attributes?.status !== "published" ? true : false
            }
            buttonStyle={
              rowData?.attributes?.status !== "published"
                ? "disabled"
                : "primary"
            }
          />

          <ActionEditButton
            iconOnly
            onClick={() => {
              navigate("./quizzes/new-quiz", {
                state: {
                  quizId: rowData?.id,
                  quizData: {
                    id: rowData?.id,
                    talentId: rowData?.attributes?.talent?.id,
                    category: rowData?.attributes?.category,
                    status: rowData?.attributes?.status,
                    question: rowData?.attributes?.quiz?.attributes?.question,
                    optionA: rowData?.attributes?.quiz?.attributes?.options[0],
                    optionB: rowData?.attributes?.quiz?.attributes?.options[1],
                    optionC: rowData?.attributes?.quiz?.attributes?.options[2],
                    correctOption: getCorrectOption(
                      rowData?.attributes?.quiz?.attributes?.correctOption,
                      rowData?.attributes?.quiz?.attributes?.options
                    ),
                    attachments: rowData?.attributes?.attachments,
                  },
                },
              });
            }}
          />
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              setDeleteId(rowData?.id);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]);

  //render required for passing the updated media data when checking individal posts
  useEffect(() => {
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery]);

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const loadContent = () => {
    if (_.isEmpty(talent) || _.isEmpty(quizzes)) {
      return (
        <LoadContentHelper
          title="Quizzes"
          cta="Create Quiz"
          message="Please create a new Quiz by clicking below."
          ctaOnClick={() => navigate("./quizzes/new-quiz")}
          data={quizzes}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
        />
      );
    }

    return <Table column={columns} data={quizzes} />;
  };

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deletePost,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper title="Quiz Post" config={modalConfig} />
      {userType === "talent" ? (
        <PostTalentPortalHeader
          talentName={
            talent?.attributes?.fanAccount?.attributes?.name || "Quizzes"
          }
          talentId={talentId}
          active={urlLastSegment}
        />
      ) : (
        <PostHeader
          talentName={
            talent?.attributes?.fanAccount?.attributes?.name || "Quizzes"
          }
          talentId={talentId}
          active={urlLastSegment}
        />
      )}
      {searchParamsQuery || !_.isEmpty(quizzes) ? (
        <div className="flex justify-between items-center">
          <SearchBar
            searchTerm={searchParamsQuery}
            size="xl"
            align="center"
            onSearch={(val) => {
              navigateSelf(1, val, parsed);
            }}
            loading={loading}
          />

          <Button
            className=" py-1.5 mr-7"
            onClick={() => navigate("./quizzes/new-quiz")}
          >
            Create Quiz
          </Button>
        </div>
      ) : null}

      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Quiz List"
          paginationInfo={paginationInfo}
          loading={loading}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default TmaQuizzes;
