import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";
export const useAllTalentList = (searchTerm, { defaultValue, page = "1" }) => {
  const queryObject = { page, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/talents${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    allTalents: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
