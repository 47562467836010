import React from "react";
import Author from "../stories/Author";
import classNames from "classnames";
import TalentInfoHelper from "./TalentInfoHelper";

const TalentInfo = ({
  talent,
  selectedTalent,
  setSelectedTalent,
  setSelectedPostId,
}) => {
  return (
    <div
      className={classNames(
        "pl-5 pt-4 h-48 border-b-2 cursor-pointer",
        selectedTalent === parseInt(talent?.id) ? "bg-gray-100" : ""
      )}
      onClick={() => {
        setSelectedTalent(parseInt(talent?.id));
        setSelectedPostId("");
      }}
    >
      <Author
        name={talent?.attributes?.fanAccount?.attributes?.name}
        imageSrc={talent?.attributes?.fanAccount?.attributes?.image}
        cta={`@${talent?.attributes?.fanAccount?.attributes?.username}`}
        size="lg"
      />
      <TalentInfoHelper talent={talent} />
    </div>
  );
};

export default TalentInfo;
