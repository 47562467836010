import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useLocation } from "@reach/router";
import _ from "lodash";
import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import { useCommentReportList } from "../../lib/services/ReportsService";
import Table from "../../components/Table";
import moment from "moment";
import {
  ActionDeleteButton,
  ActionBlockAccountButton,
} from "../../table-components/Buttons";
import { ModalWrapper } from "../../utils/Modal";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import Author from "../../stories/Author";
import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import TableLayout from "../../components/TableLayout";
import { navigateSelf } from "../../utils/Helper";

const Reports = () => {
  const location = useLocation();

  const [commentId, setCommentId] = useState(false);
  const [fanId, setFanId] = useState(false);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);
  const [
    blockFanModalPrimaryCtaLoading,
    setBlockFanModalPrimaryCtaLoading,
  ] = useState(false);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;

  const deleteComment = async () => {
    if (commentId) {
      setModalPrimaryCtaLoading(true);
      // eslint-disable-next-line no-unused-vars
      const apiParams = {
        data: {
          attributes: {
            id: commentId,
          },
        },
      };

      const endpoint = "/portal_api/v1/comments/block";
      const { error } = await API.put(endpoint, apiParams);
      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${
            error.status ?? null
          }: Something went Wrong. Please try again`
        );
      } else {
        toast.success("Comment Deleted Successfully");

        setCommentId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const blockFan = async () => {
    if (fanId) {
      setBlockFanModalPrimaryCtaLoading(true);
      const apiParams = {
        data: {
          attributes: {
            id: fanId,
          },
        },
      };

      const endpoint = "/portal_api/v1/fans/block_account";
      const { error } = await API.put(endpoint, apiParams);

      setBlockFanModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${error.status ?? null}: ${
            error.message
              ? error.message
              : "Something went Wrong. Please try again"
          } `
        );
      } else {
        toast.success("Account Blocked Successfully");
        setFanId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const {
    reports,
    loading,
    error,
    mutate,
    isValidating,
    paginationInfo,
  } = useCommentReportList({ page: pageNumberQuery });

  const onAction = (action, data) => {
    if (action === "delete") {
      setCommentId(data.commentId);
    } else if (action === "remove") {
      setFanId(data.fanId);
    }
  };

  const columns = [
    {
      field: "post",
      render: (rowData) => (
        <div className="text-left break-words text-sm font-normal text-gray-900 ">
          <Author
            imageSrc={
              rowData?.attributes?.comment?.attributes?.post?.attributes
                ?.previewImage?.thumbnail?.url
            }
            size="lg"
            className="cursor-default"
            imageShape="round-md"
          />
        </div>
      ),
    },

    {
      field: "comment",
      render: (rowData) => (
        <div className="text-left break-words w-48 text-sm text-gray-500">
          {rowData?.attributes?.comment?.attributes?.content}
        </div>
      ),
    },

    {
      field: "commented by",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.comment?.attributes?.fan?.attributes?.name}
        </div>
      ),
    },
    {
      field: "reported by",
      render: (rowData) => (
        <div className="flex flex-row text-left items-center  text-sm text-gray-500 space-x-2">
          <div>{rowData?.attributes?.fan?.attributes?.name}</div>
          <div className=" text-xs font-extralight">
            (<span>On: </span>
            {moment(`${rowData?.attributes?.createdAt}`).format("ll")})
          </div>
        </div>
      ),
    },
    {
      field: "reason",
      render: (rowData) => (
        <div className="text-left break-words text-sm text-gray-500 capitalize">
          {rowData?.attributes?.reason || "-NA-"}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm  flex flex-row space-x-4 ">
          {rowData?.attributes?.comment?.attributes?.status !== "blocked" &&
          rowData?.attributes?.comment?.attributes?.fan?.attributes?.status !==
            "blocked" ? (
            <>
              <ActionBlockAccountButton
                iconOnly
                onClick={() => {
                  onAction("remove", {
                    fanId: rowData?.attributes?.comment?.attributes?.fan?.id,
                  });
                }}
              />

              <ActionDeleteButton
                iconOnly
                onClick={() => {
                  onAction("delete", {
                    commentId: rowData?.attributes?.comment?.id,
                    postId: rowData?.attributes?.comment?.attributes?.post?.id,
                  });
                }}
              />
            </>
          ) : (
            "-"
          )}
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(reports)) {
      return (
        <LoadContentHelper
          title="Reports"
          data={reports}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
        />
      );
    }
    return <Table column={columns} data={reports} />;
  };

  useEffect(() => {
    if (!fanId || !commentId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fanId, commentId]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(notWithingRange ? 1 : pageNumberQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo]);

  const deleteCommentModalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deleteComment,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setCommentId,
    },
    open: { value: commentId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  const blockAccountModalConfig = {
    primaryCta: {
      value: "Yes",
      loading: blockFanModalPrimaryCtaLoading,
      onClick: blockFan,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setFanId,
    },
    open: { value: fanId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper title="Comment" config={deleteCommentModalConfig} />
      <ModalWrapper
        fullTitle="Block Account"
        desc="Are you sure you want to block this Account?"
        config={blockAccountModalConfig}
      />
      <div className="sticky top-0 z-50">
        <SectionHeading title="Reports" />
      </div>
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Reports List"
          loading={loading || error}
          paginationInfo={paginationInfo}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default Reports;
