import React, { useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import Button from "../../../stories/Button";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import { blacklistKeywordValidationSchema } from "../../validation";
import FormFields from "../../FormFields";

const BlacklistedKeywordForm = ({
  setToggle,
  initialValues,
  editMode,
  formButtonCta,
}) => {
  const buttonRef = useRef(null);

  const formValues = [
    {
      label: "Keyword *",
      attributeId: "word",
      placeholder: "",
      type: "TextInput",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    const fetchedValues = {
      word: values.word.trim(),
    };

    const apiParams = {
      data: { attributes: fetchedValues },
    };
    const endpoint = `/portal_api/v1/black_listed_words/${
      editMode ? initialValues.id : ""
    }`;

    const { error } = editMode
      ? await API.put(endpoint, apiParams)
      : await API.post(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(`Keyword ${editMode ? "updated" : "added"} successfully`);
      setToggle(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={blacklistKeywordValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="space-y-7">
          {/* json Form component */}
          <FormFields
            setFieldValue={setFieldValue}
            values={values}
            formValues={formValues}
          />

          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="w-full"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default BlacklistedKeywordForm;
