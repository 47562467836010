import React, { useEffect } from "react";
import { useLocation, Link } from "@reach/router";
import queryString from "query-string";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import Table from "../../../components/Table";
import { useCampaignsList } from "../../../lib/services/advertiser/CampaignsService";
import moment from "moment";
import SearchBar from "../../../components/SearchBar";
import _ from "lodash";
import TableLayout from "../../../components/TableLayout";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../utils/Helper";
import Author from "../../../stories/Author";
import Avatar from "../../../stories/Avatar";

const Campaigns = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const columns = [
    {
      field: "Campaigns",
      render: (rowData) => (
        <Link
          to={`/advertiser/campaigns/${rowData?.id}`}
          className=" capitalize text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center"
        >
          <Author
            className="pr-4 2xl:pr-0"
            name={rowData?.attributes?.name}
            imageSrc={rowData?.attributes?.brand?.attributes?.image}
            type="brand"
            size="lg"
          />
        </Link>
      ),
    },
    {
      field: "Post",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {!_.isEmpty(rowData?.attributes?.posts) ? (
            <div className="flex flex-col space-y-1">
              {rowData?.attributes?.posts?.map((post, idx) => (
                <Avatar
                  imageSrc={post?.attributes?.previewImage?.thumbnail?.url}
                  size="lg"
                  key={idx}
                />
              ))}
            </div>
          ) : (
            "-NA-"
          )}
        </div>
      ),
    },
    {
      field: "Impressions",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.targetImpressions}
        </div>
      ),
    },
    {
      field: "Replay Rate",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.replayRate || "-NA-"}
        </div>
      ),
    },
    {
      field: "Likes",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.likesCount}
        </div>
      ),
    },
    {
      field: "Average Replay",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.averageReplays}
        </div>
      ),
    },
    {
      field: "Added on",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.createdAt
            ? moment(`${rowData?.attributes?.createdAt}`).format("ll")
            : "-NA-"}
        </div>
      ),
    },
  ];

  const {
    campaigns,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useCampaignsList(pageNumberQuery, searchParamsQuery);

  const loadContent = () => {
    if (_.isEmpty(campaigns)) {
      return (
        <LoadContentHelper
          title="Campaign"
          data={campaigns}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    return <Table column={columns} data={campaigns} />;
  };

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  return (
    <Layout>
      <div className="sticky top-0 z-50">
        <SectionHeading title="Campaigns" />
      </div>
      <SearchBar
        searchTerm={searchParamsQuery}
        size="xl"
        align="center"
        onSearch={(val) => {
          navigateSelf(1, val, parsed);
        }}
        loading={loading}
      />

      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Campaigns List"
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default Campaigns;
