import React, { useRef, useState } from "react";
import { PencilIcon } from "@heroicons/react/solid";
import Button from "../stories/Button";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageCropperModal from "./ImageCropperModal";

const ImageInput = ({ imageData, setFieldValue, imgPlaceholder }) => {
  const [image, setImage] = useState(imageData);
  const [cropper, setCropper] = useState();
  const [imgObj, setImgObj] = useState({});
  const [modal, setModal] = useState(false);

  const onChangeHandler = (e) => {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let file_object;
        file_object = {
          uri: files?.[0]?.name?.toString(),
          file: files[0],
          data: reader.result,
        };
        setImgObj(file_object);
        setModal(true);
        setImage(reader.result);
      };
      if (e.target.files.length) {
        reader.readAsDataURL(files[0]);
        e.target.files = null;
      } else {
        setModal(false);
      }
    }
  };

  const getCropData = async (e) => {
    e.preventDefault();
    if (typeof cropper !== "undefined") {
      const file_obj = {
        ...imgObj,
        data: cropper.getCroppedCanvas()?.toDataURL(),
      };
      if (cropper.getCroppedCanvas()?.toDataURL()) {
        setFieldValue("image", file_obj);
      }
      setModal(false);
    }
  };

  const fileInputRef = useRef();
  const currentImg = (imageData, imgPlaceholder) => {
    if (imageData?.image?.data?.length) {
      return imageData?.image?.data;
    } else if (imageData?.image?.uri?.length) {
      return imageData?.image?.uri;
    } else if (imageData?.image?.length) {
      return imageData.image;
    } else if (imgPlaceholder) {
      return imgPlaceholder;
    } else return "/assets/images/placeholder/userPlaceholder.svg";
  };

  const cropperHandler = () => {
    return (
      <div className="flex flex-col items-end">
        <Cropper
          style={{ height: 400, width: "100%" }}
          zoomTo={0}
          aspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
          dragMode={1}
        />
        <h1 className="mt-4 ">
          <Button onClick={getCropData}>Crop Image</Button>
        </h1>
      </div>
    );
  };

  return (
    <div>
      <ImageCropperModal
        cropperImg={cropperHandler()}
        open={modal}
        setOpen={setModal}
        setImgObj={setImgObj}
        setFieldValue
      ></ImageCropperModal>

      <div className="flex items-center justify-center ">
        <div className="relative w-36 h-auto bg-gray-200">
          <img
            src={currentImg(imageData, imgPlaceholder)}
            alt="Img"
            className=" w-36 h-36   border-2"
          />

          <Button
            onClick={() => {
              fileInputRef?.current?.click();
            }}
            className=" absolute -bottom-4 -right-4 cursor-pointer"
            iconAlso={true}
            circular={true}
            iconPlacement="leading"
            icon={<PencilIcon />}
            size="xl"
          ></Button>
        </div>
      </div>

      <input
        ref={fileInputRef}
        onChange={onChangeHandler}
        accept="image/*"
        type="file"
        className="opacity-0 hidden absolute  px-12 border-gray-300 border"
      />
    </div>
  );
};

export default ImageInput;
