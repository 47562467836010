import React, { useState } from "react";
import _ from "lodash";
import SearchBar from "../components/SearchBar";
import TalentInfo from "./TalentInfo";
import Loader from "./Loader";
import { useTalentsByBrandCategory } from "../lib/services/TalentService";

const TalentList = ({
  brandCategoryId,
  selectedTalent,
  setSelectedTalent,
  setSelectedPostId,
}) => {
  const [searchParamsQuery, setSearchParamsQuery] = useState("");
  const { talents, loading } = useTalentsByBrandCategory(searchParamsQuery, {
    brandCategoryId: brandCategoryId,
  });
  const loadContent = () => {
    if (loading) {
      return (
        <div className="h-full mt-40">
          <Loader />
        </div>
      );
    } else if (_.isEmpty(talents)) {
      return (
        <h3 className="mx-6 font-medium flex justify-center mt-4 ">No Data</h3>
      );
    }
    return talents?.map((talent, idx) => (
      <TalentInfo
        talent={talent}
        setSelectedTalent={setSelectedTalent}
        selectedTalent={selectedTalent}
        setSelectedPostId={setSelectedPostId}
        key={idx}
      />
    ));
  };
  return (
    <>
      <div className="border-r-2 px-6  py-4 text-2xl font-medium">Talents</div>
      <div className="border-t-2 border-r-2 border-b-2 h-full overflow-auto">
        <div className="bg-white sticky top-0 z-50">
          <SearchBar
            searchTerm={searchParamsQuery}
            align="right"
            onSearch={(val) => {
              setSearchParamsQuery(val);
            }}
            loading={loading}
            showButton={false}
          />
        </div>
        {loadContent()}
      </div>
    </>
  );
};

export default TalentList;
