import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "@reach/router";
import Table from "../../../../components/Table";
import SlideOver from "../../../../stories/SlideOver";
import moment from "moment";
import { ActionEditButton } from "../../../../table-components/Buttons";
import _ from "lodash";
import TableLayout from "../../../../components/TableLayout";
import { LoadContentHelper } from "../../../../table-components/LoadContentHelper";
import EditCreditForm from "../../../../forms/pageForms/talentForms/EditCreditForm";
import Layout from "../../../../components/sideBar/Layout";
import { useTalent } from "../../../../lib/services/TalentService";
import AllTalentHeader from "./AllTalentHeader";

import { CurrentUser } from "../../../../lib/network/auth/Auth";
const TalentCreditModel = () => {
  const location = useLocation();

  const { talentId } = useParams();
  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const { talent, loading, error, mutate, isValidating } = useTalent(talentId);
  const urlLastSegment = location.pathname.split("/").pop();

  const columns = [
    {
      field: "name",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center">
          Credit Per Quiz Winning
        </div>
      ),
    },
    {
      field: "credit",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.credit}
        </div>
      ),
    },
    {
      field: "last updated on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {moment(`${rowData?.attributes?.updated_at}`).format("LL")}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionEditButton
            iconOnly
            onClick={() => {
              setEditData({
                ...rowData?.attributes,
                id: rowData.id,
              });
              setToggle(true);
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(talent) || _.isEmpty(talent?.attributes?.talentCredits)) {
      return (
        <LoadContentHelper
          title="Credit"
          cta=" Update Credit"
          ctaOnClick={() => setToggle(true)}
          data={talent?.attributes?.talentCredits}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
        />
      );
    }
    if (CurrentUser.isFanshipAdmin()) {
      return (
        <Table column={columns} data={[talent?.attributes?.talentCredits]} />
      );
    } else {
      return (
        <Table
          column={_.initial(columns)}
          data={[talent?.attributes?.talentCredits]}
        />
      );
    }
  };

  useEffect(() => {
    if (!toggle || !editData) {
      mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData]);

  return (
    <Layout>
      <SlideOver
        open={toggle}
        backgroundOverlay="dark"
        size="xl"
        title="Edit Credits"
        dismissButtonOnClick={() => {
          setToggle(false);
          setEditData(false);
        }}
      >
        <EditCreditForm
          setToggle={setToggle}
          initialValues={editData}
          formButtonCta="Update Credits"
          talentId={talent.id}
        />
      </SlideOver>

      <AllTalentHeader
        talentName={
          talent?.attributes?.fanAccount?.attributes?.name || "Credit Model"
        }
        talentId={talentId}
        active={urlLastSegment}
      />
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout title="Credit Model" loading={loading}>
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default TalentCreditModel;
