import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import Layout from "../../../../../../components/sideBar/Layout";
import queryString from "query-string";
import { navigate, useLocation, useParams } from "@reach/router";
import { useTalent } from "../../../../../../lib/services/TalentService";
import SearchBar from "../../../../../../components/SearchBar";
import Table from "../../../../../../components/Table";
import TableLayout from "../../../../../../components/TableLayout";
import { LoadContentHelper } from "../../../../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../../../../utils/Helper";

import { usePostByTalentList } from "../../../../../../lib/services/PostService";
import { ViewDataButton } from "../../../../../../table-components/Buttons";
import Author from "../../../../../../stories/Author";
import PostHeader from "./PostHeader";
import PostTalentPortalHeader from "../../../../../navigation/talent-portal/talent-info/detail-page/posts/PostHeader";
import GroupAvatar from "../../../../../../stories/GroupAvatar";
import { CurrentUser } from "../../../../../../lib/network/auth/Auth";
import GenericModal from "../../../../../../components/GenericVideoModal";
const TmaMonetizedPosts = () => {
  const location = useLocation();
  const userType = CurrentUser.getType();
  const parsed = queryString.parse(location.search);
  const userRole = CurrentUser.getRole();
  const { talentId: tId } = useParams();
  const talentId = userType === "talent" ? CurrentUser.getId() : tId;
  const { talent } = useTalent(talentId);
  const [videoModal, setVideoModal] = useState(false);
  const [videoPlayUrl, setVideoPlayUrl] = useState(false);
  const urlLastSegment = location.pathname.split("/").pop();
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const filterType = "monetized";
  const {
    posts,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = usePostByTalentList(
    pageNumberQuery,
    searchParamsQuery,
    talentId,
    filterType
  );

  const getValue = (item) => item["id"];
  const setFieldValue = (values) => {
    return values.map((item) => {
      return { brandCategoryId: getValue(item) };
    });
  };

  const columns = [
    {
      field: "Caption",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap  text-sm font-medium text-gray-900">
          {rowData?.attributes?.caption ?? "-na-"}
        </div>
      ),
    },

    {
      field: "Post Attachments",
      render: (rowData) => (
        <div className="text-left  text-sm text-gray-500">
          {_.isEmpty(rowData?.attributes?.attachments) ? (
            "-na-"
          ) : (
            <>
              {rowData?.attributes?.containsVideo ? (
                <>
                  <div
                    class="w-16 h-16 ring-2 ring-white rounded-md bg-gray-900  grid place-items-center hover:bg-gray-800 cursor-pointer transition"
                    onClick={() => {
                      setVideoModal(true);
                      setVideoPlayUrl(
                        _.find(rowData?.attributes?.attachments, [
                          "attributes.category",
                          "video",
                        ])?.attributes?.key
                      );
                    }}
                  >
                    {rowData?.attributes?.previewImage?.thumbnail?.url && (
                      <img
                        className="rounded-md"
                        src={rowData?.attributes?.previewImage?.thumbnail?.url}
                        alt="video"
                      />
                    )}
                    <svg
                      class="ml-1 w-4 absolute "
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </>
              ) : (
                <GroupAvatar
                  size="xl"
                  group={(rowData?.attributes?.attachments).filter(
                    (attachment) => attachment?.attributes?.category === "image"
                  )}
                  imageShape="round-md"
                />
              )}
            </>
          )}
        </div>
      ),
    },
    {
      field: "status",
      render: (rowData) => (
        <div className="text-left uppercase whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.status || "-na-"}
        </div>
      ),
    },
    {
      field: "published on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.publishedAt
            ? moment(rowData?.attributes?.publishedAt).format("ll")
            : "-na-"}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          <ViewDataButton
            iconOnly
            onClick={() => {
              navigate(`./monetized-posts/${rowData?.id}`);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const loadContent = () => {
    if (_.isEmpty(talent) || _.isEmpty(posts)) {
      return (
        <LoadContentHelper
          title="Post"
          message="No monetized post available."
          data={posts}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }

    return <Table column={columns} data={posts} />;
  };

  return (
    <Layout>
      <GenericModal
        open={videoModal}
        handleClose={() => setVideoModal(false)}
        videoUrl={videoPlayUrl}
      />
      {userType === "talent" ? (
        <PostTalentPortalHeader
          talentName={
            talent?.attributes?.fanAccount?.attributes?.name ||
            "Monetized Posts"
          }
          talentId={talentId}
          active={urlLastSegment}
        />
      ) : (
        <PostHeader
          talentName={
            talent?.attributes?.fanAccount?.attributes?.name ||
            "Monetized Posts"
          }
          talentId={talentId}
          active={urlLastSegment}
        />
      )}
      {searchParamsQuery || !_.isEmpty(posts) ? (
        <div className="flex justify-between items-center">
          <SearchBar
            searchTerm={searchParamsQuery}
            size="xl"
            align="center"
            onSearch={(val) => {
              navigateSelf(1, val, parsed);
            }}
            loading={loading}
          />
        </div>
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Monetized Post List"
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
          loading={loading}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default TmaMonetizedPosts;
