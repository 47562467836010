import { useAPI } from "../network/SWR";

export const useProfile = () => {
  const key = "/portal_api/v1/profile";

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});
  return {
    profile: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
