import React, { useState, useContext } from "react";
import _ from "lodash";
import TableLayout from "./TableLayout";
import { LoadContentHelper } from "../table-components/LoadContentHelper";
import { useCampaignPostByTalentList } from "../lib/services/CampaignService";
import CampaignContext from "../lib/store/campaignContext";
import Posts from "./Posts";

const PostsSection = ({ talentId, selectedPostId, setSelectedPostId }) => {
  const campaignData = useContext(CampaignContext);
  const [pageNumberQuery, setPageNumberQuery] = useState(1);
  const {
    posts,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useCampaignPostByTalentList({
    page: pageNumberQuery,
    talentId: talentId,
    campaignId: campaignData.campaign_id,
  });

  const loadContent = () => {
    if (_.isEmpty(posts)) {
      return (
        <LoadContentHelper
          title="Media"
          ctaOnClick={null}
          data={posts}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
        />
      );
    }

    return (
      <Posts
        setSelectedPostId={setSelectedPostId}
        selectedPostId={selectedPostId}
        posts={posts}
      />
    );
  };

  return (
    <div className="min-h-full bg-gray-100">
      <TableLayout
        title="Media List (Select*)"
        paginationInfo={paginationInfo}
        loading={loading}
        setPageNumberQuery={setPageNumberQuery}
        forModal={true}
      >
        {loadContent()}
      </TableLayout>
    </div>
  );
};

export default PostsSection;
