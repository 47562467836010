import React, { useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import Button from "../../../stories/Button";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import { creditValidationSchema } from "../../validation";
import FormFields from "../../FormFields";

const EditCreditForm = ({
  setToggle,
  initialValues,
  editMode,
  formButtonCta,
  talentId,
}) => {
  const buttonRef = useRef(null);

  const formValues = [
    {
      label: "Credits *",
      attributeId: "credit",
      placeholder: "Enter credits",
      type: "TextInput",
      inputType: "number",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    const fetchedValues = {
      ..._.pick(values, ["credit"]),
    };

    const apiParams = {
      data: {
        attributes: fetchedValues,
      },
    };

    const endpoint = `/portal_api/v1/talents/${talentId}/talent_credit`;

    const { error } = await API.put(endpoint, apiParams);
    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success("Credit updated Successfully");
      setToggle(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={creditValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="space-y-7">
          <FormFields
            values={values}
            formValues={formValues}
            setFieldValue={setFieldValue}
          />
          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="w-full py-2"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default EditCreditForm;
