import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../stories/Button";
import Author from "../../stories/Author";
import SideNav from "./SideNavContent";
import { LogoutIcon, XIcon } from "@heroicons/react/outline";

const MobileSideBar = ({ show, as, sidebarOpen, setSidebarOpen }) => {
  return (
    <Transition.Root show={show} as={as}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 md:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex items-center flex-shrink-0 px-4 pt-5 pb-2">
              <img
                className="h-10 w-auto"
                src="/assets/images/logo/logo-white.svg"
                alt="fanship"
              />
            </div>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <SideNav />
            </div>
            <div className="flex-shrink-0 flex bg-gray-800 p-4">
              <Button
                type="submit"
                className="mx-auto w-full"
                iconAlso={true}
                iconPlacement="leading"
                icon={<LogoutIcon />}
                buttonStyle="secondary"
              >
                Logout
              </Button>
            </div>
            <div className="flex-shrink-0 flex bg-gray-700 p-4  ">
              <Author
                name={"Tom Cook"}
                darkmode={false}
                cta={"View Profile"}
                imageSrc={
                  "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                }
              />
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileSideBar;
