import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

const SlideOver = ({
  title,
  description,
  size,
  backgroundOverlay,
  children,
  open,
  setOpen,
  dismissButtonOnClick,
  ...rest
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={open}
        onClose={dismissButtonOnClick}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay
            className={classNames(
              "absolute inset-0 ",
              backgroundOverlay === "dark"
                ? "bg-gray-500 bg-opacity-75 transition-opacity"
                : ""
            )}
          />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={classNames(
                  "w-screen",
                  size === "xl" ? "max-w-2xl" : "max-w-md"
                )}
              >
                <div className="h-full flex flex-col bg-white shadow-xl ">
                  <div className="py-6 px-4 bg-black sm:px-6 sticky top-0 z-50">
                    <div className="flex items-center justify-between ">
                      <Dialog.Title className=" text-lg font-medium text-white">
                        {title}
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          className="bg-black rounded-md text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={dismissButtonOnClick}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-sm text-gray-300">{description}</p>
                    </div>
                  </div>
                  <div className="my-4 relative flex-1 px-4 sm:px-6">
                    {/* Replace with your content */}
                    {children}
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

SlideOver.defaultProps = {
  title: "Page Title",
};

SlideOver.propTypes = {
  /**
   * Title
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * size of Slide over Tab
   */
  size: PropTypes.oneOf(["xl"]),

  /**
   * Background Overlay color
   */
  backgroundOverlay: PropTypes.oneOf(["dark"]),
};

export default SlideOver;
