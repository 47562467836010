import React, { useState, useRef } from "react";
import Button from "../../../stories/Button";
import { Formik, Form } from "formik";
import { navigate } from "@reach/router";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import _ from "lodash";
import QuizDetailsForm from "./QuizDetailsForm";
import QuizMediaForm from "./QuizMediaForm";

const QuizForm = ({
  initialValues,
  editing,
  step,
  published,
  talentId = null,
}) => {
  const buttonRef = useRef(null);
  const [formNumber, setFormNumber] = useState(editing ? step : 0);
  const [quizId, setQuizId] = useState(editing ? initialValues?.id : "");
  const quizFormNumber = {
    QUIZ_DETAILS_FORM: 1,
    QUIZ_MEDIA_FORM: 2,
  };

  const hasReachedForm = (form) => {
    return formNumber >= form;
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const apiParams = {
      data: {
        attributes: {
          status: "ready_to_publish",
        },
      },
    };

    const endpoint = `/portal_api/v1/quizzes/${quizId}`;

    const { error } = await API.put(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went wrong. Please try again"}`
      );
    } else {
      toast.success(`Quiz published successfully`);
      navigate(-1);
    }
  };

  return (
    <>
      <div className="space-y-7">
        <QuizDetailsForm
          initialValues={initialValues}
          quizId={quizId}
          setQuizId={setQuizId}
          editing={editing}
          quizFormNumber={quizFormNumber}
          hasReachedForm={hasReachedForm}
          formNumber={formNumber}
          setFormNumber={setFormNumber}
          published={published}
          talentId={talentId}
        />
        {hasReachedForm(quizFormNumber.QUIZ_DETAILS_FORM) && (
          <QuizMediaForm
            initialValues={initialValues}
            quizId={quizId}
            editing={editing}
            quizFormNumber={quizFormNumber}
            formNumber={formNumber}
            hasReachedForm={hasReachedForm}
            setFormNumber={setFormNumber}
            published={published}
          />
        )}
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="space-y-7 mt-7">
            <div className="flex flex-row-reverse">
              {!published &&
                hasReachedForm(quizFormNumber.QUIZ_MEDIA_FORM) &&
                (values?.status === "draft" ? (
                  <Button
                    buttonStyle="danger"
                    ref={buttonRef}
                    type="submit"
                    loading={isSubmitting}
                    className="w-1/6"
                  >
                    Publish
                  </Button>
                ) : null)}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default QuizForm;
