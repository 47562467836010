import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { CurrentUser } from "../../lib/network/auth/Auth";
import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import SlideOver from "../../stories/SlideOver";
import Button from "../../stories/Button";
import { useProfile } from "../../lib/services/ProfileService";
import ResetPasswordForm from "../../forms/pageForms/ResetPasswordForm";
import ProfileForm from "../../forms/pageForms/ProfileForm";
import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import TalentProfileForm from "../../forms/pageForms/TalentProfileForm";

const ProfilePage = () => {
  const data = CurrentUser.getDetails();
  const userType = CurrentUser.getType();
  const profileId = data?.claims?.entity?.id;
  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  };

  const getValue = (item) => item["id"];
  const setFieldValue = (values) => {
    return values.map((item) => {
      return { talentCategoryId: getValue(item) };
    });
  };

  const { profile, loading, error, mutate, isValidating } = useProfile(
    profileId
  );
  const loadContent = () => {
    if (_.isEmpty(profile)) {
      return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg  ">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Profile Details
            </h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <LoadContentHelper
              title="Profile"
              ctaOnClick={null}
              data={profile}
              loading={loading}
              error={error}
              mutate={mutate}
              isValidating={isValidating}
              searchTerm={null}
              message="We’re facing some technical issue, please! try again."
            />
          </div>
        </div>
      );
    }
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg  ">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Profile Details
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Profile Picture
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="relative w-36 h-auto bg-gray-200">
                  <img
                    src={
                      profile.attributes.image ??
                      "/assets/images/placeholder/userPlaceholder.svg"
                    }
                    alt="Img"
                    className=" w-36 h-36 object-contain  border-2"
                  />
                </div>
              </dd>
            </div>

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Full Name</dt>
              {profile?.type === "talent" ? (
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 capitalize">
                  {profile?.type === "talent"
                    ? profile?.attributes?.name
                    : "-NA-"}
                </dd>
              ) : (
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 capitalize">
                  {profile.attributes?.firstName
                    ? `${profile.attributes?.firstName} ${
                        profile.attributes?.lastName
                          ? profile.attributes?.lastName
                          : null
                      }`
                    : "-NA-"}
                </dd>
              )}
            </div>

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Phone Number
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {profile.attributes?.countryCode
                  ? `+${profile.attributes?.countryCode}-`
                  : null}
                {profile.attributes?.phone || "-NA-"}
              </dd>
            </div>

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Email address
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {profile.attributes?.email || "-NA-"}
              </dd>
            </div>

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                {profile?.type === "talent" ? "Type" : "Role"}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 capitalize">
                {profile?.type === "talent"
                  ? profile?.type
                  : profile.attributes?.role
                  ? profile.attributes?.role
                  : "-NA-"}
              </dd>
            </div>
            {profile?.type === "talent" ? (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Talent Category
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {_.isEmpty(profile?.attributes?.talentCategories)
                    ? "-na-"
                    : profile?.attributes?.talentCategories?.map(
                        (item, idx) => {
                          return <div key={idx}>{item?.attributes?.name}</div>;
                        }
                      )}
                </dd>
              </div>
            ) : null}
            {profile?.type === "talent" ? (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Talent Management Agency
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {profile?.attributes?.talentManagement?.attributes?.name ??
                    "-Not Assinged-"}
                </dd>
              </div>
            ) : null}

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Joined On</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {profile.attributes?.createdAt
                  ? moment(profile.attributes?.createdAt).format("ll")
                  : "-NA-"}
              </dd>
            </div>

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ">
              <dt className="text-sm my-2 font-medium text-gray-500 space-y-4">
                Security
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ">
                <Button
                  onClick={() => {
                    setToggle(true);
                  }}
                >
                  Change Password
                </Button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!toggle) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData]);

  return (
    <Layout>
      <SlideOver
        open={toggle}
        size="xl"
        backgroundOverlay="dark"
        title={editData ? "Update Profile" : "Change Password"}
        dismissButtonOnClick={() => {
          setToggle(false);
          setTimeout(() => {
            setEditData(false);
          }, 500);
        }}
      >
        {/* password reset form */}
        {editData ? (
          userType === "talent" ? (
            <TalentProfileForm
              setToggle={setToggle}
              initialValues={editData}
              formButtonCta="Update Profile"
              setEditData={setEditData}
            />
          ) : (
            <ProfileForm
              setToggle={setToggle}
              initialValues={editData}
              formButtonCta="Update Profile"
              setEditData={setEditData}
            />
          )
        ) : (
          <ResetPasswordForm
            setToggle={setToggle}
            initialValues={initialValues}
            formButtonCta="Change Password"
            editMode={false}
          />
        )}
      </SlideOver>

      <div className="sticky top-0 z-50">
        <SectionHeading
          title="Profile"
          primaryCta="Edit Profile"
          primaryOnClick={() => {
            setToggle(true);
            if (userType === "talent") {
              setEditData({
                ...profile.attributes,
                countryCode: profile.attributes?.countryCode || "91",
                phone: (profile.attributes?.countryCode || "91").concat(
                  profile.attributes?.phone
                ),
                gender:
                  profile?.attributes?.gender === "undisclosed_gender"
                    ? "undisclosed gender"
                    : profile?.attributes?.gender,
                costPerImpression: profile?.attributes?.costPerImpression
                  ? (profile?.attributes?.costPerImpression / 100).toFixed(2)
                  : "",
                talentCategoryId: setFieldValue(
                  profile?.attributes?.talentCategories
                ),
                talentCategories: profile?.attributes?.talentCategories,
                id: profile.id,
              });
            } else {
              setEditData({
                ...profile.attributes,
                countryCode: profile.attributes?.countryCode || "91",
                phone: (profile.attributes?.countryCode || "91").concat(
                  profile.attributes?.phone
                ),
                id: profile.id,
              });
            }
          }}
        />
      </div>
      <main className="flex-1 relative overflow-y-auto focus:outline-none w-full lg:w-5/6 xl:w-3/5 p-6">
        {loadContent()}
      </main>
    </Layout>
  );
};

export default ProfilePage;
