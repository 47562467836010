import React, { useState, useRef } from "react";
import { privacyPolicyValidationSchema } from "../../validation";
import { Formik, Form } from "formik";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import _ from "lodash";
import FormHeader from "../../../components/FormHeader";
import FormFields from "../../FormFields";
const PrivacyPolicyForm = ({ initialValues, editing }) => {
  const buttonRef = useRef(null);
  const formValues = [
    {
      attributeId: "privacyPolicy",
      type: "TextEditorInput",
      placeholder: "Ex: How do I sign up in the App ?",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    const displayData = {
      privacyPolicy: values?.privacyPolicy,
    };
    const apiParams = {
      data: {
        attributes: {
          privacyPolicy: values?.privacyPolicy,
        },
      },
    };

    const endpoint = `/portal_api/v1/privacy`;

    const { error } = await API.put(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went wrong. Please try again"}`
      );
    } else {
      toast.success(`privacy Policy published successfully`);
      setEditMode(false);
      setFormData(displayData);
    }
  };

  const [editMode, setEditMode] = useState(editing ? false : true);
  const [showCancel, setShowCancel] = useState(editing ? true : false);

  const [formData, setFormData] = useState(editing ? initialValues : []);

  const onEdit = (e) => {
    e.preventDefault();
    setEditMode(true);
  };
  const onCancel = () => {
    setEditMode(false);
  };

  return (
    <div className="rounded-md bg-white px-2 pb-6 border-2 border-gray-300 ">
      <Formik
        initialValues={initialValues}
        validationSchema={privacyPolicyValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, setFieldValue, resetForm }) => (
          <>
            <Form className="flex flex-col justify-between">
              <FormHeader
                sectionHeading=" Fanship privacy policy"
                cancelOnClick={onCancel}
                editOnClick={onEdit}
                editMode={editMode}
                isSubmitting={isSubmitting}
                buttonRef={buttonRef}
                showCancel={showCancel}
              />
              <div className="pb-3 px-2">
                <FormFields
                  setFieldValue={setFieldValue}
                  values={values}
                  formValues={formValues}
                  editMode={editMode}
                  formData={formData}
                />
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default PrivacyPolicyForm;
