import React from "react";
import { XIcon } from "@heroicons/react/solid";
import Label from "../Label";
import UploadImage from "./UpLoadImage";

export const MediaAttachments = ({
  imageList,
  editImageList,
  editing,
  onImageListHandler,
  onEditImageListHandler,
  attachmentType = "multiple",
  mediaStatus,
  label = null,
  labelMessage = null,
  showCancelButton = true,
}) => {
  return (
    <div className="flex flex-col">
      <Label>
        {label}{" "}
        <span className=" text-gray-800 text-xs font-bold">
          {labelMessage ? `(${labelMessage})` : null}
        </span>
      </Label>

      <div className="flex flex-wrap">
        {imageList?.map((el, index) => (
          <React.Fragment key={index}>
            <div className="bg-gray-200 flex items-center justify-center w-56 h-56 my-4 cursor-pointer mr-8 relative">
              <UploadImage
                mediaStatus={mediaStatus}
                className="w-10 h-10 mb-4"
                h="40"
                w="40"
                sizeLabel=""
                aspectRatio={1 / 1}
                cropHeight={170}
                cropWidth={360}
                cropBoxResizable={true}
                instructionText="Click to add photo"
                onvalueChange={(url) => {
                  let images = [...imageList];
                  images[index].key = url;
                  onImageListHandler(images);
                }}
                imageUrl={imageList[index]?.key}
                key={imageList[index]?.key}
              />
              {showCancelButton &&
                mediaStatus === "draft" &&
                imageList[index]?.key && (
                  <div className="absolute" style={{ top: -10, left: -10 }}>
                    <div
                      className="w-6 h-6 bg-red-900 rounded-full flex justify-center items-center cursor-pointer"
                      onClick={() => {
                        let images = [...imageList];
                        //Edit check to delete attachments(storing it in a different state)
                        if (editing) {
                          onEditImageListHandler([
                            ...editImageList,
                            {
                              key: imageList[index]?.key,
                              id: imageList[index]?.id,
                              _destroy: true,
                            },
                          ]);
                        }
                        if (index < 1 && images.length < 2) {
                          images.splice(index, 1, {});
                        } else {
                          images.splice(index, 1);
                        }

                        onImageListHandler(images);
                      }}
                    >
                      <XIcon className="bg-gray-900 text-white rounded-full " />
                    </div>
                  </div>
                )}
            </div>
            {attachmentType === "multiple" && mediaStatus === "draft" ? (
              <>
                {index < 9 && index === imageList?.length - 1 && (
                  <div className="flex items-center justify-center w-56">
                    <button
                      type="button"
                      className="transition-all duration-500 py-3 rounded px-4 cursor-pointer bg-transparent hover:bg-gray-200 text-gray-900"
                      style={{ outline: "none" }}
                      onClick={() => {
                        const temp = { key: "" };
                        const clonedImageList = [...imageList];
                        clonedImageList.push(temp);
                        onImageListHandler(clonedImageList);
                      }}
                    >
                      + Add Images
                    </button>
                  </div>
                )}
              </>
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
