import React from "react";
import Header from "../../../components/Header";
import { navigate } from "@reach/router";

import { CurrentUser } from "../../../lib/network/auth/Auth";
const AllPostsHeader = ({ talentName, talentId, active }) => {
  const userRole = CurrentUser.getRole();

  const breadcrumbsData = [
    {
      to: "../../posts",
      name: "Posts",
    },
    {
      to: "",
      name: talentName,
    },
  ];

  const tabsData = [
    {
      id: "regular-posts",
      path: `/${userRole}/posts/${talentId}/regular-posts`,
      icon: () => {
        return null;
      },
      name: "regular posts",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "monetizable-posts",
      path: `/${userRole}/posts/${talentId}/monetizable-posts`,
      icon: () => {
        return null;
      },
      name: "monetizable posts",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "quizzes",
      path: `/${userRole}/posts/${talentId}/quizzes`,
      icon: () => {
        return null;
      },
      name: "quizzes",
      get current() {
        return active === this.id ? true : false;
      },
    },
  ];

  return (
    <Header
      tabsData={tabsData}
      breadcrumbsData={breadcrumbsData}
      talentName={talentName}
      primaryCta="Back to posts"
      primaryOnClick={() => navigate(`/${userRole}/posts`)}
    />
  );
};

export default AllPostsHeader;
