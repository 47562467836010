import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import { quizDetailValidationSchema } from "../../validation";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import FormFields from "../../FormFields";
import FormHeader from "../../../components/FormHeader";
import { useQuizQuestionList } from "../../../lib/services/QuizQuestionService";
import { getCorrectOption, getCorrectOptionApi } from "../../../utils/Helper";

const QuizDetailsForm = ({
  initialValues,
  quizId,
  setQuizId,
  editing,
  quizFormNumber,
  hasReachedForm,
  formNumber,
  setFormNumber,
  published,
  talentId = null,
}) => {
  const buttonRef = useRef(null);
  const [formValueData, setformValueData] = useState(initialValues);
  const [editMode, setEditMode] = useState(
    editing && hasReachedForm(quizFormNumber.QUIZ_DETAILS_FORM) ? false : true
  );
  const [showCancel, setShowCancel] = useState(
    editing && hasReachedForm(quizFormNumber.QUIZ_DETAILS_FORM) ? true : false
  );
  const [formData, setFormData] = useState(editing ? initialValues : []);
  const [searchQuestion, setSearchQuestion] = useState("");

  const { quiz, isValidating } = useQuizQuestionList(
    searchQuestion?.attributes?.question ? null : searchQuestion,
    {
      defaultValue: initialValues?.question,
      filterByTalentId: talentId,
    }
  );
  useEffect(() => {
    if (searchQuestion?.attributes?.category === "general") {
      setformValueData({
        ...formValueData,
        question: searchQuestion?.attributes?.question,
        correctOption: getCorrectOption(
          searchQuestion?.attributes?.correctOption,
          searchQuestion?.attributes?.options
        ),
        optionA: searchQuestion?.attributes?.options[0],
        optionB: searchQuestion?.attributes?.options[1],
        optionC: searchQuestion?.attributes?.options[2],
      });
    } else if (searchQuestion?.attributes?.category === "personal") {
      setformValueData({
        ...formValueData,
        question: searchQuestion?.attributes?.question,
        correctOption: null,
        optionA: "",
        optionB: "",
        optionC: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchQuestion, searchQuestion]);

  const formValues = [
    {
      label: "Quiz Question *",
      attributeId: "question",
      type: "Search",
      placeholder: "Select Quiz Question",
      displayKey: "attributes.question",
      valueKey: "attributes.question",
      items: _.isArray(quiz) ? quiz : [],
      onChange: setSearchQuestion,
      isValidating: isValidating,
      changeIdFlag: true,
      displayTag: true,
    },
    {
      type: "Row",
      formValues: [
        {
          label: "Option A *",
          attributeId: "optionA",
          placeholder: "Option A",
          type: "TextInput",
        },
        {
          label: "Option B *",
          attributeId: "optionB",
          placeholder: "Option B",
          type: "TextInput",
        },
        {
          label: "Option C *",
          attributeId: "optionC",
          placeholder: "Option C",
          type: "TextInput",
        },
      ],
    },

    {
      type: "Row",
      formValues: [
        {
          label: "Correct Option *",
          attributeId: "correctOption",
          placeholder: "Correct Option",
          type: "DropDown",
          options: [
            { attributes: { option: "Option A" } },
            { attributes: { option: "Option B" } },
            { attributes: { option: "Option C" } },
          ],
          displayKey: "option",
          displayCurrentOption: true,
        },
      ],
    },
  ];

  const onEdit = (e) => {
    e.preventDefault();
    setEditMode(true);
  };
  const onCancel = () => {
    setEditMode(false);
    setSearchQuestion("");
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const options = [values?.optionA, values?.optionB, values?.optionC];

    const fetchedValues = {
      ..._.pick(values, ["talentId", "status"]),
      category: "quiz",
      quizAttributes: {
        question: values?.question,
        options: options,
        correctOption: getCorrectOptionApi(values?.correctOption, options),
      },
    };

    const apiParams = {
      data: { attributes: fetchedValues },
    };
    const endpoint = quizId
      ? `/portal_api/v1/quizzes/${quizId}`
      : `/portal_api/v1/quizzes`;
    const { data, error } = quizId
      ? await API.put(endpoint, apiParams)
      : await API.post(endpoint, apiParams);
    if (error) {
      toast.error(
        ` ${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(`Quiz details saved successfully`);
      setQuizId(data?.data?.id);
      setEditMode(false);
      setShowCancel(true);
      setFormData(values);
      setFormNumber(
        hasReachedForm(quizFormNumber.QUIZ_DETAILS_FORM)
          ? formNumber
          : quizFormNumber.QUIZ_DETAILS_FORM
      );
    }
  };
  return (
    <div className="rounded-md bg-white px-2 pb-6 border-2 border-gray-300 ">
      <Formik
        initialValues={formValueData}
        validationSchema={quizDetailValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="flex flex-col justify-between">
            <FormHeader
              sectionHeading="Quiz Details"
              cancelOnClick={onCancel}
              editOnClick={values.status === "draft" ? onEdit : false}
              editMode={values.status === "draft" ? editMode : false}
              isSubmitting={isSubmitting}
              buttonRef={buttonRef}
              showCancel={showCancel}
              published={published}
              notAllowed={values?.status !== "draft" ? true : false}
            />
            <div className="px-2">
              <FormFields
                className=" mb-2 w-full"
                innerContainer=" w-3/5 px-2 pb-6"
                customInnerRowStyle="w-1/3 pl-1 pr-2"
                setFieldValue={setFieldValue}
                values={values}
                formValues={formValues}
                editMode={editMode}
                formData={formData}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuizDetailsForm;
