import React, { useEffect } from "react";

import { useLocation, navigate, Link } from "@reach/router";
import queryString from "query-string";
import { MailIcon, PhoneIcon } from "@heroicons/react/solid";

import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import Breadcrumb from "../../stories/Breadcrumb";
import Table from "../../components/Table";
import {
  useShowAgency,
  useTalentList,
} from "../../lib/services/TalentAgencyService";
import moment from "moment";
import Author from "../../stories/Author";

import SearchBar from "../../components/SearchBar";
import _ from "lodash";

import TableLayout from "../../components/TableLayout";

import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import { navigateSelf } from "../../utils/Helper";
import { CurrentUser } from "../../lib/network/auth/Auth";

const TmaWiseTalents = ({ agencyId }) => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";
  const userType = CurrentUser.getRole();
  const {
    talents,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useTalentList(searchParamsQuery, { page: pageNumberQuery, agencyId });

  const { agency: talentAgency } = useShowAgency(agencyId);
  const columns = [
    {
      field: "Talent Name",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center ">
          <Link
            to={
              userType
                ? `/${userType}/talents/all-talents/${rowData.id}/earning-ongoing-campaigns`
                : ""
            }
          >
            <Author
              name={rowData?.attributes?.fanAccount?.attributes?.name}
              imageSrc={rowData?.attributes?.fanAccount?.attributes?.image}
              cta={`@${rowData?.attributes?.fanAccount?.attributes?.username}`}
              size="lg"
            />
          </Link>
        </div>
      ),
    },
    {
      field: "Agency Contact",
      render: (rowData) => (
        <div className="flex flex-col">
          <div className="text-left whitespace-nowrap text-md font-medium text-gray-500">
            {(_.isEmpty(rowData?.attributes?.talentManagementMember)
              ? rowData?.attributes?.talentManagement?.attributes?.firstName
              : rowData?.attributes?.talentManagementMember?.attributes
                  ?.firstName) +
              " " +
              (_.isEmpty(rowData?.attributes?.talentManagementMember)
                ? rowData?.attributes?.talentManagement?.attributes?.lastName
                : rowData?.attributes?.talentManagementMember?.attributes
                    ?.lastName)}
            <span className="mx-1.5 bg-gray-500 rounded-lg border border-gray-500 px-1.5 py-0.5 text-xs text-gray-100 uppercase">
              {_.isEmpty(rowData?.attributes?.talentManagementMember)
                ? "Admin"
                : rowData?.attributes?.talentManagementMember?.attributes?.role}
            </span>
          </div>
          <div className=" text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
            <a
              href={`mailto:${
                _.isEmpty(rowData?.attributes?.talentManagementMember)
                  ? rowData?.attributes?.talentManagement?.attributes?.email
                  : rowData?.attributes?.talentManagementMember?.attributes
                      ?.email
              }`}
            >
              <MailIcon className="w-4 h-4" />
            </a>

            <span>
              {_.isEmpty(rowData?.attributes?.talentManagementMember)
                ? rowData?.attributes?.talentManagement?.attributes?.email ??
                  "-NA-"
                : rowData?.attributes?.talentManagementMember?.attributes
                    ?.email ?? "-NA-"}
            </span>
          </div>
          <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
            <a
              href={`tel:${
                _.isEmpty(rowData?.attributes?.talentManagementMember)
                  ? rowData?.attributes?.talentManagement?.attributes
                      ?.adminPhone
                  : rowData?.attributes?.talentManagementMember?.attributes
                      ?.phone
              }`}
            >
              <PhoneIcon className="w-4 h-4" />
            </a>
            <span>
              {_.isEmpty(rowData?.attributes?.talentManagementMember)
                ? rowData?.attributes?.talentManagement?.attributes
                    ?.adminPhone ?? "-NA-"
                : rowData?.attributes?.talentManagementMember?.attributes
                    ?.phone ?? "-NA-"}
            </span>
          </div>
        </div>
      ),
    },

    {
      field: "status",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 uppercase">
          {rowData?.attributes?.status}
        </div>
      ),
    },
    {
      field: "added on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {moment(`${rowData?.attributes?.createdAt}`).format("ll")}
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(talents)) {
      return (
        <LoadContentHelper
          title="Talent List"
          ctaOnClick={null}
          data={talents}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }

    return <Table column={columns} data={talents} />;
  };

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  return (
    <Layout>
      <div className="sticky top-0 z-50 bg-white">
        <div className="pt-4 ">
          <Breadcrumb
            pages={[
              {
                to: "../talent-management-agencies",
                name: "Talent Management Agencies",
              },
              {
                to: "",
                name: talentAgency?.attributes?.name ?? "Talent",
              },
            ]}
            type="chevron"
          />
        </div>
        <SectionHeading
          title={talentAgency?.attributes?.name ?? "Talent"}
          primaryCta="Back to Agencies"
          primaryOnClick={() => navigate("../talent-management-agencies")}
        />
      </div>
      {/* Hide searchbar if no data */}
      {searchParamsQuery || !_.isEmpty(talents) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Talent List"}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default TmaWiseTalents;
