import React, { useEffect } from "react";
import { useLocation, navigate, Link } from "@reach/router";
import queryString from "query-string";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import Table from "../../../components/Table";
import { useCampaignsList } from "../../../lib/services/CampaignService";
import moment from "moment";
import SearchBar from "../../../components/SearchBar";
import _ from "lodash";
import TableLayout from "../../../components/TableLayout";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../utils/Helper";

import { ViewDataButton } from "../../../table-components/Buttons";
import { CurrentUser } from "../../../lib/network/auth/Auth";
const UnApprovedCampaigns = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const columns = [
    {
      field: "Campaigns",
      render: (rowData) => (
        <>
          {CurrentUser.isFanshipAdmin() ? (
            <Link
              to="/admin/campaigns/campaign-new"
              state={{ campaignId: rowData?.id, step: 5, published: true }}
              className=" capitalize text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center"
            >
              {rowData?.attributes?.name}
            </Link>
          ) : (
            <div className=" capitalize text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center">
              {rowData?.attributes?.name}
            </div>
          )}
        </>
      ),
    },
    {
      field: "Brand (Category)",
      render: (rowData) => (
        <div className="text-left text-sm whitespace-nowrap  text-gray-500 capitalize">
          <div>
            {!_.isEmpty(rowData?.attributes?.brand)
              ? rowData?.attributes?.brand?.attributes?.name
              : "-NA-"}
          </div>
          <div>
            {!_.isEmpty(rowData?.attributes?.brandCategory)
              ? `(${rowData?.attributes?.brandCategory?.attributes?.name})`
              : "-NA-"}
          </div>
        </div>
      ),
    },
    {
      field: "Advertiser",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {!_.isEmpty(rowData?.attributes?.advertisers)
            ? rowData?.attributes?.advertisers?.map((advertiser, idx) => (
                <div key={idx}>
                  {advertiser?.attributes?.firstName +
                    " " +
                    advertiser?.attributes?.lastName}
                </div>
              ))
            : "-NA-"}
        </div>
      ),
    },
    {
      field: "Talent",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {!_.isEmpty(rowData?.attributes?.talents)
            ? rowData?.attributes?.talents?.map((talents, idx) => (
                <div key={idx}>{talents?.attributes?.name}</div>
              ))
            : "-NA-"}
        </div>
      ),
    },
    {
      field: "Added on",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.createdAt
            ? moment(`${rowData?.attributes?.createdAt}`).format("ll")
            : "-NA-"}
        </div>
      ),
    },
    {
      field: "Action",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          <ViewDataButton
            iconOnly
            onClick={() => {
              navigate(rowData?.id);
            }}
          />
        </div>
      ),
    },
  ];
  const filterType = "pending";
  const {
    campaigns,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useCampaignsList(pageNumberQuery, searchParamsQuery, filterType);

  const loadContent = () => {
    if (_.isEmpty(campaigns)) {
      return (
        <LoadContentHelper
          title="Unapproved Campaign"
          data={campaigns}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    return <Table column={columns} data={campaigns} />;
  };

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  return (
    <Layout>
      <div className="sticky top-0 z-50">
        <SectionHeading
          title="Unapproved Campaigns"
          primaryCta={CurrentUser.isFanshipAdmin() ? "Create Campaign" : null}
          primaryOnClick={() => {
            navigate("/admin/campaigns/campaign-new");
          }}
        />
      </div>
      {searchParamsQuery || !_.isEmpty(campaigns) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}

      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Unapproved Campaign List"
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default UnApprovedCampaigns;
