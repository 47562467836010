import React from "react";
import Header from "../../../components/Header";
import { navigate } from "@reach/router";

import { CurrentUser } from "../../../lib/network/auth/Auth";
const AllQuestionHeader = ({ talentCategory, categoryId, active }) => {
  const userRole = CurrentUser.getRole();

  const breadcrumbsData = [
    {
      to: "../../talent-categories",
      name: "Quiz Questions",
    },
    {
      to: "",
      name: talentCategory,
    },
  ];

  const tabsData = [
    {
      id: "general",
      path: `/${userRole}/quiz-questions/talent-categories/${categoryId}/general`,
      icon: () => {
        return null;
      },
      name: "General Questions",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "personal",
      path: `/${userRole}/quiz-questions/talent-categories/${categoryId}/personal`,
      icon: () => {
        return null;
      },
      name: "Personal Questions",
      get current() {
        return active === this.id ? true : false;
      },
    },
  ];

  return (
    <Header
      tabsData={tabsData}
      breadcrumbsData={breadcrumbsData}
      talentName={
        talentCategory ? `${talentCategory}  Quiz Questions` : "Quiz Questions"
      }
      primaryCta="Back"
      primaryOnClick={() =>
        navigate(`/${userRole}/quiz-questions/talent-categories`)
      }
    />
  );
};

export default AllQuestionHeader;
