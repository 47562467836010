import { XIcon } from "@heroicons/react/outline";
import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import Button from "../../stories/Button";
import classNames from "classnames";
const Crop = ({
  open,
  handleClose,
  preCropImage,
  setCroppedData,
  cropHeight,
  cropWidth,
  cropBoxResizable,
  loading,
  progress,
  aspectRatio = 1,
}) => {
  const ref = useRef(null);
  const [cropper, setCropper] = useState(null);
  const [aspectValue, setAspectValue] = useState("1");

  if (!open) return null;
  return (
    <div className="fixed w-full h-full bg-black bg-opacity-75 inset-0 z-50 cursor-default">
      <div
        className={`top-0 inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center ${"absolute"}`}
      >
        <div
          className="bg-white rounded animate-modal shadow-xl overflow-auto transform transition-all md:px-8 lg:py-12 p-4 sm:max-w-2xl sm:w-full"
          style={{
            maxHeight: "calc(100vh - 10px)",
          }}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <button
            type="button"
            onClick={handleClose}
            className="right-2 top-2 absolute focus:outline-none"
          >
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="bg-white">
            <Cropper
              style={{ height: 500, width: "100%" }}
              preview=".img-preview"
              src={preCropImage}
              viewMode={2}
              guides={true}
              dragMode="move"
              scalable={cropBoxResizable}
              cropBoxResizable={cropBoxResizable}
              minCropBoxHeight={300}
              minCropBoxWidth={500}
              background={false}
              responsive={true}
              autoCropArea={0.5}
              aspectRatio={aspectRatio}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              zoomto={0.1}
              ref={ref}
            />
            <div className="mt-5">
              <div className="space-x-5">
                <p
                  className={classNames(
                    " inline-flex items-center text-gray-500 px-1 pb-1 border border-transparent text-xs font-medium"
                  )}
                >
                  Select Aspect Ratio:
                </p>
                <button
                  type="button"
                  className={classNames(
                    "inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-xl shadow-sm text-white bg-gray-900 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                    aspectValue === "1" &&
                      "bg-white text-white pointer-events-none ring-2 ring-offset-2 ring-gray-500  "
                  )}
                  onClick={() => {
                    setAspectValue("1");
                    cropper?.setAspectRatio(1);
                  }}
                >
                  1/1
                </button>
                <button
                  type="button"
                  className={classNames(
                    "inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-xl shadow-sm text-white bg-gray-900 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                    aspectValue === "4/5" &&
                      "bg-white text-white pointer-events-none ring-2 ring-offset-2 ring-gray-500  "
                  )}
                  onClick={() => {
                    setAspectValue("4/5");
                    cropper?.setAspectRatio(4 / 5);
                  }}
                >
                  4/5
                </button>
                <button
                  type="button"
                  className={classNames(
                    "inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-xl shadow-sm text-white bg-gray-900 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                    aspectValue === "6/5" &&
                      "bg-white text-white pointer-events-none ring-2 ring-offset-2 ring-gray-500  "
                  )}
                  onClick={() => {
                    setAspectValue("6/5");
                    cropper?.setAspectRatio(6 / 5);
                  }}
                >
                  6/5
                </button>
                <button
                  type="button"
                  className={classNames(
                    "inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-xl shadow-sm text-white bg-gray-900 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                    aspectValue === "5/4" &&
                      "bg-white text-white pointer-events-none ring-2 ring-offset-2 ring-gray-500  "
                  )}
                  onClick={() => {
                    setAspectValue("5/4");
                    cropper?.setAspectRatio(5 / 4);
                  }}
                >
                  5/4
                </button>
              </div>

              <Button
                type="button"
                onClick={() => {
                  setCroppedData(cropper.getCroppedCanvas().toDataURL());
                }}
                className="float-right"
                buttonStyle={!!cropper ? "primary" : "disabled"}
                disabled={!cropper}
              >
                {loading ? `Uploading (${progress}%)` : "Crop Image"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Crop.defaultProps = {
  cropHeight: 150,
  cropWidth: 150,
  cropBoxResizable: false,
  loading: false,
};

export default Crop;
