import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";

export const useConfiguration = () => {
  const key = "/portal_api/v1/configs/get_config";

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    config: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useBlacklistedKeywords = (pageParam = "1", searchTerm) => {
  const queryObject = { page: pageParam, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);

  const key = `portal_api/v1/black_listed_words${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    keywords: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
