import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";

export const useTalentList = (
  searchTerm,
  { defaultValue, categoryId = null, page = "1" }
) => {
  const queryObject = { page, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/talent_categories${
    categoryId ? `/${categoryId}/talents` : ""
  }${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    talents: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

// particular talent category info - for internal page headings
export const useShowTalentCategory = (categoryId) => {
  const key = categoryId
    ? `/portal_api/v1/talent_categories/${categoryId}`
    : null;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    talent: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};

export const useTalent = (talentId) => {
  const key = `/portal_api/v1/talents/${talentId}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    talent: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useTalentAssignedBrands = (
  pageParam = "1",
  searchTerm,
  talentId
) => {
  const queryObject = { page: pageParam, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/talents/${talentId}/brands${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    brands: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
export const useTalentBlockedUsers = (
  searchTerm,
  { talentId = null, page = "1" }
) => {
  const queryObject = { id: talentId, page, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/blocked_users/blocked_by_talent${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    blockedUser: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useTalentCampaignsList = (
  searchTerm,
  { talentId, defaultValue, page = "1", filterByStatus = "active" }
) => {
  const queryObject = {
    page,
    query: searchTerm,
    per_page: 20,
    filterByStatus: filterByStatus,
  };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/talents/${talentId}/campaigns${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    talentCampaigns: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useTalentsByBrandCategory = (
  searchTerm,
  { brandCategoryId, defaultValue }
) => {
  const queryObject = {
    query: searchTerm,
  };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/talents/${brandCategoryId}/brand_category_filtered${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    talents: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
