import React, { useRef } from "react";
import { useParams, navigate } from "@reach/router";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import Breadcrumb from "../../../stories/Breadcrumb";
import _ from "lodash";
import { useCampaignByPost } from "../../../lib/services/CampaignService";
import { HeartIcon, CheckIcon } from "@heroicons/react/outline";
import moment from "moment";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import CampaignDetails from "../../../components/CampaignDetails";
import Button from "../../../stories/Button";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import { Formik, Form } from "formik";

const TmaViewCampaign = () => {
  const initialValues = {
    status: "",
  };
  const buttonRef = useRef(null);
  const { campaignId: campaignPostId } = useParams();
  const { campaign, mutate, isValidating, loading, error } = useCampaignByPost(
    campaignPostId
  );

  const breadcrumbsData = [
    {
      to: "../campaigns/approve-campaigns",
      name: "Campaigns Approvals",
    },
    {
      to: "",
      name: campaign?.attributes?.name,
      current: true,
    },
  ];

  const getStatus = (status) => {
    if (status === "active") {
      return "Ongoing";
    }
    if (status === "ended") {
      return "Completed";
    }
    return status;
  };

  const blockFields = [
    {
      label: "Target Impressions",
      value: campaign?.attributes?.targetImpressions ?? "-NA-",
      image: true,
    },
    {
      label: "Achieved Impressions",
      value:
        _.sumBy(campaign?.attributes?.campaignsPosts, (attr) => {
          return attr?.attributes?.receivedImpressions;
        }) ??
        campaign?.attributes?.receivedImpressions ??
        "-NA-",
      Icon: CheckIcon,
    },
    {
      label: "Post Likes",
      value: campaign?.attributes?.likesCount ?? "-NA-",
      Icon: HeartIcon,
    },
  ];

  const getAdvertiserNames = () => {
    return !_.isEmpty(campaign?.attributes?.advertisers)
      ? campaign?.attributes?.advertisers?.map((advertiser) => {
          return (
            <div className="my-2">
              <div className="flex flex-row  items-center text-md">
                {_.chain([
                  advertiser?.attributes?.firstName,
                  advertiser?.attributes?.lastName,
                ])
                  .compact()
                  .join(" ")
                  .value()}
                {advertiser?.attributes?.advertiserType && (
                  <div className="text-xs font-medium mx-1.5 px-1 py-0.5 bg-gray-500 rounded-lg border border-gray-500 text-gray-100 capitalize ">
                    {advertiser?.attributes?.advertiserType === "brand_owner"
                      ? "brand owner"
                      : advertiser?.attributes?.advertiserType}
                  </div>
                )}
              </div>
              <div className="normal-case font-medium text-sm">
                {advertiser?.attributes?.email}
              </div>
            </div>
          );
        })
      : [];
  };

  getAdvertiserNames();

  const campaignFields = [
    {
      label: "Average Replay",
      value: campaign?.attributes?.averageReplays,
    },
    { label: "Replay Rate", value: campaign?.attributes?.replayRate || "-NA-" },
    {
      label: "Campaign Budget",
      value: (campaign?.attributes?.budget / 100).toFixed(2),
      price: true,
    },
    {
      label: "Used Budget",
      value:
        _.sumBy(campaign?.attributes?.campaignsPosts, (attr) => {
          return attr?.attributes?.usedBudget / 100;
        }).toFixed(2) ??
        (campaign?.attributes?.usedBudget / 100).toFixed(2) ??
        "-NA-",
      price: true,
    },
    {
      label: "Talent",
      value: campaign?.attributes?.talents,
      isArray: true,
      displayKey: "attributes.name",
    },
    {
      label: "Talent CPI",
      value: campaign?.attributes?.talents,
      isArray: true,
      displayKey: "attributes.costPerImpression",
      talentCPI: true,
    },
    { label: "Brand", value: campaign?.attributes?.brand?.attributes?.name },
    {
      label: "Brand Category",
      value: campaign?.attributes?.brandCategory?.attributes?.name,
    },
    {
      label: "Advertiser Name",
      value: getAdvertiserNames(),
      isArray: true,
    },
    {
      label: "Start Date",
      value: campaign?.attributes?.startTime
        ? moment.unix(campaign?.attributes?.startTime).format("lll")
        : "-NA-",
    },
    {
      label: "End Date",
      value: campaign?.attributes?.endTime
        ? moment.unix(campaign?.attributes?.endTime).format("lll")
        : "-NA-",
    },
    {
      label: "Target Location",
      value: campaign?.attributes?.locations,
      isArray: true,
      displayKey: "attributes.name",
    },
    {
      label: "Age Group",
      value: campaign?.attributes?.ageGroups,
      isArray: true,
    },
    {
      label: "Gender",
      value:
        campaign?.attributes?.gender === "any_gender"
          ? "Any"
          : campaign?.attributes?.gender,
    },
    { label: "Status", value: getStatus(campaign?.attributes?.status) },
  ];

  const loadContent = () => {
    if (_.isEmpty(campaign)) {
      return (
        <div className="px-3 my-4">
          <LoadContentHelper
            title="Campaign"
            data={campaign}
            loading={loading}
            error={error}
            mutate={mutate}
            isValidating={isValidating}
          />
        </div>
      );
    }
    return (
      <CampaignDetails
        blockFields={blockFields}
        campaignFields={campaignFields}
        campaign={campaign}
      />
    );
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const endpoint = `/portal_api/v1/campaigns_posts/${campaignPostId}/approve`;

    const { error } = await API.put(endpoint);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went wrong. Please try again"}`
      );
      setSubmitting(false);
      buttonRef.current.shake();
    } else {
      toast.success(`Campaign Approved successfully`);
      navigate(-1);
      setSubmitting(false);
    }
  };

  return (
    <Layout>
      <div className="pt-4 bg-white sticky top-0 z-50">
        <Breadcrumb pages={breadcrumbsData} type="chevron" />
        <SectionHeading
          title={campaign?.attributes?.name}
          primaryCta="Back"
          primaryOnClick={() => navigate(-1)}
        />
      </div>
      <div className=" mt-8 my-4 mx-6 pt-6 px-3 bg-white rounded-md">
        <h3 className="text-lg px-3 leading-6 font-medium text-gray-900">
          Campaign Reach
        </h3>
        <div className="pb-4">{loadContent()}</div>
      </div>

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            {campaign?.attributes?.campaignPostStatus === "pending" ? (
              <div className="flex justify-end  py-4 px-6 ">
                <Button
                  buttonStyle="danger"
                  ref={buttonRef}
                  type="submit"
                  loading={isSubmitting}
                  className="w-1/6 "
                >
                  Approve
                </Button>
              </div>
            ) : null}
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default TmaViewCampaign;
