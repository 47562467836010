import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useLocation, Link } from "@reach/router";
import queryString from "query-string";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import Table from "../../../components/Table";
import SlideOver from "../../../stories/SlideOver";
import { useTalentList } from "../../../lib/services/TalentService";
import moment from "moment";
import Author from "../../../stories/Author";
import {
  ActionDeleteButton,
  ActionEditButton,
} from "../../../table-components/Buttons";
import SearchBar from "../../../components/SearchBar";
import _ from "lodash";
import TalentCategoryForm from "../../../forms/pageForms/TalentCategoryForm";

import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";

import TableLayout from "../../../components/TableLayout";

import { ModalWrapper } from "../../../utils/Modal";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../utils/Helper";
import { CurrentUser } from "../../../lib/network/auth/Auth";
const TalentCategories = () => {
  const location = useLocation();

  //queryString --> to get the query object
  const parsed = queryString.parse(location.search);

  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const [deleteId, setDeleteId] = useState(false);

  // for setting loader for modal
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  //dymanic content changing  base dof url params
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const addInitialValues = {
    name: "",
    brief: "",
    image: { uri: "", file: "", data: "" },
    status: "disabled",
  };

  const deleteUser = async () => {
    if (deleteId) {
      setModalPrimaryCtaLoading(true);
      const { error } = await API.delete(
        `/portal_api/v1/talent_categories/${deleteId}`,
        {}
      );
      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${
            error?.status ?? null
          }: Something went Wrong. Please try again`
        );
      } else {
        toast.success("Category Deleted Successfully");
        setDeleteId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };
  const {
    talents,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useTalentList(searchParamsQuery, { page: pageNumberQuery });

  const onAction = (action, data) => {
    if (action === "delete") {
      setDeleteId(data.id);
    } else if (action === "edit") {
      setEditData(data);
    }
  };

  const columns = [
    {
      field: "category",
      render: (rowData) => (
        <Link to={`${rowData?.id}`}>
          <div className="text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center ">
            <Author
              name={rowData?.attributes?.name}
              imageSrc={rowData?.attributes?.image}
              size="lg"
            />
          </div>
        </Link>
      ),
    },
    {
      field: "brief",
      render: (rowData) => (
        <div className="text-left text-sm text-gray-500">
          {rowData?.attributes?.brief}
        </div>
      ),
    },
    {
      field: "status",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 uppercase">
          {rowData?.attributes?.status}
        </div>
      ),
    },
    {
      field: "added on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {moment(`${rowData?.attributes?.createdAt}`).format("ll")}
        </div>
      ),
    },

    {
      field: "actions",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionEditButton
            iconOnly
            onClick={() => {
              onAction("edit", {
                ...rowData?.attributes,
                id: rowData.id,
              });
            }}
          />
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              onAction("delete", { id: rowData.id });
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(talents)) {
      return (
        <LoadContentHelper
          title="Talent Category"
          ctaOnClick={() => setToggle(true)}
          data={talents}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    if (CurrentUser.isFanshipAdmin()) {
      return <Table column={columns} data={talents} />;
    } else {
      return <Table column={_.initial(columns)} data={talents} />;
    }
  };

  useEffect(() => {
    if (!toggle || !editData || !deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData, deleteId]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  //Modal Value JSON
  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deleteUser,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      {/* Modal funtion */}
      <ModalWrapper title="Talent Category" config={modalConfig} />

      <SlideOver
        open={toggle || !!editData}
        backgroundOverlay="dark"
        size="xl"
        title={`${toggle ? "Add" : "Edit"} Talent Category`}
        dismissButtonOnClick={() => {
          setToggle(false);
          setEditData(false);
        }}
      >
        {/*  Talent Category form */}
        <TalentCategoryForm
          setToggle={editData ? setEditData : setToggle}
          initialValues={editData ? editData : addInitialValues}
          formButtonCta={`${toggle ? "Create" : "Update"} Talent Category`}
          editMode={!!editData}
        />
      </SlideOver>
      <div className="sticky top-0 z-50">
        <SectionHeading
          title="Talent Categories"
          primaryCta={
            CurrentUser.isFanshipAdmin() ? "Add Talent Category" : null
          }
          primaryOnClick={() => {
            setToggle(true);
          }}
        />
      </div>
      {/* //SearchBar */}
      {searchParamsQuery || !_.isEmpty(talents) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Talent Categories List"}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default TalentCategories;
