import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CampaignModalData from "./CampaignModalData";

const CampaignModal = ({
  modal,
  setModal,
  arrayHelpers,
  fieldValue,
  selectedTalent,
  setSelectedTalent,
  selectedPostId,
  setSelectedPostId,
  selectedPostBudget,
  setSelectedPostBudget,
}) => {
  return (
    <Transition.Root show={modal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={modal}
        onClose={setModal}
      >
        <div className="flex items-end justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 text-left overflow-hidden shadow-xl transform transition-all w-2/3 sm:my-4 sm:align-middle h-5/6">
              <CampaignModalData
                setModal={setModal}
                arrayHelpers={arrayHelpers}
                fieldValue={fieldValue}
                selectedTalent={selectedTalent}
                setSelectedTalent={setSelectedTalent}
                selectedPostId={selectedPostId}
                setSelectedPostId={setSelectedPostId}
                selectedPostBudget={selectedPostBudget}
                setSelectedPostBudget={setSelectedPostBudget}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CampaignModal;
