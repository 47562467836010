import React, { useEffect } from "react";
import { useLocation, navigate, Link } from "@reach/router";
import queryString from "query-string";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import Table from "../../../components/Table";
import { useCampaignsList } from "../../../lib/services/CampaignService";
import moment from "moment";
import SearchBar from "../../../components/SearchBar";
import _ from "lodash";
import TableLayout from "../../../components/TableLayout";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../utils/Helper";

const DraftCampaigns = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const campaignFormNumber = {
    CAMPAIGN_DETAILS_FORM: 0,
    TARGET_DEMOGRAPHY_FORM: 1,
    CAMPAIGN_POST_FORM: 2,
    CAMPAIGN_DATE_TIME_FORM: 3,
    COMPLETED: 4,
  };

  const getStep = (data) => {
    if (
      data?.attributes?.monetizationType === "" ||
      data?.attributes?.name === "" ||
      _.isEmpty(data?.attributes?.brandCategory) ||
      _.isEmpty(data?.attributes?.brand) ||
      data?.attributes?.advertiser?.length === 0
    ) {
      return campaignFormNumber.CAMPAIGN_DETAILS_FORM;
    }

    if (
      _.isEmpty(data?.attributes?.locations) ||
      data?.attributes?.ageGroups?.length === 0 ||
      data?.attributes?.gender === ""
    ) {
      return campaignFormNumber.TARGET_DEMOGRAPHY_FORM;
    }

    if (_.isEmpty(data?.attributes?.posts)) {
      return campaignFormNumber.CAMPAIGN_POST_FORM;
    }
    if (
      data?.attributes?.budget === 0 ||
      _.isNull(data?.attributes?.startTime)
    ) {
      return campaignFormNumber.CAMPAIGN_DATE_TIME_FORM;
    }
    return campaignFormNumber.COMPLETED;
  };

  const columns = [
    {
      field: "Campaigns",
      render: (rowData) => (
        <Link
          to="/admin/campaigns/campaign-new"
          state={{
            campaignId: rowData?.id,
            step: getStep(rowData),
            published: false,
          }}
          className=" capitalize text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center"
        >
          {rowData?.attributes?.name}
        </Link>
      ),
    },
    {
      field: "Brand (Category)",
      render: (rowData) => (
        <div className="text-left text-sm whitespace-nowrap  text-gray-500 capitalize">
          <div>
            {!_.isEmpty(rowData?.attributes?.brand)
              ? rowData?.attributes?.brand?.attributes?.name
              : "-NA-"}
          </div>
          <div>
            {!_.isEmpty(rowData?.attributes?.brandCategory)
              ? `(${rowData?.attributes?.brandCategory?.attributes?.name})`
              : "-NA-"}
          </div>
        </div>
      ),
    },
    {
      field: "Advertiser",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {!_.isEmpty(rowData?.attributes?.advertisers)
            ? rowData?.attributes?.advertisers?.map((advertiser, idx) => (
                <div key={idx}>
                  {advertiser?.attributes?.firstName +
                    " " +
                    advertiser?.attributes?.lastName}
                </div>
              ))
            : "-NA-"}
        </div>
      ),
    },
    {
      field: "Talent",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {!_.isEmpty(rowData?.attributes?.talents)
            ? rowData?.attributes?.talents?.map((talents, idx) => (
                <div key={idx}>{talents?.attributes?.name}</div>
              ))
            : "-NA-"}
        </div>
      ),
    },
    {
      field: "Added on",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.createdAt
            ? moment(`${rowData?.attributes?.createdAt}`).format("ll")
            : "-NA-"}
        </div>
      ),
    },
  ];
  const filterType = "draft";
  const {
    campaigns,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useCampaignsList(pageNumberQuery, searchParamsQuery, filterType);

  const loadContent = () => {
    if (_.isEmpty(campaigns)) {
      return (
        <LoadContentHelper
          title="Draft Campaign"
          data={campaigns}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    return <Table column={columns} data={campaigns} />;
  };

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  return (
    <Layout>
      <div className="sticky top-0 z-50">
        <SectionHeading
          title="Draft Campaigns"
          primaryCta="Create Campaign"
          primaryOnClick={() => navigate("/admin/campaigns/campaign-new")}
        />
      </div>
      {searchParamsQuery || !_.isEmpty(campaigns) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}

      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Draft Campaign List"
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default DraftCampaigns;
