import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";
import _ from "lodash";

//for brand Category list
export const useBrandList = (searchTerm, { defaultValue, page = "1" }) => {
  const queryObject = { page, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/brand_categories${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});
  return {
    brands: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useBrandsList = (searchBrand, { defaultValue, page = "1" }) => {
  const queryObject = { page, query: searchBrand, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);

  const key = `/portal_api/v1/brands${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});
  return {
    brands: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useBrandsByCategoryList = (
  searchBrand,
  { defaultValue, page = "1", brandCategoryId }
) => {
  const queryObject = { page, query: searchBrand, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);

  const key = brandCategoryId
    ? `/portal_api/v1/brand_categories/${brandCategoryId}
  /brands${queryValues}`
    : "";

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});
  return {
    brands: !_.isEmpty(data?.data) ? data?.data : [] || defaultValue,
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
