import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "@reach/router";
import { useTalent } from "../../../../../lib/services/TalentService";
import Layout from "../../../../../components/sideBar/Layout";
import PostHeader from "./PostHeader";
import moment from "moment";
import _ from "lodash";
import Table from "../../../../../components/Table";
import Author from "../../../../../stories/Author";
import TableLayout from "../../../../../components/TableLayout";
import { LoadContentHelper } from "../../../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../../../utils/Helper";
import { usePostByTalentList } from "../../../../../lib/services/PostService";
import { API } from "../../../../../lib/network/API";
import { toast } from "../../../../../components/Toast";
import { ModalWrapper } from "../../../../../utils/Modal";
import { ActionDeleteButton } from "../../../../../table-components/Buttons";
import { ExclamationIcon } from "@heroicons/react/outline";

const TalentRegularPosts = () => {
  const location = useLocation();
  const { talentId } = useParams();
  const { talent } = useTalent(talentId);
  const urlLastSegment = location.pathname.split("/").pop();
  const [deleteId, setDeleteId] = useState(false);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const filterType = "regular";
  const {
    posts,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = usePostByTalentList(pageNumberQuery, "", talentId, filterType);
  const total_pages = Number(paginationInfo?.totalPages);

  const deletePost = async () => {
    if (deleteId) {
      setModalPrimaryCtaLoading(true);
      const { error } = await API.delete(
        `/portal_api/v1/posts/${deleteId}`,
        {}
      );
      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ??
            "This regular post cannot be deleted. Please try again"
          }`
        );
      } else {
        toast.success("Regular Post deleted successfully");
        setDeleteId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const columns = [
    {
      field: "post",
      render: (rowData) => (
        <div className="text-left  text-sm font-medium text-gray-900 max-w-xs">
          <Author
            name={rowData?.attributes?.caption}
            imageSrc={rowData?.attributes?.previewImage?.thumbnail?.url}
            size="lg"
            className="cursor-default"
            imageShape="round-md"
          />
        </div>
      ),
    },
    {
      field: "views",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.impressionsCount ?? "-NA-"}
        </div>
      ),
    },

    {
      field: "likes",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.likesCount ?? "-NA-"}
        </div>
      ),
    },

    {
      field: "comments",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.commentsCount ?? "-NA-"}
        </div>
      ),
    },
    {
      field: "status",
      render: (rowData) => (
        <div className="text-left uppercase whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.status || "-NA-"}
        </div>
      ),
    },
    {
      field: "published on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.publishedAt
            ? moment(rowData?.attributes?.publishedAt).format("LL")
            : "-NA-"}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              setDeleteId(rowData?.id);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]);

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(notWithingRange ? 1 : pageNumberQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo]);

  const loadContent = () => {
    if (_.isEmpty(talent) || _.isEmpty(posts)) {
      return (
        <LoadContentHelper
          title="Post"
          data={posts}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
        />
      );
    }

    return <Table column={columns} data={posts} />;
  };
  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deletePost,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper title="Regular Post" config={modalConfig} />
      <PostHeader
        talentName={
          talent?.attributes?.fanAccount?.attributes?.name || "Regular Posts"
        }
        talentId={talentId}
        active={urlLastSegment}
      />

      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Regular Post List"
          paginationInfo={paginationInfo}
          loading={loading}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default TalentRegularPosts;
