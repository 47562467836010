import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import Button from "../../../../stories/Button";
import { API } from "../../../../lib/network/API";
import { toast } from "../../../../components/Toast";
import FormFields from "../../../FormFields";
import { PaperAirplaneIcon } from "@heroicons/react/solid";
import { useAllTalentList } from "../../../../lib/services/AllTalentsService";

const CommentForm = ({
  initialValues,
  postId,
  fanId,
  setInitialValues,
  mutate,
  setReplyingTo,
}) => {
  const buttonRef = useRef(null);
  const [searchTalent, setSearchTalent] = useState("");
  const { allTalents } = useAllTalentList(searchTalent, {});

  const formValues = [
    {
      attributeId: "comment",
      type: "MentionInput",
      displayKey: "attributes.username",
      options: allTalents,
      valueKey: "id",
      onChange: setSearchTalent,
      height: "4rem",
      setReplyingTo: setReplyingTo,
    },
  ];
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    if (_.trim(values?.comment) && _.trim(values?.comment)?.length <= 80) {
      const fetchedValues = {
        ..._.pick(values, ["status"]),
        content: values?.comment,
        fanId: fanId,
      };

      const apiParams = {
        data: { attributes: fetchedValues },
      };

      const endpoint = `/portal_api/v1/posts/${postId}/comments`;

      const { error } = await API.post(endpoint, apiParams);
      if (error) {
        toast.error(
          `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
        );
        buttonRef.current.shake();
      } else {
        setInitialValues({
          ...initialValues,
          comment: "",
        });
        resetForm(initialValues);
        toast.success("Comment added successfully");
        mutate();
      }
      setSubmitting(false);
    } else {
      if (values?.comment?.length > 80) {
        toast.error("Comment should be 80 characters or less");
      } else toast.error("Comment can't be empty");
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className="flex justify-evenly items-center ">
            <FormFields
              className="w-10/12"
              setFieldValue={setFieldValue}
              values={values}
              formValues={formValues}
            />
            <div className="h-12 transform rotate-90">
              <Button
                iconAlso={true}
                icon={<PaperAirplaneIcon />}
                ref={buttonRef}
                type="submit"
                circular={true}
                size="lg"
                loading={isSubmitting}
                className="w-12 h-12"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CommentForm;
