import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import Button from "../../../../stories/Button";
import { tmaManagerValidationSchema } from "../../../validation";
import { useTalentList } from "../../../../lib/services/tma/TalentService";
import { API } from "../../../../lib/network/API";
import { toast } from "../../../../components/Toast";
import FormFields from "../../../FormFields";

const TmaManagerForm = ({
  setToggle,
  initialValues,
  editMode,
  formButtonCta,
}) => {
  const buttonRef = useRef(null);

  const [searchTalent, setSearchTalent] = useState("");

  //talent List
  const {
    talents: talentsData,
    isValidating: isValidatingTalents,
  } = useTalentList(searchTalent, {
    defaultValue: initialValues.talents,
  });

  const formValues = [
    {
      label: "First Name *",
      attributeId: "firstName",
      placeholder: "Rahul",
      type: "TextInput",
    },
    {
      label: "Last Name *",
      attributeId: "lastName",
      placeholder: "Gupta",
      type: "TextInput",
    },
    {
      label: "Select Talents",
      attributeId: "talentId",
      placeholder: "Select Talent ",
      type: "Multiselect",
      displayKey: "attributes.name",
      items: editMode ? talentsData.concat(initialValues.talents) : talentsData,
      valueKey: "id",
      onChange: setSearchTalent,
      isValidating: isValidatingTalents,
    },

    {
      label: "Email *",
      attributeId: "email",
      placeholder: "xyz@example.com",
      type: "TextInput",
    },
    {
      label: "Manager Phone Number *",
      attributeId: "phone",
      placeholder: "+91 98568-74589",
      type: "PhoneInput",
      countryCode: "countryCode",
    },
    {
      label: "Status : ",
      attributeId: "status",
      type: "Toggle",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    // uploading data to backend db
    const fetchedValues = {
      ..._.pick(values, [
        "firstName",
        "lastName",
        "email",
        "countryCode",
        "status",
      ]),
      phone: values.phone.slice(values.countryCode.length),
      role: "manager",
      managersTalentsAttributes: values?.talentId,
    };

    const apiParams = {
      data: { attributes: fetchedValues },
    };
    const endpoint = `/portal_api/v1/talent_management_members/${
      editMode ? initialValues.id : ""
    }`;

    const { error } = editMode
      ? await API.put(endpoint, apiParams)
      : await API.post(endpoint, apiParams);
    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(`Manager ${editMode ? "Updated" : "Created"} Successfully`);
      setToggle(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={tmaManagerValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="space-y-7">
          <FormFields
            setFieldValue={setFieldValue}
            values={values}
            formValues={formValues}
          />
          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="w-full py-2"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default TmaManagerForm;
