import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import Button from "../../stories/Button";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import _ from "lodash";
import { quizQuestionValidationSchema } from "../../forms/validation.js";
import FormFields from "../FormFields";
import { getCorrectOptionApi } from "../../utils/Helper";
// TalentCategory data
import { useTalentList } from "../../lib/services/TalentService";
const QuizQuestionForm = ({
  setToggle,
  initialValues,
  editMode = false,
  formButtonCta,
}) => {
  const buttonRef = useRef(null);
  const [categoryAttribute, setCategoryAttribute] = useState(
    initialValues?.category
  );
  const [searchTalentCategory, setSearchTalentCategory] = useState("");

  //talenty Category
  const {
    talents: talentCategoriesData,
    isValidating: isValidatingTalentCategory,
  } = useTalentList(searchTalentCategory, {
    defaultValue: initialValues.talentCategories,
  });

  const formValues = [
    {
      label: "Quiz Question Type *",
      attributeId: "category",
      placeholder: "Question type ",
      type: "DropDown",
      options: [
        { attributes: { category: "personal" } },
        { attributes: { category: "general" } },
      ],
      displayKey: "category",
      setCategoryAttribute: setCategoryAttribute,
    },

    {
      label: "Talent Category *",
      attributeId: "talentCategoryId",
      placeholder: "Select Talent Category",
      type: "Search",
      displayKey: "attributes.name",
      items: talentCategoriesData,
      onChange: setSearchTalentCategory,
      isValidating: isValidatingTalentCategory,
    },
    {
      label: "Question *",
      attributeId: "question",
      type: "TextArea",
      placeholder: "Enter quiz question...",
    },
  ];

  const formOptions = [
    {
      type: "Row",
      formValues: [
        {
          label: "Option A *",
          attributeId: "optionA",
          placeholder: "Option A",
          type: "TextInput",
        },
        {
          label: "Option B *",
          attributeId: "optionB",
          placeholder: "Option B",
          type: "TextInput",
        },
        {
          label: "Option C *",
          attributeId: "optionC",
          placeholder: "Option C",
          type: "TextInput",
        },
      ],
    },
    {
      type: "Row",
      formValues: [
        {
          label: "Correct Option *",
          attributeId: "correctOption",
          placeholder: "Correct Option",
          type: "DropDown",
          options: [
            { attributes: { option: "Option A" } },
            { attributes: { option: "Option B" } },
            { attributes: { option: "Option C" } },
          ],
          displayKey: "option",
        },
      ],
    },
  ];

  const formStatus = [
    {
      type: "Row",
      formValues: [
        {
          label: "Status : ",
          attributeId: "status",
          type: "Toggle",
        },
      ],
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    // uploading data to backend db

    const options = [values?.optionA, values?.optionB, values?.optionC];

    const fetchedValues = (() => {
      switch (values?.category) {
        case "personal":
          return {
            ..._.pick(values, ["question", "category", "status"]),
            talentCategoryId: values.talentCategoryId,
            options: _.times(3, ""),
            correctOption: null,
          };
        case "general":
          return {
            ..._.pick(values, ["category", "status", "question"]),
            talentCategoryId: values.talentCategoryId,
            options: options,
            correctOption: getCorrectOptionApi(values?.correctOption, options),
          };
        default:
          break;
      }
    })();

    const apiParams = {
      data: { attributes: fetchedValues },
    };
    const endpoint = `/portal_api/v1/quiz_questions/${
      editMode ? initialValues.id : ""
    }`;
    const { error } = editMode
      ? await API.put(endpoint, apiParams)
      : await API.post(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(
        `Quiz Question ${editMode ? "Updated" : "Created"} Successfully`
      );
      setTimeout(() => {
        setToggle(false);
      }, 400);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={quizQuestionValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="space-y-7">
          <FormFields
            setFieldValue={setFieldValue}
            values={values}
            formValues={formValues}
          />
          {categoryAttribute === "general" ? (
            <FormFields
              setFieldValue={setFieldValue}
              values={values}
              formValues={formOptions}
            />
          ) : null}

          <FormFields
            setFieldValue={setFieldValue}
            values={values}
            formValues={formStatus}
          />

          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="w-full py-2"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default QuizQuestionForm;
