import React from "react";
import { useTermsAndPrivacy } from "../../lib/services/TermsAndPrivacyService";
import { Link } from "@reach/router";
import Loader from "../../components/Loader";
import parse from "html-react-parser";
const TnCPage = () => {
  const { policy, loading } = useTermsAndPrivacy();
  const termsAndConditionsContent = policy?.attributes
    ?.termsAndConditionsContent
    ? parse(policy?.attributes?.termsAndConditionsContent)
    : null;
  return (
    <div className="relative py-4 md:py-6 lg:py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <div className="flex items-center justify-center flex-shrink-0">
            <Link to="/">
              <img
                className=" h-10 md:h-12 xl:h-16 2xl:h-20 w-auto bg-gray-800 p-2 rounded-md"
                src="/assets/images/logo/logo-white.svg"
                alt="fanship"
              />
            </Link>
          </div>
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Terms And Conditions
            </span>
          </h1>
        </div>
        {loading ? (
          <div className="mt-6 prose prose-indigo prose-lg text-gray-800 mx-auto text-center">
            <Loader title={`Fetching terms and conditions ...`} />
          </div>
        ) : (
          <>
            {termsAndConditionsContent ? (
              <div className="mt-6 prose prose-indigo prose-lg text-gray-800 mx-auto text-justify max-w-7xl">
                {termsAndConditionsContent}
              </div>
            ) : (
              <div className="mt-6 prose prose-indigo prose-lg text-gray-800 mx-auto text-center">
                We are under the process of updating our terms and conditions
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default TnCPage;
