import React, { useRef, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import Button from "../../../../../stories/Button";
import { navigate } from "@reach/router";
import { API } from "../../../../../lib/network/API";
import { toast } from "../../../../../components/Toast";
import { TmaRegularPostValidationSchema } from "../../../../validation";
import FormFields from "../../../../FormFields";
import { MediaAttachments } from "../../../../../components/media/MediaAttachments";
import { VideoAttachments } from "../../../../../components/video/VideoAttachments";
import { getImageDimension } from "../../../../../utils/Helper";
import { useAllTalentList } from "../../../../../lib/services/AllTalentsService";
import Label from "../../../../../components/Label";
const ReguarPostForm = ({
  initialValues,
  editing,
  formButtonCta,
  tmaTalentId,
}) => {
  const buttonRef = useRef(null);
  const [mediaStatus, setMediaStatus] = useState("draft");
  const attachmentType = "multiple";
  const label = "Media Attachment(s)";
  const labelMessage = "*Mandatory for publishing the post";
  const [editImageList, setEditImageList] = useState([]);
  const [imageList, setImageList] = useState([
    {
      key: "",
    },
  ]);

  const [editVideoList, setEditVideoList] = useState([]);
  const [videoList, setVideoList] = useState([
    {
      key: "",
    },
  ]);

  useEffect(() => {
    if (!!editing) {
      if (initialValues?.attachments?.length > 0) {
        const videoArray = initialValues?.attachments?.filter(
          (el) => el?.attributes?.category === "video"
        );

        const videoAttachments = videoArray?.map((el) => {
          return { key: el?.attributes.key, id: el?.id };
        });
        //checking for empty published n draft case
        setVideoList(
          _.isEmpty(videoAttachments) && initialValues?.status === "draft"
            ? videoList
            : videoAttachments
        );
        const imageArray = initialValues?.attachments?.filter(
          (el) => el?.attributes?.category === "image"
        );
        const imageAttachments = imageArray?.map((el) => {
          return { key: el?.attributes.key, id: el?.id };
        });
        setImageList(
          _.isEmpty(imageAttachments) && initialValues?.status === "draft"
            ? imageList
            : imageAttachments
        );
      }
      setMediaStatus(initialValues?.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, setImageList, setVideoList]);

  //video handlers
  const onVideoListHandler = (videos) => {
    setVideoList(videos);
  };
  const onEditVideoListHandler = (videos) => {
    setEditVideoList(videos);
  };

  //image array handlers for child mediaAttachment component
  const onImageListHandler = (images) => {
    setImageList(images);
  };
  const onEditImageListHandler = (images) => {
    setEditImageList(images);
  };

  const [searchTalent, setSearchTalent] = useState("");
  const { allTalents } = useAllTalentList(searchTalent, {});

  const formValues = [
    {
      label: "Caption *",
      attributeId: "caption",
      type: "MentionInput",
      displayKey: "attributes.username",
      options: allTalents,
      valueKey: "id",
      onChange: setSearchTalent,
    },
  ];
  // ready_to_publish
  const onHandleStatus = (values, { setSubmitting }) => {
    if (
      mediaStatus === "published" ||
      mediaStatus === "ready_to_publish" ||
      mediaStatus === "processing"
    ) {
      onSubmit({ ...values, status: "ready_to_publish" }, setSubmitting);
    } else {
      onSubmit({ ...values, status: "draft" }, setSubmitting);
    }
  };
  const onSubmit = async (values, setSubmitting) => {
    const payload = await setPayLoadObj(values);
    const endpoint = `/portal_api/v1/posts/${editing ? initialValues.id : ""}`;
    const { error } = editing
      ? await API.put(endpoint, payload)
      : await API.post(endpoint, payload);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      setSubmitting(false);
      buttonRef.current.shake();
    } else {
      toast.success(`Post ${editing ? "Updated" : "Created"} Successfully.`);
      setTimeout(() => {
        navigate(-1);
      }, 100);
      setSubmitting(false);
    }
  };

  // This function to set the payload object of post data
  const setPayLoadObj = async (data) => {
    if (editing) {
      if (initialValues?.status !== "draft") {
        //for editing caption only
        const textFieldPostObj = {};
        textFieldPostObj.data = {
          attributes: {
            caption: data.caption,
            talentId: tmaTalentId,
            category: "regular",
          },
        };
        return textFieldPostObj;
      }
    }

    const videoUrlArray = videoList.filter((el) => el?.key);
    const videoAttachments = await Promise.all(
      videoUrlArray.map(async (el) => {
        return {
          ...el,
          category: "video",
          width: 1280,
          height: 720,
        };
      })
    );

    const imageUrlArray = imageList.filter((el) => el?.key);
    const imageAttachments = await Promise.all(
      imageUrlArray.map(async (el) => {
        const dimension = await getImageDimension(el.key);
        return {
          ...el,
          width: dimension.width,
          height: dimension.height,
        };
      })
    );

    if (editing) {
      //video
      const EditVideoUrlArray = editVideoList.filter((el) => el?.key);
      const EditVideoAttachments = await Promise.all(
        EditVideoUrlArray.map(async (el) => {
          return {
            ...el,
            category: "video",
            width: 1280,
            height: 720,
          };
        })
      );

      //image
      const EditImageUrlArray = editImageList.filter((el) => el?.key);
      const EditImageAttachments = await Promise.all(
        EditImageUrlArray.map(async (el) => {
          const dimension = await getImageDimension(el.key);
          return {
            ...el,
            width: dimension.width,
            height: dimension.height,
          };
        })
      );

      const EditPostObj = {};
      EditPostObj.data = {
        attributes: {
          caption: data.caption,
          attachmentsAttributes: EditImageAttachments.concat(
            imageAttachments,
            videoAttachments,
            EditVideoAttachments
          ),
          talentId: tmaTalentId,
          status: data?.status,
          category: "regular",
        },
      };
      return EditPostObj;
    }

    const postObj = {};
    postObj.data = {
      attributes: {
        caption: data.caption,
        attachmentsAttributes: imageAttachments.concat(videoAttachments),
        talentId: tmaTalentId,
        status: data?.status,
        category: "regular",
      },
    };
    return postObj;
  };
  return (
    <div className="pt-4 rounded-md bg-white px-4 pb-6 border-2 border-gray-300 ">
      <Formik
        initialValues={initialValues}
        validationSchema={TmaRegularPostValidationSchema}
        onSubmit={onHandleStatus}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="flex flex-col justify-between  ">
              <div className="px-2 pb-6 space-y-2">
                <FormFields
                  className=" mb-2 w-full"
                  innerContainer=" w-3/5 px-2 pb-6"
                  setFieldValue={setFieldValue}
                  values={values}
                  formValues={formValues}
                />
                <Label className="px-2">
                  {label}{" "}
                  <span className=" text-gray-800 text-xs font-bold">
                    {labelMessage ? `(${labelMessage})` : null}
                  </span>
                </Label>
                <div className="pl-2">
                  {!videoList[0]?.key ? (
                    <MediaAttachments
                      {...{
                        imageList,
                        onImageListHandler,
                        onEditImageListHandler,
                        editImageList,
                        editing,
                        attachmentType,
                        mediaStatus,
                      }}
                    />
                  ) : null}

                  {!videoList[0]?.key && !_.some(imageList, "key") ? (
                    <div className="flex items-center w-1/5 justify-center font-normal text-lg mr-8">
                      or
                    </div>
                  ) : null}
                  {!_.some(imageList, "key") ? (
                    <VideoAttachments
                      {...{
                        videoList,
                        onVideoListHandler,
                        onEditVideoListHandler,
                        editVideoList,
                        editing,
                        mediaStatus,
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div>
                <div className="flex justify-end space-x-5 ">
                  {values?.status !== "published" &&
                  values?.status !== "ready_to_publish" &&
                  values?.status !== "processing" ? (
                    <Button
                      size="xl"
                      ref={buttonRef}
                      type="submit"
                      onClick={() => {
                        setMediaStatus("draft");
                      }}
                      loading={mediaStatus === "draft" ? isSubmitting : null}
                      className="w-1/6"
                    >
                      Save As Draft
                    </Button>
                  ) : null}

                  <Button
                    size="xl"
                    ref={buttonRef}
                    type="submit"
                    disabled={
                      !values?.caption ||
                      !(videoList[0]?.key || imageList[0]?.key)
                    }
                    buttonStyle={
                      values?.caption &&
                      (videoList[0]?.key || imageList[0]?.key)
                        ? "danger"
                        : "disabled"
                    }
                    onClick={() => {
                      setMediaStatus("ready_to_publish");
                    }}
                    loading={
                      mediaStatus === "ready_to_publish" ||
                      mediaStatus === "published" ||
                      mediaStatus === "processing"
                        ? isSubmitting
                        : null
                    }
                    className="w-1/6"
                  >
                    {initialValues?.status === "published" ||
                    initialValues?.status === "ready_to_publish" ||
                    initialValues?.status === "processing"
                      ? "Update"
                      : "Publish"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReguarPostForm;
