import React, { useRef } from "react";
import { Link, navigate, useLocation } from "@reach/router";
import { Formik, Form } from "formik";
import { LockClosedIcon } from "@heroicons/react/solid";
import TextInput from "../../components/TextInput";
import Button from "../../stories/Button";
import Label from "../../components/Label";
import { signInValidationSchema } from "../../forms/validation";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import { CurrentUser } from "../../lib/network/auth/Auth";
import classNames from "classnames";

export default function Signin() {
  const location = useLocation();
  const subDomain = location.hostname.split(/[.\-_]/)[0];
  const initialValues = { email: "", password: "" };
  const buttonRef = useRef(null);

  const onSubmit = async (values, { setSubmitting }) => {
    const apiEndpoint = () => {
      switch (subDomain) {
        case "admin":
          return "/portal_api/v1/login";
        case "tma":
          return "/portal_api/v1/talent_managements/login";
        case "advertiser":
          return "/portal_api/v1/advertisers/login";
        case "talent":
          return "/portal_api/v1/talents/login";
        default:
          return "/portal_api/v1/login";
      }
    };
    const { data, error } = await API.post(apiEndpoint(), {
      data: { attributes: values },
    });
    if (error) {
      toast.error(error.message);
      buttonRef.current.shake();
    } else {
      await CurrentUser.setToken(data.data.token);
      // eslint-disable-next-line
      const [headers, payload, signature] = data?.data?.token?.split(".");
      await CurrentUser.setDetails(atob(payload));
      window.location.href = "/";
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signInValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <div
          className={classNames(
            "min-h-screen bg-gray-100 flex flex-col py-12 sm:px-6 lg:px-8",
            ["admin", "tma", "advertiser", "talent"].includes(subDomain) &&
              "justify-center"
          )}
        >
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="/assets/images/logo/logo.svg"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {["admin", "tma", "advertiser", "talent"].includes(subDomain)
                ? "Login to your account"
                : "Login Portal"}
            </h2>
          </div>
          <div className="bg-gray-100">
            {["admin", "tma", "advertiser", "talent"].includes(subDomain) ? (
              <span className="relative z-0 flex justify-center  pt-4 rounded-md space-x-2">
                <div
                  className={classNames(
                    "bg-gray-50 text-center  px-4 py-2 rounded-l-md rounded-r-md border border-gray-300 text-md font-medium text-gray-800 capitalize  sm:mx-auto sm:w-full sm:max-w-md"
                  )}
                >
                  {`${subDomain ?? "login"} portal`}
                </div>
              </span>
            ) : (
              <div className="mt-10 ">
                <h2 className="mt-6 text-center text-xl font-extrabold text-gray-900">
                  Choose a portal where you want to login
                </h2>
                <span className="relative z-0 flex justify-center mt-4 pt-4   rounded-md space-x-2">
                  <Button
                    onClick={() => {
                      navigate(
                        `${location.protocol}//admin.${location.host}/signin`,
                        { replace: true }
                      );
                    }}
                    type="click"
                    buttonStyle="link"
                    className="p-2"
                  >
                    Admin Portal
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(
                        `${location.protocol}//tma.${location.host}/signin`,
                        { replace: true }
                      );
                    }}
                    type="click"
                    buttonStyle="link"
                    className="p-2"
                  >
                    TMA Portal
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(
                        `${location.protocol}//advertiser.${location.host}/signin`,
                        { replace: true }
                      );
                    }}
                    type="click"
                    buttonStyle="link"
                    className="p-2"
                  >
                    Advertiser Portal
                  </Button>
                  <Button
                    onClick={() => {
                      navigate(
                        `${location.protocol}//talent.${location.host}/signin`,
                        { replace: true }
                      );
                    }}
                    type="click"
                    buttonStyle="link"
                    className="p-2"
                  >
                    Talent Portal
                  </Button>
                </span>
              </div>
            )}
          </div>
          {["admin", "tma", "advertiser", "talent"].includes(subDomain) ? (
            <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md ">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <Form className="space-y-6">
                  <div>
                    <Label labelFor="email">Email Address</Label>
                    <TextInput
                      name="email"
                      id="email"
                      type="email"
                      placeholder="joe@fanship.com"
                    />
                  </div>
                  <div>
                    <Label labelFor="password">Password</Label>
                    <TextInput
                      name="password"
                      id="password"
                      type="password"
                      placeholder="Min 8 characters"
                    />
                  </div>
                  <div className="flex justify-end ">
                    <Link
                      to="/forgot-password"
                      state={{ portal: subDomain }}
                      className="font-medium text-red-700 hover:text-red-600 text-sm"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                  <Button
                    ref={buttonRef}
                    type="submit"
                    className="w-full"
                    loading={isSubmitting}
                    iconAlso={true}
                    iconPlacement="leading"
                    icon={<LockClosedIcon />}
                  >
                    Login
                  </Button>
                </Form>
              </div>
            </div>
          ) : null}
          <footer className="w-full  max-w-container mx-auto  py-10 text-center flex flex-col md:flex-row items-center justify-center text-sm text-gray-700 lg:fixed lg:bottom-0 lg:left-0 ">
            <p>© 2021 Fanship Inc. All rights reserved.</p>

            <Link
              className="ml-3 md:border-l md:border-gray-400 pl-3 text-gray-700 hover:text-blue-700"
              to="/privacy"
            >
              Privacy Policy
            </Link>

            <Link
              className="hover:text-blue-700 ml-3 md:border-l md:border-gray-400  text-gray-700 pl-3"
              to="/terms"
            >
              Terms
            </Link>
          </footer>
        </div>
      )}
    </Formik>
  );
}
