import React, { useEffect } from "react";
import { useLocation, useParams } from "@reach/router";
import queryString from "query-string";
import Table from "../../../../components/Table";
import moment from "moment";
import Author from "../../../../stories/Author";
import SearchBar from "../../../../components/SearchBar";
import _ from "lodash";
import TableLayout from "../../../../components/TableLayout";
import { LoadContentHelper } from "../../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../../utils/Helper";
import Layout from "../../../../components/sideBar/Layout";
import {
  useTalent,
  useTalentCampaignsList,
} from "../../../../lib/services/TalentService";
import AllTalentHeader from "./AllTalentHeader";

const EarningOngoingCampaigns = () => {
  const location = useLocation();
  const { talentId } = useParams();
  const { talent } = useTalent(talentId);
  const urlLastSegment = location.pathname.split("/").pop();
  const parsed = queryString.parse(location.search);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const getTalentPost = (posts) => {
    return _.find(posts || [], [
      "attributes.post.attributes.talent.id",
      talentId,
    ]);
  };

  const columns = [
    {
      field: "post",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm  text-gray-900 flex flex-row  items-center">
          <Author
            imageSrc={
              getTalentPost(rowData?.attributes?.campaignsPosts)?.attributes
                ?.post?.attributes?.previewImage?.thumbnail?.url
            }
            size="lg"
            className="cursor-default"
            imageShape="round-none"
          />
        </div>
      ),
    },
    {
      field: "target impressions",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.targetImpressions}
        </div>
      ),
    },
    {
      field: "received impressions",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm  text-gray-900 flex flex-row  items-center">
          {rowData?.attributes?.receivedImpressions}
        </div>
      ),
    },
    {
      field: "brand",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm  text-gray-500 capitalize">
          {rowData?.attributes?.brand?.attributes?.name}
        </div>
      ),
    },
    {
      field: "start date & time",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.startTime ? (
            <div>
              {moment.unix(`${rowData?.attributes?.startTime}`).format("lll")}
            </div>
          ) : (
            "-NA-"
          )}
        </div>
      ),
    },
    {
      field: "end date & time",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.startTime ? (
            <div>
              {moment.unix(`${rowData?.attributes?.endTime}`).format("lll")}
            </div>
          ) : (
            "-NA-"
          )}
        </div>
      ),
    },
  ];

  const {
    talentCampaigns,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useTalentCampaignsList(searchParamsQuery, {
    talentId: talentId,
    page: pageNumberQuery,
    filterByStatus: "active",
  });

  const loadContent = () => {
    if (_.isEmpty(talentCampaigns)) {
      return (
        <LoadContentHelper
          title="Ongoing Campaign"
          data={talentCampaigns}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }

    return <Table column={columns} data={talentCampaigns} />;
  };

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  return (
    <Layout>
      <AllTalentHeader
        talentName={
          talent?.attributes?.fanAccount?.attributes?.name ||
          "Ongoing Campaigns"
        }
        talentId={talentId}
        active={urlLastSegment}
      />
      <div className="flex justify-between items-center">
        {searchParamsQuery || !_.isEmpty(talentCampaigns) ? (
          <SearchBar
            searchTerm={searchParamsQuery}
            size="xl"
            align="center"
            onSearch={(val) => {
              navigateSelf(1, val, parsed);
            }}
            loading={loading}
          />
        ) : null}
      </div>
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Ongoing Compaigns List"}
          loading={loading}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default EarningOngoingCampaigns;
