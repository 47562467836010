import * as Yup from "yup";
import moment from "moment";
import _ from "lodash";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const urlRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

/**
 * In case of using file size and formats
 * const FILE_SIZE = 160 * 1024;
 */

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "jpg",
  "jpeg",
  "png",
];

const get_url_extension = (url) => {
  return url?.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
};

const getImageDimension = (value) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve({ height: img.height, width: img.width });
    img.onerror = reject;
    img.src = value?.data || value;
  });
};

export const signInValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your Email address"),
  password: Yup.string()
    .min(8, "Must be 8 characters or more")
    .required("Please enter your password"),
});

export const passwordResetValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your Email address"),
});

export const resetPasswordValidationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Must be 8 characters or more")
    .required("Please enter your password"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password don't  match")
    .required("Please enter your confirm password"),
});

export const talentCategoryValidationSchema = Yup.object({
  name: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter Category name"),
  brief: Yup.string()
    .min(4, "Must be 4 characters or more")
    .max(100, "Must be less than 100 characters")
    .required("Please enter brief"),
});
// image: Yup.mixed().required(),
// file: Yup.mixed()
//   .required("Image is required")
//   .test(
//     "fileSize",
//     "File too large",
//     (value) => value && value.size <= FILE_SIZE
//   )
//   .test(
//     "fileFormat",
//     "Unsupported Format",
//     (value) => value && SUPPORTED_FORMATS.includes(value.type)
//   ),

//Brand Category Validation Schema

export const brandCategoryValidationSchema = Yup.object({
  name: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter Category name"),
  brief: Yup.string()
    .min(4, "Must be 4 characters or more")
    .max(100, "Must be less than 100 characters")
    .required("Please enter brief"),
});

export const talentAgencyValidationSchema = Yup.object({
  name: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter Agency name"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your Email address"),

  adminFirstName: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(20, "Must be less than 20 characters")
    .required("Please enter Admin First name"),
  adminLastName: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(20, "Must be less than 20 characters")
    .required("Please enter Admin Last name"),

  adminPhone: Yup.string()
    .min(10, "Invalid phone number")
    .required("Please enter phone number")
    .matches(phoneRegExp, "Phone number is not valid"),
});

export const talentValidationSchema = Yup.object({
  name: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter talent name"),
  quote: Yup.string()
    .min(4, "Must be 4 characters or more")
    .max(100, "Must be less than 100 characters")
    .required("Please enter quote")
    .nullable(),
  username: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter username"),
  location: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter location")
    .nullable(),
  phone: Yup.string()
    .min(10, "Invalid phone number")
    .required("Please enter phone number")
    .matches(phoneRegExp, "Phone number is not valid"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter talent email")
    .nullable(),
  socialProfile: Yup.string()
    .matches(urlRegExp, "Enter correct url!")
    .required("Please enter website")
    .nullable(),
  gender: Yup.string().required("Please select gender"),
  costPerImpression: Yup.number()
    .required("Please enter cost per impression")
    .min(0.01, "Cost per impression should be greater than 0")
    .nullable(),
  talentCategoryId: Yup.array()
    .of(
      Yup.object().shape({
        talentCategoryId: Yup.string().required(
          "Please select Talent Category"
        ),
      })
    )
    .required("Please select talent category ")
    .min(1, "Please select at least one talent category"),
  dob: Yup.date()
    .required("Please select date of birth")
    .test("dob", "Talent must be alteast 7 years old", function (dob) {
      return moment().diff(moment(dob), "years") >= 7;
    }),
});

export const GuestFeedTalentValidationSchema = Yup.object({
  talentId: Yup.string().required("Please select Talent"),
});

// BrandsValidationSchema

export const BrandValidationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Must be 2 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter brand name"),

  brandCategoryId: Yup.array()
    .min(1, "Please select Brand Category")
    .required("Please select Brand Category"),
});

export const teamValidationSchema = Yup.object({
  firstName: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter first name"),
  lastName: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter last name"),

  phone: Yup.string()
    .min(10, "Invalid phone number")
    .required("Please enter phone number")
    .matches(phoneRegExp, "Phone number is not valid"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your Email address"),

  role: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(20, "Must be less than 20 characters")
    .required("Please enter role"),
});

export const AdvertiserValidationSchema = Yup.object({
  firstName: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter first name"),
  lastName: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter last name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your Email address"),
  advertiserType: Yup.string().required("Please select advertiser type"),
  brandId: Yup.array()
    .min(1, "Please select Brand")
    .required("Please select a Brand "),
});

export const AssignBrandValidationSchema = Yup.object({
  brandId: Yup.string().required("Please select Brand"),
});

export const creditValidationSchema = Yup.object({
  credit: Yup.number()
    .integer("Credit must be an integer")
    .required("Please enter credit")
    .min(1, "Credit must be greater than 0"),
});

export const blacklistKeywordValidationSchema = Yup.object({
  word: Yup.string().required("Please enter keyword"),
});

//profile -> change password
export const changePasswordValidationSchema = Yup.object({
  oldPassword: Yup.string()
    .trim()
    .min(8, "Must be 8 characters or more")
    .required("Please enter your password"),
  newPassword: Yup.string()
    .trim()
    .min(8, "Must be 8 characters or more")
    .required("Please enter a new password")
    .notOneOf(
      [Yup.ref("oldPassword"), null],
      "New password should be different from old password"
    ),

  passwordConfirmation: Yup.string()
    .trim()
    .oneOf([Yup.ref("newPassword"), null], "Password doesn't match")
    .required("Please enter your confirm password"),
});

// profile->profile edit
export const profileValidationSchema = Yup.object({
  firstName: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter first name"),
  lastName: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter last name"),

  phone: Yup.string()
    .min(10, "Invalid phone number")
    .required("Please enter phone number")
    .matches(phoneRegExp, "Phone number is not valid"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your Email address"),
});

export const quizQuestionValidationSchema = Yup.object({
  question: Yup.string()
    .min(4, "Must be 4 characters or more")
    .max(100, "Must be less than 100 characters")
    .required("Please enter quiz question"),
  category: Yup.string().required("Please select question type"),
  optionA: Yup.string().when("category", {
    is: (category) => category === "general",
    then: Yup.string()
      .required("Please enter option A")
      .max(20, "Must be less than 20 characters"),
  }),
  optionB: Yup.string().when("category", {
    is: (category) => category === "general",
    then: Yup.string()
      .required("Please enter option B")
      .max(20, "Must be less than 20 characters"),
  }),
  optionC: Yup.string().when("category", {
    is: (category) => category === "general",
    then: Yup.string()
      .required("Please enter option C")
      .max(20, "Must be less than 20 characters"),
  }),
  correctOption: Yup.string().when("category", {
    is: (category) => category === "general",
    then: Yup.string().required("Please select correct option"),
  }),
  talentCategoryId: Yup.string().required(
    "Please select talent category for question"
  ),
});

export const campaignDetailsValidationSchema = Yup.object({
  monetizationType: Yup.string().required("Please enter monetizable type"),
  name: Yup.string()
    .required("Please enter campaign name")
    .min(2, "Must be 2 characters or more")
    .max(60, "Must be less than 60 characters"),
  brandCategoryId: Yup.string().required("Please select brand category"),
  associateBrandId: Yup.string().required("Please select brand"),
  advertiserId: Yup.array()
    .min(1, "Please select advertiser")
    .required("Please select a advertiser "),
});

export const targetDemographyValidationSchema = Yup.object({
  location: Yup.array()
    .required("Please select location ")
    .min(1, "Please select at least one location "),
  ageGroups: Yup.array()
    .required("Please select age group ")
    .min(1, "Please select at least one age group"),
  gender: Yup.string().required("Please select gender"),
});

export const dateandTimeValidationSchema = Yup.object({
  startTime: Yup.date()
    .required("Please select start date")
    .test("startTime", "Start date can not be in past", function (startTime) {
      return moment(startTime).isSameOrAfter(moment(), "time");
    }),
  endTime: Yup.date().min(
    Yup.ref("startTime"),
    "End date can not be before Start date"
  ),
});
export const priceInputValidationSchema = Yup.object({
  budget: Yup.number()
    .required("Please enter budget")
    .min(1000.0, "Must be ₹ 1000.00 or more"),
});
export const tmaManagerValidationSchema = Yup.object({
  firstName: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter first name"),
  lastName: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter last name"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your Email address"),

  phone: Yup.string()
    .min(10, "Invalid phone number")
    .required("Please enter phone number")
    .matches(phoneRegExp, "Phone number is not valid"),
});

export const postValidationSchema = Yup.object({
  image: Yup.mixed()
    .required("Please select ad banner")
    .test(
      "imageDimension",
      "Ad Banner Validation Error",
      async function (value) {
        const correctFormat = SUPPORTED_FORMATS.some((type) =>
          value?.file?.type
            ? type === value?.file?.type
            : type === get_url_extension(value)
        );
        if (!correctFormat) {
          return this.createError({
            message: "Please select ad banner of correct format",
          });
        }
        const dimension = await getImageDimension(value);
        if (dimension.height === 80 && dimension.width === 1200) {
          return true;
        }
        return this.createError({
          message: "Please select ad banner of mentioned dimension",
        });
      }
    ),
});

//tma regualar post Schema

export const TmaRegularPostValidationSchema = Yup.object({
  caption: Yup.string()
    .min(2, "Must be 2 characters or more")
    .max(420, "Must be less than 420 characters")
    .required("Please enter post caption"),
});

export const TmaMonetizablePostValidationSchema = Yup.object({
  caption: Yup.string()
    .min(2, "Must be 2 characters or more")
    .max(420, "Must be less than 420 characters")
    .required("Please enter post caption"),
  brandCategoryId: Yup.array()
    .min(1, "Please select Brand Category")
    .required("Please select Brand Category"),
});

export const quizDetailValidationSchema = Yup.object({
  question: Yup.string().required("Please select question"),
  optionA: Yup.string()
    .required("Please enter option A")
    .max(20, "Must be less than 20 characters"),
  optionB: Yup.string()
    .required("Please enter option B")
    .max(20, "Must be less than 20 characters"),
  optionC: Yup.string()
    .required("Please enter option C")
    .max(20, "Must be less than 20 characters"),
  correctOption: Yup.string()
    .required("Please select correct option")
    .nullable(),
});

export const faqValidationSchema = Yup.object({
  question: Yup.string()
    .min(10, "Must be 10 characters or more")
    .max(100, "Must be less than 100 characters")
    .required("Please enter a question"),
  answer: Yup.string()
    .min(2, "Must be 2 characters or more")
    .max(200, "Must be less than 200 characters")
    .required("Please enter an answer"),
});

export const tnCValidationSchema = Yup.object({
  termsAndConditions: Yup.string()
    .trim()
    .required("Terms and conditions cannot be empty"),
});

export const privacyPolicyValidationSchema = Yup.object({
  privacyPolicy: Yup.string()
    .trim()
    .required("Terms and conditions cannot be empty"),
});

//talent Profile validation schema
export const talentProfileValidationSchema = Yup.object({
  name: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter talent name"),
  quote: Yup.string()
    .min(4, "Must be 4 characters or more")
    .max(100, "Must be less than 100 characters")
    .required("Please enter quote")
    .nullable(),
  username: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter username"),
  location: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(30, "Must be less than 30 characters")
    .required("Please enter location")
    .nullable(),
  phone: Yup.string()
    .min(10, "Invalid phone number")
    .required("Please enter phone number")
    .matches(phoneRegExp, "Phone number is not valid"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter talent email")
    .nullable(),
  socialProfile: Yup.string()
    .matches(urlRegExp, "Enter correct url!")
    .required("Please enter website")
    .nullable(),
  gender: Yup.string().required("Please select gender"),
  costPerImpression: Yup.number()
    .required("Please enter cost per impression")
    .min(0.01, "Cost per impression should be greater than 0")
    .nullable(),
  dob: Yup.date()
    .required("Please select date of birth")
    .test("dob", "Talent must be alteast 7 years old", function (dob) {
      return moment().diff(moment(dob), "years") >= 7;
    }),
});
