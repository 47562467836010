import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";

export const useTalentAgencyList = (
  searchTerm,
  { defaultValue, page = "1" }
) => {
  const queryObject = { page, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);

  const key = `/portal_api/v1/talent_managements${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});
  return {
    agency: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

// talents under particular tma agency
export const useShowAgency = (agencyId) => {
  const key = agencyId ? `/portal_api/v1/talent_managements/${agencyId}` : null;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    agency: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
  };
};

//talents under particular tma
export const useTalentList = (
  searchTerm,
  { defaultValue, agencyId = null, page = "1" }
) => {
  const queryObject = { page, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/talent_managements${
    agencyId ? `/${agencyId}/talents` : ""
  }${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    talents: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
