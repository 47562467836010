import React, { useState } from "react";
import classNames from "classnames";
import moment from "moment";
import Author from "../stories/Author";
import _ from "lodash";
import { ModalWrapper } from "../utils/Modal";
import { API } from "../lib/network/API";
import { toast } from "./Toast";
import { ExclamationIcon } from "@heroicons/react/outline";
import { ReplyIcon, HeartIcon } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/solid";
import Button from "../stories/Button";

const CommentBox = ({
  comments,
  setReplyingTo,
  postId,
  talentId,
  deleteId,
  setDeleteId,
  fanId,
  commentMutate,
}) => {
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);
  const deletePost = async () => {
    if (deleteId) {
      setModalPrimaryCtaLoading(true);
      const { error } = await API.delete(
        `/portal_api/v1/posts/${postId}/comments/${deleteId}`,
        {}
      );
      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${
            error?.status ?? null
          }: Something went Wrong. Please try again`
        );
      } else {
        toast.success("Comment Deleted Successfully");
        setDeleteId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const onCommentLike = async (liked, commentId) => {
    const apiParams = {
      data: { attributes: { talent_fan_id: fanId } },
    };
    const endpoint = `/api/v1/comments/${commentId}/likes`;
    const { error } = !!liked
      ? await API.delete(endpoint, apiParams)
      : await API.post(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
    } else {
      toast.success(`Comment ${!!liked ? "Unliked" : "Liked"} Successfully`);
    }
    commentMutate();
  };

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deletePost,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };
  return (
    <div className="bg-white ">
      <ModalWrapper title="Comment" config={modalConfig} />
      {comments?.map((comment, commentIdx) => (
        <div
          key={comment.id}
          className="flex text-sm text-gray-500 space-x-4 px-4"
        >
          <div
            className={classNames(
              commentIdx === 0 ? "" : "border-t border-gray-200",
              "flex-1 py-4"
            )}
          >
            <div className="font-medium text-gray-900 px-1 flex justify-between">
              <Author
                className="pr-4 2xl:pr-0"
                name={comment?.attributes?.fan?.attributes?.name}
                imageSrc={comment?.attributes?.fan?.attributes?.image}
                cta={`@${comment?.attributes?.fan?.attributes?.username}`}
                size="sm"
              />
            </div>
            <p>
              <time
                dateTime={moment(`${comment?.attributes?.createdAt}`).format(
                  "ll"
                )}
                className="flex justify-end"
              >
                {moment(`${comment?.attributes?.createdAt}`).format("ll")}
              </time>
            </p>

            <div className="mt-4 px-4 text-md font-bold prose prose-sm max-w-none text-gray-800">
              {comment?.attributes?.content}
            </div>
            <div className="flex justify-end space-x-2">
              <Button
                icon={<HeartIcon />}
                buttonStyle={
                  !!comment?.attributes?.isLiked ? "danger" : "white"
                }
                circular={true}
                onClick={() => {
                  onCommentLike(!!comment?.attributes?.isLiked, comment?.id);
                }}
              >
                {" "}
                {comment?.attributes?.likesCount}
              </Button>
              <Button
                icon={<ReplyIcon />}
                buttonStyle="white"
                circular={true}
                onClick={() => setReplyingTo(comment?.attributes?.fan)}
              >
                {" "}
                Reply
              </Button>
              {parseInt(talentId) ===
                comment?.attributes?.fan?.attributes?.talentId && (
                <Button
                  buttonStyle="white"
                  icon={<TrashIcon />}
                  circular={true}
                  onClick={() => setDeleteId(comment?.id)}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentBox;
