import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import _ from "lodash";

import Layout from "../../../../components/sideBar/Layout";
import SectionHeading from "../../../../stories/SectionHeading";
import SlideOver from "../../../../stories/SlideOver";
import Table from "../../../../components/Table";
import { useTalentList } from "../../../../lib/services/tma/TalentService";
import Author from "../../../../stories/Author";
import moment from "moment";
import {
  ActionDeleteButton,
  ActionEditButton,
  ViewDataButton,
} from "../../../../table-components/Buttons";

import SearchBar from "../../../../components/SearchBar";
import TmaTalentForm from "../../../../forms/pageForms/talentAgencyForms/tmaPortal/TmaTalentForm";

import { API } from "../../../../lib/network/API";
import { toast } from "../../../../components/Toast";

import { ModalWrapper } from "../../../../utils/Modal";
import { LoadContentHelper } from "../../../../table-components/LoadContentHelper";
import TableLayout from "../../../../components/TableLayout";
import { navigateSelf } from "../../../../utils/Helper";
import { ExclamationIcon } from "@heroicons/react/outline";
import { MailIcon, PhoneIcon, CurrencyRupeeIcon } from "@heroicons/react/solid";
import { Link } from "@reach/router";

import QuickView from "../../../../components/QuickView";

import { CurrentUser } from "../../../../lib/network/auth/Auth";

const TmaTalents = () => {
  const location = useLocation();
  const userRole = CurrentUser.getRole();

  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const [deleteId, setDeleteId] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const parsed = queryString.parse(location.search);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const addInitialValues = {
    name: "",
    quote: "",
    image: { uri: "", file: "", data: "" },
    username: "",
    email: "",
    socialProfile: "",
    gender: "",
    costPerImpression: "",
    dob: "",
    location: "",
    phone: "",
    countryCode: "",
    talentCategories: "",
    talentCategoryId: "",
    status: "disabled",
  };

  const deleteUser = async () => {
    if (deleteId) {
      setModalPrimaryCtaLoading(true);
      const { error } = await API.delete(
        `/portal_api/v1/talents/${deleteId}`,
        {}
      );
      setModalPrimaryCtaLoading(false);

      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ?? "This talent cannot be deleted. Please try again"
          }`
        );
      } else {
        toast.success("Talent Deleted Successfully");
        setDeleteId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const getValue = (item) => item["id"];
  const setFieldValue = (values) => {
    return values.map((item) => {
      return { talentCategoryId: getValue(item) };
    });
  };

  const onAction = (action, data) => {
    if (action === "delete") {
      setDeleteId(data.id);
    } else if (action === "edit") {
      setEditData({
        id: data?.id,
        costPerImpression: data?.costPerImpression
          ? data.costPerImpression / 100
          : "",
        talentCategoryId: setFieldValue(data?.talentCategories),
        ...data?.fanAccount?.attributes,
        talentCategories: data?.talentCategories,
        gender:
          data?.gender === "undisclosed_gender"
            ? "undisclosed gender"
            : data?.gender,
        dob: moment(data?.fanAccount?.attributes?.dob).format("LL"),
        countryCode: data?.fanAccount?.attributes?.countryCode ?? "91",
        phone: (data?.fanAccount?.attributes?.countryCode ?? "91").concat(
          data?.fanAccount?.attributes?.phone
        ),
        status: data?.status,
        quote: data?.fanAccount?.attributes?.quote,
        email: data?.fanAccount?.attributes?.email,
      });
    }
    if (action === "view") {
      setViewData({
        ...data,
        costPerImpression: (data.costPerImpression / 100).toFixed(2),
      });
      setViewModal(true);
    }
  };

  const columns = [
    {
      field: "name",
      render: (rowData) => (
        <Link to={`${rowData.id}/brands`}>
          <Author
            className={"pr-4 2xl:pr-0"}
            name={rowData?.attributes?.fanAccount?.attributes?.name}
            imageSrc={rowData?.attributes?.fanAccount?.attributes?.image}
            cta={`@${rowData?.attributes?.fanAccount?.attributes?.username}`}
            size="md"
          />
        </Link>
      ),
    },
    {
      field: "Category",
      render: (rowData) => (
        <div className="text-left text-sm text-gray-500">
          {rowData?.attributes?.talentCategories?.map((talentCat, idx) => {
            return <div key={idx}>{talentCat?.attributes?.name}</div>;
          }) ?? "-NA-"}
        </div>
      ),
    },
    {
      field: "Agency Contact",
      render: (rowData) => (
        <div className="flex flex-col">
          <div className="text-left whitespace-nowrap text-md font-medium text-gray-500">
            {(_.isEmpty(rowData?.attributes?.talentManagementMember)
              ? rowData?.attributes?.talentManagement?.attributes?.firstName
              : rowData?.attributes?.talentManagementMember?.attributes
                  ?.firstName) +
              " " +
              (_.isEmpty(rowData?.attributes?.talentManagementMember)
                ? rowData?.attributes?.talentManagement?.attributes?.lastName
                : rowData?.attributes?.talentManagementMember?.attributes
                    ?.lastName)}
            <span className="mx-1.5 bg-gray-500 rounded-lg border border-gray-500 px-1.5 py-0.5 text-xs text-gray-100 uppercase">
              {_.isEmpty(rowData?.attributes?.talentManagementMember)
                ? "Admin"
                : rowData?.attributes?.talentManagementMember?.attributes?.role}
            </span>
          </div>
          <div className=" text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
            <a
              href={`mailto:${
                _.isEmpty(rowData?.attributes?.talentManagementMember)
                  ? rowData?.attributes?.talentManagement?.attributes?.email
                  : rowData?.attributes?.talentManagementMember?.attributes
                      ?.email
              }`}
            >
              <MailIcon className="w-4 h-4" />
            </a>

            <span>
              {_.isEmpty(rowData?.attributes?.talentManagementMember)
                ? rowData?.attributes?.talentManagement?.attributes?.email ??
                  "-NA-"
                : rowData?.attributes?.talentManagementMember?.attributes
                    ?.email ?? "-NA-"}
            </span>
          </div>
          <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
            <a
              href={`tel:${
                _.isEmpty(rowData?.attributes?.talentManagementMember)
                  ? rowData?.attributes?.talentManagement?.attributes
                      ?.adminPhone
                  : rowData?.attributes?.talentManagementMember?.attributes
                      ?.phone
              }`}
            >
              <PhoneIcon className="w-4 h-4" />
            </a>
            <span>
              {_.isEmpty(rowData?.attributes?.talentManagementMember)
                ? rowData?.attributes?.talentManagement?.attributes
                    ?.adminPhone ?? "-NA-"
                : rowData?.attributes?.talentManagementMember?.attributes
                    ?.phone ?? "-NA-"}
            </span>
          </div>
        </div>
      ),
    },
    {
      field: "Location",
      render: (rowData) => (
        <div className="text-left text-sm text-gray-500">
          {rowData?.attributes?.fanAccount?.attributes?.location ?? "-NA-"}
        </div>
      ),
    },
    {
      field: "added on",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {moment(`${rowData?.attributes?.createdAt}`).format("ll")}
        </div>
      ),
    },
    {
      field: "actions",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-2 ">
          {CurrentUser.isAdmin() ? (
            <>
              <ViewDataButton
                iconOnly
                onClick={() => {
                  onAction("view", {
                    ...rowData?.attributes,
                    id: rowData.id,
                  });
                }}
              />
              <ActionEditButton
                iconOnly
                onClick={() => {
                  onAction("edit", {
                    ...rowData?.attributes,
                    id: rowData.id,
                  });
                }}
              />
              <ActionDeleteButton
                iconOnly
                onClick={() => {
                  onAction("delete", { id: rowData.id });
                }}
              />
            </>
          ) : (
            <ViewDataButton
              iconOnly
              onClick={() => {
                onAction("view", {
                  ...rowData?.attributes,
                  id: rowData.id,
                });
              }}
            />
          )}
        </div>
      ),
    },
  ];

  const {
    talents: allTalents,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useTalentList(searchParamsQuery, { page: pageNumberQuery, all: true });

  const loadContent = () => {
    if (_.isEmpty(allTalents)) {
      return (
        <LoadContentHelper
          title="Talent"
          ctaOnClick={() => setToggle(true)}
          data={allTalents}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    return <Table column={columns} data={allTalents} />;
  };

  useEffect(() => {
    if (!toggle || !editData || !deleteId) {
      mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData, deleteId]);

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deleteUser,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  const renderViewData = [
    {
      fieldData: (
        <Author
          name={viewData?.fanAccount?.attributes?.name}
          imageSrc={viewData?.fanAccount?.attributes?.image}
          size="lg"
          cta={`@${viewData?.fanAccount?.attributes?.username}`}
          className="cursor-default"
        />
      ),
    },
    {
      field: "Social Profile",
      fieldSpan: "1",
      fieldData: (
        <dd className="mt-1 text-sm  underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
          <a
            href={viewData?.fanAccount?.attributes?.socialProfile}
            target="_blank"
            rel="noreferrer"
          >
            {viewData?.fanAccount?.attributes?.socialProfile}
          </a>
        </dd>
      ),
    },

    {
      field: "Talent Categories",
      fieldSpan: 1,
      fieldData: (
        <div className=" flex flex-row flex-wrap  items-center   ">
          {_.isEmpty(viewData?.talentCategories)
            ? "-NA-"
            : viewData?.talentCategories?.map((talentCat, idx) => {
                return (
                  <dd
                    key={idx}
                    className="mr-1 my-1 bg-gray-100 rounded-lg border border-gray-600 px-1 py-0.5 text-sm  text-gray-900"
                  >
                    {talentCat?.attributes?.name}
                  </dd>
                );
              })}
        </div>
      ),
    },
    {
      field: "Talent Management Agency",
      fieldData: (
        <dd className="mt-1 text-sm text-gray-900">
          {viewData?.talentManagement?.attributes?.name || "-NA-"}
        </dd>
      ),
    },
    {
      field: "Location",
      fieldData: (
        <dd className="mt-1 text-sm text-gray-900">
          {viewData?.fanAccount?.attributes?.location}
        </dd>
      ),
    },
    {
      field: "Phone Number",
      fieldData: viewData?.fanAccount?.attributes?.phone && (
        <div className="mt-1 text-sm text-gray-900 flex flex-row items-center space-x-1">
          <a
            href={`tel:${
              viewData?.fanAccount?.attributes?.countryCode
                ? `+${viewData?.fanAccount?.attributes?.countryCode}-`
                : ""
            }${viewData?.fanAccount?.attributes?.phone}`}
          >
            <PhoneIcon className="w-4 h-4" />
          </a>
          <span>
            {viewData?.fanAccount?.attributes?.countryCode
              ? `+${viewData?.fanAccount?.attributes?.countryCode}-`
              : null}
            {viewData?.fanAccount?.attributes?.phone ?? "-NA-"}
          </span>
        </div>
      ),
    },
    {
      field: "Email",
      fieldData: (
        <dd className="mt-1 text-sm text-gray-900">
          {viewData?.fanAccount?.attributes?.email ?? "-NA-"}
        </dd>
      ),
    },
    {
      field: "Cost Per Impression",
      fieldData: (
        <dd className="mt-1 text-sm text-gray-900 flex flex-row items-center space-x-1">
          <CurrencyRupeeIcon className="w-4 h-4 " />{" "}
          <span>{viewData?.costPerImpression}</span>
        </dd>
      ),
    },
    {
      field: "Gender",
      fieldData: (
        <dd className="mt-1 text-sm text-gray-900 uppercase">
          {viewData?.fanAccount?.attributes?.gender}
        </dd>
      ),
    },
    {
      field: "Date Of Birth",
      fieldData: (
        <dd className="mt-1 text-sm text-gray-900">
          {moment(`${viewData?.fanAccount?.attributes?.dob}`).format("LL")}
        </dd>
      ),
    },
    {
      field: "Status",
      fieldData: (
        <dd className="mt-1 text-sm text-gray-900 uppercase">
          {viewData?.status}
        </dd>
      ),
    },
    {
      field: "Quote",
      fieldSpan: "2",
      fieldData: (
        <dd className="mt-1 text-sm text-gray-900">
          {viewData?.fanAccount?.attributes?.quote}
        </dd>
      ),
    },
  ];

  return (
    <Layout>
      <QuickView
        setOpen={setViewModal}
        open={viewModal}
        title="Talent Detail"
        subtitle=""
        renderData={renderViewData}
      />
      {/* Delete talent modal */}
      <ModalWrapper title="Talent" config={modalConfig} />

      <SlideOver
        open={toggle || !!editData}
        backgroundOverlay="dark"
        size="xl"
        title={`${toggle ? "Add" : "Edit"} Talent`}
        dismissButtonOnClick={() => {
          setToggle(false);
          setEditData(false);
        }}
      >
        <TmaTalentForm
          setToggle={editData ? setEditData : setToggle}
          initialValues={editData ? editData : addInitialValues}
          formButtonCta={`${toggle ? "Create" : "Update"} Talent`}
          editMode={!!editData}
        />
      </SlideOver>

      <div className="sticky top-0 z-50">
        <SectionHeading
          title="Talents"
          primaryCta={CurrentUser.isAdmin() ? "Add Talent" : null}
          primaryOnClick={() => {
            setToggle(true);
          }}
        />
      </div>
      {searchParamsQuery || !_.isEmpty(allTalents) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}

      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Talent List"
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default TmaTalents;
