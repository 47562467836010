import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import FormHeader from "../../../components/FormHeader";
import { MediaAttachments } from "../../../components/media/MediaAttachments";
import { getImageDimension } from "../../../utils/Helper";
import { VideoAttachments } from "../../../components/video/VideoAttachments";

const QuizMediaForm = ({
  initialValues,
  quizId,
  editing,
  quizFormNumber,
  hasReachedForm,
  formNumber,
  setFormNumber,
  published,
}) => {
  const buttonRef = useRef(null);
  const [editMode, setEditMode] = useState(
    editing && hasReachedForm(quizFormNumber.QUIZ_MEDIA_FORM) ? false : true
  );
  const [showCancel, setShowCancel] = useState(
    editing && hasReachedForm(quizFormNumber.QUIZ_MEDIA_FORM) ? true : false
  );
  const [editImageList, setEditImageList] = useState([]);
  const [imageList, setImageList] = useState([
    { category: "video", purpose: "quiz_question", key: "" },
  ]);
  const [responseData, setResponseData] = useState({});
  const [editVideoList, setEditVideoList] = useState([]);
  const [videoList, setVideoList] = useState([
    { category: "video", purpose: "quiz_question", key: "" },
  ]);

  const getAttachmentData = (data) => {
    return data?.map((el) => {
      return {
        category: el?.attributes?.category,
        purpose: el?.attributes?.purpose,
        key: el?.attributes.key,
        id: el?.id,
        thumbnail: el?.attributes?.thumbnail?.key,
      };
    });
  };
  const getObject = (attachments, purpose) => {
    return _.find(attachments, ["attributes.purpose", purpose]);
  };

  const getData = (data, purpose) => {
    return getAttachmentData([getObject(data, purpose)]);
  };

  //image
  const getImageAttachmentData = (data) => {
    return data?.map((el) => {
      return {
        category: el?.attributes?.category,
        purpose: el?.attributes?.purpose,
        id: el?.id,
        key: el?.attributes?.thumbnail?.key,
      };
    });
  };
  const getImageObject = (attachments, purpose) => {
    return _.find(attachments, ["attributes.purpose", purpose]);
  };

  const getImageData = (data, purpose) => {
    return getImageAttachmentData([getImageObject(data, purpose)]);
  };

  const [formValue, setFormValue] = useState({
    videoThumnail: editing
      ? getImageData(initialValues?.attachments, "quiz_question")
      : [],
    questionVideo: editing
      ? getData(initialValues?.attachments, "quiz_question")
      : [],
  });
  useEffect(() => {
    if (!!editing) {
      if (initialValues?.attachments?.length > 0) {
        const videoArray = initialValues?.attachments?.filter(
          (el) => el?.attributes?.purpose === "quiz_question"
        );

        const videoAttachments = videoArray?.map((el) => {
          return { key: el?.attributes.key, id: el?.id };
        });
        const imageArray = initialValues?.attachments?.filter(
          (el) => el?.attributes?.purpose === "quiz_question"
        );
        const imageAttachments = imageArray?.map((el) => {
          return {
            key: el?.attributes?.attachmentVersions?.thumbnail?.key
              ? `https://fanship-uploads.s3.ap-south-1.amazonaws.com/${el?.attributes?.attachmentVersions?.thumbnail?.key}`
              : null,
            id: el?.id,
          };
        });
        setImageList(
          _.isEmpty(imageAttachments) && initialValues?.status === "draft"
            ? imageList
            : imageAttachments
        );

        setVideoList(
          _.isEmpty(videoAttachments) && initialValues?.status === "draft"
            ? videoList
            : videoAttachments
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setImageList, setVideoList]);

  const onImageListHandler = (images) => {
    setImageList(images);
  };
  const onEditImageListHandler = (images) => {
    setEditImageList(images);
  };

  //video handlers
  const onVideoListHandler = (videos) => {
    setVideoList(videos);
  };
  const onEditVideoListHandler = (videos) => {
    setEditVideoList(videos);
  };

  const onEdit = (e) => {
    e.preventDefault();
    setEditMode(true);
  };
  const onCancel = () => {
    setEditMode(false);
    setImageList(formValue.videoThumnail);
    setVideoList(formValue.questionVideo);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const payload = await setPayLoadObj();
    if (_.isEmpty(payload?.data?.attributes?.attachmentsAttributes)) {
      return toast.error(`Fields can not be empty`);
    }
    if (
      _.find(payload?.data?.attributes?.attachmentsAttributes, [
        "purpose",
        "quiz_question",
      ]).thumbnail === undefined
    ) {
      return toast.error(`Video Thumbnail can not be empty`);
    }
    if (
      _.find(payload?.data?.attributes?.attachmentsAttributes, [
        "purpose",
        "quiz_question",
      ]).key === undefined
    ) {
      return toast.error(`Question Video can not be empty`);
    } else {
      const endpoint = `/portal_api/v1/quizzes/${quizId}`;

      const { data, error } = await API.put(endpoint, payload);

      if (error) {
        toast.error(
          `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
        );
        setSubmitting(false);
        buttonRef.current.shake();
      } else {
        setResponseData(data?.data);
        toast.success(`Quiz Media saved successfully`);
        setEditMode(false);
        setShowCancel(true);
        setFormNumber(
          hasReachedForm(quizFormNumber.QUIZ_MEDIA_FORM)
            ? formNumber
            : quizFormNumber.QUIZ_MEDIA_FORM
        );
        setFormValue({
          videoThumnail: getImageData(
            data?.data?.attributes?.attachments,
            "quiz_question"
          ),
          questionVideo: getData(
            data?.data?.attributes?.attachments,
            "quiz_question"
          ),
        });
      }
    }
  };
  const setPayLoadObj = async () => {
    const videoUrlArray = videoList.filter((el) => el?.key);
    const videoAttachments = await Promise.all(
      videoUrlArray.map(async (el) => {
        return {
          ...el,
          purpose: "quiz_question",
          width: 1280,
          height: 720,
        };
      })
    );

    const imageUrlArray = imageList.filter((el) => el?.key);
    const imageAttachments = await Promise.all(
      imageUrlArray.map(async (el) => {
        const dimension = await getImageDimension(el.key);
        return {
          ...el,
          thumbnail: el?.key,
          purpose: "quiz_question",
          thumbnailWidth: dimension.width,
          thumbnailHeight: dimension.height,
        };
      })
    );

    if (editing || !!responseData?.attributes?.attachments) {
      const EditPostObj = {};
      EditPostObj.data = {
        attributes: {
          attachmentsAttributes: [
            {
              category: "video",
              purpose: "quiz_question",
              key: videoAttachments[0]?.key,
              width: videoAttachments[0]?.width,
              height: videoAttachments[0]?.height,
              id:
                videoAttachments[0]?.id ||
                imageAttachments[0]?.id ||
                imageList[0]?.id,
              thumbnail: imageAttachments[0]?.thumbnail, //key of thumbnail
              thumbnailWidth: imageAttachments[0]?.thumbnailWidth,
              thumbnailHeight: imageAttachments[0]?.thumbnailHeight,
            },
          ],
        },
      };
      return EditPostObj;
    }

    const postObj = {};
    postObj.data = {
      attributes: {
        attachmentsAttributes: [
          {
            category: "video",
            purpose: "quiz_question",
            key: videoAttachments[0]?.key,
            width: videoAttachments[0]?.width,
            height: videoAttachments[0]?.height,
            thumbnail: imageAttachments[0]?.thumbnail,
            thumbnailWidth: imageAttachments[0]?.thumbnailWidth,
            thumbnailHeight: imageAttachments[0]?.thumbnailHeight,
          },
        ],
      },
    };
    return postObj;
  };

  return (
    <div className="rounded-md bg-white px-2 pb-6 border-2 border-gray-300 ">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="flex flex-col justify-between">
            <FormHeader
              sectionHeading="Quiz Media"
              cancelOnClick={onCancel}
              editOnClick={values.status === "draft" ? onEdit : false}
              editMode={values.status === "draft" ? editMode : false}
              isSubmitting={isSubmitting}
              buttonRef={buttonRef}
              showCancel={showCancel}
              published={published}
              notAllowed={values?.status !== "draft" ? true : false}
            />
            <div className="px-4 flex">
              {/* Quiz Question Video thumbnail */}
              <MediaAttachments
                {...{
                  imageList,
                  onImageListHandler,
                  editImageList,
                  onEditImageListHandler,
                  editing,
                }}
                attachmentType="single"
                mediaStatus={editMode ? "draft" : "notEditable"}
                label="Video Thumbnail"
                showCancelButton={false}
              />
              {/* Quiz Question Video */}
              <VideoAttachments
                {...{
                  videoList,
                  onVideoListHandler,
                  onEditVideoListHandler,
                  editVideoList,
                  editing,
                }}
                attachmentType="single"
                mediaStatus={editMode ? "draft" : "notEditable"}
                label="Question Video"
                showCancelButton={false}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuizMediaForm;
