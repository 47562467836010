import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import { ExclamationIcon } from "@heroicons/react/outline";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import SlideOver from "../../../stories/SlideOver";
import Table from "../../../components/Table";
import { useFaqList } from "../../../lib/services/FaqService";
import FaqForm from "../../../forms/pageForms/Configurations/FaqForm";
import moment from "moment";
import {
  ActionDeleteButton,
  ActionEditButton,
} from "../../../table-components/Buttons";

import SearchBar from "../../../components/SearchBar";
import _ from "lodash";

import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";

import { ModalWrapper } from "../../../utils/Modal";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import TableLayout from "../../../components/TableLayout";
import { navigateSelf } from "../../../utils/Helper";

import { CurrentUser } from "../../../lib/network/auth/Auth";

const Faq = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const [deleteId, setDeleteId] = useState(false);

  //setting form headings and button in slideover
  const [heading, setHeading] = useState("");

  // for setting loader for modal
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const addInitialValues = {
    question: "",
    answer: "",
    status: "disabled",
  };

  const deleteFaq = async () => {
    setModalPrimaryCtaLoading(true);
    if (deleteId) {
      // eslint-disable-next-line no-unused-vars
      const { data, error } = await API.delete(
        `/portal_api/v1/faqs/${deleteId}`,
        {}
      );
      if (error) {
        toast.error(
          `Error ${
            error.status ?? null
          }: Something went Wrong. Please try again`
        );
        setModalPrimaryCtaLoading(false);
      } else {
        toast.success("FAQ Deleted Successfully");
        setModalPrimaryCtaLoading(false);
        setDeleteId(false);
      }
    } else {
      setModalPrimaryCtaLoading(false);
      toast.error("Something went Wrong. Please try again");
    }
  };

  const {
    faq,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useFaqList(searchParamsQuery, { page: pageNumberQuery });

  const total_pages = Number(paginationInfo?.totalPages);

  const onAction = (action, data) => {
    if (action === "delete") {
      setDeleteId(data.id);
    } else if (action === "edit") {
      setHeading(false);
      setEditData(data);
    }
  };

  const columns = [
    {
      field: "Question",
      render: (rowData) => (
        <div className="text-left break-normal text-sm font-medium text-gray-900 flex flex-row  items-center ">
          {rowData?.attributes?.question}
        </div>
      ),
    },
    {
      field: "Answer",
      render: (rowData) => (
        <div className="text-left break-normal text-sm text-gray-500">
          {rowData?.attributes?.answer}
        </div>
      ),
    },
    {
      field: "status",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 uppercase">
          {rowData?.attributes?.status}
        </div>
      ),
    },
    {
      field: "added on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {moment(`${rowData?.attributes?.createdAt}`).format("ll")}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionEditButton
            iconOnly
            onClick={() => {
              onAction("edit", {
                ...rowData?.attributes,
                id: rowData.id,
              });
            }}
          />
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              onAction("delete", { id: rowData.id });
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(faq)) {
      return (
        <LoadContentHelper
          title="FAQ"
          ctaOnClick={() => setToggle(true)}
          data={faq}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    if (CurrentUser.isFanshipAdmin()) {
      return <Table column={columns} data={faq} />;
    } else {
      return <Table column={_.initial(columns)} data={faq} />;
    }
  };

  useEffect(() => {
    if (!toggle || !editData || !deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData, deleteId]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  //Modal Value JSON
  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deleteFaq,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      {/* Modal funtion */}
      <ModalWrapper title="FAQ" config={modalConfig} />

      <SlideOver
        open={toggle || !!editData}
        backgroundOverlay="dark"
        size="xl"
        title={`${heading ? "Add" : "Edit"} FAQ`}
        dismissButtonOnClick={() => {
          setToggle(false);
          setEditData(false);
        }}
      >
        {/* Add FAQ  */}
        <FaqForm
          setToggle={editData ? setEditData : setToggle}
          initialValues={editData ? editData : addInitialValues}
          formButtonCta={`${heading ? "Create" : "Update"} FAQ`}
          editMode={!!editData}
        />
      </SlideOver>
      <div className="sticky top-0 z-50">
        <SectionHeading
          title="Frequently Asked Questions"
          primaryCta={CurrentUser.isFanshipAdmin() ? "Add FAQ" : null}
          primaryOnClick={() => {
            setHeading(true);
            setToggle(true);
          }}
        />
      </div>
      {/* SearchBar */}
      {searchParamsQuery || !_.isEmpty(faq) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}

      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"FAQ List"}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default Faq;
