import React from "react";
import "react-phone-input-2/lib/style.css";

import { ErrorMessage, useField, useFormikContext } from "formik";
import classNames from "classnames";
import ReactPhoneInput from "react-phone-input-2";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

const PhoneInput = ({ countryCode, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(props);
  return (
    <div className="mt-1 relative">
      <ReactPhoneInput
        inputClass={classNames(
          "border-gray-300 focus:ring-2 focus:ring-gray-900 sm:text-sm  w-full focus:border-gray-900 ",
          meta.touched &&
            meta.error &&
            "border w-full focus:ring-2 border-red-600 focus:ring-red-600 focus:border-red-600 rounded-md "
        )}
        containerClass={classNames(
          "phone-input-custom w-full",
          " border border-gray-300 focus:ring-2 shadow-sm w-full  block sm:text-sm  rounded-md ",
          meta.touched &&
            meta.error &&
            "border w-full border-red-600 focus:ring-2 focus:ring-red-600 focus:border-red-600 rounded-md "
        )}
        inputStyle={{
          width: "100%",
          borderStyle: "none",
          fontFamily: "Barlow",
        }}
        name={field.name}
        id={field.id}
        {...field}
        {...props}
        country={"in"}
        enableSearch={true}
        disableSearchIcon={true}
        onChange={(value, data) => {
          setFieldValue("countryCode", data.dialCode);
          setFieldValue(field.name, value);
        }}
        onBlur={() => setFieldTouched(field.name, true)}
      />
      {meta.touched && meta.error && (
        <ExclamationCircleIcon
          className=" absolute h-5 w-5 text-red-500 right-2 top-2"
          aria-hidden="true"
        />
      )}

      <ErrorMessage
        name={props.name}
        component="span"
        className="text-red-700 text-sm absolute"
      ></ErrorMessage>
    </div>
  );
};

export default PhoneInput;
