import { API } from "../network/API";
import { toast } from "../../components/Toast";

const AwsImageService = async (values) => {
  //signed url
  const fileExt = values?.image?.data.split(";")[0].split("/")[1];
  const fileName = `${Date.now() + (fileExt ? "." + fileExt : ".png")}`;
  const { data: urlData, error: urlError } = await API.post(
    "/portal_api/v1/signed_url",
    {
      key: `${fileName}`,
    }
  );
  if (urlError) {
    toast.error(urlError.message);
  } else {
  }
  // uploading to s3
  let binary = atob(values.image.data.split(",")[1]);
  let array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  let blobData = new Blob([new Uint8Array(array)], { type: "image/*" });
  // eslint-disable-next-line
  const resp_aws = await fetch(`${urlData.data.url}`, {
    method: "PUT",
    body: blobData,
  }).catch((err) => {
    toast.error("Image Upload fail.Try Again...");
    return null;
  });
  return urlData.data.url.split("?")[0];
};

export default AwsImageService;
