import React from "react";
import {
  ActionCancelButton,
  ActionEditButton,
  ActionSaveButton,
} from "../table-components/Buttons";
import SearchBar from "../components/SearchBar";

const FormHeader = ({
  sectionHeading,
  editMode,
  editOnClick,
  cancelOnClick,
  isSubmitting,
  searchBar,
  searchParamsQuery,
  loading,
  buttonRef,
  showCancel,
  setSearchParamsQuery,
  setPageNumberQuery,
  published,
  notAllowed = false,
}) => {
  return (
    <div className=" flex justify-between mx-2 my-5 px-2">
      <div className=" text-lg leading-6 font-medium text-gray-900 flex items-center">
        {sectionHeading}
      </div>
      {!published ? (
        <div className="flex ">
          {!searchBar ? (
            editMode ? (
              <>
                {showCancel ? (
                  <ActionCancelButton
                    className="mr-3"
                    onClick={cancelOnClick}
                  />
                ) : (
                  ""
                )}

                <ActionSaveButton
                  buttonRef={buttonRef}
                  type="submit"
                  loading={isSubmitting}
                />
              </>
            ) : (
              <ActionEditButton
                onClick={editOnClick}
                buttonStyle={notAllowed ? "disabled" : "primary"}
                disabled={notAllowed ? true : false}
              />
            )
          ) : (
            <div className="-my-3 flex -mr-4">
              <SearchBar
                searchTerm={searchParamsQuery}
                size="md"
                align="right"
                onSearch={(val) => {
                  setSearchParamsQuery(val);
                  setPageNumberQuery(1);
                }}
                loading={loading}
                contained={true}
              />
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FormHeader;
