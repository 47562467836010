import React, { useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import AwsImageService from "../../lib/services/AwsImageService";
import Button from "../../stories/Button";
import { profileValidationSchema } from "../validation";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import FormFields from "../FormFields";

const ProfileForm = ({
  setToggle,
  initialValues,
  editMode,
  formButtonCta,
  setEditData,
}) => {
  const buttonRef = useRef(null);

  const formValues = [
    {
      label: "Profile Image",
      attributeId: "image",
      type: "ImageInput",
    },
    {
      label: "First Name *",
      attributeId: "firstName",
      placeholder: "Rahul",
      type: "TextInput",
    },
    {
      label: "Last Name *",
      attributeId: "lastName",
      placeholder: "Gupta",
      type: "TextInput",
    },
    {
      label: "Email *",
      attributeId: "email",
      placeholder: "xyz@example.com",
      type: "TextInput",
    },
    {
      label: "Phone Number *",
      attributeId: "phone",
      placeholder: "+91 98568-74589",
      type: "PhoneInput",
      countryCode: "countryCode",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    // uploading data to backend db

    const fetchedValues = {
      ..._.pick(values, ["firstName", "lastName", "email", "countryCode"]),
      phone: values.phone.slice(values.countryCode.length),
      image: values?.image?.data
        ? await AwsImageService(values)
        : initialValues.image,
    };

    const apiParams = {
      data: { attributes: fetchedValues },
    };

    const endpoint = "/portal_api/v1/profile";

    const { error } = await API.put(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(`Profile updated successfully`);
      setToggle(false);
      setEditData(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={profileValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="flex flex-col h-screen">
          <div className="sticky pl-2 pr-4 -mr-5 pb-10 overflow-y-auto h-3/4 ">
            <FormFields
              setFieldValue={setFieldValue}
              values={values}
              formValues={formValues}
            />
          </div>
          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="mt-4 w-full sticky bottom-2 z-50"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
