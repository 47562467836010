import React from "react";
import _ from "lodash";
import InfoContainer from "./InfoContainer";
import CampaignInfoContainer from "./CampaignInfoContainer";
import TalentInfoHelper from "./TalentInfoHelper";
import Author from "../stories/Author";

const CampaignDetails = ({ blockFields, campaignFields, campaign }) => {
  const getTalentInfo = (talentId) => {
    return _.find(campaign?.attributes?.talents || [], ["id", talentId]);
  };

  //conditionally hiding fields which are redundant for Scheduled Campaigns
  const blockFieldArr =
    _.find(campaignFields, { label: "Status" })?.value === "scheduled" ||
    _.find(campaignFields, { label: "Status" })?.value === "pending" ||
    _.find(campaignFields, { label: "Status" })?.value === "rejected"
      ? [_.first(blockFields)]
      : blockFields;
  const campaignFieldArr =
    _.find(campaignFields, { label: "Status" })?.value === "scheduled" ||
    _.find(campaignFields, { label: "Status" })?.value === "pending" ||
    _.find(campaignFields, { label: "Status" })?.value === "rejected"
      ? _.reject(
          campaignFields,
          (item) =>
            item.label === "Average Replay" ||
            item.label === "Replay Rate" ||
            item.label === "Used Budget"
        )
      : campaignFields;
  return (
    <>
      <div className="px-3 flex space-x-6 my-6">
        {blockFieldArr.map((item, idx) => {
          return (
            <CampaignInfoContainer
              title={item.label}
              value={item.value}
              Icon={item.Icon}
              image={item.image}
              key={idx}
            />
          );
        })}
      </div>
      <div className="px-3 w-full flex flex-wrap">
        {campaignFieldArr.map((item, idx) => {
          return (
            <div className="my-2 w-1/4 pr-4" key={idx}>
              <InfoContainer
                label={item?.label}
                value={item?.value}
                isArray={item?.isArray}
                displayKey={item.displayKey}
                price={item.price}
                talentCPI={item.talentCPI}
              />
            </div>
          );
        })}
      </div>
      <div>
        <h3 className="px-3 text-lg leading-6 font-medium text-gray-900">
          Post Preview
        </h3>
        <div className="w-full flex flex-wrap mt-2">
          {!_.isEmpty(campaign?.attributes?.campaignsPosts) &&
            campaign?.attributes?.campaignsPosts?.map((post, idx) => {
              const talent = getTalentInfo(
                post?.attributes?.post?.attributes?.talent?.id
              );
              return (
                <div className="w-3/5 md:w-80 xl:w-96 px-3 py-3">
                  <div className=" border-2 rounded-md px-4 pt-4">
                    <div className="flex justify-between items-start">
                      <Author
                        name={talent?.attributes?.name}
                        cta={`@${talent?.attributes?.username}`}
                        imageSrc={
                          talent?.attributes?.image?.thumbnail?.url ??
                          talent?.attributes?.image
                        }
                        className="cursor-default"
                      />
                      {post?.attributes?.status && (
                        <div className="inline-flex items-center justify-end px-1 py-0.5 my-2 text-xs font-medium  bg-gray-500 rounded-lg border border-gray-500 text-gray-100 capitalize">
                          {post?.attributes?.status}
                        </div>
                      )}
                    </div>
                    <div className="my-3 ">
                      {post?.attributes?.post?.attributes?.containsVideo ? (
                        <video
                          poster={
                            post?.attributes?.post?.attributes?.previewImage
                              ?.post?.url
                          }
                          className=" object-contain bg-gradient-to-tl from-gray-200 to-gray-600 h-80 w-full mt-2 "
                          controls
                        >
                          <source
                            src={
                              _.find(
                                post?.attributes?.post?.attributes?.attachments,
                                ["attributes.category", "video"]
                              )?.attributes?.key
                            }
                          />
                        </video>
                      ) : (
                        <img
                          className="mt-2 h-80 w-full"
                          src={
                            post?.attributes?.post?.attributes?.previewImage
                              ?.post?.url
                          }
                          alt="Post"
                        />
                      )}

                      <img
                        className="mt-1 h-6 w-full"
                        src={campaign?.attributes?.adBanner}
                        alt="Ad Banner"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="block text-base font-medium text-gray-500 mt-4">
                      Post Budget :
                      <span className="bold text-base font-medium text-gray-700 capitalize">
                        {" "}
                        ₹ {(post?.attributes?.budget / 100).toFixed(2) ?? "-"}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="block text-base font-medium text-gray-500 mt-4">
                      Post Metrics
                    </div>
                    <div className="h-42 border-2 rounded-md px-4 my-4 pt-4">
                      <TalentInfoHelper talent={talent} />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default CampaignDetails;
