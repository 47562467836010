import React, { useState, useEffect, useRef } from "react";
import { ErrorMessage, useField, useFormikContext } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const TextEditorInput = ({ size, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ ...props });

  const [text, setText] = useState(field?.value);
  return (
    <div>
      <div className="mt-1 relative prose prose-indigo prose-sm  2xl:prose-md max-w-none text-justify ">
        <CKEditor
          editor={ClassicEditor}
          data={text}
          onChange={(event, editor) => {
            const data = editor.getData();
            setText(data);
            setFieldValue(props.name, data);
          }}
          onBlur={() => {
            setFieldTouched(props.name);
          }}
          config={{
            toolbar: {
              items: [
                "heading",
                "|",
                "fontfamily",
                "fontsize",
                "|",
                "alignment",
                "fontColor",
                "fontBackgroundColor",
                "|",
                "bold",
                "italic",
                "strikethrough",
                "underline",
                "subscript",
                "superscript",
                "|",
                "link",
                "|",
                "bulletedList",
                "numberedList",
                "todoList",
                "|",
                "code",
                "codeBlock",
                "|",
                "insertTable",
                "|",
                "blockQuote",
                "|",
                "undo",
                "redo",
              ],
              shouldNotGroupWhenFull: true,
            },
          }}
        />
      </div>
      <ErrorMessage
        name={props.name}
        component="span"
        className="text-red-700 text-sm absolute"
      ></ErrorMessage>
    </div>
  );
};

export default TextEditorInput;
