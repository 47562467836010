import React from "react";
import classNames from "classnames";
import { Link } from "@reach/router";
import queryString from "query-string";
import { navigate, useLocation } from "@reach/router";
import { paginate } from "../utils/Paginate";
const TableLayout = ({
  title,
  children,
  loading,
  paginationInfo,
  searchTerm,
  setPageNumberQuery,
  setSearchParamsQuery,
  forModal = false,
  hidePaginationInfo = false,
}) => {
  const current_page = Number(paginationInfo?.page);
  const total_pages = Number(paginationInfo?.totalPages);
  const per_page = Number(paginationInfo?.perPage);
  const paginationArray = paginate(current_page, total_pages);
  //conditionally show pagination buttons
  const buttonRenderBool =
    paginationArray[0] !== paginationArray[1] ? true : false;

  const location = useLocation();

  //New query Link using queryString
  const parsed = queryString.parse(location.search);

  const paginationLinkHandler = (pageNo, query) => {
    if (pageNo === "...") {
      return window.location.search;
    }
    return queryString.stringifyUrl(
      {
        url: "",
        query: {
          ...parsed,
          pageNo,
          query,
        },
      },
      { skipNull: true, skipEmptyString: true }
    );
  };

  const modalPaginationHandler = (pageNo, query) => {
    if (pageNo === "...") {
      return window.location.search;
    }
    setPageNumberQuery(pageNo);
    if (query) {
      setSearchParamsQuery(query);
    }
  };

  const tableHeading = (title) => {
    return (
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
          </div>
        </div>
      </div>
    );
  };

  const paginationDetails = (current_page, total_pages, per_page) => {
    return (
      <p
        className={classNames(
          "text-sm text-gray-700",
          hidePaginationInfo ? "hidden" : "block"
        )}
      >
        Showing <span className="font-medium">{current_page}</span> of{" "}
        <span className="font-medium">{total_pages}</span> page(s) {" ( "}
        <span className="font-medium">{per_page}</span> results per page {")"}
      </p>
    );
  };

  const styles = (buttonStyle) => {
    switch (buttonStyle) {
      case "Primary":
        return "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium  text-gray-700 bg-white hover:bg-gray-50 focus:outline-none";
      default:
        return "";
    }
  };

  const disabledButtonStyle = (pageVal1, pageVal2) => {
    return pageVal1 === pageVal2
      ? "cursor-not-allowed hover:bg-gray-300 disabled:opacity-50"
      : "";
  };

  return (
    <div
      className={classNames(
        "flex flex-col rounded-lg overflow-hidden p-6",
        hidePaginationInfo ? "pt-1 px-3 pb-0" : "p-6"
      )}
    >
      <div className="flex flex-col shadow rounded-lg overflow-hidden  ">
        {title ? tableHeading(title) : null}

        <div className=" -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200">
              {children}

              {paginationInfo ? (
                <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  {!loading ? (
                    <>
                      <div className="hidden sm:block">
                        {!!total_pages &&
                          paginationDetails(
                            current_page,
                            total_pages,
                            per_page
                          )}
                      </div>
                      <div className="flex-1 flex justify-between sm:justify-end">
                        {buttonRenderBool ? (
                          <div
                            onClick={() =>
                              !forModal
                                ? navigate(
                                    paginationLinkHandler(
                                      current_page > 1 ? current_page - 1 : 1,
                                      searchTerm
                                    )
                                  )
                                : modalPaginationHandler(
                                    current_page > 1 ? current_page - 1 : 1,
                                    searchTerm
                                  )
                            }
                            className={classNames(
                              " rounded-l-md cursor-pointer",
                              styles("Primary"),
                              disabledButtonStyle(current_page, 1)
                            )}
                          >
                            Previous
                          </div>
                        ) : null}
                        {/* pagination page numbers start */}
                        {buttonRenderBool
                          ? paginationArray?.map((pageNo, pageIdx) => (
                              <div
                                onClick={() =>
                                  !forModal
                                    ? navigate(
                                        paginationLinkHandler(
                                          pageNo,
                                          searchTerm
                                        )
                                      )
                                    : modalPaginationHandler(pageNo, searchTerm)
                                }
                                key={`${pageIdx}-${pageNo}`}
                                className={classNames(
                                  "cursor-pointer",
                                  styles("Primary"),
                                  disabledButtonStyle(pageNo, current_page),
                                  disabledButtonStyle(pageNo, "..."),
                                  pageNo === current_page
                                    ? "border-gray-800"
                                    : "",
                                  hidePaginationInfo ? "hidden" : "block"
                                )}
                              >
                                {pageNo}
                              </div>
                            ))
                          : null}
                        {/* pagination page numbers end */}
                        {buttonRenderBool ? (
                          <div
                            onClick={() =>
                              !forModal
                                ? navigate(
                                    paginationLinkHandler(
                                      current_page < total_pages
                                        ? current_page + 1
                                        : total_pages,
                                      searchTerm
                                    )
                                  )
                                : modalPaginationHandler(
                                    current_page < total_pages
                                      ? current_page + 1
                                      : total_pages,
                                    searchTerm
                                  )
                            }
                            className={classNames(
                              " rounded-r-md cursor-pointer",
                              styles("Primary"),
                              disabledButtonStyle(current_page, total_pages)
                            )}
                          >
                            Next
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </nav>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TableLayout;
