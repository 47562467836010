import { useAPI } from "../../network/SWR";
import { queryStringParseFuntion } from "../../../utils/Helper";

export const useTmaCampaignsList = (
  pageParam = "1",
  searchTerm,
  filterType = "all",
  userType = null
) => {
  const queryObject = {
    page: pageParam,
    query: searchTerm,
    per_page: 20,
    filterType: filterType,
  };
  const queryValues = queryStringParseFuntion(queryObject);
  const key =
    userType === "talent"
      ? `/portal_api/v1/talents/campaigns_posts${queryValues}`
      : `/portal_api/v1/talent_managements/campaigns${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    campaigns: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
