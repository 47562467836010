import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useLocation, useParams } from "@reach/router";
import queryString from "query-string";
import Table from "../../../../components/Table";
import moment from "moment";
import Author from "../../../../stories/Author";
import { ActionUnBlockButton } from "../../../../table-components/Buttons";
import SearchBar from "../../../../components/SearchBar";
import _ from "lodash";
import { API } from "../../../../lib/network/API";
import { toast } from "../../../../components/Toast";
import TableLayout from "../../../../components/TableLayout";
import { ModalWrapper } from "../../../../utils/Modal";
import { LoadContentHelper } from "../../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../../utils/Helper";
import Layout from "../../../../components/sideBar/Layout";
import {
  useTalent,
  useTalentBlockedUsers,
} from "../../../../lib/services/TalentService";
import AllTalentHeader from "./AllTalentHeader";
import { CurrentUser } from "../../../../lib/network/auth/Auth";

const BlockedAccounts = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);
  const [blockedId, setBlockedId] = useState(false);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);
  const { talentId } = useParams();
  const { talent } = useTalent(talentId);

  const urlLastSegment = location.pathname.split("/").pop();

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const unblockUser = async () => {
    if (blockedId) {
      setModalPrimaryCtaLoading(true);
      const apiParams = {
        data: {
          attributes: {
            blockedId: blockedId,
            blockedById: talentId,
          },
        },
      };

      const endpoint = "/portal_api/v1/blocked_users/unblock";
      const { error } = await API.post(endpoint, apiParams);

      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${error.status ?? null}: ${
            error.message
              ? error.message
              : "Something went Wrong. Please try again"
          } `
        );
      } else {
        toast.success("Account Unblocked Successfully");
        setBlockedId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const {
    blockedUser,
    loading,
    error,
    mutate,
    isValidating,
    paginationInfo,
  } = useTalentBlockedUsers(searchParamsQuery, {
    page: pageNumberQuery,
    talentId,
  });

  const columns = [
    {
      field: "name",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm flex flex-row  items-center mr-4">
          <Author
            name={rowData?.attributes?.blocked?.attributes?.name}
            imageSrc={rowData?.attributes?.blocked?.attributes?.image}
            cta={`@${rowData?.attributes?.blocked?.attributes?.username}`}
            size="lg"
          />
        </div>
      ),
    },
    {
      field: "blocked on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {moment(`${rowData?.attributes?.createdAt}`).format("LL")}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionUnBlockButton
            iconOnly
            onClick={() => {
              setBlockedId(rowData?.attributes?.blocked?.id);
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(blockedUser)) {
      return (
        <LoadContentHelper
          title="Blocked Account"
          data={blockedUser}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    if (CurrentUser.isFanshipAdmin()) {
      return <Table column={columns} data={blockedUser} />;
    } else {
      return <Table column={_.initial(columns)} data={blockedUser} />;
    }
  };

  useEffect(() => {
    if (!blockedId) {
      mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockedId]);

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: unblockUser,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setBlockedId,
    },
    open: { value: blockedId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper
        fullTitle="Unblock Account"
        desc="Are you sure you want to unblock this account?"
        config={modalConfig}
      />

      <AllTalentHeader
        talentName={
          talent?.attributes?.fanAccount?.attributes?.name || "Blocked Accounts"
        }
        talentId={talentId}
        active={urlLastSegment}
      />
      {searchParamsQuery || !_.isEmpty(blockedUser) ? (
        <div className="flex justify-between items-center">
          <SearchBar
            searchTerm={searchParamsQuery}
            size="xl"
            align="center"
            onSearch={(val) => {
              navigateSelf(1, val, parsed);
            }}
            loading={loading}
          />
        </div>
      ) : null}

      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Blocked Accounts"}
          loading={loading}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default BlockedAccounts;
