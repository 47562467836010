import React from "react";
import { useField } from "formik";

const CheckBox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        className="h-4 w-4 text-gray-900 focus:ring-gray-900 border-gray-300 rounded"
        {...field}
        {...props}
      />

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CheckBox;
