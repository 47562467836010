import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage, useField, useFormikContext } from "formik";
import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

const Datepicker = ({ name, placeholder, includeTime = false, ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name, ...props });

  return (
    <div>
      <div className="mt-1 relative">
        <DatePicker
          placeholderText={placeholder}
          className={classNames(
            " shadow-sm focus:ring-gray-900 focus:border-gray-900 block  sm:text-sm border-gray-300 rounded-md w-full z-50",
            meta.touched &&
              meta.error &&
              "border border-red-600 focus:ring-red-600 focus:border-red-600"
          )}
          dateFormat={includeTime ? "MM/dd/yyyy h:mm aa" : "MMMM d, yyyy"}
          showTimeInput={includeTime ? true : false}
          timeInputLabel={includeTime ? "Time:" : null}
          name={name}
          id={name}
          {...field}
          {...props}
          onFocus={(e) => e.target.blur()}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
          autoComplete="off"
          onChangeRaw={(e) => {
            setFieldTouched(field.name, true, true);
          }}
        />
        {meta.touched && meta.error && (
          <ExclamationCircleIcon
            className=" absolute h-5 w-5 text-red-500 right-2 top-2"
            aria-hidden="true"
          />
        )}
      </div>

      <ErrorMessage
        name={name}
        component="span"
        className="text-red-700 text-sm absolute"
      ></ErrorMessage>
    </div>
  );
};

export default Datepicker;
