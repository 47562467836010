import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import Loader from "./Loader";
import _ from "lodash";
import classNames from "classnames";

const QuickView = ({ title, subtitle, open, setOpen, renderData }) => {
  const loadContent = () => {
    if (_.isEmpty(renderData)) {
      return (
        <div className="flex justify-center items-center h-60 ">
          <Loader title={`Fetching data ...`} />
        </div>
      );
    }
    return (
      <dl className="grid grid-cols-1 gap-x-12 space-y-3 sm:grid-cols-2">
        {renderData?.map((data, idx) => {
          return (
            <div
              key={idx}
              className={classNames(
                data?.fieldSpan === "2" ? "sm:col-span-2" : "sm:col-span-1"
              )}
            >
              <dt className="text-sm font-medium text-gray-500">
                {data?.field}
              </dt>
              {data?.fieldData}
            </div>
          );
        })}
      </dl>
    );
  };
  return (
    <Transition.Root show={!!open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center h-screen  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:h-4/6 2xl:h-3/5 sm:w-full sm:p-6 sm:pb-2">
              <div className="hidden z-30 sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none "
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {/* ---- */}

              <div className="bg-white h-full overflow-hidden sm:rounded-lg">
                <div className="px-4 pb-5 pt-3 sm:px-6 sticky top-0 bg-white border-b ">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {title || "View Details"}
                  </h3>

                  {subtitle && (
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      {subtitle || "Details"}
                    </p>
                  )}
                </div>
                <div className="h-5/6 overflow-y-auto ">
                  <div className=" border-gray-200 px-4 py-2.5 sm:px-6  ">
                    {loadContent()}
                  </div>
                </div>
              </div>
              {/* --- */}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default QuickView;
