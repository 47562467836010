import { useAPI } from "../../network/SWR";
import { queryStringParseFuntion } from "../../../utils/Helper";

export const useManagerList = (searchTerm, { defaultValue, page = "1" }) => {
  const queryObject = {
    page,
    query: searchTerm,
    per_page: 20,
    filterByRole: "manager",
  };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/talent_managements/members${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    agency: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
