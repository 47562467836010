import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { MailIcon } from "@heroicons/react/solid";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import _ from "lodash";
import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import SlideOver from "../../stories/SlideOver";
import { useAdvertiserList } from "../../lib/services/AdvertiserService";
import Table from "../../components/Table";
import AdvertiserForm from "../../forms/pageForms/AdvertiserForm";
import moment from "moment";
import {
  ActionDeleteButton,
  ActionEditButton,
} from "../../table-components/Buttons";

import SearchBar from "../../components/SearchBar";
import { ModalWrapper } from "../../utils/Modal";

import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";

import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import TableLayout from "../../components/TableLayout";
import { navigateSelf } from "../../utils/Helper";
import { CurrentUser } from "../../lib/network/auth/Auth";

const Advertisers = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const [deleteId, setDeleteId] = useState(false);

  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const addInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    brandId: "",
    status: "disabled",
    role: "admin",
    brands: "",
    advertiserType: "",
  };

  const deleteAdvertiser = async () => {
    setModalPrimaryCtaLoading(true);
    if (deleteId) {
      // eslint-disable-next-line no-unused-vars
      const { data, error } = await API.delete(
        `portal_api/v1/advertisers/${deleteId}`,
        {}
      );
      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ??
            "This advertiser cannot be deleted. Please try again"
          }`
        );
        setModalPrimaryCtaLoading(false);
      } else {
        toast.success("Advertiser Deleted Successfully");
        setModalPrimaryCtaLoading(false);
        setDeleteId(false);
      }
    } else {
      setModalPrimaryCtaLoading(false);
      toast.error("Something went Wrong. Please try again");
    }
  };

  const {
    advertiser,
    loading,
    error,
    mutate,
    isValidating,
    paginationInfo,
  } = useAdvertiserList(pageNumberQuery, searchParamsQuery);

  const total_pages = Number(paginationInfo?.totalPages);
  const getValue = (item) => item["id"];
  const setFieldValue = (values) => {
    const data = values.map((item) => {
      return { brandId: getValue(item) };
    });
    return data;
  };
  const onAction = (action, data) => {
    if (action === "delete") {
      setDeleteId(data.id);
    } else if (action === "edit") {
      setEditData({
        ...data,
        brandId: _.isEmpty(data?.brands) ? [] : setFieldValue(data?.brands),
        brands: data?.brands,
        advertiserType:
          data?.advertiserType === "brand_owner"
            ? "brand owner"
            : data?.advertiserType,
      });
    }
  };

  const columns = [
    {
      field: "name",
      render: (rowData) => (
        <>
          <div className="text-left whitespace-nowrap text-base capitalize font-medium text-gray-900 flex flex-row  items-center ">
            {_.chain([
              rowData?.attributes?.firstName,
              rowData?.attributes?.lastName,
            ])
              .compact()
              .join(" ")
              .value()}

            <div className="mx-1.5 px-0.5 py-0.5 bg-gray-500 rounded-lg border border-gray-500 text-xs  text-gray-100 capitalize ">
              {_.isEmpty(rowData?.attributes?.advertiserType)
                ? "agency"
                : rowData?.attributes?.advertiserType === "brand_owner"
                ? "brand owner"
                : rowData?.attributes?.advertiserType}
            </div>
          </div>
          {rowData?.attributes?.email && (
            <div className=" text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              <a href={`mailto:${rowData?.attributes?.email}`}>
                {" "}
                <MailIcon className="w-4 h-4" />
              </a>
              <span>{rowData?.attributes?.email ?? null}</span>
            </div>
          )}
        </>
      ),
    },
    {
      field: "brand",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm  text-gray-500 capitalize">
          {!_.isEmpty(rowData?.attributes?.brands)
            ? rowData?.attributes?.brands?.map((item, idx) => {
                return <div key={idx}>{item?.attributes?.name}</div>;
              })
            : []}
        </div>
      ),
    },

    {
      field: "status",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 uppercase">
          {rowData?.attributes?.status}
        </div>
      ),
    },

    {
      field: "added on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.createdAt
            ? moment(rowData?.attributes?.createdAt).format("ll")
            : null}
        </div>
      ),
    },
    {
      field: "actions",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionEditButton
            iconOnly
            onClick={() => {
              onAction("edit", {
                ...rowData?.attributes,
                id: rowData.id,
              });
            }}
          />
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              onAction("delete", { id: rowData.id });
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(advertiser)) {
      return (
        <LoadContentHelper
          title="Advertiser"
          ctaOnClick={() => setToggle(true)}
          data={advertiser}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }

    if (CurrentUser.isFanshipAdmin()) {
      return <Table column={columns} data={advertiser} />;
    } else {
      return <Table column={_.initial(columns)} data={advertiser} />;
    }
  };

  useEffect(() => {
    if (!toggle || !editData || !deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData, deleteId]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deleteAdvertiser,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper title="Advertiser" config={modalConfig} />

      <SlideOver
        open={toggle || !!editData}
        backgroundOverlay="dark"
        size="xl"
        title={`${toggle ? "Add" : "Edit"} Advertiser`}
        dismissButtonOnClick={() => {
          setToggle(false);
          setEditData(false);
        }}
      >
        {/* Advertiser form  */}
        <AdvertiserForm
          setToggle={editData ? setEditData : setToggle}
          initialValues={editData ? editData : addInitialValues}
          formButtonCta={`${toggle ? "Create" : "Update"} Advertiser`}
          editMode={!!editData}
        />
      </SlideOver>
      <div className="sticky top-0 z-50">
        <SectionHeading
          title="Advertisers"
          primaryCta={CurrentUser.isFanshipAdmin() ? "Add Advertiser" : null}
          primaryOnClick={() => {
            setToggle(true);
          }}
        />
      </div>
      {searchParamsQuery || !_.isEmpty(advertiser) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Advertiser List"}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default Advertisers;
