import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import FormHeader from "../../../components/FormHeader";
import FormFields from "../../FormFields";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import CampaignContext from "../../../lib/store/campaignContext";
import AwsImageService from "../../../lib/services/AwsImageService";
import { postValidationSchema } from "../../validation";

const PostForm = ({
  initialValues,
  campaignId,
  formNumber,
  setFormNumber,
  editing,
  published,
  responseData,
  setResponseData,
  campaignFormNumber,
  hasReachedForm,
}) => {
  const [editMode, setEditMode] = useState(
    editing && hasReachedForm(campaignFormNumber.CAMPAIGN_POST_FORM)
      ? false
      : true
  );
  const [showCancel, setShowCancel] = useState(
    editing && hasReachedForm(campaignFormNumber.CAMPAIGN_POST_FORM)
      ? true
      : false
  );
  const [formData, setFormData] = useState(editing ? initialValues : []);
  const [matchData, setMatchData] = useState(false);
  const buttonRef = useRef(null);
  const [adBannerData, setAdBannerData] = useState(
    editing ? initialValues?.image : ""
  );

  const budgetTotal = () => {
    if (_.isEmpty(responseData?.data?.attributes?.campaignsPosts)) return 0;
    else
      return responseData?.data?.attributes?.campaignsPosts?.reduce(
        (item, { attributes }) =>
          item + parseFloat((attributes?.budget / 100).toFixed(2)),
        0
      );
  };

  const formValues = [
    {
      type: "Row",
      formValues: [
        {
          label: "Ad Banner *",
          attributeId: "image",
          setAdBannerData: setAdBannerData,
          type: "Image",
          message: "(Banner image should be of 1200px * 80px)",
        },
        {
          label: "Campaign Demography Reach",
          value:
            responseData?.data?.attributes?.totalFollowers ||
            responseData?.totalFollowers ||
            0,
          price: false,
          type: "InfoContainer",
        },
        {
          label: "Total Budget",
          value: (
            responseData?.data?.attributes?.budget / 100 ||
            budgetTotal() ||
            parseFloat(responseData?.totalBudget ?? 0) ||
            0
          ).toFixed(2),
          price: true,
          type: "InfoContainer",
        },
      ],
    },
    {
      type: "PostInput",
      attributeId: "posts",
      selectedPost: formData.posts,
      matchData: matchData,
      adBannerData: adBannerData,
      budgetData: formData.campaignsPosts,
    },
  ];

  const onEdit = (e) => {
    e.preventDefault();
    setMatchData(false);
    setEditMode(true);
  };
  const onCancel = () => {
    setAdBannerData(formData?.image);
    setMatchData(true);
    setEditMode(false);
  };

  const getAttributes = (items, keyName1, keyName2) => {
    return items.map((item) => {
      if (!!keyName2) {
        return {
          id: parseInt(item[keyName1]),
          budget: parseFloat(item[keyName2]) * 100,
        };
      }
      return { id: parseInt(item[keyName1]) };
    });
  };

  const onSubmit = async (values, { setSubmitting }) => {
    if (!_.isEmpty(values.posts)) {
      const fetchedValues = {
        adBanner: values?.image?.data
          ? await AwsImageService(values)
          : initialValues.image,
        postsAttributes: getAttributes(values.posts, "postId", "budget"),
        talentsAttributes: getAttributes(values.posts, "talentId"),
      };
      const apiParams = {
        data: { attributes: fetchedValues },
      };
      const endpoint = `/portal_api/v1/campaigns/${campaignId}`;

      const { data, error } = await API.put(endpoint, apiParams);

      if (error) {
        toast.error(
          `${_.head(error.message) ?? "Something went wrong. Please try again"}`
        );
      } else {
        toast.success(`Posts saved successfully`);
        setEditMode(false);
        setFormNumber(
          hasReachedForm(campaignFormNumber.CAMPAIGN_POST_FORM)
            ? formNumber
            : campaignFormNumber.CAMPAIGN_POST_FORM
        );
        setShowCancel(true);
        setFormData({
          ...initialValues,
          posts: values.posts,
          image: data?.data?.attributes?.adBanner,
        });
        setResponseData(data);
      }
    } else {
      toast.error(`Posts can not be empty`);
    }
  };

  return (
    <div>
      <CampaignContext.Provider
        value={{
          campaign_id: campaignId,
          brand_category:
            responseData?.data?.attributes?.brandCategory ||
            responseData?.brandCategory,
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={postValidationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <>
              <Form className="flex flex-col justify-between space-y-7">
                <div className="rounded-md bg-white px-2 pb-6 border-2 border-gray-300">
                  <FormHeader
                    sectionHeading="Campaign Posts"
                    cancelOnClick={onCancel}
                    editOnClick={onEdit}
                    editMode={editMode}
                    isSubmitting={isSubmitting}
                    buttonRef={buttonRef}
                    showCancel={showCancel}
                    published={published}
                  />
                  <div className="pb-3 px-2">
                    <FormFields
                      setFieldValue={setFieldValue}
                      values={values}
                      formValues={formValues}
                      editMode={editMode}
                      formData={formData}
                    />
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </CampaignContext.Provider>
    </div>
  );
};

export default PostForm;
