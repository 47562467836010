import React, { useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import Button from "../../../stories/Button";
import { talentAgencyValidationSchema } from "../../validation";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import FormFields from "../../FormFields";

const TalentAgencyForm = ({
  setToggle,
  initialValues,
  editMode,
  formButtonCta,
}) => {
  const buttonRef = useRef(null);

  const formValues = [
    {
      label: "Agency Name *",
      attributeId: "name",
      placeholder: "Rapid Kings",
      type: "TextInput",
    },
    {
      label: "Admin First Name *",
      attributeId: "adminFirstName",
      placeholder: "Rahul",
      type: "TextInput",
    },
    {
      label: "Admin Last Name *",
      attributeId: "adminLastName",
      placeholder: "Jain",
      type: "TextInput",
    },

    {
      label: "Email *",
      attributeId: "email",
      placeholder: "xyz@example.com",
      type: "TextInput",
    },
    {
      label: "Admin Phone Number *",
      attributeId: "adminPhone",
      placeholder: "+91 98568-74589",
      type: "PhoneInput",
      countryCode: "countryCode",
    },
    {
      label: "Status : ",
      attributeId: "status",
      type: "Toggle",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    // uploading data to backend db
    const fetchedValues = {
      ..._.pick(values, ["name", "email", "countryCode", "status"]),
      adminPhone: values.adminPhone.slice(values.countryCode.length),
      firstName: values?.adminFirstName,
      lastName: values?.adminLastName,
    };

    const apiParams = {
      data: { attributes: fetchedValues },
    };

    const endpoint = `/portal_api/v1/talent_managements/${
      editMode ? initialValues.id : ""
    }`;

    const { error } = editMode
      ? await API.put(endpoint, apiParams)
      : await API.post(endpoint, apiParams);
    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(
        `Talent Agency ${editMode ? "Updated" : "Created"} Successfully`
      );
      setToggle(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={talentAgencyValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="space-y-7">
          <FormFields
            setFieldValue={setFieldValue}
            values={values}
            formValues={formValues}
          />
          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="w-full py-2"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default TalentAgencyForm;
