import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import _ from "lodash";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import SlideOver from "../../../stories/SlideOver";
import Table from "../../../components/Table";
import { useBlacklistedKeywords } from "../../../lib/services/Configuration";
import moment from "moment";
import {
  ActionDeleteButton,
  ActionEditButton,
} from "../../../table-components/Buttons";
import SearchBar from "../../../components/SearchBar";
import BlacklistedKeywordForm from "../../../forms/pageForms/Configurations/BlacklistedKeywordForm";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import { ModalWrapper } from "../../../utils/Modal";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import TableLayout from "../../../components/TableLayout";
import { navigateSelf } from "../../../utils/Helper";
import { ExclamationIcon } from "@heroicons/react/outline";

const BlacklistedKeywords = () => {
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const [deleteId, setDeleteId] = useState(false);
  const parsed = queryString.parse(location.search);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const addInitialValues = {
    word: "",
  };

  const deleteKeyword = async () => {
    if (deleteId) {
      setModalPrimaryCtaLoading(true);
      const { error } = await API.delete(
        `/portal_api/v1/black_listed_words/${deleteId}`,
        {}
      );
      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${
            error?.status ?? null
          }: Something went Wrong. Please try again`
        );
      } else {
        toast.success("Keyword deleted successfully");
        setDeleteId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const onAction = (action, data) => {
    if (action === "delete") {
      setDeleteId(data.id);
    } else if (action === "edit") {
      setEditData({
        ...data,
        talentCategoryId: data.talentCategories?.[0]?.id,
        talentManagementId: data.talentManagement?.id,
      });
    }
  };
  const columns = [
    {
      field: "Keywords",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 capitalize">
          {rowData?.attributes?.word}
        </div>
      ),
    },
    {
      field: "added on",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {moment(`${rowData?.attributes?.createdAt}`).format("ll")}
        </div>
      ),
    },
    {
      field: "actions",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionEditButton
            iconOnly
            onClick={() => {
              onAction("edit", {
                ...rowData?.attributes,
                id: rowData.id,
              });
            }}
          />
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              onAction("delete", { id: rowData.id });
            }}
          />
        </div>
      ),
    },
  ];

  const {
    keywords,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useBlacklistedKeywords(pageNumberQuery, searchParamsQuery);

  const loadContent = () => {
    if (_.isEmpty(keywords)) {
      return (
        <LoadContentHelper
          title="Keyword"
          message="Please start by adding a new Keyword by clicking below "
          ctaOnClick={() => setToggle(true)}
          data={keywords}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    return <Table column={columns} data={keywords} />;
  };

  useEffect(() => {
    if (!toggle || !editData || !deleteId) {
      mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData, deleteId]);

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deleteKeyword,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper title="blacklisted keyword" config={modalConfig} />

      <SlideOver
        open={toggle || !!editData}
        backgroundOverlay="dark"
        size="xl"
        title={`${toggle ? "Add" : "Edit"} Keyword`}
        dismissButtonOnClick={() => {
          setToggle(false);
          setEditData(false);
        }}
      >
        <BlacklistedKeywordForm
          setToggle={editData ? setEditData : setToggle}
          initialValues={editData ? editData : addInitialValues}
          formButtonCta={`${toggle ? "Add" : "Update"} Keyword`}
          editMode={!!editData}
        />
      </SlideOver>

      <div className="sticky top-0 z-50">
        <SectionHeading
          title="Blacklisted Keywords"
          primaryCta={"Add Keyword"}
          primaryOnClick={() => {
            setToggle(true);
          }}
        />
      </div>
      {searchParamsQuery || !_.isEmpty(keywords) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}

      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Blacklisted Keywords List"
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default BlacklistedKeywords;
