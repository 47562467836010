import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";
export const useTeamList = (pageParam = "1", searchTerm) => {
  const queryObject = { page: pageParam, query: searchTerm, per_page: 20 };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/fanship_admins${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});
  return {
    team: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
