import React from "react";
import _ from "lodash";
import { usePost } from "../lib/services/PostService";
import { useTalent } from "../lib/services/TalentService";
import Author from "../stories/Author";
import {
  ActionEditButton,
  ActionCancelButton,
} from "../table-components/Buttons";
import TalentInfoHelper from "./TalentInfoHelper";

const TalentPost = ({
  postId,
  postBudget,
  talentId,
  setModal,
  editMode,
  setSelectedTalent,
  setSelectedPostId,
  setSelectedPostBudget,
  adBannerData,
  removePost,
}) => {
  const { talent } = useTalent(talentId);
  const { post } = usePost(postId);
  return (
    <div className=" w-3/5 md:w-80 xl:w-96 px-3 py-3">
      <div className=" border-2 rounded-md px-4 pt-4">
        <div className="relative">
          <Author
            name={talent?.attributes?.name}
            cta={`@${talent?.attributes?.username}`}
            imageSrc={talent?.attributes?.image}
            className="cursor-default"
          />
          {editMode ? (
            <div className="absolute top-0 right-0 text-gray-400 cursor-pointer flex space-x-2">
              <ActionEditButton
                buttonStyle="white"
                iconOnly
                onClick={() => {
                  setSelectedTalent(talentId);
                  setSelectedPostId(postId);
                  setSelectedPostBudget({
                    budget: postBudget,
                  });
                  setModal(true);
                }}
              />
              <ActionCancelButton
                buttonStyle="white"
                iconOnly
                onClick={() => {
                  const res = window.confirm(
                    "Are you sure you want to remove this post?"
                  );
                  if (res === true) {
                    removePost();
                  }
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="my-3 ">
          {post?.attributes?.containsVideo ? (
            <video
              poster={post?.attributes?.previewImage?.gallery?.url}
              className=" object-contain bg-gradient-to-tl from-gray-200 to-gray-600 h-80 w-full mt-2 "
              controls
            >
              <source
                src={
                  _.find(post?.attributes?.attachments, [
                    "attributes.category",
                    "video",
                  ])?.attributes?.key
                }
              />
            </video>
          ) : (
            <img
              className="mt-2 object-contain h-80 w-full"
              src={post?.attributes?.previewImage?.gallery?.url}
              alt="Post"
            />
          )}
          <img className="mt-1 h-6 w-full" src={adBannerData} alt="Ad Banner" />
        </div>
      </div>

      <div>
        <div className="block text-sm font-medium text-gray-700 mt-4  capitalize">
          post budget : ₹ {parseFloat(postBudget).toFixed(2)}
        </div>
      </div>
      <div>
        <div className="block text-sm font-medium text-gray-700 mt-4">
          Post Metrics
        </div>
        <div className="h-42 border-2 rounded-md px-4 my-4 pt-4">
          <TalentInfoHelper talent={talent} />
        </div>
      </div>
    </div>
  );
};

export default TalentPost;
