import React, { useState } from "react";
import Layout from "../../../../../../../components/sideBar/Layout";
import SectionHeading from "../../../../../../../stories/SectionHeading";
import Breadcrumb from "../../../../../../../stories/Breadcrumb";
import { navigate, useParams } from "@reach/router";
import ReguarPostForm from "../../../../../../../forms/pageForms/talentAgencyForms/tmaPortal/post/ReguarPostForm";
import { useTalent } from "../../../../../../../lib/services/TalentService";
import { CurrentUser } from "../../../../../../../lib/network/auth/Auth";

const CreateTmaRegularPost = ({ location }) => {
  const userType = CurrentUser.getType();
  const editData = location?.state?.editData;
  const tmaTalentId = location?.state?.tmaTalentId;
  const { talentId: tId } = useParams();
  const talentId = userType === "talent" ? CurrentUser.getId() : tId;
  const { talent } = useTalent(tmaTalentId ?? talentId);
  const [editing, setEditing] = useState(editData ? true : false);

  const addInitialValues = {
    caption: "",
  };

  const breadcrumbsData = [
    {
      to: `../brands`,
      name: talent?.attributes?.fanAccount?.attributes?.name || "Talent",
    },
    {
      to: `../posts/regular-posts`,
      name: "Posts",
    },
    {
      to: `../posts/regular-posts`,
      name: "Regular Posts",
    },
    {
      to: "",
      name: editing ? "Edit Regular post" : "Create Regular Post",
      current: true,
    },
  ];

  return (
    <Layout>
      <div className="pt-4 bg-white sticky top-0 z-50">
        <Breadcrumb pages={breadcrumbsData} type="chevron" />
        <SectionHeading
          title={`${editing ? "Edit" : "Create"} Regular Post`}
          primaryCta="Back"
          primaryOnClick={() => navigate(-1)}
        />
      </div>
      <div className="mx-8 my-3 ">
        <ReguarPostForm
          editing={editing}
          initialValues={editing ? editData : addInitialValues}
          formButtonCta={`${editing ? "Update" : "Create"} Post`}
          tmaTalentId={tmaTalentId}
        />
      </div>
    </Layout>
  );
};

export default CreateTmaRegularPost;
