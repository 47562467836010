import React from "react";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import _ from "lodash";
import TermsAndConditionsForm from "../../../forms/pageForms/Configurations/TermsAndConditionsForm";
import { useTermsAndPrivacy } from "../../../lib/services/TermsAndPrivacyService";

import Loader from "../../../components/Loader";

const TermsAndConditons = () => {
  const { policy, loading } = useTermsAndPrivacy();
  const editing = policy?.attributes?.termsAndConditionsContent ? true : false;
  const addInitialValues = {
    termsAndConditions: "",
  };

  const editData = {
    id: policy?.id,
    termsAndConditions: policy?.attributes?.termsAndConditionsContent,
  };

  return (
    <Layout>
      <div className="pt-4 bg-white sticky top-0 z-50">
        <SectionHeading title="Terms And Conditions" />
      </div>
      <div className="m-8">
        {policy && loading ? (
          <div className="my-72">
            <Loader />
          </div>
        ) : (
          <TermsAndConditionsForm
            editing={editing}
            initialValues={editing ? editData : addInitialValues}
          />
        )}
      </div>
    </Layout>
  );
};

export default TermsAndConditons;
