import React from "react";
import AllTalentHeader from "../AllTalentHeader";
import Tabs from "../../../../../../stories/Tabs";

const PostHeader = ({ active, talentId, talentName }) => {
  const urlLastSegment = "posts";

  const tabsData = [
    {
      id: "regular-posts",
      path: `/talent/posts/regular-posts`,
      icon: () => {
        return null;
      },
      name: "regular posts",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "monetized-posts",
      path: `/talent/posts/monetized-posts`,
      icon: () => {
        return null;
      },
      name: "monetized posts",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "quizzes",
      path: `/talent/posts/quizzes`,
      icon: () => {
        return null;
      },
      name: "quizzes",
      get current() {
        return active === this.id ? true : false;
      },
    },
  ];

  return (
    <div className="sticky bg-gray-100 top-0 z-50">
      <AllTalentHeader
        talentName={talentName}
        talentId={talentId}
        active={urlLastSegment}
      />
      <div className="mt-3 -ml-2 pb-3">
        <Tabs type="button" tabs={tabsData} />
      </div>
    </div>
  );
};

export default PostHeader;
