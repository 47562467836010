import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import _ from "lodash";
import moment from "moment";
import Layout from "../../../../../../components/sideBar/Layout";
import Button from "../../../../../../stories/Button";
import queryString from "query-string";
import { navigate, useLocation, useParams } from "@reach/router";
import { useTalent } from "../../../../../../lib/services/TalentService";
import SearchBar from "../../../../../../components/SearchBar";
import Table from "../../../../../../components/Table";
import { API } from "../../../../../../lib/network/API";
import { toast } from "../../../../../../components/Toast";
import TableLayout from "../../../../../../components/TableLayout";
import { LoadContentHelper } from "../../../../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../../../../utils/Helper";
import { ModalWrapper } from "../../../../../../utils/Modal";
import { usePostByTalentList } from "../../../../../../lib/services/PostService";
import {
  ActionDeleteButton,
  ActionEditButton,
} from "../../../../../../table-components/Buttons";
import AllTalentHeader from "../../detail-page/AllTalentHeader";
import AllTalentPortalHeader from "../../../../../navigation/talent-portal/talent-info/detail-page/AllTalentHeader";

import GroupAvatar from "../../../../../../stories/GroupAvatar";
import { CurrentUser } from "../../../../../../lib/network/auth/Auth";
import GenericModal from "../../../../../../components/GenericVideoModal";
const TmaMonetizablePosts = () => {
  const location = useLocation();
  const userType = CurrentUser.getType();
  const parsed = queryString.parse(location.search);
  const userRole = CurrentUser.getRole();
  const { talentId: tId } = useParams();
  const talentId = userType === "talent" ? CurrentUser.getId() : tId;
  const { talent } = useTalent(talentId);
  const [deleteId, setDeleteId] = useState(false);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);
  const urlLastSegment = location.pathname.split("/").pop();
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";
  const [videoModal, setVideoModal] = useState(false);
  const [videoPlayUrl, setVideoPlayUrl] = useState(false);
  const filterType = "monetizable";
  const {
    posts,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = usePostByTalentList(
    pageNumberQuery,
    searchParamsQuery,
    talentId,
    filterType
  );

  const deletePost = async () => {
    if (deleteId) {
      setModalPrimaryCtaLoading(true);
      const { error } = await API.delete(
        `/portal_api/v1/posts/${deleteId}`,
        {}
      );
      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ?? "This post cannot be deleted. Please try again"
          }`
        );
      } else {
        toast.success("monetizable post Deleted Successfully");
        setDeleteId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const getValue = (item) => item["id"];
  const setFieldValue = (values) => {
    return values.map((item) => {
      return { brandCategoryId: getValue(item) };
    });
  };

  const onAction = (action, data) => {
    if (action === "delete") {
      setDeleteId(data.id);
    } else if (action === "edit") {
      navigate(
        userType === "talent"
          ? `/talent/posts/create-monetizable-media`
          : `/tma/${userRole}/talents/${talentId}/posts/create-monetizable-media`,
        {
          state: {
            tmaTalentId: talentId,
            editData: {
              ...data,
              caption: data?.caption || "",
              brandCategoryId: _.isEmpty(data.brandCategories)
                ? []
                : setFieldValue(data?.brandCategories),
            },
          },
        }
      );
    }
  };

  const columns = [
    {
      field: "Caption",
      render: (rowData) => (
        <div className="text-left  text-sm font-medium text-gray-900">
          {rowData?.attributes?.caption ?? "-na-"}
        </div>
      ),
    },
    // {
    //   field: "Post Attachments",
    //   render: (rowData) => (
    //     <div className="text-left  text-sm text-gray-500">
    //       {_.isEmpty(rowData?.attributes?.attachments) ? (
    //         "-na-"
    //       ) : (
    //         <GroupAvatar
    //           size="xl"
    //           group={(rowData?.attributes?.attachments).filter(
    //             (attachment) => attachment?.attributes?.category === "image"
    //           )}
    //         />
    //       )}
    //     </div>
    //   ),
    // },
    {
      field: "Post Attachments",
      render: (rowData) => (
        <div className="text-left  text-sm text-gray-500">
          {_.isEmpty(rowData?.attributes?.attachments) ? (
            "-na-"
          ) : (
            <>
              {rowData?.attributes?.containsVideo ? (
                <>
                  <div
                    class="w-16 h-16 ring-2 ring-white rounded-md bg-gray-900  grid place-items-center hover:bg-gray-800 cursor-pointer transition"
                    onClick={() => {
                      setVideoModal(true);
                      setVideoPlayUrl(
                        _.find(rowData?.attributes?.attachments, [
                          "attributes.category",
                          "video",
                        ])?.attributes?.key
                      );
                    }}
                  >
                    {rowData?.attributes?.previewImage?.thumbnail?.url && (
                      <img
                        className="rounded-md"
                        src={rowData?.attributes?.previewImage?.thumbnail?.url}
                        alt="video"
                      />
                    )}
                    <svg
                      class="ml-1 w-4 absolute "
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </>
              ) : (
                <GroupAvatar
                  size="xl"
                  group={(rowData?.attributes?.attachments).filter(
                    (attachment) => attachment?.attributes?.category === "image"
                  )}
                  imageShape="round-md"
                />
              )}
            </>
          )}
        </div>
      ),
    },
    {
      field: "Category",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm  text-gray-500 capitalize">
          {_.isEmpty(rowData?.attributes?.brandCategories)
            ? "-na-"
            : rowData?.attributes?.brandCategories?.map((item, idx) => {
                return <div key={idx}>{item?.attributes?.name}</div>;
              })}
        </div>
      ),
    },
    {
      field: "status",
      render: (rowData) => (
        <div className="text-left uppercase whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.status || "-na-"}
        </div>
      ),
    },
    //as will remain empty ,backend needs to provide a different key
    // {
    //   field: "posted on",
    //   render: (rowData) => (
    //     <div className=" text-left whitespace-nowrap text-sm text-gray-500">
    //       {rowData?.attributes?.publishedAt
    //         ? moment(rowData?.attributes?.publishedAt).format("LL")
    //         : "-na-"}
    //     </div>
    //   ),
    // },
    {
      field: "actions",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <>
            <ActionEditButton
              iconOnly
              onClick={() => {
                onAction("edit", {
                  ...rowData?.attributes,
                  id: rowData.id,
                });
              }}
            />
            <ActionDeleteButton
              iconOnly
              onClick={() => {
                onAction("delete", { id: rowData.id });
              }}
            />
          </>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]);

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const loadContent = () => {
    if (_.isEmpty(talent) || _.isEmpty(posts)) {
      return (
        <LoadContentHelper
          title="Monetizable Media List"
          cta="Create Media"
          message="Please create a new Media by clicking below."
          ctaOnClick={() =>
            navigate(
              userType === "talent"
                ? `/talent/posts/create-monetizable-media`
                : `/tma/${userRole}/talents/${talentId}/posts/create-monetizable-media`,
              {
                state: {
                  editData: false,
                  tmaTalentId: talentId,
                },
              }
            )
          }
          data={posts}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }

    return <Table column={columns} data={posts} />;
  };

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deletePost,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <GenericModal
        open={videoModal}
        handleClose={() => setVideoModal(false)}
        videoUrl={videoPlayUrl}
      />
      <ModalWrapper title="Monetizable Media " config={modalConfig} />
      {userType === "talent" ? (
        <AllTalentPortalHeader
          talentName={
            talent?.attributes?.fanAccount?.attributes?.name ||
            "Media Collection"
          }
          talentId={talentId}
          active={urlLastSegment}
        />
      ) : (
        <AllTalentHeader
          talentName={
            talent?.attributes?.fanAccount?.attributes?.name ||
            "Media Collection"
          }
          talentId={talentId}
          active={urlLastSegment}
        />
      )}
      {searchParamsQuery || !_.isEmpty(posts) ? (
        <div className="flex justify-between items-center">
          <SearchBar
            searchTerm={searchParamsQuery}
            size="xl"
            align="center"
            onSearch={(val) => {
              navigateSelf(1, val, parsed);
            }}
            loading={loading}
          />
          <Button
            className=" py-1.5 mr-7"
            onClick={() =>
              navigate(
                userType === "talent"
                  ? `/talent/posts/create-monetizable-media`
                  : `/tma/${userRole}/talents/${talentId}/posts/create-monetizable-media`,
                {
                  state: {
                    editData: false,
                    tmaTalentId: talentId,
                  },
                }
              )
            }
          >
            Create Media
          </Button>
        </div>
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Monetizable Media List"
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
          loading={loading}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default TmaMonetizablePosts;
