import React from "react";
import SectionHeading from "../../../../../../../stories/SectionHeading";
import { useParams } from "@reach/router";
import Layout from "../../../../../../../components/sideBar/Layout";
import Breadcrumb from "../../../../../../../stories/Breadcrumb";
import { useTalent } from "../../../../../../../lib/services/TalentService";
import _ from "lodash";
import QuizForm from "../../../../../../../forms/pageForms/QuizForm/QuizForm";
import { useQuiz } from "../../../../../../../lib/services/QuizQuestionService";
import Loader from "../../../../../../../components/Loader";
import { CurrentUser } from "../../../../../../../lib/network/auth/Auth";

const CreateQuiz = ({ location }) => {
  const userType = CurrentUser.getType();
  const quizId = location?.state?.quizId;
  const editing = quizId ? true : false;
  const { talentId: tId } = useParams();
  const talentId = userType === "talent" ? CurrentUser.getId() : tId;
  const { talent } = useTalent(talentId);
  const { quiz, loading } = useQuiz(quizId || "");

  const addInitialValues = {
    talentId: talentId,
    category: "quiz",
    status: "draft",
    question: "",
    optionA: "",
    optionB: "",
    optionC: "",
    correctOption: "",
  };
  const breadcrumbsData = [
    {
      to: userType === "talent" ? "../../brands" : "../../../../talents",
      name: userType === "talent" ? "Talent" : "Talents",
    },

    {
      to: "../../brands",
      name: talent?.attributes?.name,
    },
    {
      to: "../../posts/regular-posts",
      name: "Posts",
    },

    {
      to: "../quizzes",
      name: "Quizzes",
    },
    {
      to: "",
      name: `${editing ? "Edit" : "Create"} Quiz`,
      current: true,
    },
  ];

  const quizFormNumber = {
    QUIZ_DETAILS_FORM: 0,
    QUIZ_MEDIA_FORM: 1,
    QUIZ_COMPLETED: 2,
  };

  const getStep = (data) => {
    if (
      _.isEmpty(data?.question) ||
      _.isEmpty(data?.optionA) ||
      _.isEmpty(data?.optionB) ||
      _.isEmpty(data?.optionC) ||
      _.isEmpty(data?.correctOption)
    ) {
      return quizFormNumber.QUIZ_DETAILS_FORM;
    }

    if (_.isEmpty(data?.attachments)) {
      return quizFormNumber.QUIZ_MEDIA_FORM;
    }
    return quizFormNumber.QUIZ_COMPLETED;
  };

  const editData = editing ? location?.state?.quizData : null;

  return (
    <Layout>
      <div className="pt-4 bg-white sticky top-0 z-50">
        <Breadcrumb pages={breadcrumbsData} type="chevron" />
        <SectionHeading title={`${editing ? "Update Quiz" : "Create Quiz"}`} />
      </div>
      <div className="m-8">
        {quizId && loading ? (
          <div className="my-72">
            <Loader />
          </div>
        ) : (
          <QuizForm
            talentId={talentId}
            initialValues={editing ? editData : addInitialValues}
            editing={editing}
            step={getStep(editData)}
            published={
              !!quiz?.attributes?.status
                ? quiz?.attributes?.status !== "draft"
                  ? true
                  : false
                : false
            }
          />
        )}
      </div>
    </Layout>
  );
};

export default CreateQuiz;
