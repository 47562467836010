import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import TimeKeeper from "react-timekeeper";
import TextInput from "./TextInput";

const Timepicker = ({ name, placeholder, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name, ...props });

  const [time, setTime] = useState(field.value ? field.value : "12:00 am");
  const [showTime, setShowTime] = useState(false);

  return (
    <div className="relative">
      <TextInput
        name={name}
        onClick={() => {
          setShowTime(true);
          setTime(time);
          setFieldValue(field.name, time);
        }}
        type="text"
        placeholder={placeholder}
        readOnly
      />
      <div className="absolute top-12 z-20">
        {showTime && (
          <TimeKeeper
            time={time}
            onChange={(newTime) => {
              setTime(newTime.formatted12);
              setFieldValue(field.name, newTime.formatted12);
            }}
            onDoneClick={() => {
              setShowTime(false);
            }}
            switchToMinuteOnHourSelect
          />
        )}
      </div>
    </div>
  );
};

export default Timepicker;
