import React from "react";
import SectionHeading from "../../../../../../../stories/SectionHeading";
import { useParams } from "@reach/router";
import Layout from "../../../../../../../components/sideBar/Layout";
import Breadcrumb from "../../../../../../../stories/Breadcrumb";
import { useTalent } from "../../../../../../../lib/services/TalentService";
import { useQuizPost } from "../../../../../../../lib/services/tma/PostService";
import _ from "lodash";
import { LoadContentHelper } from "../../../../../../../table-components/LoadContentHelper";
import QuizPostDetail from "../../../../../../../components/QuizPostDetail";
import { CurrentUser } from "../../../../../../../lib/network/auth/Auth";
const ViewQuizPost = () => {
  const { talentId: tId, postId } = useParams();
  const userType = CurrentUser.getType();
  const talentId = userType === "talent" ? CurrentUser.getId() : tId;
  const { talent } = useTalent(talentId);
  const { post, loading, error, mutate, isValidating } = useQuizPost(postId);

  const breadcrumbsData = [
    {
      to: userType === "talent" ? "../../brands" : "../../../../talents",
      name: userType === "talent" ? "Talent" : "Talents",
    },
    {
      to: "../../brands",
      name: talent?.attributes?.name,
    },
    {
      to: "../../posts/regular-posts",
      name: "Posts",
    },

    {
      to: "../quizzes",
      name: "Quizzes",
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(post)) {
      return (
        <div className="border-2 rounded-md">
          <LoadContentHelper
            title="Quiz Post"
            data={post}
            loading={loading}
            error={error}
            mutate={mutate}
            isValidating={isValidating}
          />
        </div>
      );
    }
    return <QuizPostDetail post={post} talent={talent} />;
  };

  return (
    <Layout>
      <div className="pt-4 bg-white sticky top-0 z-50">
        <Breadcrumb pages={breadcrumbsData} type="chevron" />
        <SectionHeading title="Post" />
      </div>

      <main className="flex-1 relative overflow-y-auto focus:outline-none m-8 h-3/4">
        {loadContent()}
      </main>
    </Layout>
  );
};

export default ViewQuizPost;
