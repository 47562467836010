import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { useField, ErrorMessage, useFormikContext } from "formik";

const Dropdown = ({
  dropDownData,
  name,
  placeholder,
  options,
  displayKey,
  size,
  setCategoryAttribute = null,
  displayCurrentOption = false,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name, ...props });
  const [selectedVal, setSelectedVal] = useState(field?.value ?? "");
  useEffect(() => {
    if (displayCurrentOption) {
      setSelectedVal(field?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field?.value]);

  const dimension = () => {
    switch (size) {
      case "xs":
        return "w-1/3";
      case "md":
        return "w-1/2";
      case "lg":
        return "2/3";
      case "xl":
        return "w-full";
      default:
        return "w-full";
    }
  };

  const menuOptions = () => {
    return (
      <ul>
        {options?.map((option, idx) => (
          <Menu.Item key={idx}>
            {({ active }) => (
              <li
                className={classNames(
                  active
                    ? "bg-gray-100 text-gray-900 cursor-pointer"
                    : "text-gray-700",
                  "block px-4 py-2 text-sm"
                )}
                onClick={() => {
                  setFieldValue(
                    name,
                    !!(displayKey && option.id)
                      ? option.id
                      : option?.attributes[displayKey]
                  );
                  setSelectedVal(option?.attributes[displayKey]);
                  if (setCategoryAttribute) {
                    setCategoryAttribute(option?.attributes[displayKey]);
                  }
                }}
              >
                {displayKey
                  ? option.attributes[displayKey]
                  : option?.attributes.name || option?.attributes.title}
              </li>
            )}
          </Menu.Item>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <div className="mt-1 relative">
        <Menu
          as="div"
          className={classNames(
            "relative inline-block text-left bg-white",
            dimension()
          )}
        >
          {({ open }) => (
            <>
              <Menu.Button
                className={classNames(
                  "inline-flex justify-between px-4 py-2 w-full shadow-sm border capitalize focus:ring-gray-900 focus:border-gray-900 sm:text-sm border-gray-300 rounded-md",
                  !selectedVal && "text-gray-500",
                  meta.touched &&
                    meta.error &&
                    "border border-red-600 focus:ring-red-600 focus:border-red-600"
                )}
                {...field}
              >
                {selectedVal || placeholder}
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className=" z-50 h-auto overflow-y-auto origin-top-right absolute capitalize right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none "
                >
                  <div className="py-1">{menuOptions()}</div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
      <ErrorMessage
        name={name}
        component="span"
        className="text-red-700 text-sm absolute"
      ></ErrorMessage>
    </div>
  );
};

export default Dropdown;
