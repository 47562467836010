import _ from "lodash";
import classNames from "classnames";
const InfoContainer = ({
  title,
  label,
  value,
  price = false,
  isArray = false,
  displayKey,
  talentCPI,
  darkMode = false,
}) => {
  const getDisplayValue = (item) =>
    displayKey
      ? talentCPI
        ? `₹ ${(_.get(item, displayKey) / 100).toFixed(2)}`
        : _.get(item, displayKey)
      : item;

  return (
    <div className="flex flex-col mb-4">
      {title ? (
        <div className=" text-lg leading-6 font-medium text-gray-900 mb-3">
          {title}
        </div>
      ) : (
        ""
      )}
      <div
        className={classNames(
          "block text-sm font-medium ",
          darkMode ? "text-gray-700" : "text-gray-400"
        )}
      >
        {label}
      </div>
      <div
        className={classNames(
          !darkMode ? "bold text-base font-medium text-gray-700 capitalize" : ""
        )}
      >
        {!isArray
          ? price
            ? `₹ ${value}`
            : value
          : !_.isEmpty(value) &&
            value?.map((item, idx) => {
              return <div key={idx}>{getDisplayValue(item)}</div>;
            })}
      </div>
    </div>
  );
};

export default InfoContainer;
