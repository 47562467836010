import React, { useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import Button from "../../stories/Button";
import { teamValidationSchema } from "../validation";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import FormFields from "../FormFields";

const TeamForm = ({ setToggle, initialValues, editMode, formButtonCta }) => {
  const buttonRef = useRef(null);

  const formValues = [
    {
      label: "First Name *",
      attributeId: "firstName",
      placeholder: "Rahul",
      type: "TextInput",
    },
    {
      label: "Last Name *",
      attributeId: "lastName",
      placeholder: "Gupta",
      type: "TextInput",
    },
    // {
    //   label: "Role",
    //   attributeId: "role",
    //   placeholder: "Sales/Admin",
    //   type: "TextInput",
    // },

    {
      label: "Role *",
      attributeId: "role",
      placeholder: "Select role",
      type: "DropDown",
      options: [
        { attributes: { role: "admin" } },
        { attributes: { role: "sales" } },
      ],
      displayKey: "role",
    },

    {
      label: "Email *",
      attributeId: "email",
      placeholder: "xyz@example.com",
      type: "TextInput",
    },
    {
      label: "Admin Phone Number *",
      attributeId: "phone",
      placeholder: "+91 98568-74589",
      type: "PhoneInput",
      countryCode: "countryCode",
    },
    {
      label: "Status : ",
      attributeId: "status",
      type: "Toggle",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    // uploading data to backend db
    const fetchedValues = {
      ..._.pick(values, [
        "firstName",
        "lastName",
        "email",
        "role",
        "status",
        "countryCode",
      ]),
      phone: values.phone.slice(values.countryCode.length),
    };
    const apiParams = {
      data: { attributes: fetchedValues },
    };

    const endpoint = `/portal_api/v1/fanship_admins/${
      editMode ? initialValues.id : ""
    }`;

    const { error } = editMode
      ? await API.put(endpoint, apiParams)
      : await API.post(endpoint, apiParams);
    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(
        `Team member ${editMode ? "Updated" : "Created"} Successfully`
      );
      setToggle(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={teamValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="flex flex-col  h-screen">
          <div className="sticky pl-2 pr-4 -mr-5 pb-10 overflow-y-auto h-3/4 ">
            <FormFields
              setFieldValue={setFieldValue}
              values={values}
              formValues={formValues}
            />
          </div>
          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="mt-4 w-full sticky bottom-2 z-50"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default TeamForm;
