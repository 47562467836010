import React, { useEffect } from "react";
import SectionHeading from "../../../../../../stories/SectionHeading";
import { useParams } from "@reach/router";
import Layout from "../../../../../../components/sideBar/Layout";
import Breadcrumb from "../../../../../../stories/Breadcrumb";
import { useTalent } from "../../../../../../lib/services/TalentService";
import {
  usePost,
  useCommentList,
} from "../../../../../../lib/services/PostService";
import { navigate } from "@reach/router";
import _ from "lodash";
import { LoadContentHelper } from "../../../../../../table-components/LoadContentHelper";
import PostDetail from "../../../../../../components/PostDetail";
import { useLocation } from "@reach/router";
import { navigateSelf } from "../../../../../../utils/Helper";
import { CurrentUser } from "../../../../../../lib/network/auth/Auth";
const ViewRegularPosts = () => {
  const { talentId: tId, postId } = useParams();
  const userType = CurrentUser.getType();
  const talentId = userType === "talent" ? CurrentUser.getId() : tId;
  const { talent } = useTalent(talentId);
  const { post, loading, error, mutate, isValidating } = usePost(postId);
  const location = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;

  const fanId = talent?.attributes?.fanAccount?.id;
  const {
    comments,
    loading: commentLoading,
    paginationInfo,
    error: commentError,
    mutate: commentMutate,
    isValidating: commentIsValidating,
  } = useCommentList(postId, pageNumberQuery, fanId);
  //comment pagination
  useEffect(() => {
    if (!paginationInfo) {
      commentMutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(notWithingRange ? 1 : pageNumberQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo]);

  const breadcrumbsData = [
    {
      to: userType === "talent" ? "../../brands" : "../../../../talents",
      name: userType === "talent" ? "Talent" : "Talents",
    },

    {
      to: "../../brands",
      name: talent?.attributes?.name,
    },
    {
      to: "../../posts/regular-posts",
      name: "Posts",
    },

    {
      to: "../regular-posts",
      name: "Regular Post",
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(post)) {
      return (
        <div className="border-2 rounded-md">
          <LoadContentHelper
            title="Regular Post"
            data={post}
            loading={loading}
            error={error}
            mutate={mutate}
            isValidating={isValidating}
          />
        </div>
      );
    }
    return (
      <PostDetail
        post={post}
        talent={talent}
        comments={comments}
        paginationInfo={paginationInfo}
        commentLoading={commentLoading}
        commentError={commentError}
        commentMutate={commentMutate}
        commentIsValidating={commentIsValidating}
      />
    );
  };

  return (
    <Layout>
      <div className="pt-4 bg-white sticky top-0 z-50">
        <Breadcrumb pages={breadcrumbsData} type="chevron" />
        <SectionHeading
          title="Regular Post"
          primaryCta="Back"
          primaryOnClick={() => navigate("../regular-posts")}
        />
      </div>

      <main className="flex-1 relative focus:outline-none m-8 h-3/4">
        {loadContent()}
      </main>
    </Layout>
  );
};

export default ViewRegularPosts;
