import React from "react";
import Header from "../../../../components/Header";
import { navigate } from "@reach/router";
import _ from "lodash";

import { CurrentUser } from "../../../../lib/network/auth/Auth";
const AllTalentHeader = ({ talentName, talentId, active, posts }) => {
  const userRole = CurrentUser.getRole();

  const breadcrumbsData = [
    {
      to: posts ? "../../../all-talents" : "../../all-talents",
      name: "Talents",
    },
    {
      to: posts ? "../../../all-talents" : "../../all-talents",
      name: "All Talents",
    },
    {
      to: "",
      name: talentName,
    },
  ];

  const tabsData = [
    {
      id: "earning-ongoing-campaigns",
      path: `/${userRole}/talents/all-talents/${talentId}/earning-ongoing-campaigns`,
      icon: () => {
        return null;
      },
      name: "ongoing campaigns",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "earning-completed-campaigns",
      path: `/${userRole}/talents/all-talents/${talentId}/earning-completed-campaigns`,
      icon: () => {
        return null;
      },
      name: "completed campaigns",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "endorsed-brands",
      path: `/${userRole}/talents/all-talents/${talentId}/endorsed-brands`,
      icon: () => {
        return null;
      },
      name: "endorsed brands",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "posts",
      path: `/${userRole}/talents/all-talents/${talentId}/posts/regular-posts`,
      icon: () => {
        return null;
      },
      name: "posts",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "blocked-accounts",
      path: `/${userRole}/talents/all-talents/${talentId}/blocked-accounts`,
      icon: () => {
        return null;
      },
      name: "blocked accounts",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "credit-model",
      path: `/${userRole}/talents/all-talents/${talentId}/credit-model`,
      icon: () => {
        return null;
      },
      name: "credit model",
      get current() {
        return active === this.id ? true : false;
      },
    },
  ];

  return (
    <Header
      tabsData={
        CurrentUser.isFanshipAdmin()
          ? tabsData
          : _.reject(tabsData, { id: "posts" })
      }
      breadcrumbsData={breadcrumbsData}
      talentName={talentName}
      primaryCta="Back to talents"
      primaryOnClick={() => navigate(`/${userRole}/talents/all-talents`)}
    />
  );
};

export default AllTalentHeader;
