import React, { useState, useEffect } from "react";
import { useLocation, Link } from "@reach/router";
import queryString from "query-string";
import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import Table from "../../components/Table";
import SlideOver from "../../stories/SlideOver";
import { useTalentList } from "../../lib/services/TalentService";
import Author from "../../stories/Author";
import SearchBar from "../../components/SearchBar";
import _ from "lodash";
import TableLayout from "../../components/TableLayout";
import QuizQuestionForm from "../../forms/pageForms/QuizQuestionForm";
import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import { navigateSelf } from "../../utils/Helper";
import { CurrentUser } from "../../lib/network/auth/Auth";
const QuizQuestionCategories = () => {
  const location = useLocation();

  //queryString --> to get the query object
  const parsed = queryString.parse(location.search);

  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const addInitialValues = {
    question: "",
    optionA: "",
    optionB: "",
    optionC: "",
    category: "",
    correctOption: "",
    status: "disabled",
    talentCategoryId: "",
  };

  const {
    talents,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useTalentList(searchParamsQuery, { page: pageNumberQuery });

  const columns = [
    {
      field: "category",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center ">
          <Link to={`${rowData?.id}/general`}>
            <Author
              name={rowData?.attributes?.name}
              imageSrc={rowData?.attributes?.image}
              size="lg"
            />
          </Link>
        </div>
      ),
    },
    {
      field: "brief",
      render: (rowData) => (
        <div className="text-left text-sm text-gray-900   whitespace-nowrap">
          <Link to={`${rowData?.id}/general`}>
            {rowData?.attributes?.brief}
          </Link>
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(talents)) {
      return (
        <LoadContentHelper
          title="Talent Category"
          ctaOnClick={() => setToggle(true)}
          data={talents}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }

    return <Table column={columns} data={talents} />;
  };

  useEffect(() => {
    if (!toggle || !editData) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  return (
    <Layout>
      <SlideOver
        open={toggle || !!editData}
        backgroundOverlay="dark"
        size="xl"
        title={`${toggle ? "Add" : "Edit"} Quiz Question`}
        dismissButtonOnClick={() => {
          setToggle(false);
          setEditData(false);
        }}
      >
        {/* quiz Question form */}

        <QuizQuestionForm
          setToggle={editData ? setEditData : setToggle}
          initialValues={editData ? editData : addInitialValues}
          formButtonCta={`${toggle ? "Create" : "Update"} Quiz Question`}
          editMode={!!editData}
        />
      </SlideOver>
      <div className="sticky top-0 z-50">
        <SectionHeading
          title=" Quiz Questions (By Talent Categories)"
          primaryCta={CurrentUser.isFanshipAdmin() ? "Add Quiz Question" : null}
          primaryOnClick={() => {
            setToggle(true);
          }}
        />
      </div>
      {/* //SearchBar */}
      {searchParamsQuery || !_.isEmpty(talents) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Quiz Question Categories List"}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default QuizQuestionCategories;
