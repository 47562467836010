import React from "react";
import classNames from "classnames";
import InfoContainer from "./InfoContainer";

const TalentInfoHelper = ({ talent }) => {
  const talentAttributes = [
    {
      label: "Average Impressions",
      value: talent?.attributes?.averageImpressions,
      price: false,
    },
    {
      label: "Cost Per Impressions",
      value: talent?.attributes?.costPerImpression
        ? (talent.attributes.costPerImpression / 100).toFixed(2)
        : "",
      price: true,
    },
    {
      label: "Total Followers",
      value: talent?.attributes?.followersCount,
      price: false,
    },

    {
      label: "Average Likes",
      value: talent?.attributes?.averageLikes,
      price: false,
    },
  ];
  return (
    <div className="mt-3 w-full flex flex-wrap justify-between ">
      {talentAttributes?.map((item, idx) => (
        <div key={idx} className={classNames("pr-3 w-1/2")}>
          <InfoContainer
            label={item.label}
            value={item.value}
            price={item.price}
          />
        </div>
      ))}
    </div>
  );
};

export default TalentInfoHelper;
