import React, { useState, useEffect } from "react";
import _ from "lodash";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import SlideOver from "../../../stories/SlideOver";
import Table from "../../../components/Table";
import { useConfiguration } from "../../../lib/services/Configuration";
import moment from "moment";
import { ActionEditButton } from "../../../table-components/Buttons";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import TableLayout from "../../../components/TableLayout";
import EditCreditForm from "../../../forms/pageForms/Configurations/EditCreditForm";

const CreditModel = () => {
  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");

  const columns = [
    {
      field: "name",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row  items-center">
          {rowData?.attributes?.name}
        </div>
      ),
    },
    {
      field: "credit",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.credit ? rowData.attributes.credit : "-"}
        </div>
      ),
    },
    {
      field: "last updated on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.updated_at
            ? moment(`${rowData?.attributes?.updated_at}`).format("ll")
            : "-"}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionEditButton
            iconOnly
            onClick={() => {
              setEditData({
                ...rowData?.attributes,
                id: rowData.attributes.name,
              });
              setToggle(true);
            }}
          />
        </div>
      ),
    },
  ];

  const { config, loading, error, mutate, isValidating } = useConfiguration();
  const creditData = [
    {
      attributes: {
        name: "Credit Per Quiz Winning",
        credit: config?.attributes?.credit,
        updated_at: config?.attributes?.creditUpdatedAt,
      },
    },
    {
      attributes: {
        name: "Credit Per Friend Referral",
        credit: config?.attributes?.creditReferral,
        updated_at: config?.attributes?.creditReferralUpdatedAt,
      },
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(config)) {
      return (
        <LoadContentHelper
          title="Credit"
          cta=" Update Credit"
          ctaOnClick={() => setToggle(true)}
          data={config}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
        />
      );
    }

    return <Table column={columns} data={creditData} />;
  };

  useEffect(() => {
    if (!toggle || !editData) {
      mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData]);

  return (
    <Layout>
      <SlideOver
        open={toggle}
        backgroundOverlay="dark"
        size="xl"
        title="Edit Credits"
        dismissButtonOnClick={() => {
          setToggle(false);
          setEditData(false);
        }}
      >
        <EditCreditForm
          setToggle={setToggle}
          initialValues={editData}
          formButtonCta="Update Credits"
          configId={config.id}
        />
      </SlideOver>
      <div className="sticky top-0 z-50">
        <SectionHeading title="Credit Model" />
      </div>
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout title="Credit Model" loading={loading || error}>
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default CreditModel;
