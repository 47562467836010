import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";

export const useCampaignsList = (
  pageParam = "1",
  searchTerm,
  filterType = "scheduled"
) => {
  const queryObject = {
    page: pageParam,
    query: searchTerm,
    per_page: 20,
    filterByStatus: filterType,
  };
  const queryValues = queryStringParseFuntion(queryObject);

  const key = `/portal_api/v1/campaigns${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    campaigns: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useDemographyOptions = ({ defaultValue }) => {
  const key = `/portal_api/v1/campaign_options`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    demography: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useCampaignPostByTalentList = ({
  defaultValue,
  page = "1",
  campaignId,
  talentId,
}) => {
  const queryObject = {
    page,
    per_page: 20,
  };
  const queryValues = queryStringParseFuntion(queryObject);

  const key = `/portal_api/v1/campaigns/${campaignId}/talents/${talentId}/applicable_posts${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    posts: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useCampaign = (campaignId) => {
  const key = `/portal_api/v1/campaigns/${campaignId}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    campaign: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useCampaignByPost = (campaignId) => {
  const key = `/portal_api/v1/campaigns_posts/${campaignId}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    campaign: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
