import React from "react";
import Header from "../../../../../components/Header";
import { navigate } from "@reach/router";
import _ from "lodash";

import { CurrentUser } from "../../../../../lib/network/auth/Auth";
const AllTalentHeader = ({ talentName, talentId, active }) => {
  const userRole = CurrentUser.getRole();

  const breadcrumbsData = [
    {
      to: `/tma/${userRole}/talents/`,
      name: "Talents",
    },

    {
      to: "",
      name: talentName,
      current: true,
    },
  ];

  const tabsData = [
    {
      id: "brands",
      path: `/tma/${userRole}/talents/${talentId}/brands`,
      icon: () => {
        return null;
      },
      name: "brands",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "posts",
      path: `/tma/${userRole}/talents/${talentId}/posts/regular-posts`,
      icon: () => {
        return null;
      },
      name: "posts",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "media-bank",
      path: `/tma/${userRole}/talents/${talentId}/media-bank`,
      icon: () => {
        return null;
      },
      name: "Monetizable Media Bank",
      get current() {
        return active === this.id ? true : false;
      },
    },

    {
      id: "reports",
      path: `/tma/${userRole}/talents/${talentId}/reports`,
      icon: () => {
        return null;
      },
      name: "reports",
      get current() {
        return active === this.id ? true : false;
      },
    },
  ];

  return (
    <Header
      tabsData={tabsData}
      breadcrumbsData={breadcrumbsData}
      talentName={talentName}
      primaryCta="Back to talents"
      primaryOnClick={() => navigate(`/tma/${userRole}/talents`)}
    />
  );
};

export default AllTalentHeader;
