import {
  BriefcaseIcon,
  ViewGridAddIcon,
  ClockIcon,
  CloudUploadIcon,
  CogIcon,
  DatabaseIcon,
  ArrowDownIcon,
  ExclamationIcon,
  ExclamationCircleIcon,
  FlagIcon,
  GlobeIcon,
  PencilAltIcon,
  PhotographIcon,
  ArrowUpIcon,
  UserIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
  LightBulbIcon,
  UserAddIcon,
  ClipboardCheckIcon,
  CollectionIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/solid";

export const ADMIN_NAVIGATON = [
  {
    name: "Categories",
    icon: ViewGridAddIcon,
    current: false,
    subMenuPath: "/admin/categories/:submenu",
    children: [
      {
        name: "Talent Categories",
        icon: UserIcon,
        href: "/admin/categories/talent-categories",
      },
      {
        name: "Brand Categories",
        icon: GlobeIcon,
        href: "/admin/categories/brand-categories",
      },
    ],
  },
  { name: "Brands", icon: GlobeIcon, href: "/admin/brands", current: false },
  {
    name: "Talent Management Agencies",
    icon: BriefcaseIcon,
    href: "/admin/talent-management-agencies",
    current: false,
  },
  {
    name: "Talents",
    icon: UserIcon,
    current: false,
    subMenuPath: "/admin/talents/:submenu",
    children: [
      {
        name: "All Talents",
        icon: UserIcon,
        href: "/admin/talents/all-talents",
      },
      {
        name: "Guest Feed Talents",
        icon: UserCircleIcon,
        href: "/admin/talents/guest-feed-talents",
      },
    ],
  },
  {
    name: "Posts",
    icon: PhotographIcon,
    href: "/admin/posts",
    current: false,
  },
  {
    name: "Quiz Questions",
    icon: LightBulbIcon,
    href: "/admin/quiz-questions/talent-categories",
    current: false,
  },
  { name: "Fans", icon: UserGroupIcon, href: "/admin/fans", current: false },
  { name: "Team", icon: UserAddIcon, href: "/admin/team", current: false },
  {
    name: "Advertisers",
    icon: UsersIcon,
    href: "/admin/advertisers",
    current: false,
  },
  {
    name: "Campaigns",
    icon: FlagIcon,
    subMenuPath: "/admin/campaigns/:submenu",
    current: false,
    children: [
      {
        name: "Active Campaigns",
        icon: ArrowUpIcon,
        href: "/admin/campaigns/active-campaigns",
      },
      {
        name: "Completed Campaigns",
        icon: ArrowDownIcon,
        href: "/admin/campaigns/completed-campaigns",
      },
      {
        name: "Scheduled Campaigns",
        icon: ClockIcon,
        href: "/admin/campaigns/scheduled-campaigns",
      },
      {
        name: "Draft Campaigns",
        icon: PencilAltIcon,
        href: "/admin/campaigns/draft-campaigns",
      },
      {
        name: "Unapproved Campaigns",
        icon: CollectionIcon,
        href: "/admin/campaigns/unapproved-campaigns",
      },
    ],
  },
  {
    name: "Reports",
    icon: ExclamationIcon,
    href: "/admin/reports",
    current: false,
  },
  {
    name: "Configuration",
    icon: CogIcon,
    current: false,
    subMenuPath: "/admin/configurations/:submenu",
    children: [
      {
        name: "Credit Model",
        icon: DatabaseIcon,
        href: "/admin/configurations/credit-model",
      },
      {
        name: "Blacklisted Keywords",
        icon: ExclamationCircleIcon,
        href: "/admin/configurations/blacklisted-keywords",
      },
      {
        name: "Export Data",
        icon: CloudUploadIcon,
        href: "/admin/configurations/export-data",
      },
      {
        name: "FAQs",
        icon: QuestionMarkCircleIcon,
        href: "/admin/configurations/faq",
      },

      {
        name: "Terms & Conditions",
        icon: ShieldExclamationIcon,
        href: "/admin/configurations/terms-and-conditions",
      },
      {
        name: "Privacy Policy",
        icon: ShieldCheckIcon,
        href: "/admin/configurations/privacy-policy",
      },
    ],
  },
];

export const SALES_NAVIGATION = [
  {
    name: "Categories",
    icon: ViewGridAddIcon,
    current: false,
    subMenuPath: "/sales/categories/:submenu",
    children: [
      {
        name: "Talent Categories",
        icon: UserIcon,
        href: "/sales/categories/talent-categories",
      },
      {
        name: "Brand Categories",
        icon: GlobeIcon,
        href: "/sales/categories/brand-categories",
      },
    ],
  },
  { name: "Brands", icon: GlobeIcon, href: "/sales/brands", current: false },
  {
    name: "Talents",
    icon: UserIcon,
    current: false,
    subMenuPath: "/sales/talents/:submenu",
    children: [
      {
        name: "All Talents",
        icon: UserIcon,
        href: "/sales/talents/all-talents",
      },
      {
        name: "Guest Feed Talents",
        icon: UserCircleIcon,
        href: "/sales/talents/guest-feed-talents",
      },
    ],
  },
  {
    name: "Advertisers",
    icon: UsersIcon,
    href: "/sales/advertisers",
    current: false,
  },
  {
    name: "Campaigns",
    icon: FlagIcon,
    subMenuPath: "/sales/campaigns/:submenu",
    current: false,
    children: [
      {
        name: "Active Campaigns",
        icon: ArrowUpIcon,
        href: "/sales/campaigns/active-campaigns",
      },
      {
        name: "Completed Campaigns",
        icon: ArrowDownIcon,
        href: "/sales/campaigns/completed-campaigns",
      },
      {
        name: "Scheduled Campaigns",
        icon: ClockIcon,
        href: "/sales/campaigns/scheduled-campaigns",
      },
    ],
  },
  {
    name: "Configuration",
    icon: CogIcon,
    current: false,
    subMenuPath: "/sales/configurations/:submenu",
    children: [
      {
        name: "Export Data",
        icon: CloudUploadIcon,
        href: "/sales/configurations/export-data",
      },
    ],
  },
];

export const TMA_NAVIGATION = [
  {
    name: "Managers",
    icon: UserGroupIcon,
    href: "/tma/admin/managers",
    current: false,
  },
  {
    name: "Talents",
    icon: UserIcon,
    href: "/tma/admin/talents",
    current: false,
  },
  {
    name: "Campaign Approvals",
    icon: ClipboardCheckIcon,
    href: "/tma/admin/campaigns/approve-campaigns",
    current: false,
  },
];

export const TMA_MANAGER_NAVIGATION = [
  {
    name: "Talents",
    icon: UserIcon,
    href: "/tma/manager/talents",
    current: false,
  },
  {
    name: "Campaign Approvals",
    icon: ClipboardCheckIcon,
    href: "/tma/manager/campaigns/approve-campaigns",
    current: false,
  },
];

export const ADVERTISER_NAVIGATION = [
  {
    name: "Campaigns",
    icon: FlagIcon,
    href: "/advertiser/campaigns",
    current: false,
  },
];

export const TALENT_NAVIGATION = [
  {
    name: "Talent Info",
    icon: UserIcon,
    href: "/talent/brands",
    current: false,
  },
  {
    name: "Campaign Approvals",
    icon: ClipboardCheckIcon,
    href: "/talent/campaigns/approve-campaigns",
    current: false,
  },
];
