import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import Button from "../../stories/Button";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import { GuestFeedTalentValidationSchema } from "../validation";
import FormFields from "../FormFields";
import { useAllTalentList } from "../../lib/services/AllTalentsService";

const GuestFeedTalentForm = ({ setToggle, initialValues, formButtonCta }) => {
  const buttonRef = useRef(null);
  const [searchTalent, setSearchTalent] = useState("");

  const { allTalents, isValidating } = useAllTalentList(searchTalent, {});

  const formValues = [
    {
      label: "Name *",
      attributeId: "talentId",
      placeholder: "Select Talent",
      type: "Search",
      displayKey: "attributes.fanAccount.attributes.name",
      items: allTalents,
      onChange: setSearchTalent,
      isValidating: isValidating,
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    const apiParams = {
      data: { attributes: values },
    };
    const endpoint = "/portal_api/v1/guest_feeds";

    const { error } = await API.post(endpoint, apiParams);
    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success("Talent Added Successfully");
      setToggle(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={GuestFeedTalentValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="space-y-7">
          <FormFields
            values={values}
            formValues={formValues}
            setFieldValue={setFieldValue}
          />
          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="w-full py-2"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default GuestFeedTalentForm;
