import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import Button from "../../stories/Button";

import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import { useBrandsList } from "../../lib/services/BrandService";
import { AdvertiserValidationSchema } from "../validation";
import FormFields from "../FormFields";

const AdvertiserForm = ({
  setToggle,
  initialValues,
  editMode,
  formButtonCta,
}) => {
  const buttonRef = useRef(null);
  const [searchBrand, setSearchBrand] = useState("");
  //brands Data
  const { brands: brandsData, isValidating: isValidatingBrand } = useBrandsList(
    searchBrand,
    {
      defaultValue: initialValues.brands,
    }
  );

  const formValues = [
    {
      label: "First Name *",
      attributeId: "firstName",
      placeholder: "Niharika",
      type: "TextInput",
    },
    {
      label: "Last Name *",
      attributeId: "lastName",
      placeholder: "Sabarwal",
      type: "TextInput",
    },
    {
      label: "Email *",
      attributeId: "email",
      placeholder: "xyz@example.com",
      type: "TextInput",
    },
    {
      label: "Advertiser Type *",
      attributeId: "advertiserType",
      placeholder: "Select Advertiser type",
      type: "DropDown",
      options: [
        { attributes: { advertiserType: "brand owner" } },
        { attributes: { advertiserType: "agency" } },
      ],
      displayKey: "advertiserType",
    },
    {
      label: "Select Brand *",
      attributeId: "brandId",
      placeholder: "Select Brand",
      type: "Multiselect",
      displayKey: "attributes.name",
      items: brandsData,
      valueKey: "id",
      onChange: setSearchBrand,
      isValidating: isValidatingBrand,
    },

    {
      label: "Status :",
      attributeId: "status",
      type: "Toggle",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    const fetchedValues = {
      ..._.pick(values, ["firstName", "lastName", "email", "status", "role"]),
      advertiserType:
        values?.advertiserType === "brand owner"
          ? "brand_owner"
          : values?.advertiserType,
      advertisersBrandsAttributes: values?.brandId,
    };
    const apiParams = {
      data: { attributes: fetchedValues },
    };

    const endpoint = `/portal_api/v1/advertisers/${
      editMode ? initialValues.id : ""
    }`;

    const { error } = editMode
      ? await API.put(endpoint, apiParams)
      : await API.post(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(
        `Advertiser ${editMode ? "Updated" : "Created"} Successfully`
      );
      setToggle(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AdvertiserValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="flex flex-col h-screen">
          {/* json Form component */}
          <div className=" sticky pl-2 pr-4 -mr-5 pb-10 overflow-y-auto h-3/4 ">
            <FormFields
              setFieldValue={setFieldValue}
              values={values}
              formValues={formValues}
            />
          </div>

          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="mt-4 w-full sticky bottom-2 z-50"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AdvertiserForm;
