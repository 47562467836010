import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";

export const useQuizQuestionList = (
  searchBrand,
  {
    defaultValue,
    page = "1",
    categoryId = null,
    questionType = null,
    filterByTalentId = null,
  }
) => {
  const queryObject = {
    page,
    query: searchBrand,
    per_page: 20,
    filterByTalentCategoryId: categoryId,
    filterByQuestionType: questionType,
    filterByTalentId,
  };
  const queryValues = queryStringParseFuntion(queryObject);

  const key = `/portal_api/v1/quiz_questions${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});
  return {
    quiz: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useQuiz = (quizId) => {
  const key = quizId ? `/portal_api/v1/quizzes/${quizId}` : null;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    quiz: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
