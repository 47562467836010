import React from "react";
import Header from "../../../../../components/Header";
import _ from "lodash";

const AllTalentHeader = ({ talentName, talentId, active }) => {
  const breadcrumbsData = [
    {
      to: "",
      name: talentName,
      current: true,
    },
    {
      to: "",
      name: "Talent Info",
    },
  ];

  const tabsData = [
    {
      id: "brands",
      path: `/talent/brands`,
      icon: () => {
        return null;
      },
      name: "brands",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "posts",
      path: `/talent/posts/regular-posts`,
      icon: () => {
        return null;
      },
      name: "posts",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "media-bank",
      path: `/talent/media-bank`,
      icon: () => {
        return null;
      },
      name: "Monetizable Media Bank",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "reports",
      path: `/talent/reports`,
      icon: () => {
        return null;
      },
      name: "reports",
      get current() {
        return active === this.id ? true : false;
      },
    },
  ];

  return (
    <Header
      tabsData={tabsData}
      breadcrumbsData={breadcrumbsData}
      talentName={talentName}
    />
  );
};

export default AllTalentHeader;
