import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import Table from "../../../components/Table";
import SlideOver from "../../../stories/SlideOver";
import { useGuestFeedTalentList } from "../../../lib/services/GuestFeedTalentService";
import moment from "moment";
import Author from "../../../stories/Author";
import { ActionDeleteButton } from "../../../table-components/Buttons";
import SearchBar from "../../../components/SearchBar";
import _ from "lodash";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import TableLayout from "../../../components/TableLayout";
import { ModalWrapper } from "../../../utils/Modal";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../utils/Helper";
import GuestFeedTalentForm from "../../../forms/pageForms/GuestFeedTalentForm";
import { CurrentUser } from "../../../lib/network/auth/Auth";
const GuestFeedTalents = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);
  const [toggle, setToggle] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);
  const [modalTalentId, setModalTalentId] = useState("");

  //to manage delete transition of headlessUI
  useEffect(() => {
    if (!modalTalentId) {
      setModalTalentId(deleteId);
    } else {
      setTimeout(() => {
        setModalTalentId(deleteId);
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDeleteId, deleteId]);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const deleteGuestFeed = async () => {
    if (deleteId) {
      setModalPrimaryCtaLoading(true);
      const { error } = await API.delete(
        `/portal_api/v1/guest_feeds/${deleteId}`,
        {}
      );
      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${
            error?.status ?? null
          }: Something went Wrong. Please try again`
        );
      } else {
        toast.success("Talent Deleted Successfully");
        setDeleteId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const addInitialValues = {
    talentId: "",
  };

  const {
    guestFeedTalents,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useGuestFeedTalentList(pageNumberQuery, searchParamsQuery);

  const columns = [
    {
      field: "name",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm flex flex-row  items-center mr-4">
          <Author
            name={
              rowData?.attributes?.talent?.attributes?.fanAccount?.attributes
                ?.name
            }
            imageSrc={
              rowData?.attributes?.talent?.attributes?.fanAccount?.attributes
                ?.image
            }
            cta={`@${rowData?.attributes?.talent?.attributes?.fanAccount?.attributes?.username}`}
            size="lg"
            className="cursor-default"
          />
        </div>
      ),
    },
    {
      field: "talent management agency",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {
            rowData?.attributes?.talent?.attributes?.talentManagement
              ?.attributes?.name
          }
        </div>
      ),
    },
    {
      field: "location",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 ">
          {
            rowData?.attributes?.talent?.attributes?.fanAccount?.attributes
              ?.location
          }
        </div>
      ),
    },
    {
      field: "added on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {moment(`${rowData?.attributes?.createdAt}`).format("ll")}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              setDeleteId(rowData.id);
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(guestFeedTalents)) {
      return (
        <LoadContentHelper
          title="Guest Feed Talent"
          ctaOnClick={() => setToggle(true)}
          data={guestFeedTalents}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    if (CurrentUser.isFanshipAdmin()) {
      return <Table column={columns} data={guestFeedTalents} />;
    } else {
      return <Table column={_.initial(columns)} data={guestFeedTalents} />;
    }
  };

  useEffect(() => {
    if (!toggle || !deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, deleteId]);

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deleteGuestFeed,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };
  return (
    <Layout>
      <ModalWrapper
        title={`${
          _.find(guestFeedTalents, {
            id: modalTalentId,
          })?.attributes?.talent?.attributes?.name ?? "Guest Feed Talent"
        } `}
        desc={`Are you sure you want to remove ${
          _.find(guestFeedTalents, {
            id: modalTalentId,
          })?.attributes?.talent?.attributes?.name ?? "this talent"
        } from showing in guest feed ?`}
        config={modalConfig}
      />

      <SlideOver
        open={toggle}
        backgroundOverlay="dark"
        size="xl"
        title={"Add Guest Feed Talent"}
        dismissButtonOnClick={() => {
          setToggle(false);
        }}
      >
        <GuestFeedTalentForm
          setToggle={setToggle}
          initialValues={addInitialValues}
          formButtonCta="Add Guest Feed Talent"
        />
      </SlideOver>
      <div className="sticky top-0 z-50">
        <SectionHeading
          title="Guest Feed Talents"
          primaryCta={
            CurrentUser.isFanshipAdmin() ? "Add Guest Feed Talent" : null
          }
          primaryOnClick={() => {
            setToggle(true);
          }}
        />
      </div>
      {searchParamsQuery || !_.isEmpty(guestFeedTalents) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Guest Feed Talent List"}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
          hidePaginationInfo={_.isEmpty(guestFeedTalents)}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default GuestFeedTalents;
