import React, { useEffect } from "react";
import { useLocation, Link } from "@reach/router";
import queryString from "query-string";
import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import Table from "../../components/Table";
import { usePostList } from "../../lib/services/PostService";
import Author from "../../stories/Author";
import SearchBar from "../../components/SearchBar";
import _ from "lodash";
import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import TableLayout from "../../components/TableLayout";
import { navigateSelf } from "../../utils/Helper";
const Posts = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const columns = [
    {
      field: "name",
      render: (rowData) => (
        <Link to={`${rowData?.id}/regular-posts`}>
          <div className="text-left whitespace-nowrap text-sm flex flex-row  items-center mr-4">
            <Author
              name={rowData?.attributes?.fanAccount?.attributes?.name ?? "-NA"}
              imageSrc={rowData?.attributes?.fanAccount?.attributes?.image}
              cta={`@${rowData?.attributes?.fanAccount?.attributes?.username}`}
              size="lg"
            />
          </div>
        </Link>
      ),
    },
    {
      field: "regular posts",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.regularPostsCount}
        </div>
      ),
    },
    {
      field: "monetizable post",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.monetizablePostsCount}
        </div>
      ),
    },
    {
      field: "quizzes",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.quizzesCount}
        </div>
      ),
    },
    {
      field: "total engagement",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.totalEngagement}
        </div>
      ),
    },
    {
      field: "total views",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.totalImpressions}
        </div>
      ),
    },
  ];

  const {
    posts,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = usePostList(pageNumberQuery, searchParamsQuery);

  const loadContent = () => {
    if (_.isEmpty(posts)) {
      return (
        <LoadContentHelper
          title="Post"
          data={posts}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    return <Table column={columns} data={posts} />;
  };

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  return (
    <Layout>
      <div className="sticky top-0 z-50">
        <SectionHeading title="Posts" />
      </div>
      {searchParamsQuery || !_.isEmpty(posts) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Post List"
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default Posts;
