import React, { useState } from "react";
import Loader from "../components/Loader";
import classNames from "classnames";

const Posts = ({ posts, selectedPostId, setSelectedPostId }) => {
  const [loading, setLoading] = useState(true);
  const showLoader = () => {
    return (
      <div className="flex justify-center items-center h-96">
        <Loader title={`Fetching Posts ...`} />
      </div>
    );
  };
  return (
    <div className="bg-white p-2 w-full">
      <div className={loading ? "block" : "hidden"}>{showLoader()}</div>
      <div
        className={classNames(!loading ? "block" : "hidden", "flex flex-wrap")}
      >
        {posts?.map((item, idx) => (
          <div key={idx} className="w-1/4 p-2 relative object-cover">
            {item?.attributes?.containsVideo ? (
              <>
                <div className=" ring-2 ring-white rounded-md bg-gray-900  grid place-items-center hover:bg-gray-800 cursor-pointer transition">
                  {item?.attributes?.previewImage?.thumbnail?.url && (
                    <img
                      onClick={() => setSelectedPostId(parseInt(item?.id))}
                      onLoad={() => setLoading(false)}
                      className="w-full "
                      src={item?.attributes?.previewImage?.thumbnail?.url}
                      alt="video Post"
                    />
                  )}
                  <svg
                    class="ml-1 w-4 absolute "
                    viewBox="0 0 16 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </>
            ) : (
              <img
                className="w-full"
                src={item?.attributes?.previewImage?.gallery?.url}
                onClick={() => setSelectedPostId(parseInt(item?.id))}
                onLoad={() => setLoading(false)}
                alt="Post"
              />
            )}
            {selectedPostId === parseInt(item?.id) ? (
              <img
                className="absolute top-3 right-3 h-6 w-6"
                src="/assets/images/icon/White Check circle.png"
                alt="Checked"
              />
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Posts;
