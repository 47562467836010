import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";

const GroupAvatar = ({ size, group, imageShape = "round-full", ...rest }) => {
  const sizeStyle = () => {
    switch (size) {
      case "xs":
        return "h-6 w-6 ";
      case "sm":
        return "h-8 w-8 ";
      case "md":
        return "h-10 w-10 ";
      case "xl":
        return "h-16 w-16 ";
      default:
        return "";
    }
  };

  const imageStyle = () => {
    switch (imageShape) {
      case "round-sm":
        return "rounded-sm ring-2 ring-white";
      case "round-md":
        return "rounded-md ring-2 ring-white";
      case "round-lg":
        return "rounded-lg ring-2 ring-white";
      case "round-none":
        return "rounded-none ring-2 ring-white ";
      case "round-full":
        return "rounded-full ring-2 ring-white ";
      default:
        return "";
    }
  };

  const spaceStyle = size === "xs" ? "-space-x-1 " : "-space-x-2";

  return (
    <div>
      <div className={classNames("flex", spaceStyle, "overflow-hidden")}>
        {group.map((item, idx) => (
          <img
            key={idx}
            className={classNames("inline-block", sizeStyle(), imageStyle())}
            src={item?.attributes?.attachmentVersions?.gallery?.url}
            alt="post"
          />
        ))}
      </div>
    </div>
  );
};

GroupAvatar.defaultProps = {
  size: "sm",
  bottomToTop: true,
};

GroupAvatar.propTypes = {
  /**
   * Size
   */
  size: PropTypes.oneOf(["xs", "sm", "md", "xl"]),

  /**
   * Bottom To Top
   */
  bottomToTop: PropTypes.bool,

  /**
   * Avatar Data
   */
  group: PropTypes.array,
};

export default GroupAvatar;
