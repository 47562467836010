import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { PencilIcon, PhotographIcon } from "@heroicons/react/solid";
import { dataURLtoFile } from "../../utils/Helper";
import axios from "axios";
import Crop from "./Crop";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";

const UploadImage = ({
  iconClass,
  sizeLabel,
  instructionText,
  h,
  w,
  cropHeight,
  cropWidth,
  cropBoxResizable,
  onvalueChange,
  imageUrl,
  imageClassName,
  aspectRatio,
  mediaStatus,
}) => {
  const [loading, setLoading] = useState(false);
  const [cropData, setCropData] = useState("");
  const [open, setOpen] = useState(false);
  const [preCropData, setPreCropData] = useState("");
  const [fileType, setFileType] = useState("");
  const [progress, setProgress] = useState(0);

  const onDropAccepted = (acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      setPreCropData(reader.result);
    };
    setOpen(!open);
    setFileType(acceptedFiles[0].type);
    reader.readAsDataURL(acceptedFiles[0]);
  };

  // Use Effect when image get cropped
  useEffect(() => {
    if (cropData) {
      const fileExt = cropData.split(";")[0].split("/")[1];
      const fileName = `${Date.now() + (fileExt ? "." + fileExt : ".png")}`;

      const file = dataURLtoFile(cropData, fileName);
      handleChange(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropData]);

  //Function triggers when image change event happen
  const handleChange = async (file) => {
    setLoading(true);
    //signed url
    const { data: urlData, error: urlError } = await API.post(
      "/portal_api/v1/signed_url",
      {
        key: `${file.name ?? "postImg.png"}`,
      }
    );
    if (urlError) {
      toast.error(urlError.message);
      setLoading(false);
    } else {
      const url = urlData.data.url;
      uploadToAws(url, file);
    }
  };

  //  Function for uploading the file to aws

  const uploadToAws = (url, file) => {
    var instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];

    instance
      .put(url, file, {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      })

      .then((_) => {
        const uploadedUrl = url?.split("?")[0];
        setLoading(false);
        onvalueChange(uploadedUrl);
        setOpen(false);
      })
      .catch((err) => {
        toast.error("Image Upload fail.Try Again...");
        setOpen(false);
        setLoading(false);
      });
  };

  const onDropRejected = (rejectedFiles) => {
    if (rejectedFiles[0].file.size > 15000000) {
      toast.error("Image size cannot be greater than 15MB");
    } else {
      toast.error("Invalid image format");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: "image/jpeg, image/png, image/jpg",
    disabled: loading,
    maxSize: 5000000,
  });
  return (
    <>
      <Crop
        open={open}
        handleClose={() => setOpen(false)}
        setCroppedData={setCropData}
        preCropImage={preCropData}
        {...{
          cropHeight,
          cropWidth,
          cropBoxResizable,
          loading,
          progress,
          aspectRatio,
        }}
      />
      <div
        className="w-full h-full flex items-center flex-1"
        {...getRootProps()}
      >
        {mediaStatus === "draft" && (
          <input className="outline-none" {...getInputProps()} />
        )}
        {loading ? (
          <div className="flex flex-col items-center w-full">Loading...</div>
        ) : (
          <>
            {imageUrl ? (
              <div className="h-full w-full cursor-pointer overflow-visible relative">
                <img
                  src={imageUrl}
                  className={`h-full w-full ${
                    imageClassName ? imageClassName : ""
                  }`}
                  alt="img"
                />
                {mediaStatus === "draft" && (
                  <div className="absolute" style={{ top: -10, right: -10 }}>
                    <div className="w-6 h-6 bg-black rounded-full flex justify-center items-center cursor-pointer">
                      <PencilIcon className="bg-gray-900 text-white rounded-full" />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col items-center w-full">
                <PhotographIcon className={iconClass} {...{ h, w }} />
                <div className="text-gray-900">{instructionText}</div>
                <div className="text-gray-900 mt-3 text-center">
                  {sizeLabel}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

UploadImage.defaultProps = {
  iconClass: "w-10 h-10 mb-4",
  instructionText: "Click to add picture",
  sizeLabel: "(320 x 250)",
  cropHeight: 150,
  cropWidth: 150,
  cropBoxResizable: false,
};

export default UploadImage;
