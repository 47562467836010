import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import FormFields from "../../FormFields";
import FormHeader from "../../../components/FormHeader";
import { targetDemographyValidationSchema } from "../../validation";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import { useDemographyOptions } from "../../../lib/services/CampaignService";

const TargetDemographyForm = ({
  initialValues,
  campaignId,
  formNumber,
  setFormNumber,
  editing,
  campaignFormNumber,
  hasReachedForm,
}) => {
  const [editMode, setEditMode] = useState(
    editing && hasReachedForm(campaignFormNumber.TARGET_DEMOGRAPHY_FORM)
      ? false
      : true
  );
  const [showCancel, setShowCancel] = useState(
    editing && hasReachedForm(campaignFormNumber.TARGET_DEMOGRAPHY_FORM)
      ? true
      : false
  );
  const [formData, setFormData] = useState(
    editing ? { ...initialValues, location: initialValues.locationName } : []
  );
  const buttonRef = useRef(null);

  const { demography, isValidating } = useDemographyOptions({
    defaultValue: initialValues.location,
  });

  const ageGroups = () => {
    return demography?.age_groups?.map((item, idx) => {
      return { id: idx, ageGroup: item };
    });
  };

  const locations = demography?.demographic_locations;

  const formValues = [
    {
      type: "Row",
      formValues: [
        {
          label: "Location *",
          attributeId: "location",
          type: "Multiselect",
          placeholder: "Select Location",
          displayKey: "name",
          valueKey: "id",
          items: locations,
          isValidating: isValidating,
        },
        {
          label: "Age Groups *",
          attributeId: "ageGroups",
          type: "Multiselect",
          placeholder: "Select Age Group",
          displayKey: "ageGroup",
          valueKey: "ageGroup",
          items: ageGroups(),
          isValidating: isValidating,
        },
        {
          label: "Gender *",
          attributeId: "gender",
          placeholder: "Select Gender",
          type: "DropDown",
          options: [
            { attributes: { gender: "male" } },
            { attributes: { gender: "female" } },
            { attributes: { gender: "any" } },
          ],
          displayKey: "gender",
        },
      ],
    },
  ];

  const onEdit = (e) => {
    e.preventDefault();
    setEditMode(true);
  };
  const onCancel = () => {
    setEditMode(false);
  };
  const getLocationName = (id) => {
    const obj = _.find(locations || [], (obj) => {
      return obj.id === id;
    });
    return obj?.name;
  };

  const getDisplayValue = (items) => {
    return items.map((item) => {
      return { location: getLocationName(item.location) };
    });
  };

  const getAttributes = (items, key, asArrayOfObject) => {
    return items.map((item) => {
      return asArrayOfObject ? { id: item[key] } : item[key];
    });
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const displayData = {
      ..._.pick(values, ["ageGroups", "gender"]),
      location: getDisplayValue(values.location),
    };

    const fetchedValues = {
      locationsAttributes: getAttributes(values.location, "location", true),
      ageGroups: getAttributes(values?.ageGroups, "ageGroups", false),
      gender: values.gender === "any" ? "any_gender" : values.gender,
    };

    const apiParams = {
      data: { attributes: fetchedValues },
    };

    const endpoint = `/portal_api/v1/campaigns/${campaignId}`;

    const { error } = await API.put(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went wrong. Please try again"}`
      );
    } else {
      toast.success(`Target demography saved successfully`);
      setEditMode(false);
      setFormData(displayData);
      setFormNumber(
        hasReachedForm(campaignFormNumber.TARGET_DEMOGRAPHY_FORM)
          ? formNumber
          : campaignFormNumber.TARGET_DEMOGRAPHY_FORM
      );
      setShowCancel(true);
    }
  };

  return (
    <div className="rounded-md bg-white px-2 pb-6 border-2 border-gray-300 ">
      <Formik
        initialValues={initialValues}
        validationSchema={targetDemographyValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <>
            <Form className="flex flex-col justify-between">
              <FormHeader
                sectionHeading="Target Demography"
                cancelOnClick={onCancel}
                editOnClick={onEdit}
                editMode={editMode}
                isSubmitting={isSubmitting}
                buttonRef={buttonRef}
                showCancel={showCancel}
              />
              <div className="px-2">
                <FormFields
                  setFieldValue={setFieldValue}
                  values={values}
                  formValues={formValues}
                  editMode={editMode}
                  formData={formData}
                />
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default TargetDemographyForm;
