import React, { useState, useContext } from "react";
import _ from "lodash";
import Button from "../stories/Button";
import { toast } from "../components/Toast";
import CampaignContext from "../lib/store/campaignContext";
import TalentList from "./TalentList";
import PostSectionContainer from "./PostSectionContainer";
import { Formik, Form } from "formik";
import FormFields from "../forms/FormFields";
import { priceInputValidationSchema } from "../forms/validation";

const CampaignModalData = ({
  setModal,
  arrayHelpers,
  fieldValue,
  selectedTalent,
  setSelectedTalent,
  selectedPostId,
  setSelectedPostId,
  selectedPostBudget,
  setSelectedPostBudget,
}) => {
  const campaignData = useContext(CampaignContext);
  const [formData, setFormData] = useState(selectedPostBudget);

  const formValues = [
    {
      label: "Post Budget *",
      attributeId: "budget",
      type: "PriceInput",
      message: "( Minimum budget is ₹ 11000.00 )",
    },
  ];

  const selectPost = (values) => {
    if (selectedPostId) {
      if (fieldValue.some((post, idx) => post.talentId === selectedTalent)) {
        const idx = _.findIndex(fieldValue, (post) => {
          return post.talentId === selectedTalent;
        });
        arrayHelpers.replace(idx, {
          talentId: selectedTalent,
          postId: selectedPostId,
          budget: values.budget,
        });
      } else {
        arrayHelpers.push({
          talentId: selectedTalent,
          postId: selectedPostId,
          budget: values.budget,
        });
      }
      setModal(false);
    } else {
      toast.error("Please select media to add");
    }
  };

  return (
    <div className="relative h-full -mx-4 -mt-5">
      <div className=" h-3/4  w-full flex">
        <div className="h-full w-2/5 ">
          <TalentList
            brandCategoryId={campaignData?.brand_category?.id}
            setSelectedTalent={setSelectedTalent}
            selectedTalent={selectedTalent}
            setSelectedPostId={setSelectedPostId}
          />
        </div>
        <div className="w-3/4">
          <PostSectionContainer
            brandCategory={campaignData?.brand_category?.attributes?.name}
            selectedTalent={selectedTalent}
            selectedPostId={selectedPostId}
            setSelectedPostId={setSelectedPostId}
          />
        </div>
      </div>

      <div>
        <Formik
          initialValues={selectedPostBudget}
          validationSchema={priceInputValidationSchema}
          onSubmit={selectPost}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="sticky w-1/3 py-16 my-2 px-4 ">
                <FormFields
                  setFieldValue={setFieldValue}
                  values={values}
                  formValues={formValues}
                  editMode={true}
                  formData={formData}
                />
              </div>
              <div className=" absolute  -bottom-1.5 right-0 flex flex-row-reverse px-8 ">
                <Button size="lg" type="submit">
                  Add
                </Button>
                <Button
                  size="lg"
                  buttonStyle="white"
                  type="button"
                  className="mr-5"
                  onClick={() => setModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CampaignModalData;
