import React from "react";
import { useField, FieldArray } from "formik";
import TextInput from "./TextInput";
import Label from "./Label";
import Dropdown from "./Dropdown";

const AdvertiserInput = ({
  name,
  label1,
  label2,
  label3,
  placeholder1,
  placeholder2,
  placeholder3,
  editMode,
  advertiserData,
  ...props
}) => {
  const [field] = useField({ name, ...props });
  return (
    <div className="w-full px-3">
      <div className="flex">
        <Label className="w-1/3 ">{label1}</Label>
        <Label className="px-3 w-1/3">{label2}</Label>
        <Label className="px-3">{label3}</Label>
      </div>
      {editMode ? (
        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <div className="flex flex-col ">
              {field?.value?.length > 0 &&
                field?.value?.map((item, index) => (
                  <div className="flex w-full mb-4" key={index}>
                    <div className="w-1/3 pr-3">
                      <TextInput
                        name={`${name}[${index}].name`}
                        type="text"
                        placeholder={
                          placeholder1 ?? "Enter Advertiser Fullname"
                        }
                      />
                    </div>
                    <div className="w-1/3 px-3">
                      <TextInput
                        name={`${name}[${index}].email`}
                        type="text"
                        placeholder={placeholder2 ?? "Enter Advertiser email"}
                      />
                    </div>
                    <div className="w-1/3 px-3">
                      <Dropdown
                        name={`${name}[${index}].advertiserType`}
                        placeholder={placeholder3 ?? "Select advertiser type"}
                        options={[
                          { attributes: { advertiserType: "brand owner" } },
                          { attributes: { advertiserType: "agency" } },
                        ]}
                        displayKey="advertiserType"
                      />
                    </div>

                    {field?.value?.length > 1 ? (
                      <div
                        className="w-/3 cursor-pointer text-sm font-medium text-red-600  mt-3"
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      >
                        Remove
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              <div className=" text-sm font-medium text-gray-700 mt-2">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    arrayHelpers.push({
                      name: "",
                      email: "",
                      advertiserType: "",
                    });
                  }}
                >
                  + Add More Advertiser
                </span>
              </div>
            </div>
          )}
        />
      ) : (
        <>
          {advertiserData?.map((item, idx) => (
            <div className="flex" key={idx}>
              <div className="w-1/3 pr-3 capitalize">{item?.name}</div>
              <div className="w-1/3 px-3">{item.email}</div>
              <div className="px-3 capitalize">{item.advertiserType}</div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default AdvertiserInput;
