import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import { useLocation } from "@reach/router";
import TextInput from "../../components/TextInput";
import Button from "../../stories/Button";
import Label from "../../components/Label";
import _ from "lodash";

import { passwordResetValidationSchema } from "../../forms/validation";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";

export default function ForgotPassword() {
  const initialValues = { email: "" };
  const buttonRef = useRef(null);
  const [emailSent, setEmailSent] = useState(false);
  const location = useLocation();
  const portal = !!location?.state?.portal
    ? location?.state?.portal
    : location.hostname.split(/[.\-_]/)[0];

  const onSubmit = async (values) => {
    const apiEndpoint = () => {
      switch (portal) {
        case "admin":
          return "/portal_api/v1/forgot_password";
        case "tma":
          return "/portal_api/v1/talent_managements/forgot_password";
        case "advertiser":
          return "/portal_api/v1/advertisers/forgot_password";
        case "talent":
          return "/portal_api/v1/talents/forgot_password";
        default:
          return null;
      }
    };
    const { error } = await API.post(apiEndpoint(), {
      data: { attributes: values },
    });

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );

      buttonRef.current.shake();
    } else {
      setEmailSent(true);
      toast.success("Email sent");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={passwordResetValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <div className="min-h-screen bg-gray-100 flex flex-col py-12 sm:px-6 lg:px-8 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md px-16 mt-24">
            <img
              className="mx-auto h-12 w-auto mt-1"
              src="/assets/images/logo/logo.svg"
              alt="Workflow"
            />
            {!emailSent && (
              <>
                <div className="mx-3">
                  <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Trouble Logging In
                  </h2>
                  <h5 className="text-sm text-center leading-5 text-gray-500 mt-2.5">
                    Enter your email and we’ll send you a link to reset your
                    password
                  </h5>
                </div>
              </>
            )}
          </div>
          {emailSent && (
            <div className="mt-16 max-w-xl mx-auto">
              <h2 className="mt-6 text-center text-xl sm:text-2xl font-semibold text-gray-900">
                We have sent you a mail on your Email address with the Reset
                Password Link.
              </h2>
            </div>
          )}

          {!emailSent && (
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <Form className="space-y-7">
                  <div>
                    <Label labelFor="email">Email Address</Label>
                    <TextInput
                      name="email"
                      id="email"
                      type="email"
                      placeholder="joe@fanship.com"
                    />
                  </div>

                  <Button
                    ref={buttonRef}
                    type="submit"
                    className="w-full"
                    loading={isSubmitting}
                  >
                    Send Reset Password Link
                  </Button>
                </Form>
              </div>
            </div>
          )}
        </div>
      )}
    </Formik>
  );
}
