import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { MailIcon, PhoneIcon } from "@heroicons/react/solid";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import _ from "lodash";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import SlideOver from "../../../stories/SlideOver";
import { useManagerList } from "../../../lib/services/tma/ManagerService";
import Table from "../../../components/Table";
import TmaManagerForm from "../../../forms/pageForms/talentAgencyForms/tmaPortal/TmaManagerForm";
import moment from "moment";
import {
  ActionDeleteButton,
  ActionEditButton,
} from "../../../table-components/Buttons";

import SearchBar from "../../../components/SearchBar";
import { ModalWrapper } from "../../../utils/Modal";

import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";

import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import TableLayout from "../../../components/TableLayout";
import { navigateSelf } from "../../../utils/Helper";
import { CurrentUser } from "../../../lib/network/auth/Auth";
const TmaManagers = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const [deleteId, setDeleteId] = useState(false);

  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const addInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    countryCode: "",
    status: "disabled",
    talents: "",
    talentId: "",
  };

  const deleteAgency = async () => {
    setModalPrimaryCtaLoading(true);
    if (deleteId) {
      // eslint-disable-next-line no-unused-vars
      const { data, error } = await API.delete(
        `/portal_api/v1/talent_management_members/${deleteId}`,
        {}
      );
      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ??
            "This talent manager cannot be deleted. Please try again"
          }`
        );
        setModalPrimaryCtaLoading(false);
      } else {
        toast.success("Manager Deleted Successfully");
        setModalPrimaryCtaLoading(false);
        setDeleteId(false);
      }
    } else {
      setModalPrimaryCtaLoading(false);
      toast.error("Something went Wrong. Please try again");
    }
  };

  const {
    agency,
    loading,
    error,
    mutate,
    isValidating,
    paginationInfo,
  } = useManagerList(searchParamsQuery, { page: pageNumberQuery });

  const getValue = (item) => item["id"];
  const setFieldValue = (values) => {
    return values.map((item) => {
      return { talentId: getValue(item) };
    });
  };

  const total_pages = Number(paginationInfo?.totalPages);

  const onAction = (action, data) => {
    if (action === "delete") {
      setDeleteId(data.id);
    } else if (action === "edit") {
      // setEditData(data);
      setEditData({
        ...data,
        countryCode: data?.countryCode ?? "91",
        phone: (data?.countryCode ?? "91").concat(data?.phone),
        talentId: _.isEmpty(data.talents) ? [] : setFieldValue(data?.talents),
      });
    }
  };

  const columns = [
    {
      field: "manager",
      render: (rowData) => (
        <div className="flex flex-col">
          <div className="  text-md font-medium text-gray-900">
            {rowData?.attributes?.firstName
              ? `${rowData.attributes?.firstName} ${
                  rowData.attributes?.lastName
                    ? rowData.attributes?.lastName
                    : null
                }`
              : "-NA-"}
          </div>
          {rowData?.attributes?.phone && (
            <div className="   text-sm text-gray-500 flex flex-row items-center space-x-1">
              {rowData?.attributes?.email ? (
                <a href={`mailto:${rowData?.attributes?.email}`}>
                  <MailIcon className="w-4 h-4" />
                </a>
              ) : null}
              <div>{rowData?.attributes?.email}</div>
            </div>
          )}
          {rowData?.attributes?.phone && (
            <div className="  text-sm text-gray-500 flex flex-row items-center space-x-1">
              <a
                href={`tel:${
                  rowData?.attributes?.countryCode
                    ? `+${rowData?.attributes?.countryCode}-`
                    : ""
                }${rowData?.attributes?.phone}`}
              >
                <PhoneIcon className="w-4 h-4" />
              </a>
              <p>
                {rowData?.attributes?.countryCode
                  ? `+${rowData?.attributes?.countryCode}-`
                  : null}
                {rowData?.attributes?.phone ?? "-NA-"}
              </p>
            </div>
          )}
        </div>
      ),
    },
    {
      field: "Talents",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm  text-gray-500 capitalize">
          {_.isEmpty(rowData?.attributes?.talents)
            ? "-na-"
            : rowData?.attributes?.talents?.map((item, idx) => {
                return <div key={idx}>{item?.attributes?.name}</div>;
              })}
        </div>
      ),
    },
    {
      field: "status",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 uppercase">
          {rowData?.attributes?.status}
        </div>
      ),
    },
    {
      field: "added on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {moment(`${rowData?.attributes?.createdAt}`).format("ll")}
        </div>
      ),
    },
    {
      field: "actions",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionEditButton
            iconOnly
            onClick={() => {
              onAction("edit", {
                ...rowData?.attributes,
                talents: _.isEmpty(rowData?.attributes?.talents)
                  ? []
                  : rowData?.attributes?.talents,
                id: rowData.id,
              });
            }}
          />
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              onAction("delete", { id: rowData.id });
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(agency)) {
      return (
        <LoadContentHelper
          title="Manager"
          ctaOnClick={() => setToggle(true)}
          data={agency}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    if (CurrentUser.isAdmin()) {
      return <Table column={columns} data={agency} />;
    } else {
      return <Table column={_.initial(columns)} data={agency} />;
    }
  };

  useEffect(() => {
    if (!toggle || !editData || !deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData, deleteId]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deleteAgency,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper title="Manager" config={modalConfig} />

      <SlideOver
        open={toggle || !!editData}
        backgroundOverlay="dark"
        size="xl"
        title={`${toggle ? "Add" : "Edit"} Manager`}
        dismissButtonOnClick={() => {
          setToggle(false);
          setEditData(false);
        }}
      >
        {/* Talent Management Agency Manager form  */}
        <TmaManagerForm
          setToggle={editData ? setEditData : setToggle}
          initialValues={editData ? editData : addInitialValues}
          formButtonCta={`${toggle ? "Create" : "Update"} Manager`}
          editMode={!!editData}
        />
      </SlideOver>
      <div className="sticky top-0 z-50">
        <SectionHeading
          title="Managers"
          primaryCta={CurrentUser.isAdmin() ? "Add Manager" : null}
          primaryOnClick={() => setToggle(true)}
        />
      </div>
      {searchParamsQuery || !_.isEmpty(agency) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Manager List"}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default TmaManagers;
