import React from "react";
import toaster from "toasted-notes";
import {
  XIcon,
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";

/**
 * Primary UI component for user interaction
 */

const IconType = ({ type }) => {
  const commonClass =
    "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10 ";
  switch (type) {
    case "warning":
    case "error":
      return (
        <div className={classNames(commonClass, "bg-red-100 text-red-400")}>
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
      );
    case "success":
      return (
        <div className={classNames(commonClass, "bg-green-100 text-green-400")}>
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
      );
    default:
      return (
        <div
          className={classNames(
            commonClass,
            " bg-primary bg-opacity-25 text-primary"
          )}
        >
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
      );
  }
};

const showToast = (type = "info", title, options = {}) => {
  const { subtitle = null, position = "top-right", duration = 2000 } = options;

  toaster.notify(
    ({ onClose }) => (
      <div className="bg-white shadow-md overflow-hidden border border-gray-200 sm:rounded-lg m-4">
        <div className="relative">
          <div className="flex flex-row p-4 space-x-3 text-left">
            <IconType type={type} />
            <div
              className={classNames(
                "flex flex-col w-auto pr-4",
                subtitle ? "justify-start" : "justify-center"
              )}
            >
              <span className="font-medium text-gray-700">{title}</span>
              {subtitle && (
                <span className="text-xs text-gray-500 font-light">
                  {subtitle}
                </span>
              )}
            </div>
            <div>
              <button
                onClick={onClose}
                className="text-gray-400 transform outline-none focus:outline-none flex-shrink-0"
              >
                <XIcon className="w-5 h-5 text-gray-800" />
              </button>
            </div>
          </div>
        </div>
      </div>
    ),
    { position, duration }
  );
};

export const toast = {
  error: (title, options) => showToast("error", title, options),
  info: (title, options) => showToast("info", title, options),
  success: (title, options) => showToast("success", title, options),
};
