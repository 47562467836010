import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import FormFields from "../../FormFields";
import FormHeader from "../../../components/FormHeader";
import { dateandTimeValidationSchema } from "../../validation";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import moment from "moment";

const DateTimeForm = ({
  initialValues,
  campaignId,
  formNumber,
  setFormNumber,
  editing,
  campaignFormNumber,
  hasReachedForm,
}) => {
  const [editMode, setEditMode] = useState(
    editing && hasReachedForm(campaignFormNumber.CAMPAIGN_DATE_TIME_FORM)
      ? false
      : true
  );
  const [formData, setFormData] = useState(editing ? initialValues : []);
  const [showCancel, setShowCancel] = useState(
    editing && hasReachedForm(campaignFormNumber.CAMPAIGN_DATE_TIME_FORM)
      ? true
      : false
  );
  const buttonRef = useRef(null);

  const formValues = [
    {
      type: "Row",
      formValues: [
        {
          label: "Start Date *",
          attributeId: "startTime",
          type: "DatePicker",
          includeTime: true,
        },
      ],
    },
    {
      type: "Note",
      message:
        "Field below is optional, leave it empty if you want the campaign to run until your budget/impressions is reached.",
    },
    {
      type: "Row",
      formValues: [
        {
          label: "End Date",
          attributeId: "endTime",
          type: "DatePicker",
          includeTime: true,
        },
      ],
    },
  ];

  const onEdit = (e) => {
    e.preventDefault();
    setEditMode(true);
  };
  const onCancel = () => {
    setEditMode(false);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const displayData = {
      startTime: moment(values.startTime).unix() ?? "",
      endTime: moment(values.endTime).unix() ?? "",
    };

    const apiParams = {
      data: { attributes: displayData },
    };

    const endpoint = `/portal_api/v1/campaigns/${campaignId}`;

    const { data, error } = await API.put(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went wrong. Please try again"}`
      );
    } else {
      setEditMode(false);
      setFormData({
        ...displayData,
        startTime: moment(data?.data?.attributes?.startTime * 1000).format(
          "lll"
        ),
        endTime: data?.data?.attributes?.endTime
          ? moment(data?.data?.attributes?.endTime * 1000).format("lll")
          : null,
      });
      setFormNumber(
        hasReachedForm(campaignFormNumber.CAMPAIGN_DATE_TIME_FORM)
          ? formNumber
          : campaignFormNumber.CAMPAIGN_DATE_TIME_FORM
      );
      setShowCancel(true);
    }
  };

  return (
    <div className="rounded-md bg-white px-2 pb-6 border-2 border-gray-300 ">
      <Formik
        initialValues={initialValues}
        validationSchema={dateandTimeValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <>
            <Form className="flex flex-col justify-between">
              <FormHeader
                sectionHeading="Campaign Schedule"
                cancelOnClick={onCancel}
                editOnClick={onEdit}
                editMode={editMode}
                isSubmitting={isSubmitting}
                buttonRef={buttonRef}
                showCancel={showCancel}
              />
              <div className="px-2">
                <FormFields
                  setFieldValue={setFieldValue}
                  values={values}
                  formValues={formValues}
                  editMode={editMode}
                  formData={formData}
                />
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default DateTimeForm;
