import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import classNames from "classnames";
import { Link } from "@reach/router";

const Tabs = ({ type, fullWidth, showIcon, showBadges, tabs }) => {
  const selectStyle = showBadges
    ? "pl-3 pr-10 py-2 text-base focus:outline-none sm:text-sm"
    : "";

  const navContainerStyle =
    type === "underline" ? "border-b border-gray-200" : "";

  const navStyle = () => {
    switch (type) {
      case "underline":
        return fullWidth ? "-mb-px" : "-mb-px space-x-8";
      case "pill":
        return "space-x-4";
      case "button":
        return " relative z-0 rounded-lg divide-x divide-gray-200";
      default:
        return "";
    }
  };

  const anchorStyle = () => {
    switch (type) {
      case "underline":
        return fullWidth
          ? "w-1/4 py-4 px-1 text-center border-b-2"
          : showIcon
          ? "group inline-flex items-center py-4 px-1 border-b-2"
          : "whitespace-nowrap py-4 px-1 border-b-2";
      case "pill":
        return "px-3 py-2 rounded-md";
      case "button":
        return "group relative min-w-0 overflow-hidden bg-white py-4 px-8 text-center hover:bg-gray-50 focus:z-10";
      default:
        return "whitespace-nowrap py-4 px-1 border-b-2";
    }
  };

  const activeTabStyle = () => {
    switch (type) {
      case "pill":
        return "bg-gray-300 text-gray-700";
      case "button":
        return "text-gray-900";
      default:
        return "border-gray-500 text-gray-600";
    }
  };

  const inactiveTabStyle =
    type === "underline"
      ? "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
      : "text-gray-500 hover:text-gray-700";

  const iconStyle = showIcon ? "block" : "hidden";

  const bottomBorderStyle = type === "button" ? "block" : "hidden";

  return (
    <div>
      <div className="sm:hidden ">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className={classNames(
            "block w-full",
            selectStyle,
            " focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
          )}
          defaultValue={tabs?.find((tab) => tab?.current)?.name}
        >
          {tabs?.map((tab) => (
            <option key={tab?.name}>{tab?.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block px-8">
        <div className={navContainerStyle}>
          <nav className={classNames("flex ", navStyle())} aria-label="Tabs">
            {tabs.map((tab, tabIdx) => (
              <Link
                to={tab.path}
                key={tab.name}
                className={classNames(
                  tab.current ? activeTabStyle() : inactiveTabStyle,
                  tabIdx === 0 && type === "button" ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 && type === "button"
                    ? "rounded-r-lg"
                    : "",
                  anchorStyle(),
                  "font-medium text-sm capitalize"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.current
                      ? "text-gray-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5",
                    iconStyle
                  )}
                  aria-hidden="true"
                />

                <span>{tab.name}</span>

                {tab.count && showBadges ? (
                  <span
                    className={classNames(
                      tab.current
                        ? "bg-gray-100 text-gray-600"
                        : "bg-gray-100 text-gray-900",
                      "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}

                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.current ? "bg-gray-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5",
                    bottomBorderStyle
                  )}
                />
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  type: "underline",
  fullWidth: false,
  showIcon: false,
  showBadges: false,
};

Tabs.propTypes = {
  /**
   * Type
   */
  type: PropTypes.oneOf(["underline", "pill", "button"]),

  /**
   * Width
   */
  fullWidth: PropTypes.bool,

  /**
   * Icon
   */
  showIcon: PropTypes.bool,

  /**
   * Badges
   */
  showBadges: PropTypes.bool,

  /**
   * Tab Data
   */
  tabs: PropTypes.array,
};

export default Tabs;
