import React, { useState, useEffect } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { ErrorMessage, useField, useFormikContext } from "formik";
import _ from "lodash";

const MentionInput = ({
  name,
  options,
  onChange = () => {},
  displayKey,
  valueKey,
  searchTerm,
  height = "8rem",
  setReplyingTo = () => {},
  ...props
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ name, ...props });
  const [isFocused, setFocused] = useState(false);

  const color = (error, touched) => {
    return isFocused && !error
      ? "rgba(23, 23, 23, 1)"
      : touched && error
      ? "rgba(220, 38, 38, 1)"
      : "rgba(212, 212, 212, 1)";
  };

  const defaultStyle = {
    width: "100%",
    "&multiLine": {
      height: height,
      control: {
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
      },

      highlighter: {
        boxSizing: "border-box",
        overflow: "hidden",
        borderRadius: "0.375rem",
      },

      input: {
        overflow: "auto",
        padding: "0.5rem 0.75rem",
        borderRadius: "0.375rem",
        borderWidth: isFocused ? "2px" : "1px",
        borderColor: color(meta.error, meta.touched),
        boxShadow: "none",
        backgroundColor: "white",
      },
    },

    suggestions: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      borderRadius: "0.375rem",
      width: "20rem",
      margin: "1.5rem",
      boxShadow: `0 0 0 1px rgba(212, 212, 212, 1)`,
      maxHeight: "15rem",
      overflow: "auto",

      item: {
        padding: "0.5rem 1rem",
        borderRadius: "0.375rem",
        "&focused": { backgroundColor: "rgba(243, 244, 246, 1)" },
      },
    },
  };

  const getDisplayValue = (item) =>
    displayKey ? _.get(item, displayKey) : item["name"] || item["title"];
  const getValue = (item) => (valueKey ? _.get(item, valueKey) : item["id"]);

  const getData = () => {
    return !_.isEmpty(options)
      ? options.map((item) => ({
          display: getDisplayValue(item),
          id: getValue(item),
        }))
      : [];
  };

  const [optionsData, setOptionsData] = useState(getData());

  useEffect(() => {
    setOptionsData(getData());
  }, [searchTerm, options]);

  useEffect(() => {
    if (field?.value.length === 0) {
      setReplyingTo(false);
    }
  }, [field.value]);

  return (
    <>
      <div className="mt-1 relative w-full">
        <MentionsInput
          value={field.value}
          onChange={(e) => setFieldValue(name, e.target.value)}
          style={defaultStyle}
          onBlur={() => {
            setFieldTouched(name);
            setFocused(false);
          }}
          onFocus={() => setFocused(true)}
          allowSuggestionsAboveCursor
        >
          <Mention
            trigger="@"
            markup="@__display__ "
            data={(value, callback) => {
              onChange(value.length > 1 ? value : "");
              return optionsData;
            }}
            displayTransform={(id, value) => {
              return `@${value}`;
            }}
          />
        </MentionsInput>
      </div>
      <ErrorMessage
        name={name}
        component="span"
        className="text-red-700 text-sm absolute"
      ></ErrorMessage>
    </>
  );
};

export default MentionInput;
