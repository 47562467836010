import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import Button from "../../../stories/Button";

import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import AwsImageService from "../../../lib/services/AwsImageService";
import { useBrandList } from "../../../lib/services/BrandService";

import { BrandValidationSchema } from "../../validation";
import FormFields from "../../FormFields";

const BrandForm = ({ setToggle, initialValues, editMode, formButtonCta }) => {
  const buttonRef = useRef(null);

  const [searchBrandCategory, setSearchBrandCategory] = useState("");

  //brand category List
  const {
    brands: brandCategoriesData,
    isValidating: isValidatingBrandCategory,
  } = useBrandList(searchBrandCategory, {
    defaultValue: initialValues.brandCategories,
  });

  const formValues = [
    {
      label: "Brand Logo",
      attributeId: "image",
      type: "ImageInput",
      imgPlaceholder: "/assets/images/placeholder/genericPlaceholder.svg",
    },
    {
      label: "Brand Name *",
      attributeId: "name",
      placeholder: "Audi",
      type: "TextInput",
    },
    {
      label: "Brand Category *",
      attributeId: "brandCategoryId",
      placeholder: "Select Brand Category ",
      type: "Multiselect",
      displayKey: "attributes.name",
      items: brandCategoriesData,
      valueKey: "id",
      onChange: setSearchBrandCategory,
      isValidating: isValidatingBrandCategory,
    },
    {
      label: "Status : ",
      attributeId: "status",
      type: "Toggle",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    const fetchedValues = {
      ..._.pick(values, ["name", "status"]),
      image: values?.image?.data
        ? await AwsImageService(values)
        : initialValues.image,
      brandsBrandCategoriesAttributes: values?.brandCategoryId,
    };

    const apiParams = {
      data: { attributes: fetchedValues },
    };
    const endpoint = `/portal_api/v1/brands/${
      editMode ? initialValues.id : ""
    }`;

    const { error } = editMode
      ? await API.put(endpoint, apiParams)
      : await API.post(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(`Brand ${editMode ? "Updated" : "Created"} Successfully`);
      setToggle(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={BrandValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="space-y-7">
          {/* json Form component */}
          <FormFields
            setFieldValue={setFieldValue}
            values={values}
            formValues={formValues}
          />

          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="w-full"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default BrandForm;
