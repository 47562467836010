import React, { useState } from "react";
import InfoContainer from "./InfoContainer";
import Author from "../stories/Author";
import Loader from "./Loader";
import classNames from "classnames";
import _ from "lodash";

const QuizPostDetail = ({ post, talent }) => {
  const [loading, setLoading] = useState(true);

  const showLoader = () => {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader title={`Loading Post ...`} />
      </div>
    );
  };
  const quizAttributes = [
    {
      label: "Number of Fans attempted",
      value: post?.attributes?.quiz?.attributes?.quizInteractionsCount,
    },
    {
      label: "Number of Fans answered correct",
      value: post?.attributes?.quiz?.attributes?.wonCount,
    },
    {
      label: "Number of Fans answered wrong",
      value: post?.attributes?.quiz?.attributes?.lostCount,
    },
  ];
  return (
    <div className="h-full flex rounded-md">
      <div className="w-2/3">
        <div className="h-full rounded-l-md flex justify-center bg-white">
          <div className={loading ? "block" : "hidden"}>{showLoader()}</div>
          <video
            className={classNames(
              !loading ? "block" : "hidden",
              "w-full object-contain"
            )}
            onLoadedData={() => setLoading(false)}
            controls
          >
            <source
              src={
                _.find(post?.attributes?.attachments, [
                  "attributes.purpose",
                  "quiz_question",
                ])?.attributes?.key
              }
            />
          </video>
        </div>
      </div>
      <div className="w-1/3 bg-gray-50 rounded-r-md">
        <div className="border-b-2 px-8 py-4">
          <Author
            name={talent?.attributes?.name}
            imageSrc={talent?.attributes?.image}
            size="lg"
          />
        </div>
        <div className="p-8">
          {quizAttributes?.map((item, idx) => (
            <div key={idx}>
              <InfoContainer
                label={item.label}
                value={item.value}
                price={item.price}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuizPostDetail;
