import { useAPI } from "../../network/SWR";
import { queryStringParseFuntion } from "../../../utils/Helper";

export const useTalentList = (
  searchTerm,
  { defaultValue, page = "1", all }
) => {
  const queryObject = { page, query: searchTerm, per_page: 20, all };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/talent_managements/talents${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});
  return {
    talents: data?.data || defaultValue || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
