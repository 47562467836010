import React, { useState, useEffect } from "react";
import { useField, useFormikContext, ErrorMessage } from "formik";
import Autocomplete from "react-autocomplete";
import classNames from "classnames";
import Loader from "./Loader";
import _ from "lodash";

const Search = ({
  name,
  placeholder,
  items,
  onChange,
  displayKey,
  valueKey,
  localSearch,
  isValidating,
  setSelectedId = () => {},
  selectedBrandCategoryId,
  checkAssociation,
  changeIdFlag = false,
  displayTag = false,
  ...props
}) => {
  const getDisplayValue = (item) =>
    displayKey ? _.get(item, displayKey) : item["name"] || item["title"];
  const getValue = (item) => (valueKey ? _.get(item, valueKey) : item["id"]);
  const [edited, setEdited] = useState(false);

  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name, ...props });
  const defaultItem = _.find(items || [], (obj) => {
    return getValue(obj) === field?.value;
  });
  const [searchTerm, setSearchTerm] = useState(
    field?.value ? getDisplayValue(defaultItem) : ""
  );
  const menu = (options) => {
    if (options) {
      return (
        <div
          className="z-40 origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none max-h-96 overflow-y-auto cursor-default"
          children={
            options.length === 0 ? (
              <h1 className="block px-4 py-2 text-sm">No data ...</h1>
            ) : (
              options
            )
          }
        />
      );
    }
  };

  const menuItem = (item, highlighted) => {
    return (
      <div
        key={item.id}
        className={classNames(
          highlighted ? "bg-gray-100 text-gray-900" : "text-gray-700",
          "block px-4 py-2 text-sm"
        )}
      >
        {displayTag ? (
          <div className="flex justify-between">
            <div>{getDisplayValue(item)}</div>
            <div className="inline-flex items-center h-6  px-2 rounded text-sm border border-gray-300 bg-gray-200 text-gray-800 capitalize">
              {item?.attributes?.category}
            </div>
          </div>
        ) : (
          <div>{getDisplayValue(item)}</div>
        )}
      </div>
    );
  };

  const onInputChange = (e) => {
    onChange(e.target.value.length > 1 ? e.target.value : "");
    setEdited(true);
    setSearchTerm(e.target.value);
    setFieldValue(name, "");
  };

  // This is only for Associate Brand in Create campaign
  useEffect(() => {
    if (checkAssociation && field.name === "associateBrandId") {
      setFieldValue(field.name, "");
      setSearchTerm("");
    }
    // eslint-disable-next-line
  }, [checkAssociation, selectedBrandCategoryId]);

  return (
    <div>
      <div className="relative mt-1 text-left w-full">
        <Autocomplete
          items={
            localSearch && edited
              ? items.filter((item) =>
                  _.lowerCase(getDisplayValue(item)).includes(
                    _.lowerCase(searchTerm)
                  )
                )
              : !_.isEmpty(items)
              ? items
              : []
          }
          renderMenu={(item) => menu(item)}
          getItemValue={(item) => getDisplayValue(item)}
          renderItem={(item, highlighted) => menuItem(item, highlighted)}
          wrapperProps={{
            className: "w-full",
          }}
          value={searchTerm}
          onChange={onInputChange}
          onSelect={(value, item) => {
            setSearchTerm(value);
            setFieldValue(field.name, getValue(item));
            setSelectedId(getValue(item));
            if (changeIdFlag) {
              onChange(item);
            } else {
              onChange("");
            }
          }}
          inputProps={{
            className: classNames(
              "inline-flex justify-center px-4 py-2 border shadow-sm focus:ring-gray-900 focus:border-gray-900 block w-full sm:text-sm border-gray-300 rounded-md",
              meta.touched &&
                meta.error &&
                "border border-red-600 focus:ring-red-600 focus:border-red-600"
            ),
            placeholder: placeholder,
          }}
        />
        {isValidating && (
          <div className="absolute right-0 top-2 h- w-6">
            <Loader />
          </div>
        )}
      </div>
      <ErrorMessage
        name={name}
        component="span"
        className="text-red-700 text-sm absolute"
      ></ErrorMessage>
    </div>
  );
};

export default Search;
