import React, { useState, useEffect, useContext } from "react";
import { useField, FieldArray, useFormikContext } from "formik";
import TalentPost from "./TalentPost";
import CampaignModal from "./CampaignModal";
import { useTalentsByBrandCategory } from "../lib/services/TalentService";
import AvatarInfo from "./AvatarInfo";
import CampaignContext from "../lib/store/campaignContext";

const PostInput = ({
  name,
  editMode,
  matchData,
  selectedPost,
  adBannerData,
  ...props
}) => {
  const campaignData = useContext(CampaignContext);
  const { talents } = useTalentsByBrandCategory("", {
    brandCategoryId: campaignData?.brand_category?.id,
  });
  const [selectedTalent, setSelectedTalent] = useState("");
  const [selectedPostId, setSelectedPostId] = useState("");
  const [selectedPostBudget, setSelectedPostBudget] = useState({ budget: "" });
  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name, ...props });
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setFieldValue(field.name, selectedPost);
    // eslint-disable-next-line
  }, [matchData, selectedPost]);

  return (
    <div className="-my-2 -mx-1">
      <h4 className="mx-3 block text-sm font-medium text-gray-700">Post *</h4>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <div className="flex flex-wrap">
            {field?.value?.length > 0 &&
              field?.value?.map((item, index) => (
                <TalentPost
                  editMode={editMode}
                  talentId={item?.talentId}
                  postId={item?.postId}
                  postBudget={item?.budget}
                  setModal={setModal}
                  key={index}
                  setSelectedTalent={setSelectedTalent}
                  setSelectedPostId={setSelectedPostId}
                  setSelectedPostBudget={setSelectedPostBudget}
                  adBannerData={adBannerData}
                  removePost={() => arrayHelpers.remove(index)}
                />
              ))}
            {editMode ? (
              <div className="w-3/5 md:w-80 xl:w-96 px-3 pt-3 pb-6">
                <div
                  className="h-full border-dashed rounded-md border-2 py-52 flex justify-center items-center cursor-pointer"
                  onClick={() => {
                    setSelectedTalent(parseInt(talents[0]?.id));
                    setSelectedPostId("");
                    setSelectedPostBudget({ budget: "" });
                    setModal(true);
                  }}
                >
                  <AvatarInfo
                    name="Add Media"
                    secondaryField="Add Media to campaign"
                    imageSrc="/assets/images/placeholder/userPlaceholder.svg"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <CampaignModal
              modal={modal}
              setModal={setModal}
              arrayHelpers={arrayHelpers}
              fieldValue={field.value || []}
              type="post"
              selectedTalent={selectedTalent}
              setSelectedTalent={setSelectedTalent}
              selectedPostId={selectedPostId}
              setSelectedPostId={setSelectedPostId}
              selectedPostBudget={selectedPostBudget}
              setSelectedPostBudget={setSelectedPostBudget}
            />
          </div>
        )}
      />
    </div>
  );
};

export default PostInput;
