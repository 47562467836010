import React from "react";
import TextInput from "../components/TextInput";
import TextArea from "../components/TextArea";
import ImageInput from "../components/ImageInput";
import CheckBox from "../components/CheckBox";
import Label from "../components/Label";
import Toggle from "../components/Toggle";
import Search from "../components/Search";
import PhoneInput from "../components/PhoneInput";
import Dropdown from "../components/Dropdown";
import Datepicker from "../components/Datepicker";
import PriceInput from "../components/PriceInput";
import Multiselect from "../components/Multiselect";
import Timepicker from "../components/Timepicker";
import Image from "../components/Image";
import AdvertiserInput from "../components/AdvertiserInput";
import moment from "moment";
import PostInput from "../components/PostInput";
import InfoContainer from "../components/InfoContainer";
import MentionInput from "../components/MentionsInput";
import TextEditorInput from "../components/textEditor/TextEditorInput";
import classNames from "classnames";
import _ from "lodash";
import parse from "html-react-parser";
const FormFields = ({
  formValues,
  values,
  setFieldValue,
  editMode = true,
  formData,
  className,
  innerContainer,
  customInnerRowStyle = null,
  ...rest
}) => {
  const findValue = (attributeId) => {
    return formData[attributeId];
  };

  return (
    <div className={className ? className : "space-y-7"} {...rest}>
      {formValues?.map((formValue, fieldIdx) => (
        <div className={innerContainer} key={`${fieldIdx}-${formValue.name}`}>
          {(() => {
            switch (formValue.type) {
              case "ImageInput":
                return (
                  <>
                    <div className="flex flex-col items-center py-2">
                      <Label className="pb-2" labelFor={formValue.attributeId}>
                        {formValue.label}
                      </Label>
                      {editMode ? (
                        <ImageInput
                          setFieldValue={setFieldValue}
                          imageData={values}
                          imgPlaceholder={formValue.imgPlaceholder}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                );
              case "Image":
                return (
                  <div>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                      <span className="ml-1 text-gray-400 text-xs">
                        {formValue.message}
                      </span>
                    </Label>
                    {editMode ? (
                      <Image
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        placeholder={formValue.placeholder}
                        imageData={values}
                        setFieldValue={setFieldValue}
                        setImageData={formValue.setAdBannerData}
                      />
                    ) : (
                      <>
                        {findValue(formValue.attributeId) ? (
                          <img
                            className="h-6 w-full border-0"
                            src={
                              findValue(formValue.attributeId)?.data ||
                              findValue(formValue.attributeId)
                            }
                            alt="Ad Banner"
                          />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                );
              case "TextInput":
                return (
                  <div>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                    </Label>

                    {editMode ? (
                      <TextInput
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        type={formValue.inputType || "text"}
                        placeholder={formValue.placeholder}
                        disable={formValue?.disable}
                      />
                    ) : (
                      <div> {findValue(formValue?.attributeId)}</div>
                    )}
                  </div>
                );
              case "AdvertiserInput":
                return (
                  <AdvertiserInput
                    name={formValue.attributeId}
                    label1={formValue.label1}
                    label2={formValue.label2}
                    label3={formValue.label3}
                    editMode={editMode}
                    advertiserData={formData[formValue.attributeId]}
                  />
                );
              case "PostInput":
                return (
                  <PostInput
                    name={formValue.attributeId}
                    editMode={editMode}
                    selectedPost={formValue.selectedPost}
                    matchData={formValue.matchData}
                    adBannerData={formValue.adBannerData}
                    budgetData={formValue.budgetData}
                  />
                );
              case "Note":
                return (
                  <>
                    {editMode ? (
                      <div className="mx-3 text-gray-400 text-sm font-medium">
                        {formValue.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              case "TextArea":
                return (
                  <>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                    </Label>
                    {editMode ? (
                      <TextArea
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        placeholder={formValue.placeholder}
                        size={formValue.size}
                      />
                    ) : (
                      ""
                    )}
                  </>
                );
              case "Multiselect":
                return (
                  <>
                    <Label
                      className={classNames(
                        formValue?.customDisplay ? "px-3" : ""
                      )}
                      labelFor={formValue.attributeId}
                    >
                      {formValue.label}
                    </Label>
                    {editMode ? (
                      <Multiselect
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        placeholder={formValue.placeholder}
                        valueKey={formValue?.valueKey}
                        items={formValue.items}
                        displayKey={formValue.displayKey}
                        localSearch={formValue.localSearch}
                        onChange={formValue.onChange}
                        isValidating={formValue.isValidating}
                        customDisplay={formValue?.customDisplay}
                      />
                    ) : (
                      <div
                        className={classNames(
                          formValue?.customDisplay ? "px-3 " : ""
                        )}
                      >
                        {formValue?.customDisplay ? (
                          <ul className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ">
                            {findValue(formValue?.attributeId)?.map(
                              (item, idx) => {
                                return (
                                  <li className="my-2">
                                    <div className="flex flex-row  items-center capitalize">
                                      {_.chain([
                                        item?.attributes?.firstName,
                                        item?.attributes?.lastName,
                                      ])
                                        .compact()
                                        .join(" ")
                                        .value()}
                                      {item?.attributes?.advertiserType && (
                                        <div className="text-xs  mx-1.5 px-1 py-0.5 bg-gray-500 rounded-lg border border-gray-500 text-gray-100 capitalize ">
                                          {item?.attributes?.advertiserType ===
                                          "brand_owner"
                                            ? "brand owner"
                                            : item?.attributes?.advertiserType}
                                        </div>
                                      )}
                                    </div>
                                    <div className="normal-case ">
                                      {item?.attributes?.email}
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        ) : (
                          findValue(formValue?.attributeId)?.map(
                            (item, idx) => {
                              const key = formValue.attributeId;
                              return <div key={idx}>{item[key]}</div>;
                            }
                          )
                        )}
                      </div>
                    )}
                  </>
                );
              case "Row":
                return (
                  <FormFields
                    setFieldValue={setFieldValue}
                    values={values}
                    formValues={formValue.formValues}
                    className="flex mb-3 w-full"
                    innerContainer={
                      customInnerRowStyle ? customInnerRowStyle : "w-1/3 px-3"
                    }
                    editMode={editMode}
                    formData={formData}
                  />
                );
              case "CheckBox":
                return (
                  <>
                    <div className="flex flex-row gap-2">
                      <CheckBox
                        name={formValue.attributeId}
                        id={formValue.label}
                      />
                      <Label labelFor={formValue.attributeId}>
                        {formValue.label}
                      </Label>
                    </div>
                  </>
                );
              case "Toggle":
                return (
                  <>
                    <div className="flex flex-row space-x-2">
                      <Label labelFor={formValue.attributeId}>
                        {formValue.label}
                      </Label>
                      <Toggle
                        setFieldValue={setFieldValue}
                        toggleData={values}
                        id={formValue.attributeId}
                        name={formValue.attributeId}
                      />
                    </div>
                  </>
                );
              case "Search":
                return (
                  <div>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                    </Label>
                    {editMode ? (
                      <Search
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        placeholder={formValue.placeholder}
                        type="text"
                        valueKey={formValue?.valueKey}
                        items={formValue.items}
                        displayKey={formValue.displayKey}
                        localSearch={formValue.localSearch}
                        onChange={formValue.onChange}
                        isValidating={formValue.isValidating}
                        setSelectedId={formValue.setSelectedId}
                        checkAssociation={formValue.checkAssociation}
                        selectedBrandCategoryId={
                          formValue.selectedBrandCategoryId
                        }
                        changeIdFlag={formValue.changeIdFlag}
                        displayTag={formValue?.displayTag}
                      />
                    ) : (
                      <div> {findValue(formValue?.attributeId)}</div>
                    )}
                  </div>
                );
              case "MentionInput":
                return (
                  <div>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                    </Label>
                    {editMode ? (
                      <MentionInput
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        valueKey={formValue?.valueKey}
                        options={formValue?.options}
                        displayKey={formValue.displayKey}
                        onChange={formValue.onChange}
                        height={formValue.height}
                        setReplyingTo={formValue.setReplyingTo}
                      />
                    ) : (
                      <div> {findValue(formValue?.attributeId)}</div>
                    )}
                  </div>
                );
              case "PhoneInput":
                return (
                  <>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                    </Label>
                    {editMode ? (
                      <PhoneInput
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        countryCode={formValue.countryCode}
                        placeholder={formValue.placeholder}
                      />
                    ) : (
                      ""
                    )}
                  </>
                );

              case "DropDown":
                return (
                  <>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                    </Label>
                    {editMode ? (
                      <Dropdown
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        placeholder={formValue.placeholder}
                        options={formValue.options}
                        displayKey={formValue.displayKey}
                        dropDownData={values}
                        size={formValue.size}
                        setCategoryAttribute={formValue?.setCategoryAttribute}
                        displayCurrentOption={formValue?.displayCurrentOption}
                      />
                    ) : (
                      <div className="capitalize">
                        {findValue(formValue?.attributeId)}
                      </div>
                    )}
                  </>
                );

              case "TextEditorInput":
                return (
                  <>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                    </Label>
                    {editMode ? (
                      <TextEditorInput
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        placeholder={formValue.placeholder}
                        size={formValue.size}
                      />
                    ) : (
                      <div className="px-3 mt-1 relative prose prose-indigo prose-sm  2xl:prose-md max-w-none text-justify ">
                        {" "}
                        {parse(findValue(formValue?.attributeId))}
                      </div>
                    )}
                  </>
                );

              case "DatePicker":
                return (
                  <>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                    </Label>
                    {editMode ? (
                      <Datepicker
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        placeholder={formValue.placeholder}
                        includeTime={formValue.includeTime}
                      />
                    ) : (
                      <div>
                        {findValue(formValue?.attributeId)
                          ? findValue(formValue?.attributeId)
                          : "-NA-"}
                      </div>
                    )}
                  </>
                );

              case "PriceInput":
                return (
                  <>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                      <span className="ml-1 text-gray-400 text-xs">
                        {formValue.message}
                      </span>
                    </Label>
                    {editMode ? (
                      <PriceInput
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        placeholder={formValue.placeholder}
                      />
                    ) : (
                      <div> {findValue(formValue?.attributeId)}</div>
                    )}
                  </>
                );

              case "TimePicker":
                return (
                  <>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                    </Label>
                    {editMode ? (
                      <Timepicker
                        name={formValue.attributeId}
                        id={formValue.attributeId}
                        placeholder={formValue.placeholder}
                      />
                    ) : (
                      <div> {findValue(formValue?.attributeId) || "-NA-"}</div>
                    )}
                  </>
                );
              case "InfoContainer":
                return (
                  <div className="px-6">
                    <InfoContainer
                      label={formValue.label}
                      value={formValue.value}
                      price={formValue.price}
                      darkMode={true}
                    />
                  </div>
                );
              default:
                return (
                  <>
                    <Label labelFor={formValue.attributeId}>
                      {formValue.label}
                    </Label>
                    <TextInput
                      name={formValue.attributeId}
                      id={formValue.attributeId}
                      type="text"
                      placeholder={formValue.placeholder}
                    />
                  </>
                );
            }
          })()}
        </div>
      ))}
    </div>
  );
};

FormFields.defaultProps = {
  //   unique attributeId's
  formValues: [
    {
      label: "image of Brand",
      attributeId: "image",
      type: "ImageInput",
    },
    {
      label: " First Name",
      attributeId: "name",
      placeholder: "Automobiles,Fmcg,Finance...",
      type: "TextInput",
    },
    {
      label: "Last Name",
      attributeId: "name1",
      placeholder: "Automobiles,Fmcg,Finance...",
      type: "TextInput",
    },
    {
      label: "brief Description",
      attributeId: "brief",
      type: "TextArea",
      placeholder: "A small description of the brand category...",
    },
    {
      label: "Enable Category ?",
      attributeId: "status",
      type: "CheckBox",
    },
  ],
};

export default FormFields;
