import React from "react";
import { Link, Match } from "@reach/router";
import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { CurrentUser } from "../../lib/network/auth/Auth";

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: classNames(
          props.className,
          isCurrent
            ? "bg-white text-black"
            : "text-gray-300 hover:bg-gray-700 hover:text-white"
        ),
      };
    }}
  ></Link>
);

const SideNav = () => {
  return (
    <nav className="mt-5 px-2 flex-1 space-y-1">
      {CurrentUser.sideBarNavigation()?.map((item) =>
        !item.children ? (
          <div key={item.name}>
            <NavLink
              to={item.href}
              className="group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md"
            >
              <item.icon
                className="mr-3 flex-shrink-0 h-6 w-6"
                aria-hidden="true"
              />

              {item.name}
            </NavLink>
          </div>
        ) : (
          <Match key={item.name} path={item.subMenuPath}>
            {(props) => {
              return (
                <Disclosure
                  as="div"
                  key={item.name}
                  className="space-y-1"
                  defaultOpen={props.match}
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={classNames(
                          open || props.match
                            ? "bg-gray-200 text-black"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none "
                        )}
                      >
                        <item.icon
                          className={classNames(
                            open || props.match
                              ? "text-gray-800 "
                              : "text-gray-400 group-hover:text-gray-300",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        <span className="flex-1">{item.name}</span>
                        <ChevronRightIcon
                          className={classNames(
                            open || props.match
                              ? "text-gray-600 rotate-90 group-hover:text-gray-900"
                              : "text-gray-300 group-hover:text-gray-400",
                            "ml-3 flex-shrink-0 h-5 w-5 transform transition-colors ease-in-out duration-150"
                          )}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="relative space-y-2">
                        {(open || props.match) && (
                          <div className="absolute top-0 left-5 -ml-px h-full w-0.5 bg-gray-300" />
                        )}
                        {item.children.map((subItem) => (
                          <NavLink
                            key={subItem.name}
                            to={subItem.href}
                            className="group flex items-center ml-10 pl-2 pr-2 py-2 text-sm font-medium relative rounded-md top"
                          >
                            <subItem.icon
                              className="mr-3 flex-shrink-0 h-6 w-6"
                              aria-hidden="true"
                            />
                            {subItem.name}
                          </NavLink>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              );
            }}
          </Match>
        )
      )}
    </nav>
  );
};

export default SideNav;
