import React, { useState, useEffect } from "react";
import { MailIcon } from "@heroicons/react/solid";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { useLocation, navigate } from "@reach/router";
import queryString from "query-string";
import Layout from "../../../components/sideBar/Layout";
import SectionHeading from "../../../stories/SectionHeading";
import Table from "../../../components/Table";
import { useTmaCampaignsList } from "../../../lib/services/tma/CampaignService";
import moment from "moment";
import SearchBar from "../../../components/SearchBar";
import _ from "lodash";
import TableLayout from "../../../components/TableLayout";
import { LoadContentHelper } from "../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../utils/Helper";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import { ModalWrapper } from "../../../utils/Modal";
import {
  ViewDataButton,
  ActionApproveButton,
  ActionRejectButton,
} from "../../../table-components/Buttons";
import { CurrentUser } from "../../../lib/network/auth/Auth";
const TmaCampaigns = () => {
  const location = useLocation();
  const [campaignApproveId, setCampaignApproveId] = useState(false);
  const parsed = queryString.parse(location.search);
  const userType = CurrentUser.getType();
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  const approveCampaign = async () => {
    if (campaignApproveId) {
      setModalPrimaryCtaLoading(true);
      // eslint-disable-next-line no-unused-vars

      const endpoint = `/portal_api/v1/campaigns_posts/${campaignApproveId}/approve`;

      const { error } = await API.put(endpoint);
      setModalPrimaryCtaLoading(false);
      if (error) {
        toast.error(
          `Error ${
            error.status ?? null
          }: Something went Wrong. Please try again`
        );
      } else {
        toast.success("Campaign approved Successfully");

        setCampaignApproveId(false);
      }
    } else {
      toast.error("Something went Wrong. Please try again");
    }
  };

  const onAction = (action, data) => {
    if (action === "approve") {
      setCampaignApproveId(data.campaignId);
    }
  };

  const columns = [
    {
      field: "Campaign Name (Status)",
      render: (rowData) => (
        <div className="flex justify-start items-start">
          <div className=" capitalize text-left  text-sm font-medium text-gray-900   ">
            {rowData?.attributes?.name}
          </div>
          <div className="border text-sm mx-1 px-1 rounded-md bg-gray-500 text-gray-50 border-gray-500 capitalize">
            {rowData?.attributes?.status}
          </div>
        </div>
      ),
    },
    {
      field: "Brand (Category)",
      render: (rowData) => (
        <div className="text-left text-sm  text-gray-500 capitalize">
          <div>
            {" "}
            {!_.isEmpty(rowData?.attributes?.brand)
              ? rowData?.attributes?.brand?.attributes?.name
              : "-NA-"}
          </div>
          <div>
            {!_.isEmpty(rowData?.attributes?.brandCategory)
              ? `(${rowData?.attributes?.brandCategory?.attributes?.name})`
              : "-NA-"}
          </div>
        </div>
      ),
    },
    {
      field: "Advertiser(s)",
      render: (rowData) => (
        <div className="text-left text-sm text-gray-500">
          {!_.isEmpty(rowData?.attributes?.advertisers)
            ? rowData?.attributes?.advertisers?.map((advertiser, idx) => (
                <div key={idx} className="whitespace-nowrap space-x-1 flex">
                  <span>
                    {advertiser?.attributes?.firstName +
                      " " +
                      advertiser?.attributes?.lastName}
                  </span>
                  <a
                    href={`mailto:${
                      _.isEmpty(advertiser?.attributes?.email)
                        ? advertiser?.attributes?.email
                        : advertiser?.attributes?.email
                    }`}
                  >
                    <MailIcon className="w-4 h-4 mt-0.5 " />
                  </a>
                </div>
              ))
            : "-NA-"}
        </div>
      ),
    },
    {
      field: "Talent",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500">
          {!_.isEmpty(rowData?.attributes?.talents) ? (
            <>{rowData?.attributes.talents.attributes.name}</>
          ) : (
            "-NA-"
          )}
        </div>
      ),
    },
    {
      field: "Post Status",
      render: (rowData) => (
        <div className="text-left text-sm font-normal text-gray-500 uppercase">
          {rowData?.campaignPostStatus}
        </div>
      ),
    },
    {
      field: "Scheduled on",
      render: (rowData) => (
        <div className="text-left  text-sm text-gray-500">
          {rowData?.attributes?.startTime
            ? moment.unix(`${rowData?.attributes?.startTime}`).format("lll")
            : "-NA-"}
        </div>
      ),
    },
    {
      field: "Actions",
      render: (rowData) => (
        <div className="whitespace-nowrap text-left text-sm font-medium  flex flex-row space-x-2">
          <ViewDataButton iconOnly onClick={() => navigate(`${rowData?.id}`)} />
          <>
            <ActionApproveButton
              iconOnly
              onClick={() => {
                onAction("approve", {
                  campaignId: rowData?.id,
                });
              }}
              buttonStyle={
                rowData?.campaignPostStatus === "pending"
                  ? "danger"
                  : "disabled"
              }
              disabled={
                rowData?.campaignPostStatus === "pending" ? false : true
              }
            />
          </>
        </div>
      ),
    },
  ];
  const filterType = "all";
  const {
    campaigns,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useTmaCampaignsList(
    pageNumberQuery,
    searchParamsQuery,
    filterType,
    userType
  );
  const loadContent = () => {
    if (_.isEmpty(campaigns)) {
      return (
        <LoadContentHelper
          title="Campaign Approval"
          data={campaigns}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    return <Table column={columns} data={campaigns} />;
  };

  useEffect(() => {
    if (!campaignApproveId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignApproveId]);

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const approveModalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: approveCampaign,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setCampaignApproveId,
    },
    open: { value: campaignApproveId },
    icon: { value: <CheckCircleIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper
        fullTitle="Approve Campaign"
        desc="Are you sure you want to approve this Campaign?"
        config={approveModalConfig}
      />

      <div className="sticky top-0 z-50">
        <SectionHeading title="Campaign Approvals" />
      </div>
      {searchParamsQuery || !_.isEmpty(campaigns) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}

      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title="Campaign Approval List"
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default TmaCampaigns;
