import React from "react";

const CampaignInfoContainer = ({ title, value, Icon, image = false }) => {
  return (
    <div className="w-1/3 bg-gray-100 rounded-md py-4 px-6">
      <div className=" flex">
        <div className="px-3 my-1 mr-4 flex items-center bg-black rounded-md">
          {image ? (
            <img
              className="text-white h-6 w-6"
              src="/assets/images/icon/Sun.svg"
              alt="target"
            />
          ) : (
            <Icon className=" text-white h-6 w-6" />
          )}
        </div>
        <div>
          <div className="block text-base font-medium text-gray-500">
            {title}
          </div>
          <div className="block text-lg font-medium text-gray-700">{value}</div>
        </div>
      </div>
    </div>
  );
};

export default CampaignInfoContainer;
