import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { MailIcon, PhoneIcon } from "@heroicons/react/solid";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import _ from "lodash";
import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import SlideOver from "../../stories/SlideOver";
import { useTeamList } from "../../lib/services/TeamService";
import Table from "../../components/Table";
import TeamForm from "../../forms/pageForms/TeamForm";
import moment from "moment";
import {
  ActionDeleteButton,
  ActionEditButton,
} from "../../table-components/Buttons";

import SearchBar from "../../components/SearchBar";
import { ModalWrapper } from "../../utils/Modal";

import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";

import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import TableLayout from "../../components/TableLayout";
import { navigateSelf } from "../../utils/Helper";

const Team = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const [toggle, setToggle] = useState(false);
  const [editData, setEditData] = useState("");
  const [deleteId, setDeleteId] = useState(false);

  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  //dymanic content changing  base dof url params
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const addInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    phone: "",
    countryCode: "",
    status: "disabled",
  };

  const deleteTeamMember = async () => {
    setModalPrimaryCtaLoading(true);
    if (deleteId) {
      // eslint-disable-next-line no-unused-vars
      const { data, error } = await API.delete(
        `/portal_api/v1/fanship_admins/${deleteId}`,
        {}
      );
      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ??
            "This team member cannot be deleted. Please try again"
          }`
        );
        setModalPrimaryCtaLoading(false);
      } else {
        toast.success("Team member Deleted Successfully");
        setModalPrimaryCtaLoading(false);
        setDeleteId(false);
      }
    } else {
      setModalPrimaryCtaLoading(false);
      toast.error("Something went Wrong. Please try again");
    }
  };

  const {
    team,
    loading,
    error,
    mutate,
    isValidating,
    paginationInfo,
  } = useTeamList(pageNumberQuery, searchParamsQuery);

  const total_pages = Number(paginationInfo?.totalPages);

  const onAction = (action, data) => {
    if (action === "delete") {
      setDeleteId(data.id);
    } else if (action === "edit") {
      setEditData({
        ...data,
        countryCode: data?.countryCode ?? "91",
        phone: (data?.countryCode ?? "91").concat(data?.phone),
      });
    }
  };

  const columns = [
    {
      field: "name",
      render: (rowData) => (
        <div className="text-left  text-sm font-medium text-gray-900 flex flex-row  items-center ">
          {rowData?.attributes?.firstName}{" "}
          {rowData?.attributes?.lastName ?? null}
        </div>
      ),
    },
    {
      field: "role",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 uppercase ">
          {rowData?.attributes?.role}
        </div>
      ),
    },
    {
      field: "Contact Info",
      render: (rowData) => (
        <div className="flex flex-col">
          {rowData?.attributes?.email && (
            <div className=" text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              <a href={`mailto:${rowData?.attributes?.email}`}>
                {" "}
                <MailIcon className="w-4 h-4" />
              </a>
              <span>{rowData?.attributes?.email ?? null}</span>
            </div>
          )}
          {rowData?.attributes?.phone && (
            <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              <a
                href={`tel:${
                  rowData?.attributes?.countryCode
                    ? `+${rowData?.attributes?.countryCode}-`
                    : ""
                }${rowData?.attributes?.phone}`}
              >
                <PhoneIcon className="w-4 h-4" />
              </a>
              {/* <span>{rowData?.attributes?.phone ?? "-NA-"}</span> */}
              <span>
                {rowData?.attributes?.countryCode
                  ? `+${rowData?.attributes?.countryCode}-`
                  : null}
                {rowData?.attributes?.phone ?? "-NA-"}
              </span>
            </div>
          )}
        </div>
      ),
    },

    {
      field: "status",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm text-gray-500 uppercase">
          {rowData?.attributes?.status}
        </div>
      ),
    },
    {
      field: "added on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.createdAt
            ? moment(`${rowData?.attributes?.createdAt}`).format("ll")
            : null}
        </div>
      ),
    },
    {
      field: "actions",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionEditButton
            iconOnly
            onClick={() => {
              onAction("edit", {
                ...rowData?.attributes,
                id: rowData.id,
              });
            }}
          />
          <ActionDeleteButton
            iconOnly
            onClick={() => {
              onAction("delete", { id: rowData.id });
            }}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(team)) {
      return (
        <LoadContentHelper
          title="Team"
          ctaOnClick={() => setToggle(true)}
          data={team}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    return <Table column={columns} data={team} />;
  };

  useEffect(() => {
    if (!toggle || !editData || !deleteId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, editData, deleteId]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deleteTeamMember,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper title="team member" config={modalConfig} />

      <SlideOver
        open={toggle || !!editData}
        backgroundOverlay="dark"
        size="xl"
        title={`${toggle ? "Add" : "Edit"} Team Member`}
        dismissButtonOnClick={() => {
          setToggle(false);
          setEditData(false);
        }}
      >
        {/* Team add/edit form  */}
        <TeamForm
          setToggle={editData ? setEditData : setToggle}
          initialValues={editData ? editData : addInitialValues}
          formButtonCta={`${toggle ? "Create" : "Update"} Team Member`}
          editMode={!!editData}
        />
      </SlideOver>
      <div className="sticky top-0 z-50">
        <SectionHeading
          title="Team"
          primaryCta="Add team member"
          primaryOnClick={() => setToggle(true)}
        />
      </div>
      {searchParamsQuery || !_.isEmpty(team) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Team list"}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default Team;
