import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "@reach/router";
import queryString from "query-string";
import Table from "../../../../components/Table";
import moment from "moment";
import Author from "../../../../stories/Author";
import SearchBar from "../../../../components/SearchBar";
import _ from "lodash";
import TableLayout from "../../../../components/TableLayout";
import { LoadContentHelper } from "../../../../table-components/LoadContentHelper";
import { navigateSelf } from "../../../../utils/Helper";
import Layout from "../../../../components/sideBar/Layout";
import {
  useTalent,
  useTalentCampaignsList,
} from "../../../../lib/services/TalentService";
import AllTalentHeader from "./AllTalentHeader";
import { ModalWrapper } from "../../../../utils/Modal";
import { ExportDataButton } from "../../../../table-components/Buttons";
import { API } from "../../../../lib/network/API";
import { toast } from "../../../../components/Toast";
import { DocumentDownloadIcon } from "@heroicons/react/outline";

const EarningCompletedCampaigns = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const { talentId } = useParams();
  const { talent } = useTalent(talentId);
  const urlLastSegment = location.pathname.split("/").pop();

  const [campaignId, setCampaignId] = useState(false);
  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);

  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const downloadReport = async () => {
    setModalPrimaryCtaLoading(true);
    if (campaignId) {
      // eslint-disable-next-line no-unused-vars
      const { data, error } = await API.get(
        `portal_api/v1/campaigns/${campaignId}/talents/${talentId}/export_report`,
        {}
      );
      if (error) {
        toast.error(
          `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
        );
        setModalPrimaryCtaLoading(false);
        setCampaignId(false);
      } else {
        toast.success("Downloading Campaign report...");
        setModalPrimaryCtaLoading(false);
        const aws_pdf_url = data?.data?.url.split("?")[0];
        let pdf_open = document.createElement("a");
        pdf_open.target = "_blank";
        pdf_open.href = aws_pdf_url;
        setTimeout(() => {
          pdf_open.click();
        }, 1000);
        setCampaignId(false);
      }
    } else {
      setModalPrimaryCtaLoading(false);
      toast.error("Something went Wrong. Please try again");
      setCampaignId(false);
    }
  };

  const onAction = (action, data) => {
    if (action === "export") {
      setCampaignId(data.campaignId);
    }
  };

  const getTalentPost = (posts) => {
    return _.find(posts || [], [
      "attributes.post.attributes.talent.id",
      talentId,
    ]);
  };

  const columns = [
    {
      field: "post",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm  text-gray-900 flex flex-row  items-center">
          <Author
            imageSrc={
              getTalentPost(rowData?.attributes?.campaignsPosts)?.attributes
                ?.post?.attributes?.previewImage?.thumbnail?.url
            }
            size="lg"
            className="cursor-default"
            imageShape="round-none"
          />
        </div>
      ),
    },
    {
      field: "target impressions",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.targetImpressions}
        </div>
      ),
    },
    {
      field: "received impressions",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm  text-gray-900 flex flex-row  items-center">
          {rowData?.attributes?.receivedImpressions}
        </div>
      ),
    },
    {
      field: "brand",
      render: (rowData) => (
        <div className="text-left whitespace-nowrap text-sm  text-gray-500 capitalize">
          {rowData?.attributes?.brand?.attributes?.name}
        </div>
      ),
    },
    {
      field: "start date & time",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.startTime ? (
            <div>
              {moment.unix(`${rowData?.attributes?.startTime}`).format("lll")}
            </div>
          ) : (
            "-NA-"
          )}
        </div>
      ),
    },
    {
      field: "end date & time",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.startTime ? (
            <div>
              {moment.unix(`${rowData?.attributes?.endTime}`).format("lll")}
            </div>
          ) : (
            "-NA-"
          )}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ExportDataButton
            iconOnly
            onClick={() => {
              onAction("export", {
                campaignId: rowData?.id,
              });
            }}
          />
        </div>
      ),
    },
  ];

  const {
    talentCampaigns,
    paginationInfo,
    loading,
    error,
    mutate,
    isValidating,
  } = useTalentCampaignsList(searchParamsQuery, {
    talentId: talentId,
    page: pageNumberQuery,
    filterByStatus: "ended",
  });

  const loadContent = () => {
    if (_.isEmpty(talentCampaigns)) {
      return (
        <LoadContentHelper
          title="Completed Campaign"
          data={talentCampaigns}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }

    return <Table column={columns} data={talentCampaigns} />;
  };

  useEffect(() => {
    if (!campaignId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const total_pages = Number(paginationInfo?.totalPages);
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const downloadReportModalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: downloadReport,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setCampaignId,
    },
    open: { value: campaignId },
    icon: { value: <DocumentDownloadIcon />, type: "success" },
  };

  return (
    <Layout>
      <ModalWrapper
        fullTitle="Download Campaign Data"
        desc="Are you sure you want to download data of this campaign?"
        config={downloadReportModalConfig}
      />
      <AllTalentHeader
        talentName={
          talent?.attributes?.fanAccount?.attributes?.name ||
          "Completed Campaigns"
        }
        talentId={talentId}
        active={urlLastSegment}
      />
      <div className="flex justify-between items-center">
        {searchParamsQuery || !_.isEmpty(talentCampaigns) ? (
          <SearchBar
            searchTerm={searchParamsQuery}
            size="xl"
            align="center"
            onSearch={(val) => {
              navigateSelf(1, val, parsed);
            }}
            loading={loading}
          />
        ) : null}
      </div>
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Completed Compaigns List"}
          loading={loading}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default EarningCompletedCampaigns;
