import { navigate } from "@reach/router";
import queryString from "query-string";

/* 
--------------------------------
navigateSelf function Parameters
--------------------------------
PageNo --> Current Page number
query ---> Search Query
parsed --->  retruning location.search in an object form
*/
export const navigateSelf = (pageNo, query, parsed) => {
  navigate(
    queryString.stringifyUrl(
      {
        url: "",
        query: {
          ...parsed,
          pageNo,
          query,
        },
      },
      { skipNull: true, skipEmptyString: true }
    )
  );
};

/* 
 for backend url query parsing only
 queryObject --> Contains all params objects
*/
export const queryStringParseFuntion = (queryObject) => {
  return queryString.stringifyUrl(
    {
      url: "",
      query: queryObject,
    },
    { skipNull: true, skipEmptyString: true }
  );
};

//User Roles Constants

export const USER_ROLE = {
  ADMIN: "admin",
  SALES: "sales",
  MANAGER: "manager",
};

// portal type
export const USER_TYPE = {
  ADMIN_PORTAL: "fanshipAdmin",
  TMA_PORTAL: "talentManagementMember",
  ADVERTISER_PORTAL: "advertiser",
  TALENT_PORTAL: "talent",
};

// Function to change Base64 to File type
export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

//Function to calculate image and width
export const getImageDimension = (value) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve({ height: img.height, width: img.width });
    img.onerror = reject;
    img.src = value;
  });
};

//Convert file size from byter to larger formats
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

//correct options conversion helper function

export const getCorrectOption = (value, options) => {
  const index = options?.indexOf(value);
  switch (index) {
    case 0:
      return "Option A";
    case 1:
      return "Option B";
    case 2:
      return "Option C";
    default:
      return "";
  }
};

export const getCorrectOptionApi = (value, options) => {
  switch (value) {
    case "Option A":
      return options[0];
    case "Option B":
      return options[1];
    case "Option C":
      return options[2];
    default:
      return "";
  }
};

//js Capitalize first character
export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";
