import React from "react";
import AllTalentHeader from "../AllTalentHeader";
import Tabs from "../../../../../stories/Tabs";

import { CurrentUser } from "../../../../../lib/network/auth/Auth";

const TalentPosts = ({ active, talentId, talentName }) => {
  const urlLastSegment = "posts";

  const userRole = CurrentUser.getRole();

  const tabsData = [
    {
      id: "regular-posts",
      path: `/${userRole}/talents/all-talents/${talentId}/posts/regular-posts`,
      icon: () => {
        return null;
      },
      name: "regular posts",
      get current() {
        return active === this.id ? true : false;
      },
    },

    {
      id: "monetizable-posts",
      path: `/${userRole}/talents/all-talents/${talentId}/posts/monetizable-posts`,
      icon: () => {
        return null;
      },
      name: "monetizable posts",
      get current() {
        return active === this.id ? true : false;
      },
    },
    {
      id: "quizzes",
      path: `/${userRole}/talents/all-talents/${talentId}/posts/quizzes`,
      icon: () => {
        return null;
      },
      name: "quizzes",
      get current() {
        return active === this.id ? true : false;
      },
    },
  ];

  return (
    <div className="sticky bg-gray-100 top-0 z-50">
      <AllTalentHeader
        talentName={talentName}
        talentId={talentId}
        active={urlLastSegment}
        posts={true}
      />
      <div className="mt-3 -ml-2">
        <Tabs type="button" tabs={tabsData} />
      </div>
    </div>
  );
};

export default TalentPosts;
