import { useAPI } from "../../network/SWR";

export const useQuizPost = (postId) => {
  const key = `/portal_api/v1/quizzes/${postId}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    post: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
