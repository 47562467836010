import { useAPI } from "../network/SWR";
import { queryStringParseFuntion } from "../../utils/Helper";

export const usePostList = (pageParam = "1", searchTerm) => {
  const queryObject = {
    page: pageParam,
    query: searchTerm,
    per_page: 20,
    metrics: true,
  };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/talents${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    posts: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

// List of services under posts portal_api/v1/talents/70/quizes
export const useQuizzesList = (pageParam = "1", searchTerm, talentId) => {
  const queryObject = {
    page: pageParam,
    query: searchTerm,
    per_page: 20,
  };
  const queryValues = queryStringParseFuntion(queryObject);

  const key = `/portal_api/v1/talents${
    talentId ? `/${talentId}` : ""
  }/quizes${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    quizzes: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const usePostByTalentList = (
  pageParam = "1",
  searchTerm,
  talentId,
  filterType = "regular"
) => {
  const queryObject = {
    page: pageParam,
    query: searchTerm,
    per_page: 20,
    filterByCategory: filterType,
  };
  const queryValues = queryStringParseFuntion(queryObject);

  const key = `/portal_api/v1/talents${
    talentId ? `/${talentId}` : ""
  }/posts${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    posts: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const usePost = (postId) => {
  const key = `/portal_api/v1/posts/${postId}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    post: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};

export const useCommentList = (postId, pageParam = "1", fanId) => {
  const queryObject = {
    page: pageParam,
    per_page: 10,
    talent_fan_id: fanId,
  };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/portal_api/v1/posts/${postId}/comments${queryValues}`;

  const { data, error, loading, mutate, isValidating } = useAPI(key, {});

  return {
    comments: data?.data || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.meta?.pagination || null,
  };
};
