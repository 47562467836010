import React from "react";
import { XIcon } from "@heroicons/react/solid";
import Label from "../Label";
import UploadVideo from "./UploadVideo";

export const VideoAttachments = ({
  videoList,
  editVideoList,
  editing,
  onVideoListHandler,
  onEditVideoListHandler,
  attachmentType = "single",
  mediaStatus,
  label = null,
  showCancelButton = true,
}) => {
  return (
    <div className="flex flex-col">
      <Label>{label}</Label>
      <div className="flex flex-wrap">
        {videoList?.map((el, index) => (
          <React.Fragment key={index}>
            <div className="bg-gray-200 flex items-center justify-center w-56 h-56 my-4 cursor-pointer mr-8 relative">
              <UploadVideo
                mediaStatus={mediaStatus}
                className="w-10 h-10 mb-4"
                h="40"
                w="40"
                sizeLabel=""
                instructionText="Click to add Video"
                onvalueChange={(url) => {
                  let videos = [...videoList];
                  videos[index].key = url;
                  onVideoListHandler(videos);
                }}
                videoUrl={videoList[index]?.key}
                key={videoList[index]?.key}
              />
              {showCancelButton &&
                mediaStatus === "draft" &&
                videoList[index]?.key && (
                  <div className="absolute" style={{ top: -10, left: -10 }}>
                    <div
                      className="w-6 h-6 bg-red-900 rounded-full flex justify-center items-center cursor-pointer"
                      onClick={() => {
                        let videos = [...videoList];
                        //Edit check to delete attachments(storing it in a different state)
                        if (editing) {
                          onEditVideoListHandler([
                            ...editVideoList,
                            {
                              key: videoList[index]?.key,
                              id: videoList[index]?.id,
                              _destroy: true,
                            },
                          ]);
                        }

                        if (index < 1 && videos.length < 2) {
                          videos.splice(index, 1, {});
                        } else {
                          videos.splice(index, 1);
                        }

                        onVideoListHandler(videos);
                      }}
                    >
                      <XIcon className="bg-gray-900 text-white rounded-full " />
                    </div>
                  </div>
                )}
            </div>
            {attachmentType === "multiple" && mediaStatus === "draft" ? (
              <>
                {index < 9 && index === videoList?.length - 1 && (
                  <div className="flex items-center justify-center w-56">
                    <button
                      type="button"
                      className="transition-all duration-500 py-3 rounded px-4 cursor-pointer bg-transparent hover:bg-gray-200 text-gray-900"
                      style={{ outline: "none" }}
                      onClick={() => {
                        const temp = { key: "" };
                        const clonedVideoList = [...videoList];
                        clonedVideoList.push(temp);
                        onVideoListHandler(clonedVideoList);
                      }}
                    >
                      + Add Videos
                    </button>
                  </div>
                )}
              </>
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
