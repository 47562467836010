import React, { useState, useEffect } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import {
  MailIcon,
  PhoneIcon,
  UserIcon,
  LocationMarkerIcon,
  CakeIcon,
} from "@heroicons/react/solid";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import _ from "lodash";
import Layout from "../../components/sideBar/Layout";
import SectionHeading from "../../stories/SectionHeading";
import Author from "../../stories/Author";
import { useFanList } from "../../lib/services/FanService";
import Table from "../../components/Table";
import moment from "moment";
import {
  ActionDeleteButton,
  ActionConvertButton,
} from "../../table-components/Buttons";
import SearchBar from "../../components/SearchBar";
import { ModalWrapper } from "../../utils/Modal";
import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import { LoadContentHelper } from "../../table-components/LoadContentHelper";
import TableLayout from "../../components/TableLayout";
import { navigateSelf } from "../../utils/Helper";
const Fans = () => {
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const [deleteId, setDeleteId] = useState(false);
  const [fanId, setFanId] = useState(false);

  const [modalPrimaryCtaLoading, setModalPrimaryCtaLoading] = useState(false);
  const [modalSecondaryCtaLoading, setModalSecondaryCtaLoading] = useState(
    false
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageNumberQuery =
    new URLSearchParams(location.search).get("pageNo") || 1;
  const searchParamsQuery =
    new URLSearchParams(location.search).get("query") || "";

  const deleteFan = async () => {
    setModalPrimaryCtaLoading(true);
    if (deleteId) {
      // eslint-disable-next-line no-unused-vars
      const { data, error } = await API.delete(
        `/portal_api/v1/fans/${deleteId}`,
        {}
      );
      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ?? "This fan cannot be deleted. Please try again"
          }`
        );
        setModalPrimaryCtaLoading(false);
      } else {
        toast.success("Fan Deleted Successfully");
        setModalPrimaryCtaLoading(false);
        setDeleteId(false);
      }
    } else {
      setModalPrimaryCtaLoading(false);
      toast.error("Something went Wrong. Please try again");
    }
  };
  const convertFan = async () => {
    setModalSecondaryCtaLoading(true);
    if (fanId) {
      // eslint-disable-next-line no-unused-vars
      const { data, error } = await API.get(
        `portal_api/v1/fans/${fanId}/convert_to_talent`,
        {}
      );
      if (error) {
        toast.error(
          `Error ${error?.status ?? null}: ${
            error?.message ??
            "This fan cannot be converted to a talent. Please try again"
          }`
        );
        setModalSecondaryCtaLoading(false);
      } else {
        toast.success("Fan converted to talent successfully");
        setModalSecondaryCtaLoading(false);
        setFanId(false);
      }
    } else {
      setModalSecondaryCtaLoading(false);
      toast.error("Something went Wrong. Please try again");
    }
  };

  const {
    fan,
    loading,
    error,
    mutate,
    isValidating,
    paginationInfo,
  } = useFanList(pageNumberQuery, searchParamsQuery);

  const total_pages = Number(paginationInfo?.totalPages);

  const onAction = (action, data) => {
    if (action === "delete") {
      setDeleteId(data.id);
    }
    if (action === "convert") {
      setFanId(data.fanId);
    }
  };

  const columns = [
    {
      field: "name",
      render: (rowData) => (
        <div className="text-left text-sm  flex items-start space-x-2">
          <Author
            name={rowData?.attributes?.name}
            imageSrc={rowData?.attributes?.image}
            cta={
              rowData?.attributes?.username
                ? `@${rowData?.attributes?.username}`
                : null
            }
            size="lg"
            className="cursor-default"
          />
          {!!rowData?.attributes?.talentId && (
            <span className="mx-1.5 bg-gray-500 rounded-lg border font-medium border-gray-500 px-1.5 py-0.5 text-xs text-gray-100 uppercase">
              Talent
            </span>
          )}
        </div>
      ),
    },

    {
      field: "fan info",
      render: (rowData) => (
        <div className="flex flex-col">
          {rowData?.attributes?.email && (
            <div className=" text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              <a href={`mailto:${rowData?.attributes?.email}`}>
                {" "}
                <MailIcon className="w-4 h-4" />
              </a>
              <span>{rowData?.attributes?.email ?? null}</span>
            </div>
          )}
          {rowData?.attributes?.phone && (
            <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              <a
                href={`tel:${
                  rowData?.attributes?.countryCode
                    ? `+${rowData?.attributes?.countryCode}-`
                    : ""
                }${rowData?.attributes?.phone}`}
              >
                <PhoneIcon className="w-4 h-4" />
              </a>
              <span>
                {rowData?.attributes?.countryCode
                  ? `${rowData?.attributes?.countryCode}-`
                  : null}
                {rowData?.attributes?.phone ?? "-NA-"}
              </span>
            </div>
          )}
          {rowData?.attributes?.location && (
            <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              <LocationMarkerIcon className="w-4 h-4" />
              <span>{rowData?.attributes?.location ?? "-NA-"}</span>
            </div>
          )}
          {rowData?.attributes?.dob && (
            <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              <CakeIcon className="w-4 h-4" />
              <span>
                {rowData?.attributes?.dob
                  ? moment(`${rowData?.attributes?.dob}`).format("ll")
                  : null}
              </span>
            </div>
          )}

          {rowData?.attributes?.gender && (
            <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              <UserIcon className="w-4 h-4" />
              <span>{rowData?.attributes?.gender}</span>
            </div>
          )}
        </div>
      ),
    },

    {
      field: "fan Stats",
      render: (rowData) => (
        <div className="flex flex-col">
          {
            <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              Onboarded:
              <span className="px-1">
                {rowData?.attributes?.isOnboarded ? "Yes" : "No"}
              </span>
            </div>
          }
          {
            <div className=" text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              Following:
              <span className="px-1">
                {" "}
                {rowData?.attributes?.followingsCount ?? "-NA-"}
              </span>
            </div>
          }
          {
            <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              Total Comments:
              <span className="px-1">
                {rowData?.attributes?.commentsCount ?? "-NA-"}
              </span>
            </div>
          }
          {
            <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              Total Likes:
              <span className="px-1">
                {rowData?.attributes?.likesCount ?? "-NA-"}
              </span>
            </div>
          }
          {
            <div className="text-left whitespace-nowrap text-sm text-gray-500 flex flex-row items-center space-x-1">
              Status:
              <span className="px-1 uppercase">
                {rowData?.attributes?.status}
              </span>
            </div>
          }
        </div>
      ),
    },
    {
      field: "joined on",
      render: (rowData) => (
        <div className=" text-left whitespace-nowrap text-sm text-gray-500">
          {rowData?.attributes?.createdAt
            ? moment(`${rowData?.attributes?.createdAt}`).format("ll")
            : null}
        </div>
      ),
    },
    {
      field: "action",
      render: (rowData) => (
        <div className=" whitespace-nowrap text-left text-sm font-medium flex flex-row space-x-4 ">
          <ActionDeleteButton
            iconOnly={true}
            onClick={() => {
              onAction("delete", { id: rowData.id });
            }}
          />
          <ActionConvertButton
            iconOnly
            onClick={() => {
              onAction("convert", {
                fanId: rowData?.id,
              });
            }}
            buttonStyle={
              !!rowData?.attributes?.talentId ? "disabled" : "primary"
            }
            disabled={!!rowData?.attributes?.talentId}
          />
        </div>
      ),
    },
  ];

  const loadContent = () => {
    if (_.isEmpty(fan)) {
      return (
        <LoadContentHelper
          title="Fan"
          ctaOnClick={null}
          data={fan}
          loading={loading}
          error={error}
          mutate={mutate}
          isValidating={isValidating}
          searchTerm={searchParamsQuery}
        />
      );
    }
    return <Table column={columns} data={fan} />;
  };

  useEffect(() => {
    if (!deleteId || !fanId) {
      mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId, fanId]);

  //dynamic page rendering based on URL
  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    } else {
      const notWithingRange =
        pageNumberQuery > total_pages || pageNumberQuery < 1;
      navigateSelf(
        notWithingRange ? 1 : pageNumberQuery,
        searchParamsQuery,
        parsed
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberQuery, paginationInfo, searchParamsQuery]);

  const modalConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalPrimaryCtaLoading,
      onClick: deleteFan,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setDeleteId,
    },
    open: { value: deleteId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  const modalConvertConfig = {
    primaryCta: {
      value: "Yes",
      loading: modalSecondaryCtaLoading,
      onClick: convertFan,
    },
    secondaryCta: {
      value: "Cancel",
      onClick: setFanId,
    },
    open: { value: fanId },
    icon: { value: <ExclamationIcon />, type: "warning" },
  };

  return (
    <Layout>
      <ModalWrapper title="fan" config={modalConfig} />
      <ModalWrapper
        fullTitle="Convert Fan"
        desc="Are you sure you want to convert this fan into a talent?"
        config={modalConvertConfig}
      />

      <div className="sticky top-0 z-50">
        <SectionHeading title="Fans" />
      </div>
      {searchParamsQuery || !_.isEmpty(fan) ? (
        <SearchBar
          searchTerm={searchParamsQuery}
          size="xl"
          align="center"
          onSearch={(val) => {
            navigateSelf(1, val, parsed);
          }}
          loading={loading}
        />
      ) : null}
      <main className="flex-1 relative overflow-y-auto focus:outline-none">
        <TableLayout
          title={"Fan list"}
          loading={loading || error}
          paginationInfo={paginationInfo}
          searchTerm={searchParamsQuery}
        >
          {loadContent()}
        </TableLayout>
      </main>
    </Layout>
  );
};

export default Fans;
