import React from "react";
import SectionHeading from "../stories/SectionHeading";
import Layout from "../components/sideBar/Layout";

const Sample = () => {
  return (
    <Layout>
      <SectionHeading title={"Welcome to fanship"} />
    </Layout>
  );
};

export default Sample;
