import React from "react";
import Avatar from "../stories/Avatar";

const AvatarInfo = ({
  name,
  secondaryField,
  imageSrc,
  circular = true,
  ...props
}) => {
  return (
    <div className="flex flex-col justify-center items-center  py-4 px-2">
      <Avatar
        circular={circular}
        size="xl"
        imageSrc={imageSrc}
        className="h-24 w-24"
      />

      <div className="flex flex-col  mt-2 justify-center items-center">
        <h3 className="block text-base font-medium text-gray-700">{name}</h3>
        <h6 className="block text-sm font-medium text-gray-400">
          {secondaryField}
        </h6>
      </div>
    </div>
  );
};

export default AvatarInfo;
