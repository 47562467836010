import React, { useRef } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import Button from "../../stories/Button";

import { API } from "../../lib/network/API";
import { toast } from "../../components/Toast";
import AwsImageService from "../../lib/services/AwsImageService";

import { talentCategoryValidationSchema } from "../validation";
import FormFields from "../FormFields";

const TalentCategoryForm = ({
  setToggle,
  initialValues,
  editMode,
  formButtonCta,
}) => {
  const buttonRef = useRef(null);

  const formValues = [
    {
      label: "Category Image",
      attributeId: "image",
      type: "ImageInput",
    },
    {
      label: " Category *",
      attributeId: "name",
      placeholder: "Actor",
      type: "TextInput",
    },
    {
      label: "Brief Description *",
      attributeId: "brief",
      type: "TextArea",
      placeholder: "A small description of the talent category...",
    },
    {
      label: "Status : ",
      attributeId: "status",
      type: "Toggle",
    },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    const fetchedValues = {
      ..._.pick(values, ["name", "brief", "status"]),
      image: values?.image?.data
        ? await AwsImageService(values)
        : initialValues.image,
    };

    const apiParams = {
      data: { attributes: fetchedValues },
    };
    const endpoint = `/portal_api/v1/talent_categories/${
      editMode ? initialValues.id : ""
    }`;

    const { error } = editMode
      ? await API.put(endpoint, apiParams)
      : await API.post(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went Wrong. Please try again"}`
      );
      buttonRef.current.shake();
    } else {
      toast.success(
        `Category ${editMode ? "Updated" : "Created"} Successfully`
      );
      setToggle(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={talentCategoryValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="flex flex-col  h-screen">
          {/* json Form component */}
          <div className=" sticky pl-2 pr-4 -mr-5 pb-10 overflow-y-auto h-3/4 ">
            <FormFields
              setFieldValue={setFieldValue}
              values={values}
              formValues={formValues}
            />
          </div>

          <Button
            size="xl"
            ref={buttonRef}
            type="submit"
            className="mt-4 w-full sticky bottom-2 z-50"
            loading={isSubmitting}
          >
            {formButtonCta}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default TalentCategoryForm;
