import React, { useState, useEffect } from "react";
import InfoContainer from "./InfoContainer";
import Author from "../stories/Author";
import Loader from "./Loader";
import classNames from "classnames";
import CommentBox from "../components/CommentBox";
import TableLayout from "./TableLayout";
import { LoadContentHelper } from "../table-components/LoadContentHelper";
import _ from "lodash";
import CommentForm from "../forms/pageForms/talentAgencyForms/tmaPortal/CommentForm";

const PostDetail = ({
  post,
  talent,
  comments,
  paginationInfo,
  commentLoading,
  commentError,
  commentMutate,
  commentIsValidating,
}) => {
  const [loading, setLoading] = useState(true);
  const [replyingTo, setReplyingTo] = useState(false);
  const [initialValues, setInitialValues] = useState({
    comment: "",
    status: "published",
  });
  const [deleteId, setDeleteId] = useState(false);
  const showLoader = () => {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader title={`Loading Post ...`} />
      </div>
    );
  };

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      comment: replyingTo?.attributes?.username
        ? `@${replyingTo.attributes.username}`
        : "",
    });
  }, [replyingTo]);

  useEffect(() => {
    if (!deleteId) {
      commentMutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]);

  const loadContent = () => {
    if (commentLoading || commentError || commentIsValidating) {
      return (
        <LoadContentHelper
          title="Comments"
          data={comments}
          loading={commentLoading}
          error={commentError}
          mutate={commentMutate}
          isValidating={commentIsValidating}
        />
      );
    }
    return (
      <CommentBox
        comments={comments}
        commentMutate={commentMutate}
        setReplyingTo={setReplyingTo}
        postId={post?.id}
        talentId={talent?.id}
        fanId={talent?.attributes?.fanAccount?.id}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
      />
    );
  };
  const quizAttributes = [
    {
      label: "Comments",
      value: post?.attributes?.commentsCount,
    },
    {
      label: "Replays",
      value: post?.attributes?.averageReplays,
    },
    {
      label: "Likes",
      value: post?.attributes?.likesCount,
    },
    {
      label: "Impressions",
      value: post?.attributes?.impressionsCount,
    },
  ];

  return (
    <div className="h-full flex rounded-md relative">
      <div className="w-1/2 ">
        <div className="h-full rounded-l-md flex justify-center bg-black">
          <div className="flex justify-center w-full bg-white">
            <div className={loading ? "block" : "hidden"}>{showLoader()}</div>
            {post?.attributes?.containsVideo ? (
              <video
                className={classNames(
                  !loading ? "block" : "hidden",
                  "w-full object-contain"
                )}
                onLoadedData={() => setLoading(false)}
                controls
              >
                <source
                  src={
                    _.find(post?.attributes?.attachments, [
                      "attributes.category",
                      "video",
                    ])?.attributes?.key
                  }
                />
              </video>
            ) : (
              <img
                className={classNames(
                  !loading ? "block" : "hidden",
                  "w-full object-contain"
                )}
                src={post?.attributes?.previewImage?.post?.url}
                onLoad={() => setLoading(false)}
                alt="Post"
              />
            )}
          </div>
        </div>
      </div>
      <div className="border-l-2 border-gray-100 w-1/2 bg-gray-50 rounded-r-md">
        <div className="border-b-2 px-4 py-4">
          <Author
            name={talent?.attributes?.name}
            imageSrc={talent?.attributes?.image}
            size="lg"
          />
        </div>
        <div className="border-b-2 px-6 pt-3 2xl:pt-1 flex flex-row flex-wrap justify-evenly">
          {quizAttributes?.map((item, idx) => (
            <div key={idx} className="text-center ">
              <InfoContainer
                label={item.label}
                value={item.value}
                price={item.price}
              />
            </div>
          ))}
        </div>
        <div className=" focus:outline-none bg-gray-50  px-4 pt-2 pb-1">
          <h2 className="text-lg  font-medium text-gray-900 ">Comments</h2>
        </div>
        <div className="h-3/5 absolute w-1/2 pb-16  overflow-auto focus:outline-none">
          {!commentIsValidating && _.isEmpty(comments) ? (
            <div className="text-center">
              No comments available for this post.
            </div>
          ) : (
            <TableLayout
              loading={commentLoading || commentError || commentIsValidating}
              paginationInfo={paginationInfo}
              hidePaginationInfo={true}
            >
              {loadContent()}
            </TableLayout>
          )}
        </div>
        <div className="absolute bottom-0 w-1/2 py-2 bg-white rounded-b-md">
          <CommentForm
            postId={post?.id}
            fanId={talent?.attributes?.fanAccount?.id}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            mutate={commentMutate}
            setReplyingTo={setReplyingTo}
          />
        </div>
      </div>
    </div>
  );
};

export default PostDetail;
