import React, { useRef, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import FormFields from "../../FormFields";
import {
  useBrandList,
  useBrandsByCategoryList,
} from "../../../lib/services/BrandService";
import FormHeader from "../../../components/FormHeader";
import { campaignDetailsValidationSchema } from "../../validation";

import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import { useAdvertiserByBrandList } from "../../../lib/services/AdvertiserService";
const CampaignDetailsForm = ({
  initialValues,
  campaignId,
  setCampaignId,
  formNumber,
  setFormNumber,
  editing,
  campaignFormNumber,
  hasReachedForm,
  setResponseData,
}) => {
  const [editMode, setEditMode] = useState(
    editing && hasReachedForm(campaignFormNumber.CAMPAIGN_DETAILS_FORM)
      ? false
      : true
  );
  const [showCancel, setShowCancel] = useState(
    editing && hasReachedForm(campaignFormNumber.CAMPAIGN_DETAILS_FORM)
      ? true
      : false
  );
  const [formData, setFormData] = useState(
    editing
      ? {
          ...initialValues,
          brandCategoryId: initialValues?.brandCategory?.attributes?.name,
          associateBrandId: initialValues?.associateBrand?.attributes?.name,
          advertiserId: initialValues?.advertisers,
        }
      : []
  );

  const buttonRef = useRef(null);
  const [selectedBrandCategoryId, setSelectedBrandCategoryId] = useState(
    editing ? initialValues?.brandCategoryId : ""
  );
  const [searchBrandCategory, setSearchBrandCategory] = useState("");

  const {
    brands: brandCategoriesData,
    isValidating: isValidatingBrandCategory,
  } = useBrandList(searchBrandCategory, {
    defaultValue: editing ? [initialValues.brandCategory] : [],
  });
  const [searchBrand, setSearchBrand] = useState("");

  const { brands, loading, isValidating } = useBrandsByCategoryList(
    searchBrand,
    {
      defaultValue: editing ? [initialValues.associateBrand] : [],
      brandCategoryId: selectedBrandCategoryId || initialValues.brandCategoryId,
    }
  );
  const [selectedBrandId, setSelectedBrandId] = useState(
    editing ? initialValues?.associateBrandId : ""
  );
  const [checkAssociation, setCheckAssociation] = useState(false);

  const [searchAdvertiser, setSearchAdvertiser] = useState("");
  const {
    advertisers: brandsAdvertiserData,
    isValidating: isValidatingBrandAdvertiser,
  } = useAdvertiserByBrandList(searchAdvertiser, {
    defaultValue: editing ? [initialValues?.advertisers] : [],
    associateBrandId: selectedBrandId || initialValues?.associateBrandId,
  });

  const formValues = [
    {
      type: "Row",
      formValues: [
        {
          label: "Monetizable Type *",
          attributeId: "monetizationType",
          type: "TextInput",
          disable: "true",
        },
        {
          label: "Campaign Name *",
          attributeId: "name",
          placeholder: "Campaign",
          type: "TextInput",
        },
        {
          label: "Brand Category *",
          attributeId: "brandCategoryId",
          type: "Search",
          placeholder: "Select Brand Category ",
          displayKey: "attributes.name",
          items: brandCategoriesData,
          onChange: setSearchBrandCategory,
          isValidating: isValidatingBrandCategory,
          setSelectedId: setSelectedBrandCategoryId,
        },
      ],
    },
    {
      type: "Row",
      formValues: [
        {
          label: "Associate Brand *",
          attributeId: "associateBrandId",
          type: "Search",
          placeholder: "Select Brand",
          displayKey: "attributes.name",
          items: brands,
          onChange: setSearchBrand,
          isValidating: isValidating,
          setSelectedId: setSelectedBrandId,
          checkAssociation: checkAssociation,
          selectedBrandCategoryId: selectedBrandCategoryId,
        },
      ],
    },
    {
      label: "Advertiser(s) *",
      attributeId: "advertiserId",
      placeholder: "Select Advertiser",
      type: "Multiselect",
      displayKey: "attributes.firstName",
      items: brandsAdvertiserData,
      valueKey: "id",
      onChange: setSearchAdvertiser,
      isValidating: isValidatingBrandAdvertiser,
      customDisplay: true,
    },
  ];
  useEffect(() => {
    if (!loading && !brands?.some((brand) => brand?.id === selectedBrandId)) {
      setCheckAssociation(true);
    }
    // eslint-disable-next-line
  }, [selectedBrandCategoryId, brands]);

  const onEdit = (e) => {
    e.preventDefault();
    setEditMode(true);
  };
  const onCancel = () => {
    setEditMode(false);
    setCheckAssociation(false);
  };
  const getDisplayValue = (items = [], id) => {
    const obj = _.find(items, ["id", id]);
    return obj?.attributes?.name;
  };

  const getAdvertiser = (id) => {
    return _.find(brandsAdvertiserData || [], (obj) => {
      return obj.id === id;
    });
  };
  const getAdvertisers = (items, advertiserIds) => {
    return advertiserIds.map((item) => {
      return getAdvertiser(item.advertiserId);
    });
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const displayData = {
      ..._.pick(values, ["name", "monetizationType"]),
      brandCategoryId: getDisplayValue(
        brandCategoriesData,
        values?.brandCategoryId
      ),
      advertiserId: getAdvertisers(brandsAdvertiserData, values?.advertiserId),

      associateBrandId: getDisplayValue(brands, values?.associateBrandId),
    };

    const fetchedValues = {
      ..._.pick(values, ["name", "brandCategoryId", "status"]),
      monetizationType: "cpi",
      advertisersAttributes: values?.advertiserId,
      brandId: values.associateBrandId,
    };

    const apiParams = {
      data: { attributes: fetchedValues },
    };

    const endpoint = !campaignId
      ? "/portal_api/v1/campaigns"
      : `/portal_api/v1/campaigns/${campaignId}`;

    const { data, error } = campaignId
      ? await API.put(endpoint, apiParams)
      : await API.post(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went wrong. Please try again"}`
      );
    } else {
      toast.success(`Campaign details saved successfully`);
      setEditMode(false);
      setFormData(displayData);
      setCampaignId(data?.data?.id);
      setFormNumber(
        hasReachedForm(campaignFormNumber.CAMPAIGN_DETAILS_FORM)
          ? formNumber
          : campaignFormNumber.CAMPAIGN_DETAILS_FORM
      );
      setShowCancel(true);
      setCheckAssociation(false);
      setResponseData(data);
    }
  };

  return (
    <div className="rounded-md bg-white px-2 pb-6 border-2 border-gray-300 ">
      <Formik
        initialValues={initialValues}
        validationSchema={campaignDetailsValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <>
            <Form className="flex flex-col justify-between">
              <FormHeader
                sectionHeading="Campaign Details"
                cancelOnClick={onCancel}
                editOnClick={onEdit}
                editMode={editMode}
                isSubmitting={isSubmitting}
                buttonRef={buttonRef}
                showCancel={showCancel}
              />
              <div className="pb-3 px-2">
                <FormFields
                  setFieldValue={setFieldValue}
                  values={values}
                  formValues={formValues}
                  editMode={editMode}
                  formData={formData}
                />
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CampaignDetailsForm;
