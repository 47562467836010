import React, { useState, useRef } from "react";
import CampaignDetailsForm from "./CampaignDetailsForm";
import DateTimeForm from "./Date&TimeForm";
import TargetDemographyForm from "./TargetDemography";
import Button from "../../../stories/Button";
import { Formik, Form } from "formik";
import PostForm from "./PostForm";
import { navigate } from "@reach/router";
import { API } from "../../../lib/network/API";
import { toast } from "../../../components/Toast";
import _ from "lodash";

const CampaignForm = ({ initialValues, editing, step, published = false }) => {
  const buttonRef = useRef(null);
  const [formNumber, setFormNumber] = useState(editing ? step : 0);
  const [campaignId, setCampaignId] = useState(
    editing ? initialValues?.id : ""
  );
  const [responseData, setResponseData] = useState(
    editing ? initialValues : []
  );

  const campaignFormNumber = {
    CAMPAIGN_DETAILS_FORM: 1,
    TARGET_DEMOGRAPHY_FORM: 2,
    CAMPAIGN_POST_FORM: 3,
    CAMPAIGN_DATE_TIME_FORM: 4,
  };

  const hasReachedForm = (form) => {
    return formNumber >= form;
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const apiParams = {
      data: {
        attributes: {
          status: "pending",
        },
      },
    };

    const endpoint = `/portal_api/v1/campaigns/${campaignId}`;

    const { error } = await API.put(endpoint, apiParams);

    if (error) {
      toast.error(
        `${_.head(error.message) ?? "Something went wrong. Please try again"}`
      );
    } else {
      toast.success(`Campaign published successfully`);
      navigate(-1);
    }
  };

  return (
    <>
      <div className="space-y-7">
        <CampaignDetailsForm
          initialValues={initialValues}
          campaignId={campaignId}
          setCampaignId={setCampaignId}
          formNumber={formNumber}
          setFormNumber={setFormNumber}
          editing={editing}
          campaignFormNumber={campaignFormNumber}
          hasReachedForm={hasReachedForm}
          setResponseData={setResponseData}
        />

        {hasReachedForm(campaignFormNumber.CAMPAIGN_DETAILS_FORM) && (
          <TargetDemographyForm
            initialValues={initialValues}
            campaignId={campaignId}
            formNumber={formNumber}
            setFormNumber={setFormNumber}
            editing={editing}
            campaignFormNumber={campaignFormNumber}
            hasReachedForm={hasReachedForm}
          />
        )}
        {hasReachedForm(campaignFormNumber.TARGET_DEMOGRAPHY_FORM) && (
          <PostForm
            initialValues={initialValues}
            campaignId={campaignId}
            formNumber={formNumber}
            setFormNumber={setFormNumber}
            editing={editing}
            published={published}
            responseData={responseData}
            setResponseData={setResponseData}
            campaignFormNumber={campaignFormNumber}
            hasReachedForm={hasReachedForm}
          />
        )}
        {hasReachedForm(campaignFormNumber.CAMPAIGN_POST_FORM) && (
          <DateTimeForm
            initialValues={initialValues}
            campaignId={campaignId}
            setFormNumber={setFormNumber}
            formNumber={formNumber}
            editing={editing}
            campaignFormNumber={campaignFormNumber}
            hasReachedForm={hasReachedForm}
            setResponseData={setResponseData}
            responseData={responseData}
          />
        )}
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="space-y-7 mt-7">
            <div className="flex flex-row-reverse">
              {hasReachedForm(campaignFormNumber.CAMPAIGN_DATE_TIME_FORM) &&
              !published ? (
                <Button
                  buttonStyle="danger"
                  ref={buttonRef}
                  type="submit"
                  loading={isSubmitting}
                  className="w-1/6"
                >
                  Publish
                </Button>
              ) : (
                ""
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CampaignForm;
